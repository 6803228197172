import React, { Fragment, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { Media } from "reactstrap";
import { BASE_URL, BASE_API_URL } from "../../../services/constants";

import "react-toastify/dist/ReactToastify.css";
import { getUniqueId } from "../../../helpers/LocalCart";
import {
  deleteCart,
  getCarts,
  getCartsCount,
  updateCartItem,
} from "../../../slices/headerSlice";
const CartHeader = ({ item }) => {
  let baseUri = BASE_URL;
  const dispatch = useDispatch();
  const [product, setProduct] = useState(item.productId);
  const headerState = useSelector((state) => state.header);
  const shortString = (name) => {
    var string = name;
    var count = 15;
    var result = string.slice(0, count) + (string.length > count ? "..." : "");
    return result;
  };
  const imgPath = (img) => {
    let path = img;

    if (path.indexOf("https://") > -1) {
      return path;
    } else if (path) {
      return BASE_API_URL + "/" + path;
    } else {
      return baseUri + "files/noimg.png";
    }
  };
  useEffect(() => {
    if (headerState.cartItemRemoved) {
      // alert.show("No more stock available !!!");
      // toast.success("Item removed !!!");
      dispatch(updateCartItem());
      if (headerState.user == null) {
        dispatch(getCartsCount(getUniqueId()));
        dispatch(getCarts(getUniqueId()));
      } else {
        dispatch(getCartsCount(headerState.user._id));
        dispatch(getCarts(headerState.user._id));
      }
    }
  }, [headerState.cartItemRemoved]);
  const deleteCartFromList = (cart) => {
    dispatch(deleteCart(cart._id));
  };
  return (
    <Fragment>
      <li>
        <div className="media">
          <Link to={"/product/" + product._id}>
            <a>
              <Media
                alt=""
                className="mr-3"
                src={imgPath(product.imageId[0].path)}
              />
            </a>
          </Link>
          <div className="media-body">
            <Link to={"/product/" + product._id}>
              <a>
                <h4>{shortString(product.name)}</h4>
              </a>
            </Link>
          </div>
        </div>
        <div className="close-circle">
          <i
            className="fa fa-times"
            aria-hidden="true"
            onClick={() => deleteCartFromList(item)}
          ></i>
        </div>
      </li>
    </Fragment>
  );
};

export default CartHeader;
