import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import CheckButton from "react-validation/build/button";
import GooglePlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-google-places-autocomplete";
import {
  updateProfImages,
  updateUs,
  uploadProfileImage,
  resetUploadError,
} from "../../slices/merchantSlice";
import { BASE_URL, BASE_API_URL } from "../../services/constants";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const required = (value) => {
  if (!value) {
    return (
      <div className="alert alert-danger" role="alert">
        This field is required!
      </div>
    );
  }
};

const MerchantProfile = (props) => {
  const merchantState = useSelector((state) => state.merchant);
  const form = useRef();
  const checkBtn = useRef();
  const dispatch = useDispatch();

  const [firstName, setFirstName] = useState(merchantState.user.firstName);
  const [lastName, setLastName] = useState(merchantState.user.lastName);
  const [phone, setPhone] = useState(merchantState.user.phone);
  const [gstno, setGstno] = useState(merchantState.user.gstno || "");
  const [storeName, setStoreName] = useState(
    merchantState.user.storeName || ""
  );
  const [address, setAddress] = useState(merchantState.user.address || "");
  const [lat, setLat] = useState(null);
  const [lng, setLng] = useState(null);
  const [pickupName, setPickupName] = useState(
    merchantState.user.pickupName || ""
  );
  const [pickupAddressLine1, setPickupAddressLine1] = useState(
    merchantState.user.pickupAddressLine1 || ""
  );
  const [pickupAddressLine2, setPickupAddressLine2] = useState(
    merchantState.user.pickupAddressLine2 || ""
  );
  const [pickupPincode, setPickupPincode] = useState(
    merchantState.user.pickupPincode || ""
  );
  const [pickupPhone, setPickupPhone] = useState(
    merchantState.user.pickupPhone || ""
  );

  useEffect(() => {
    // console.log("use effect fired");
    dispatch(updateProfImages(merchantState.user.imageId));
  }, [dispatch]);

  useEffect(() => {
    if (merchantState.imageUploadError) {
      toast.error(merchantState.imageUploadError);
      dispatch(resetUploadError());
    }
  }, [merchantState.imageUploadError]);

  const onFirstNameChange = (e) => {
    setFirstName(e.target.value);
  };

  const onLastNameChange = (e) => {
    setLastName(e.target.value);
  };

  const onPhoneChange = (e) => {
    setPhone(e.target.value);
  };

  const onGstChange = (e) => {
    setGstno(e.target.value);
  };

  const onStoreNameChange = (e) => {
    setStoreName(e.target.value);
  };

  const onPickupNameChange = (e) => {
    setPickupName(e.target.value);
  };

  const onPickupAddressLine1Change = (e) => {
    setPickupAddressLine1(e.target.value);
  };

  const onPickupAddressLine2Change = (e) => {
    setPickupAddressLine2(e.target.value);
  };

  const onPickupPincodeChange = (e) => {
    setPickupPincode(e.target.value);
  };

  const onPickupPhoneChange = (e) => {
    setPickupPhone(e.target.value);
  };

  const handleSave = (e) => {
    e.preventDefault();

    form.current.validateAll();

    if (checkBtn.current.context._errors.length === 0) {
      let userObj = {};
      userObj.firstName = firstName;
      userObj.lastName = lastName;
      userObj.phone = phone;
      userObj.gstno = gstno;
      userObj.storeName = storeName;
      userObj.address = address;
      userObj._id = merchantState.user._id;

      if (merchantState.profileImage) {
        userObj.imageId = merchantState.profileImage._id;
      }

      if (lat != null && lng != null) {
        userObj.loc = { type: "Point", coordinates: [lat, lng] };
      }

      userObj.pickupName = pickupName;
      userObj.pickupAddressLine1 = pickupAddressLine1;
      userObj.pickupAddressLine2 = pickupAddressLine2;
      userObj.pickupPincode = pickupPincode;
      userObj.pickupPhone = pickupPhone;

      dispatch(updateUs(userObj));

      toast.success("profile saved!");
    }
  };

  const handleChange = async (value) => {
    const results = await geocodeByAddress(value.label);
    const latLng = await getLatLng(results[0]);
    setAddress(value.label);
    setLat(latLng.lat);
    setLng(latLng.lng);
  };

  const uploadHandler = (event) => {
    let imageFormData = new FormData();
    imageFormData.append("imageData", event.target.files[0]);

    dispatch(uploadProfileImage(imageFormData));
  };

  let baseUri = BASE_URL;

  const imgPath = (img) => {
    let path = img;

    if (path.indexOf("https://") > -1) {
      return path;
    } else {
      return BASE_API_URL + "/" + path;
    }
  };

  const isPickupAlert = () => {
    let pickupName = merchantState.user.pickupName;
    let pickupAddressLine1 = merchantState.user.pickupAddressLine1;
    let pickupAddressLine2 = merchantState.user.pickupAddressLine2;
    let pickupPincode = merchantState.user.pickupPincode;
    let pickupPhone = merchantState.user.pickupPhone;

    if (
      !pickupName ||
      !pickupAddressLine1 ||
      !pickupAddressLine2 ||
      !pickupPincode ||
      !pickupPhone
    ) {
      return (
        <>
          <span style={{ fontSize: 14, color: "#d90000", fontWeight: "bold" }}>
            Please fill Pick Up infos to make order shipping work correctly!
          </span>
        </>
      );
    } else {
      return <></>;
    }
  };

  return (
    <div className="col-md-12 mt-2 mb-2">
      <div className="container">
        <div className="row">
          <Form onSubmit={handleSave} ref={form} className="merchant-form">
            <div className="col-md-12">
              <div className="row">
                <div className="form-group col-md-12">
                  {merchantState.profileImage != null ? (
                    <div className="ONM-merchant_image">
                      <img
                        alt="<no image>"
                        src={imgPath(merchantState.profileImage.path)}
                      />
                    </div>
                  ) : (
                    <div className="ONM-merchant_image"></div>
                  )}
                  {merchantState.profImageSaving ? (
                    <>
                      <div className="spinner-grow text-muted"></div>
                      <div className="spinner-grow text-muted"></div>
                      <div className="spinner-grow text-muted"></div>
                    </>
                  ) : null}
                  <input
                    type="file"
                    name="file"
                    onChange={(e) => uploadHandler(e)}
                  />
                </div>
                <div className="form-group col-md-6">
                  <label htmlFor="firstName">First Name</label>
                  <Input
                    type="text"
                    className="form-control"
                    name="firstName"
                    value={firstName}
                    onChange={onFirstNameChange}
                    validations={[required]}
                  />
                </div>
                <div className="form-group col-md-6">
                  <label htmlFor="lastName">Last Name</label>
                  <Input
                    type="text"
                    className="form-control"
                    name="lastName"
                    value={lastName}
                    onChange={onLastNameChange}
                    validations={[required]}
                  />
                </div>
                <div className="form-group col-md-6">
                  <label htmlFor="phone">Phone</label>
                  <Input
                    type="text"
                    className="form-control"
                    name="phone"
                    value={phone}
                    onChange={onPhoneChange}
                    validations={[required]}
                  />
                </div>
                <div className="form-group col-md-6">
                  <label htmlFor="gstno">Gst #</label>
                  <Input
                    type="text"
                    className="form-control"
                    name="gstno"
                    value={gstno}
                    validations={[required]}
                    onChange={onGstChange}
                  />
                </div>

                <div className="form-group col-md-6">
                  <label htmlFor="storeName">Store Name</label>
                  <Input
                    type="text"
                    className="form-control"
                    name="storeName"
                    value={storeName}
                    validations={[required]}
                    onChange={onStoreNameChange}
                  />
                </div>

                <div className="form-group col-md-6">
                  <label htmlFor="address">Google Map Location</label>
                  <div>
                    <GooglePlacesAutocomplete
                      apiKey="AIzaSyBhhIhmyHFUgqagBmIVaXO5IYmmkmLhLuM"
                      minLengthAutocomplete="5"
                      selectProps={{
                        address,
                        onChange: handleChange,
                      }}
                    />
                  </div>
                  <p>{address}</p>
                </div>
                <div className="form-group col-md-12">
                  <hr />
                  <h3>Pickup Address (*) {isPickupAlert()}</h3>
                </div>
                <div className="form-group col-md-6">
                  <label htmlFor="pickupName">Pickup Name (*)</label>
                  <Input
                    type="text"
                    className="form-control"
                    name="pickupName"
                    value={pickupName}
                    validations={[required]}
                    onChange={onPickupNameChange}
                  />
                </div>
                <div className="form-group col-md-6">
                  <label htmlFor="pickupAddressLine1">
                    Pickup Address Line 1 (*)
                  </label>
                  <Input
                    type="text"
                    className="form-control"
                    name="pickupAddressLine1"
                    value={pickupAddressLine1}
                    validations={[required]}
                    onChange={onPickupAddressLine1Change}
                  />
                </div>
                <div className="form-group col-md-6">
                  <label htmlFor="pickupAddressLine2">
                    Pickup Address Line 2 (optional)
                  </label>
                  <Input
                    type="text"
                    className="form-control"
                    name="pickupAddressLine2"
                    value={pickupAddressLine2}
                    validations={[required]}
                    onChange={onPickupAddressLine2Change}
                  />
                </div>
                <div className="form-group col-md-6">
                  <label htmlFor="pickupPincode">Pickup Pincode (*)</label>
                  <Input
                    type="text"
                    className="form-control"
                    name="pickupPincode"
                    value={pickupPincode}
                    validations={[required]}
                    onChange={onPickupPincodeChange}
                  />
                </div>
                <div className="form-group col-md-6">
                  <label htmlFor="pickupPhone">Pickup Phone (*)</label>
                  <Input
                    type="text"
                    className="form-control"
                    name="pickupPhone"
                    value={pickupPhone}
                    validations={[required]}
                    onChange={onPickupPhoneChange}
                  />
                </div>
              </div>

              <div className="">
                <button className="btn btn-solid btn-block-xs">
                  <span>Save</span>
                </button>
              </div>
            </div>
            <CheckButton style={{ display: "none" }} ref={checkBtn} />
          </Form>
        </div>
      </div>
    </div>
  );
};

export default MerchantProfile;
