import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { transitions, positions, Provider as AlertProvider } from 'react-alert';
import "bootstrap/dist/css/bootstrap.min.css";
import './index.scss';
import App from "./App";
import Store from "./store";

const options = {
  position: positions.TOP_CENTER,
  timeout: 3000,
  offset: '30px',
  type: 'success',
  transition: transitions.SCALE,
  containerStyle: {
    zIndex: 100
  }  
};



const AlertTemplate = ({ style, options, message, close }) => (
  <div style={style}>
    <div className={options.type === 'success' ? "alert alert-success": "alert alert-danger"}>
       {message}
    </div>    
  </div>
);

ReactDOM.render(
  <Provider store={Store}>
    <BrowserRouter>
      <AlertProvider template={AlertTemplate} {...options}>
        <App />
      </AlertProvider>
    </BrowserRouter>
  </Provider>,
  document.getElementById("root")
);