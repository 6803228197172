import { useDispatch, useSelector } from "react-redux";
import { login } from "../../services/auth.service";
import { updateAdmin } from "../../slices/adminSlice";
import { updateAuth } from "../../slices/authSlice";
import { updateLoggedInUser } from "../../slices/categorySlice";
import { update } from "../../slices/headerSlice";
import { updateLoggedInUserHome } from "../../slices/homeSlice";
import { updateLoggedInUserOffer } from "../../slices/OfferSlice";
import { logout as resetMerchantState } from "../../slices/merchantSlice";
import { logout as resetAdminState } from "../../slices/adminSlice";
import { logout as resetAuthState } from "../../slices/authSlice";
import AdminDash from "./AdminDash";
import AdminLogin from "./adminLogin";
import { useEffect } from "react";
import { Redirect } from "react-router";

const AdminMain = (props) => {
  const adminState = useSelector((state) => state.admin);
  const dispatch = useDispatch();

  const resetState = () => {
    if(adminState.user && adminState.user.roles.includes("ROLE_USER")){
      dispatch(resetAdminState());
      dispatch(resetMerchantState());
    }else if(adminState.user && adminState.user.roles.includes("ROLE_MERCHANT")){
      dispatch(resetAdminState());
      dispatch(resetAuthState());
    }else if(adminState.user && adminState.user.roles.includes("ROLE_ADMIN")){
      dispatch(resetMerchantState());
      dispatch(resetAuthState());
    }
  }
  
  useEffect(() => {
    if (adminState.isLoggedIn) {
      resetState();
    }
  }, [adminState.isLoggedIn]);

  if (!adminState.user || ( adminState.user && ! adminState.user.roles.includes("ROLE_ADMIN") )) {
    return <AdminLogin></AdminLogin>;
  }

  if (
    adminState.user &&
    adminState.user.roles &&
    adminState.user.roles.includes("ROLE_ADMIN")
  ) {
    dispatch(update());
    dispatch(updateLoggedInUser());
    dispatch(updateLoggedInUserHome());
    dispatch(updateLoggedInUserOffer());
    dispatch(updateAuth());

    return <Redirect to="/admin/dashboard" />;
  }

  return <h1>Admin</h1>;
};

export default AdminMain;
