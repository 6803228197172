import { FaStar, FaUser } from "react-icons/fa";
import Pagination from "react-js-pagination";
import { useSelector } from "react-redux";
import classes from "./Table.module.css";

const ReviewList = (props) => {
  const productState = useSelector((state) => state.product);

  return (
    <>
      <div className="row">
        <div className="col-md-6 mt-4">
          {productState.user &&
          productState.user.roles.includes("ROLE_USER") ? (
            <a
              className={`${classes.add__review}`}
              onClick={() => props.openModal()}
            >
              Add Reviews
            </a>
          ) : (
            <em>Please login to submit review</em>
          )}
          {/* <h3>Total Reviews: {props.count}</h3> */}
        </div>
        {props.count > 0 ? (
          <div className="col-md-6 mt-4 text-right">
            <Pagination
              activePage={props.features.page}
              itemsCountPerPage={props.features.ps}
              totalItemsCount={props.count}
              pageRangeDisplayed={5}
              onChange={props.handlePageChange.bind(this)}
              itemClass="page-item"
              linkClass="page-link"
              innerClass="pagination review-pagination float-right"
              prevPageText="Prev"
              nextPageText="Next"
              hideFirstLastPages={true}
            />
          </div>
        ) : null}
      </div>

      {props.count > 0 ? (
        <ul className="ONM_review-card">
          {props.list.map((rev) => {
            return (
              <li className="" key={rev._id}>
                <div className="col-md-12">
                  <FaUser className="mr-2"></FaUser>
                  {rev.userId.firstName} {rev.userId.lastName}
                </div>
                <div className="col-md-12 mt-2">
                  <FaStar
                    className={rev.stars >= 1 ? "OMN-star-active mr-2" : "mr-2"}
                  ></FaStar>
                  <FaStar
                    className={rev.stars >= 2 ? "OMN-star-active mr-2" : "mr-2"}
                  ></FaStar>
                  <FaStar
                    className={rev.stars >= 3 ? "OMN-star-active mr-2" : "mr-2"}
                  ></FaStar>
                  {/* <FaStar
                    className={rev.stars === 4 ? "OMN-star-active" : ""}
                  ></FaStar> */}
                </div>
                <div className="col-md-12 mt-2">{rev.description}</div>
              </li>
            );
          })}
        </ul>
      ) : null}
    </>
  );
};

export default ReviewList;
