import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  getProductsByUser,
  getProductsCountByUser,
} from "../services/product.service";
import { getBanners } from "../services/home.service";
import { getCategories } from "../services/header.service";
import { getBestOffersByMainCatGen } from "../services/category.service";

const user = JSON.parse(localStorage.getItem("user"));

export const getMainCategories = createAsyncThunk(
  "offer/getMainCategories",
  async (params, { getState, rejectWithValue }) => {
    try {
      const response = await getCategories();

      return response.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const getProducts = createAsyncThunk(
  "offer/getProducts",
  async (userId, { getState, rejectWithValue }) => {
    try {
      const state = getState().offer;
      const response = await getProductsByUser(
        userId,
        state.features.catId,
        state.features
      );

      return response.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const getProductsCount = createAsyncThunk(
  "offer/getProductsCount",
  async (userId, { getState, rejectWithValue }) => {
    try {
      const state = getState().offer;
      const response = await getProductsCountByUser(
        userId,
        '',
        state.features.catId,
        state.features
      );

      return response.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const bannerList = createAsyncThunk(
  "offer/bannerList",
  async (page, { getState, rejectWithValue }) => {
    try {
      const response = await getBanners(page);
      return response.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const getOffersByCategory = createAsyncThunk(
  "offer/getOffersByCategory",
  async (params, { getState, rejectWithValue }) => {
    try {
      const state = getState().offer;
      const response = await getBestOffersByMainCatGen(params.name.name, state.features);
      return response.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const getOffersByCategory1 = createAsyncThunk(
  "offer/getOffersByCategory1",
  async (params, { getState, rejectWithValue }) => {
    try {
      const state = getState().offer;
      const response = await getBestOffersByMainCatGen(params.name.name, state.features);
      return response.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const getOffersByCategory2 = createAsyncThunk(
  "offer/getOffersByCategory2",
  async (params, { getState, rejectWithValue }) => {
    try {
      const state = getState().offer;
      const response = await getBestOffersByMainCatGen(params.name.name, state.features);
      return response.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const getOffersByCategory3 = createAsyncThunk(
  "offer/getOffersByCategory3",
  async (params, { getState, rejectWithValue }) => {
    try {
      const state = getState().offer;
      const response = await getBestOffersByMainCatGen(params.name.name, state.features);
      return response.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const getOffersByCategory4 = createAsyncThunk(
  "offer/getOffersByCategory4",
  async (params, { getState, rejectWithValue }) => {
    try {
      const state = getState().offer;
      const response = await getBestOffersByMainCatGen(params.name.name, state.features);
      return response.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

const initialState = {
  list: [],
  bannerList: [],
  mainCat: [],
  top5Cat: null,
  selectedBanner: null,
  row1Banner:null,
  row2Banner:null,
  row3Banner:null,
  row4Banner:null,
  row5Banner:null,
  offers0List: null,
  offers1List: null,
  offers2List: null,
  offers3List: null,
  offers4List: null,
  selected: null,
  user: user,
  loading: false,
  count: 0,
  totalListCount: 0,
  features: { ps: 12, sidx: 0, page: 1, mainCatId: null },
  userLocNotFound: 0,
};

const offerSlice = createSlice({
  name: "offer",
  initialState: initialState,
  reducers: {
    updateOfferFilter: (state, action) => {
      state.features.omin = action.payload.omin;
      state.features.omax = action.payload.omax;
    },
    updatePagination: (state, action) => {
      state.features.page = action.payload;
      state.features.sidx = (state.features.page - 1) * state.features.ps;
    },
    updateMainCatId: (state, action) => {
      state.features.mainCatId = action.payload;
    },
    resetFeatures: (state, action) => {
      state.features.mainCatId = null;
      state.list = null;
      state.features.ps = 12;
      state.features.sidx = 0;
      state.features.page = 1;
      state.features.omin = null;
      state.features.omax = null;
      state.count = 0;
    },
    updateLoggedInUserOffer: (state, action) => {
      state.city = localStorage.getItem("city");
      const user = localStorage.getItem("user");
      if(user != null)
      {
        state.user = JSON.parse(user);
      }
      else
      {
        state.user = null;       
      }
    },
    resetUserLocNotFound: (state, action) => {
      state.userLocNotFound = 0;
    },
  },
  extraReducers: (builder) =>
    builder
      .addCase(getMainCategories.fulfilled, (state, action) => {
        if(action.payload && action.payload.result != null)
        {
          state.mainCat = action.payload.result;

          if(state.mainCat && state.mainCat.length > 0)
          {
            state.top5Cat = {};
            let len = state.mainCat.length > 5 ? 5 : state.mainCat.length;
            
            for(let i = 0; i < len; i++)
            {
              state.top5Cat["CAT"+i] = {'header': state.mainCat[i], 'headerName': 'Best Offers on ' + state.mainCat[i].name};
            }

            // state.selected = state.list[0];
          }
          else
          {
            state.selected = null;
            state.top5Cat = null
          }          
        }
      })
      .addCase(getProducts.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(getProducts.fulfilled, (state, action) => {
        state.loading = false;

        if (action.payload && action.payload.result != null) {
          state.list = action.payload.result;
        } else {
          state.list = null;
        }
      })
      .addCase(getProductsCount.fulfilled, (state, action) => {
        state.loading = false;

        if (action.payload) {
          state.count = action.payload.result;
        } else {
          state.count = 0;
        }
      })
      .addCase(bannerList.fulfilled, (state, action) => {
        if (action.payload) {
          state.bannerList = action.payload.result;
          if (state.bannerList != null && state.bannerList.length > 0) {
            state.bannerList.forEach((banner, index) => {
              if(banner.row === 1)
              {
                state.row1Banner = banner;
              }
              else if(banner.row === 2)
              {
                state.row2Banner = banner;
              }
              else if(banner.row === 3)
              {
                state.row3Banner = banner;
              }
              else if(banner.row === 4)
              {
                state.row4Banner = banner;
              }
              else if(banner.row === 5)
              {
                state.row5Banner = banner;
              }
            });
          } else {
            state.selectedBanner = null;
          }
        }
      })
      .addCase(getOffersByCategory.fulfilled, (state, action) => {
        state.offers0List = null;
        if(action.payload.result && action.payload.result.length > 0)
        {
          state.offers0List = action.payload.result;
          state.userLocNotFound = action.payload.userNotFound;
        }
      })
      .addCase(getOffersByCategory1.fulfilled, (state, action) => {
        state.offers1List = null;
        if(action.payload.result && action.payload.result.length > 0)
        {
          state.offers1List = action.payload.result;
          state.userLocNotFound = action.payload.userNotFound;
        }
      })
      .addCase(getOffersByCategory2.fulfilled, (state, action) => {
        state.offers2List = null;
        if(action.payload.result && action.payload.result.length > 0)
        {
          state.offers2List = action.payload.result;
          state.userLocNotFound = action.payload.userNotFound;
        }
      })
      .addCase(getOffersByCategory3.fulfilled, (state, action) => {
        state.offers3List = null;
        if(action.payload.result && action.payload.result.length > 0)
        {
          state.offers3List = action.payload.result;
          state.userLocNotFound = action.payload.userNotFound;
        }
      })
      .addCase(getOffersByCategory4.fulfilled, (state, action) => {
        state.offers4List = null;
        if(action.payload.result && action.payload.result.length > 0)
        {
          state.offers4List = action.payload.result;
          state.userLocNotFound = action.payload.userNotFound;
        }
      })  
});

export default offerSlice.reducer;
export const {
  updatePagination,
  updateOfferFilter,
  updateMainCatId,
  resetFeatures,
  updateLoggedInUserOffer,
  resetUserLocNotFound
} = offerSlice.actions;
