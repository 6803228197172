import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  getProductsByUser,
  getProductsCountByUser,
} from "../services/product.service";
import { getListByUserId } from "../services/category.service";
import { getBanners } from "../services/home.service";

const user = JSON.parse(localStorage.getItem("user"));

export const getProducts = createAsyncThunk(
  "store/getProducts",
  async (param, { getState, rejectWithValue }) => {
    try {
      const state = getState().store;
      const response = await getProductsByUser(
        param.userId,
        state.features.catId,
        state.features
      );

      return response.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const getProductsCount = createAsyncThunk(
  "store/getProductsCount",
  async (param, { getState, rejectWithValue }) => {
    try {
      const state = getState().store;
      const response = await getProductsCountByUser(
        param.userId,
        '',
        state.features.catId,
        state.features
      );

      return response.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const getCatList = createAsyncThunk(
  "store/getCatList",
  async (userId, { getState, rejectWithValue }) => {
    try {
      const response = await getListByUserId(userId);

      return response.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const bannerList = createAsyncThunk(
  "store/bannerList",
  async (page, { getState, rejectWithValue }) => {
    try {
      const response = await getBanners(page);
      return response.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

const initialState = {
  list: [],
  catList: [],
  bannerList: [],
  selectedBanner: null,
  selected: null,
  user: user,
  loading: false,
  count: 0,
  totalListCount: 0,
  features: { ps: 12, sidx: 0, page: 1, catId: null },
};

const storeSlice = createSlice({
  name: "store",
  initialState: initialState,
  reducers: {
    updatePriceFilter: (state, action) => {
      state.features.pmin = action.payload.pmin;
      state.features.pmax = action.payload.pmax;
    },
    updateColorFilter: (state, action) => {
      state.features.color = action.payload;
    },
    updateOfferFilter: (state, action) => {
      state.features.omin = action.payload.omin;
      state.features.omax = action.payload.omax;
    },
    updatePagination: (state, action) => {
      state.features.page = action.payload;
      state.features.sidx = (state.features.page - 1) * state.features.ps;
    },
    updateCatId: (state, action) => {
      state.features.catId = action.payload;
    },
    resetFeatures: (state, action) => {
      state.features.catId = null;
      state.list = null;
      state.features.ps = 12;
      state.features.sidx = 0;
      state.features.page = 1;
      state.features.omin = null;
      state.features.omax = null;
      state.features.pmin = null;
      state.features.pmax = null;
      state.features.color = null;
    },
    updateLoggedInUser: (state, action) => {
      state.city = localStorage.getItem("city");
      const user = localStorage.getItem("user");
      if(user != null)
      {
        state.user = JSON.parse(user);
      }
      else
      {
        state.user = null;       
      }
    },    
  },
  extraReducers: (builder) =>
    builder
      .addCase(getProducts.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(getProducts.fulfilled, (state, action) => {
        state.loading = false;

        if (action.payload && action.payload.result != null) {
          state.list = action.payload.result;
        } else {
          state.list = null;
        }
      })
      .addCase(getProductsCount.fulfilled, (state, action) => {
        state.loading = false;

        if (action.payload) {
          state.count = action.payload.result;
        } else {
          state.count = 0;
        }
      })
      .addCase(getCatList.fulfilled, (state, action) => {
        if (action.payload && action.payload.result != null) {
          state.catList = action.payload.result;
        }
      })
      .addCase(bannerList.fulfilled, (state, action) => {
        if (action.payload) {
          state.bannerList = action.payload.result;
          if (state.bannerList != null && state.bannerList.length > 0) {
            state.selectedBanner = state.bannerList[0];
          } else {
            state.selectedBanner = null;
          }
        }
      }),
});

export default storeSlice.reducer;
export const {
  updatePriceFilter,
  updateColorFilter,
  updatePagination,
  updateCatId,
  updateOfferFilter,
  resetFeatures,
} = storeSlice.actions;
