import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import classes from "./navbar.module.css";
import { useDispatch, useSelector } from "react-redux";
const SideBar = () => {
  const headerState = useSelector((state) => state.header);

  const items =
    window.innerWidth <= 1190
      ? headerState.allCategories
      : headerState.sideHeaderWithCategories;

  const closeNav = () => {
    var closemyslide = document.getElementById("mySidenav");
    if (closemyslide) closemyslide.classList.remove("open-side");
  };

  const handleSubmenu = (event) => {
    if (event.target.classList.contains("sub-arrow")) {
      console.log("yes");
      return;
    }

    if (event.target.nextElementSibling.classList.contains("opensub1")) {
      event.target.nextElementSibling.classList.remove("opensub1");
    } else {
      document.querySelectorAll(".opensub1").forEach(function (value) {
        value.classList.remove("opensub1");
      });
      event.target.nextElementSibling.classList.add("opensub1");
    }
  };

  const handleSubTwoMenu = (event) => {
    if (event.target.classList.contains("sub-arrow")) return;

    if (event.target.nextElementSibling.classList.contains("opensub2"))
      event.target.nextElementSibling.classList.remove("opensub2");
    else {
      document.querySelectorAll(".opensub2").forEach(function (value) {
        value.classList.remove("opensub2");
      });
      event.target.nextElementSibling.classList.add("opensub2");
    }
  };
  const handleSubThreeMenu = (event) => {
    if (event.target.classList.contains("sub-arrow")) return;

    if (event.target.nextElementSibling.classList.contains("opensub3"))
      event.target.nextElementSibling.classList.remove("opensub3");
    else {
      document.querySelectorAll(".opensub3").forEach(function (value) {
        value.classList.remove("opensub3");
      });
      event.target.nextElementSibling.classList.add("opensub3");
    }
  };

  const handleSubFourMenu = (event) => {
    if (event.target.classList.contains("sub-arrow")) return;

    if (event.target.nextElementSibling.classList.contains("opensub4"))
      event.target.nextElementSibling.classList.remove("opensub4");
    else {
      document.querySelectorAll(".opensub4").forEach(function (value) {
        value.classList.remove("opensub4");
      });
      event.target.nextElementSibling.classList.add("opensub4");
    }
  };

  const handleMegaSubmenu = (event) => {
    if (event.target.classList.contains("sub-arrow")) return;

    if (event.target.nextElementSibling.classList.contains("opensidesubmenu"))
      event.target.nextElementSibling.classList.remove("opensidesubmenu");
    else {
      event.target.nextElementSibling.classList.add("opensidesubmenu");
    }
  };
  const overflowstyle = {
    content: {
      overflow: "hidden",
      overflowY: "scroll",
      height: "400px",
    },
  };
  return (
    <Fragment>
      <div id="mySidenav" className="sidenav">
        <a href={null} className="sidebar-overlay" onClick={closeNav}></a>
        <nav>
          <a href={null} onClick={closeNav}>
            <div className="sidebar-back text-left">
              <i className="fa fa-angle-left pr-2" aria-hidden="true"></i> Back
            </div>
          </a>
          <ul id="sub-menu" className="sidebar-menu">
            {items &&
              items.map((category, i) => {
                return (
                  <li key={i}>
                    <a href="#" onClick={(e) => handleSubmenu(e)}>
                      {category.name}
                      {category.categories.length > 0 && (
                        <span
                          className="sub-arrow"
                          id="arrow"
                          onClick={(e) => handleSubmenu(e)}
                        ></span>
                      )}
                    </a>

                    {category.categories.length > 0 && (
                      <ul>
                        {category.categories.map((subcat, j) => {
                          return (
                            <>
                              <li>
                                <a
                                  href="#"
                                  onClick={(e) => handleSubTwoMenu(e)}
                                >
                                  {subcat.name}
                                  {subcat.subCategories &&
                                    subcat.subCategories.length > 0 && (
                                      <span
                                        className="sub-arrow"
                                        onClick={(e) => handleSubTwoMenu(e)}
                                      ></span>
                                    )}
                                </a>
                                <ul className={classes.overflowstyle}>
                                  {subcat.subCategories.map((subsubcat, k) => {
                                    return (
                                      <li key={k}>
                                        <Link
                                          to={
                                            "/category/" +
                                            subcat.categoryId +
                                            "?subCatId=" +
                                            subsubcat._id
                                          }
                                          onClick={closeNav}
                                        >
                                          {subsubcat.name}
                                        </Link>
                                      </li>
                                    );
                                  })}
                                </ul>
                              </li>
                            </>
                          );
                        })}
                      </ul>
                    )}
                  </li>
                );
              })}
            <li className="">
              <Link className="nav-link" to={"/offer"} onClick={closeNav}>
                {" "}
                Offer's
              </Link>
            </li>
            {/* <li className="">
              <Link
                className="nav-link"
                to={"/privacy-policy"}
                onClick={closeNav}
              >
                {" "}
                Privacy Policy
              </Link>
            </li> */}
            <li className="">
              <Link
                className="nav-link"
                to={"/return-policy"}
                onClick={closeNav}
              >
                {" "}
                Return Policy
              </Link>
            </li>
            <li className="">
              <Link
                className="nav-link"
                to={"/terms-of-use"}
                onClick={closeNav}
              >
                {" "}
                Terms of Use
              </Link>
            </li>
          </ul>
        </nav>
      </div>
    </Fragment>
  );
};

export default SideBar;
