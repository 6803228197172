import { Link } from "react-router-dom";
import { BASE_URL, BASE_API_URL } from "../services/constants";
import StoreCard from "./StoreCard";

const FashionHome = (props) => {
  let baseUri = BASE_URL;

  const imgPath = (img) => {
    let path = img;

    if (path.indexOf("https://") > -1) {
      return path;
    } else {
      return BASE_API_URL + '/' + path;
    }
  };

  const adItem =
    props.banners &&
    props.banners[0] &&
    props.banners[0].storeId &&
    props.banners[0].subCategoryId ? (
      <Link
        className="col-md-3 mb-3"
        key={props.banners[0]._id}
        to={
          "/category/" +
          props.banners[0].mainCategoryId +
          "?subCatId=" +
          props.banners[0].subCategoryId +
          "&uId=" +
          props.banners[0].storeId._id +
          "&childCatId=" +
          props.banners[0].childCategoryId
        }
      >
        <div className="ONM_fashion-ads">
          {props.banners[0].imageId ? (
            <img
              className="card-img-top"
              src={imgPath(props.banners[0].imageId.path)}
              alt="Card"
            />
          ) : null}
        </div>
      </Link>
    ) : (
      props.banners &&
      props.banners[0] && (
        <div className="col-md-3 mb-3" key={props.banners[0]._id}>
          <div className="ONM_fashion-ads">
            {props.banners[0].imageId ? (
              <img
                className="card-img-top"
                src={imgPath(props.banners[0].imageId.path)}
                alt="Card"
              />
            ) : null}
          </div>
        </div>
      )
    );

  const adItem1 =
    props.banners &&
    props.banners[1] &&
    props.banners[1].storeId &&
    props.banners[1].subCategoryId ? (
      <Link
        className="col-md-3 mb-3"
        key={props.banners[1]._id}
        to={
          "/category/" +
          props.banners[1].mainCategoryId +
          "?subCatId=" +
          props.banners[1].subCategoryId +
          "&uId=" +
          props.banners[1].storeId._id +
          "&childCatId=" +
          props.banners[1].childCategoryId
        }
      >
        <div className="ONM_fashion-ads">
          {props.banners[1].imageId ? (
            <img
              className="card-img-top"
              src={imgPath(props.banners[1].imageId.path)}
              alt="Card"
            />
          ) : null}
        </div>
      </Link>
    ) : (
      props.banners &&
      props.banners[1] && (
        <div className="col-md-3 mb-3" key={props.banners[1]._id}>
          <div className="ONM_fashion-ads">
            {props.banners[1].imageId ? (
              <img
                className="card-img-top"
                src={imgPath(props.banners[1].imageId.path)}
                alt="Card"
              />
            ) : null}
          </div>
        </div>
      )
    );

  const platItem =
    props.items && props.items.length > 0
      ? props.items.map((fash) => {
          return (
            <div key={fash._id} className="col-md-3 mb-3">
              <StoreCard item={fash}></StoreCard>
            </div>
          );
        })
      : null;
  return (
    <div className="container mt-3">
      <h2>{props.name}</h2>
      <div className="row">
        {/* {adItem} */}
        {platItem}
        {/* {adItem1} */}
      </div>
    </div>
  );
};

export default FashionHome;
