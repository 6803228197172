import React, { useState, useRef, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Redirect } from "react-router-dom";

import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import CheckButton from "react-validation/build/button";
import { isEmail } from "validator";

import { signin, updateAdmin, verifyUser, updateLoginError, updateOtpFailed } from "../../slices/adminSlice";
import { update } from "../../slices/headerSlice";
import { updateAuth } from "../../slices/authSlice";
import { updateLoggedInUser } from "../../slices/categorySlice";
import { updateLoggedInUserHome } from "../../slices/homeSlice";
import { updateLoggedInUserOffer } from "../../slices/OfferSlice";

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const required = (value) => {
  if (!value) {
    return (
      <div className="alert alert-danger" role="alert">
        This field is required!
      </div>
    );
  }
};

const validEmail = (value) => {
  if (!isEmail(value)) {
    return (
      <div className="alert alert-danger" role="alert">
        This is not a valid email.
      </div>
    );
  }
};

const AdminLogin = (props) => {
  const form = useRef();
  const checkBtn = useRef();
  const verify = useRef();
  const checkVerifyBtn = useRef();

  const [otp, setOtp] = useState("");
  const [email, setEmail] = useState("");

  const adminState = useSelector((state) => state.admin);

  const dispatch = useDispatch();

  const onChangeEmail = (e) => {
    const email = e.target.value;
    setEmail(email);
  };

  const handleLogin = (e) => {
    e.preventDefault();

    form.current.validateAll();

    if (checkBtn.current.context._errors.length === 0) {
      dispatch(signin({ email: email }));
    }
  };

  const onChangeOtp = (e) => {
    const otp = e.target.value;
    setOtp(otp);
  };

  const handleVerification = (e) => {
    e.preventDefault();
    verify.current.validateAll();

    if (checkVerifyBtn.current.context._errors.length === 0) {
      dispatch(verifyUser({ otp: otp, id: adminState.registeredUserId }));
    }
  };

  useEffect(() => {
    if (adminState.loginError) {
      let toastId = toast.error(
        adminState.loginError
      );
      dispatch(updateLoginError());
    }
  }, [adminState.loginError, dispatch]);

  useEffect(() => {
    if (adminState.otpFailed) {
      let toastId = toast.error(
        adminState.otpFailed
      );
      dispatch(updateOtpFailed());
    }
  }, [adminState.otpFailed, dispatch]);

  if (
    // adminState.isLoggedIn &&
    adminState.user &&
    adminState.user.roles &&
    adminState.user.roles.includes("ROLE_ADMIN")
  ) {
    dispatch(update());
    dispatch(updateLoggedInUser());
    dispatch(updateLoggedInUserHome());
    dispatch(updateLoggedInUserOffer());
    dispatch(updateAdmin());
    dispatch(updateAuth());
    return <Redirect to="/admin/dashboard" />;
  }

  return (
    <div className="container-fluid mt-5 mb-2">
      <div className="row d-flex justify-content-center">
        {!adminState.successful && (
          <div className="ONM_login col-md-5">
            <div className="row">
              <div className="col-md-12 align-content-center">
                <h3 className="text-center">Admin Login</h3>
                {/* <p>
                  Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry.
                </p> */}

                <Form onSubmit={handleLogin} ref={form}>
                  <div className="form-group">
                    <label htmlFor="email">Email</label>
                    <Input
                      autoFocus 
                      type="text"
                      className="form-control"
                      name="email"
                      value={email}
                      onChange={onChangeEmail}
                      validations={[required, validEmail]}
                    />
                  </div>

                  <div className="form-group">
                    <button
                      className="btn btn-primary btn-block"
                      disabled={adminState.loading}
                    >
                      {adminState.loading && (
                        <span className="spinner-border spinner-border-sm"></span>
                      )}
                      <span>Login</span>
                    </button>
                  </div>
                  <CheckButton style={{ display: "none" }} ref={checkBtn} />
                </Form>
              </div>
            </div>
          </div>
        )}

        {adminState.successful && (
          <div className="ONM_login_otp col-md-5">
            <div className="col-md-12 align-content-center p-0">
              <div className="form-group mt-2">
                <div className="col-md-12">
                  <Form onSubmit={handleVerification} ref={verify}>
                    
                    <div className="form-group">
                      <span>Otp code sent to your email</span>
                    </div>

                    <div className="form-group">
                      <label htmlFor="otp">Enter Otp</label>
                      <Input
                        type="text"
                        name="otp"
                        value={otp}
                        className="col-md-12"
                        onChange={onChangeOtp}
                        validations={[required]}
                      />
                    </div>

                    <div className="form-group">
                      <button className="btn btn-primary btn-block">
                        Verify
                      </button>
                    </div>

                    <CheckButton
                      style={{ display: "none" }}
                      ref={checkVerifyBtn}
                    />
                  </Form>
                  <br />
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default AdminLogin;
