import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getAllActivities,
  getAllActivitiesCount,
  updateActivitiesPagination,
  tobeDeletedActivity,
  deleteActivityObj,
} from "../../slices/adminSlice";

import { FaTimes, FaEye } from "react-icons/fa";
import Pagination from "react-js-pagination";

import Modal from "react-modal";

const MerchantActivities = (props) => {
  const adminState = useSelector((state) => state.admin);
  const dispatch = useDispatch();
  const [activityObj, setActivity] = useState(null);

  const [deleteActivityId, setDeleteActivity] = useState(null);

  useEffect(() => {
    dispatch(getAllActivities());
    dispatch(getAllActivitiesCount());
  }, [dispatch]);

  const handlePageChange = (pageNumber) => {
    dispatch(updateActivitiesPagination(pageNumber));
    dispatch(getAllActivities());
  };
  
  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      zIndex: "999",
      maxWidth: "90%",
      maxHeight: "90%",
    },
  };

  Modal.setAppElement("#root");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isUserModalOpen, setIsUserModalOpen] = useState(false);

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const openUserModal = (obj) => {
    setActivity(obj);
    setIsUserModalOpen(true);
  };

  const closeUserModal = () => {
    setIsUserModalOpen(false);
  };


  const deleteActivity = (id) => {
    openModal();
    setDeleteActivity(id);
    dispatch(tobeDeletedActivity(id));
  };

  const deleteAndCloseModal = () => {
    dispatch(deleteActivityObj(deleteActivityId));
    setDeleteActivity(null);
    closeModal();
  };

  const dateFormat = (date) => {

    let options = { /* weekday: 'long', */ year: 'numeric', month: 'long', day: 'numeric' };
    return new Date( date ).toLocaleDateString("en-US", options);
  }

  const renderProductDetails = (fields) => {
    
    const render = Object.keys(fields).map((field, key) => {
      return (
        <div className="col-md-12" key={key}>
          {typeof fields[field] === 'object' ?
           <>
            {field == 'userId' && (
              <>
              {/* <div className="row">
                <div className="col-md-12">
                  <label><strong>Owner:</strong> {fields.userId.firstName + ' ' +fields.userId.lastName}</label>
                </div>
                <div className="col-md-12">
                  <label><strong>Email:</strong> {fields.userId.email}</label>
                </div>
              </div> */}
              </>
            ) }

            {field == 'mainCategoryId' && fields[field]!=null && (
              <label><strong>Main Category:</strong> {fields[field].name}</label>
            )}

            {field == 'categoryId' && fields[field]!=null && (
              <label><strong>Category:</strong> {fields[field].name}</label>
            )}

            {field == 'subCategoryId' && fields[field]!=null && (
              <label><strong>Sub Category:</strong> {fields[field].name}</label>
            )}

            {field=='childCategoryId' && fields[field]!=null && (
              <label><strong>Child Category:</strong> {fields[field].name}</label>
            )}

            {field == 'imageId' && fields[field]!=null && (
              <>
              <label><strong>Image:</strong> {fields.imageId.map((image, i) => (
                <>
                  {(i+1)==fields.imageId.length ? (
                    <>{image.path}</>
                  ):(
                    <>{image.path}, </>
                  )}
                   
                </>
              ))}
              </label>
              </>
            )}

            {field!='userId' && field!='mainCategoryId' && field!='categoryId' && field!='subCategoryId' && field!='childCategoryId' && field!='imageId' && <label><strong>{field}:</strong> {JSON.stringify(fields[field])}</label>}
            {/* <label><strong>{field}:</strong> {JSON.stringify(fields[field])}</label> */}
            </>
            :
              <>
                {field=='weight' && fields[field]!=null && (
                  <label><strong>weight:</strong> {fields[field]} Kg</label>
                )}

                {field=='length' && fields[field]!=null && (
                  <label><strong>length:</strong> {fields[field]} Cm</label>
                )}

                {field=='height' && fields[field]!=null && (
                  <label><strong>height:</strong> {fields[field]} Cm</label>
                )}

                {field=='price' && fields[field]!=null && (
                  <label><strong>price:</strong> Rs. {parseInt(fields[field]).toFixed(2)}</label>
                )}



                {field!='_id' && field!='__v'&& field!='weight' && field!='length' && field!='height' && field!='price' && <label><strong>{field}:</strong> {fields[field]}</label>}
              </>
            }
        </div>
      )
    })

    return render;
  }

  return (
    <div className="col-md-12">
      <Modal isOpen={isModalOpen} style={customStyles}>
        <FaTimes
          onClick={() => closeModal()}
          className="float-right mb-2"
        ></FaTimes>
        <div className="col-md-12">
          <div className="row">
            <div className="col-md-12">
              Are you sure you want to delete the activity ?
            </div>
            <div className="col-md-12 mt-2">
              <button
                className="btn btn-danger ml-2 float-right"
                onClick={() => closeModal()}
              >
                No
              </button>
              <button
                className="btn btn-primary float-right"
                onClick={() => deleteAndCloseModal()}
              >
                Yes
              </button>
            </div>
          </div>
        </div>
      </Modal>

      <Modal isOpen={isUserModalOpen} style={customStyles}>
        <div className="col-md-12">
          <div className="row">

            {activityObj && (
                <>
                <div className="col-md-12 p-0 ONM_modal_header">
                    <h3>Activity Details</h3>
                </div>
                <div className="col-md-12">
                    <label><strong>Title:</strong> {activityObj.title}</label>
                </div>
                <div className="col-md-6">
                  <label><strong>Owner:</strong> {activityObj.userId && activityObj.userId.firstName + ' ' +activityObj.userId.lastName}</label>
                </div>
                <div className="col-md-6">
                  <label><strong>Email:</strong> {activityObj.userId && activityObj.userId.email}</label>
                </div>
                <div className="col-md-6">
                    <label><strong>Type:</strong> {activityObj.type}</label>
                </div>
                <div className="col-md-6">
                    <label><strong>Date:</strong> {dateFormat(activityObj.createdAt)}</label>
                </div>
                <div className="col-md-12">
                    <hr />
                    <div className="row">
                    {activityObj.fields!=null && Object.keys(activityObj.fields).length > 0 ? renderProductDetails(activityObj.fields) : null}
                    </div>
                </div>
                <div className="col-md-12 mt-2">
                    <button
                    className="btn btn-dark ml-2 float-right"
                    onClick={() => closeUserModal()}
                    >
                    Close
                    </button>                  
                </div>
                </>
            )}
          </div>
        </div>
      </Modal>

     
        <div className="row">
            <div className="col-md-12">
            <div className="row">
                <div className="col-md-6">
                </div>
                <div className="col-md-6 text-right">
                <Pagination
                    activePage={adminState.activitiesFeatures.page}
                    itemsCountPerPage={adminState.activitiesFeatures.ps}
                    totalItemsCount={adminState.activitiesCount}
                    pageRangeDisplayed={5}
                    onChange={handlePageChange.bind(this)}
                    itemClass="page-item"
                    linkClass="page-link"
                    innerClass="pagination ml-auto mr-auto"
                    prevPageText="Prev"
                    nextPageText="Next"
                    hideFirstLastPages={true}
                />
                </div>
            </div>
            </div>
            <div className="table-responsive mt-2">
            <table className="table">
                <thead>
                <tr>
                    <th>Activity</th>
                    <th>User</th>
                    <th>Type</th>
                    <th>Date</th>
                    <th>Action</th>
                </tr>
                </thead>
                <tbody>
                {adminState.activitiesList != null &&
                adminState.activitiesList.length > 0
                    ? adminState.activitiesList.map((activity) => {
                        return (
                        <tr key={activity._id}>
                            <td>{activity.title}</td>
                            <td>{activity.userId && activity.userId.firstName+ ' '+activity.userId.lastName}</td>
                            <td>{activity.type}</td>
                            <td>{dateFormat(activity.createdAt)}</td>
                            <td>
                            <FaEye
                                className="ONM_icons"
                                data-tip="View Fields"
                                onClick={() => openUserModal(activity)}
                            ></FaEye>
                            {/* <FaTrash
                                onClick={() => deleteActivityId(activity._id)}
                                className="ONM_icons"
                            ></FaTrash> */}
                            </td>
                        </tr>
                        );
                    })
                    : null}
                </tbody>
            </table>
            </div>
        </div>

    </div>
  );
};

export default MerchantActivities;
