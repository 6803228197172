import React from "react";
import { Table } from "reactstrap";
import classes from "./Table.module.css";
const TableSpecification = ({ details }) => {
  return (
    <>
      <Table
        striped
        style={{ marginTop: "20px" }}
        className={classes.table__spec}
      >
        <tbody>
          {details.skuNumber ? (
            <tr>
              <td> SKU</td>
              <td>:</td>
              <td>{details.skuNumber}</td>
            </tr>
          ) : null}

          {details.brand ? (
            <tr>
              <td> Brands</td>
              <td>:</td>
              <td>{details.brand.name}</td>
            </tr>
          ) : null}
          {details.weight ? (
            <tr>
              <td> Weight</td>
              <td>:</td>
              <td>{details.weight} Kg</td>
            </tr>
          ) : null}
          {details.length ? (
            <tr>
              <td> Length</td>
              <td>:</td>
              <td>{details.length} Cm</td>
            </tr>
          ) : null}
          {details.height ? (
            <tr>
              <td> Height</td>
              <td>:</td>
              <td>{details.height} Cm</td>
            </tr>
          ) : null}
          {details.color ? (
            <tr>
              <td> Color</td>
              <td>:</td>
              <td> {details.color}</td>
            </tr>
          ) : null}
          {details.caseCover ? (
            <tr>
              <td> Case Cover</td>
              <td>:</td>
              <td> {details.caseCover}</td>
            </tr>
          ) : null}

          {details.caseCoverCaseMaterialType ? (
            <tr>
              <td>Material Type</td>
              <td>:</td>
              <td> {details.caseCoverCaseMaterialType}</td>
            </tr>
          ) : null}

          {details.caseFeature ? (
            <tr>
              <td>Case Feature</td>
              <td>:</td>
              <td> {details.caseFeature}</td>
            </tr>
          ) : null}

          {details.screenProtector ? (
            <tr>
              <td>Screen Protector</td>
              <td>:</td>
              <td> {details.screenProtector}</td>
            </tr>
          ) : null}

          {details.batteryType ? (
            <tr>
              <td>Battery Type</td>
              <td>:</td>
              <td> {details.batteryType}</td>
            </tr>
          ) : null}

          {details.tvPlatForms ? (
            <tr>
              <td> TV Platforms</td>
              <td>:</td>
              <td> {details.tvPlatForms.value}</td>
            </tr>
          ) : null}

          {details.tvDisplaytech ? (
            <tr>
              <td> TV Display Tech</td>
              <td>:</td>
              <td> {details.tvDisplaytech.value}</td>
            </tr>
          ) : null}

          {details.tvHdFormats ? (
            <tr>
              <td> TV HD Formats</td>
              <td>:</td>
              <td> {details.tvHdFormats.value}</td>
            </tr>
          ) : null}

          {details.tvModelYears ? (
            <tr>
              <td> TV Model Years</td>
              <td>:</td>
              <td> {details.tvModelYears.value}</td>
            </tr>
          ) : null}

          {details.tvConnectivity ? (
            <tr>
              <td> TV Connectivity</td>
              <td>:</td>
              <td> {details.tvConnectivity.value}</td>
            </tr>
          ) : null}

          {details.cpuType ? (
            <tr>
              <td> CPU Type</td>
              <td>:</td>
              <td> {details.cpuType}</td>
            </tr>
          ) : null}

          {details.usage ? (
            <tr>
              <td>Usage</td>
              <td>:</td>
              <td> {details.usage}</td>
            </tr>
          ) : null}

          {details.operatingSystem ? (
            <tr>
              <td>Operating System</td>
              <td>:</td>
              <td> {details.operatingSystem}</td>
            </tr>
          ) : null}

          {details.memorySize ? (
            <tr>
              <td> Memory Size</td>
              <td>:</td>
              <td> {details.memorySize}</td>
            </tr>
          ) : null}
        </tbody>
      </Table>
    </>
  );
};

export default TableSpecification;
