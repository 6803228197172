import React, { useState, useRef, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import Select from "react-validation/build/select";
import CheckButton from "react-validation/build/button";
import { getProfile, setAddressLoaded } from "../../slices/authSlice";
import SelectAutocomplete from "react-select";

const ShippingForm = (props) => {
  const authState = useSelector((state) => state.auth);
  const [getShipping, setShipping] = useState({});
  const [profileLoaded, setProfileLoaded] = useState(0);
  const [deliveryAddresses, setDeliveryAddresses] = useState([]);
  const [defaultDeliveryAddress, setDefaultDeliveryAddress] = useState("");
  const form = useRef();
  const checkBtn = useRef();
  const dispatch = useDispatch();

  useEffect(() => {
    if (!profileLoaded && authState.user != null) {
      dispatch(getProfile(authState.user._id));
      setProfileLoaded(1);
    }
  }, [profileLoaded, authState.user]);

  useEffect(() => {
    if (authState.user != null) {
      if (authState.user.deliveryAddress) {
        setDeliveryAddresses(authState.user.deliveryAddress);
      }

      if (
        profileLoaded &&
        authState.user.defaultDeliveryAddress &&
        defaultDeliveryAddress
      ) {
        let addressId = authState.user.defaultDeliveryAddress;

        setDeliveryAddress(addressId, defaultDeliveryAddress);
        dispatch(setAddressLoaded(true));
      }
    }
  }, [authState.user, dispatch]);

  useEffect(() => {
    if (authState.user != null) {
      if (authState.user.defaultDeliveryAddress) {
        setDefaultDeliveryAddress(authState.user.defaultDeliveryAddress);
      }
    }
  }, [deliveryAddresses, authState.user.defaultDeliveryAddress]);

  const stateOptions = [
    {
      label: "Andaman and Nicobar Islands",
      value: "Andaman and Nicobar Islands",
    },
    { label: "Andhra Pradesh", value: "Andhra Pradesh" },
    { label: "Arunachal Pradesh", value: "Arunachal Pradesh" },
    { label: "Assam", value: "Assam" },
    { label: "Bihar", value: "Bihar" },
    { label: "Chandigarh", value: "Chandigarh" },
    { label: "Chhattisgarh", value: "Chhattisgarh" },
    {
      label: "Dadra & Nagar Haveli and Daman & Diu",
      value: "Dadra & Nagar Haveli and Daman & Diu",
    },
    { label: "Delhi", value: "Delhi" },
    { label: "Goa", value: "Goa" },
    { label: "Gujarat", value: "Gujarat" },
    { label: "Haryana", value: "Haryana" },
    { label: "Himachal Pradesh", value: "Himachal Pradesh" },
    { label: "Jammu and Kashmir", value: "Jammu and Kashmir" },
    { label: "Jharkhand", value: "Jharkhand" },
    { label: "Karnataka", value: "Karnataka" },
    { label: "Kerala", value: "Kerala" },
    { label: "Ladakh", value: "Ladakh" },
    { label: "Lakshadweep", value: "Lakshadweep" },
    { label: "Madhya Pradesh", value: "Madhya Pradesh" },
    { label: "Maharashtra", value: "Maharashtra" },
    { label: "Manipur", value: "Manipur" },
    { label: "Meghalaya", value: "Meghalaya" },
    { label: "Mizoram", value: "Mizoram" },
    { label: "Nagaland", value: "Nagaland" },
    { label: "Odisha", value: "Odisha" },
    { label: "Puducherry", value: "Puducherry" },
    { label: "Punjab", value: "Punjab" },
    { label: "Rajasthan", value: "Rajasthan" },
    { label: "Sikkim", value: "Sikkim" },
    { label: "Tamil Nadu", value: "Tamil Nadu" },
    { label: "Telangana", value: "Telangana" },
    { label: "Tripura", value: "Tripura" },
    { label: "Uttar Pradesh", value: "Uttar Pradesh" },
    { label: "Uttarakhand", value: "Uttarakhand" },
    { label: "West Bengal", value: "West Bengal" },
  ];

  const setShippingField = (e) => {
    let name = e.target.name;
    let value = e.target.value;
    let shipping = getShipping;
    setShipping({ ...shipping, [name]: value });
    props.setDeliveryAddress({ ...shipping, [name]: value });
    // console.log(props.deliveryAddress)

    props.validateForm();
  };

  const setStateField = (opt) => {
    // console.log(opt);
    let name = "state";
    let value = opt.value;
    let shipping = getShipping;
    setShipping({ ...shipping, [name]: value });
    props.setDeliveryAddress({ ...shipping, [name]: value });
    // console.log(props.deliveryAddress)

    props.validateForm();
  };

  const stateValue = (state) => {
    let index = stateOptions.findIndex((option) => option.value == state);
    // console.log(state);
    // console.log(index);
    if (index > 0) {
      return stateOptions[index].value;
    } else {
      return "";
    }
  };
  const required = (value) => {
    if (!value) {
      return (
        <div className="alert alert-danger" role="alert">
          This field is required!
        </div>
      );
    }
  };

  const formatAddress = (address) => {
    let name = address.firstName + " " + address.lastName;
    let company = address.company;
    let address1 = address.address;
    let address2 = address.address2;
    let phone = address.phone;
    let state = address.state;
    let city = address.city;
    let country = address.country;
    let postalCode = address.postalCode;

    let printCompany = company ? `(${company}) ` : "";
    let printAdress2 = address2 ? `, ${address2}` : "";

    return `${name} - ${printCompany}${address1}${printAdress2}, ${city}, ${state}, ${country} ${postalCode}`;
    // return address1;
  };

  const deliveryAddressChangeHandler = (e) => {
    let name = e.target.name;
    let addressId = e.target.value;

    setDeliveryAddress(addressId, addressId);
  };

  const setDeliveryAddress = (addressId, defaultDeliveryAddress) => {
    let addIndex = -1;

    let addressExists = deliveryAddresses.some((address, index) => {
      if (address._id == addressId) {
        addIndex = index;
        return true;
      }
      return false;
    });

    let address = addressExists ? deliveryAddresses[addIndex] : {};

    if (addressId) {
      let args = {
        id: defaultDeliveryAddress,
        firstName: address.firstName,
        lastName: address.lastName,
        company: address.company,
        address: address.address,
        address2: address.address2,
        city: address.city,
        state: address.state,
        country: address.country,
        postalCode: address.postalCode,
        phone: address.phone,
      };

      setShipping(args);
      props.setDeliveryAddress(args);
    } else {
      let args = {
        id: "",
        firstName: "",
        lastName: "",
        company: "",
        address: "",
        address2: "",
        city: "",
        state: "",
        country: "",
        postalCode: "",
        phone: "",
      };

      setShipping(args);
      props.setDeliveryAddress(args);
    }
  };

  const isSelected = (addressId) => {
    // console.log(addressId  +' == ' +defaultDeliveryAddress)
    return addressId == defaultDeliveryAddress ? "selected" : "";
  };

  useEffect(() => {
    props.setForm(form);
    props.setCheckBtn(checkBtn);
  });

  const menuStyle = {
    borderRadius: "3px",
    boxShadow: "0 2px 12px rgba(0, 0, 0, 0.1)",
    background: "rgba(255, 255, 255, 0.9)",
    padding: "2px 0",
    fontSize: "90%",
    position: "fixed",
    overflow: "auto",
    maxHeight: "50%", // TODO: don't cheat, let it flow to the bottom
    zIndex: 100,
  };

  return (
    <Form className="shipping-form theme-form" ref={form}>
      <div className="col-md-12">
        <div className="row">
          <h3 className="col-md-12">Shipping Address</h3>

          <div className="form-group col-md-12">
            <label htmlFor="firstName">Select Address</label>
            <Select
              className="form-control"
              name="id"
              onChange={deliveryAddressChangeHandler}
              value={getShipping.id}
            >
              {deliveryAddresses != null &&
                deliveryAddresses.map((address) => (
                  <option key={address._id} value={address._id}>
                    {formatAddress(address)}
                  </option>
                ))}
              <option value="">Create new address</option>
            </Select>
          </div>

          <div className="form-group col-md-6">
            <label htmlFor="firstName">First Name</label>
            <Input
              type="text"
              className="form-control"
              name="firstName"
              value={getShipping.firstName}
              onChange={setShippingField}
              validations={[required]}
            />
          </div>

          <div className="form-group col-md-6">
            <label htmlFor="lastName">Last Name</label>
            <Input
              type="text"
              className="form-control"
              name="lastName"
              value={getShipping.lastName}
              onChange={setShippingField}
              validations={[required]}
            />
          </div>

          <div className="form-group col-md-12">
            <label htmlFor="lastName">Company (optional)</label>
            <Input
              type="text"
              className="form-control"
              name="company"
              value={getShipping.company}
              onChange={setShippingField}
            />
          </div>

          <div className="form-group col-md-12">
            <label htmlFor="address">Address</label>
            <Input
              type="text"
              className="form-control"
              name="address"
              value={getShipping.address}
              onChange={setShippingField}
              validations={[required]}
            />
          </div>

          <div className="form-group col-md-12">
            <label htmlFor="address2">Apartment, suite, etc. (optional)</label>
            <Input
              type="text"
              className="form-control"
              name="address2"
              value={getShipping.address2}
              onChange={setShippingField}
            />
          </div>

          <div className="form-group col-md-12">
            <label htmlFor="phone">Phone</label>
            <Input
              type="text"
              className="form-control"
              name="phone"
              value={getShipping.phone}
              onChange={setShippingField}
              validations={[required]}
            />
          </div>

          {/* <div className="form-group col-md-12">
                  <label htmlFor="state">State</label>
                  <Input
                    type="text"
                    className="form-control"
                    name="state"
                    value={getShipping.state}
                    onChange={setShippingField}
                    validations={[required]}
                  />
                </div> */}

          <div className="col-md-12 mb-4">
            <label htmlFor="state">State</label>
            <SelectAutocomplete
              name="state"
              options={stateOptions}
              onChange={setStateField}
              validations={[required]}
              value={[{ label: getShipping.state, value: getShipping.state }]}
              inputProps={{ style: menuStyle }}
              className="auto"
            />
          </div>

          {/* <div className="form-group col-md-12">
                    <label htmlFor="country">Country</label>
                    <Input
                        type="text"
                        className="form-control"
                        name="country"
                        value={getShipping.country}
                        onChange={setShippingField}
                        validations={[required]}
                    />
                </div> */}

          <div className="form-group col-md-12">
            <label htmlFor="city">City</label>
            <Input
              type="text"
              className="form-control"
              name="city"
              value={getShipping.city}
              onChange={setShippingField}
              validations={[required]}
            />
          </div>

          <div className="form-group col-md-12">
            <label htmlFor="postalCode">Postal Code</label>
            <Input
              type="number"
              className="form-control"
              name="postalCode"
              value={getShipping.postalCode}
              onChange={setShippingField}
              validations={[required]}
            />
          </div>
        </div>
      </div>
      <Input type="hidden" name="country" value="India" />
      <CheckButton style={{ display: "none" }} ref={checkBtn} />
    </Form>
  );
};

export default ShippingForm;
