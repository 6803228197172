import axios from "axios";
import authHeader from "./auth-header";
import { BASE_API_URL } from "./constants";

const API_URL = BASE_API_URL + "/api/product/";

export const getRecents = () => {
  let url = API_URL + "recentlyViewed";
  return axios.get(url);
};

export const getProductsBySubcategory = (subCatId, features) => {
  let url = API_URL + "list";
  if (subCatId) {
    url = url + "?subCatId=" + subCatId;
  }

  url = url + "&ps=" + features.ps + "&sidx=" + features.sidx;

  if (features.pmax) {
    url = url + "&filter=PMIN:" + features.pmin + ";PMAX:" + features.pmax;
  }

  return axios.get(url);
};

export const getProductsCountBySubcategory = (subCatId, features) => {
  let url = API_URL + "count";
  if (subCatId) {
    url = url + "?subCatId=" + subCatId;
  }

  if (features.pmax) {
    url = url + "&filter=PMIN:" + features.pmin + ";PMAX:" + features.pmax;
  }

  return axios.get(url);
};

export const getProductsById = (id) => {
  let url = API_URL + "fetchById";
  if (id) {
    url = url + "?id=" + id;
  }

  return axios.get(url);
};

export const getProductsByUser = (userId, catId, features) => {
  let url = API_URL + "getListByUserId";
  if (userId) {
    url = url + "?userId=" + userId;
  }

  if (catId) {
    if (url.indexOf("?") > -1) {
      url = url + "&catId=" + catId;
    } else {
      url = url + "?catId=" + catId;
    }
  }

  let filter = null;

  if (features && features.omax) {
    filter = "OMIN:" + features.omin + ";OMAX:" + features.omax;
  }

  if (features && features.mainCatId) {
    if (url.indexOf("?") > -1) {
      url = url + "&mainCatId=" + features.mainCatId;
    } else {
      url = url + "?mainCatId=" + features.mainCatId;
    }
  }

  if (features && features.subCatId) {
    if (url.indexOf("?") > -1) {
      url = url + "&subCatId=" + features.subCatId;
    } else {
      url = url + "?subCatId=" + features.subCatId;
    }
  }

  if (features && features.childCatId) {
    if (url.indexOf("?") > -1) {
      url = url + "&childCatId=" + features.childCatId;
    } else {
      url = url + "?childCatId=" + features.childCatId;
    }
  }

  if (features && (features.pmax || features.pmin)) {
    if (filter != null) {
      filter = filter + ";PMIN:" + features.pmin;

      if (features.pmax) {
        filter = filter + ";PMAX:" + features.pmax;
      }
    } else {
      filter = "PMIN:" + features.pmin;

      if (features.pmax) {
        filter = filter + ";PMAX:" + features.pmax;
      }
    }
  }

  if (features.color) {
    if (filter != null) {
      filter = filter + ";COL:" + features.color;
    } else {
      filter = "COL:" + features.color;
    }
  }

  if (features.caseCover) {
    if (filter != null) {
      filter = filter + ";CCO:" + features.caseCover;
    } else {
      filter = "CCO:" + features.caseCover;
    }
  }

  if (features.caseCoverMatType) {
    if (filter != null) {
      filter = filter + ";CCCMT:" + features.caseCoverMatType;
    } else {
      filter = "CCCMT:" + features.caseCoverMatType;
    }
  }

  if (features.caseFeature) {
    if (filter != null) {
      filter = filter + ";CAFEA:" + features.caseFeature;
    } else {
      filter = "CAFEA:" + features.caseFeature;
    }
  }

  if (features.screenProtector) {
    if (filter != null) {
      filter = filter + ";SCPR:" + features.screenProtector;
    } else {
      filter = "SCPR:" + features.screenProtector;
    }
  }

  if (features.batteryType) {
    if (filter != null) {
      filter = filter + ";SCPBATTYPR:" + features.batteryType;
    } else {
      filter = "BATTYP:" + features.batteryType;
    }
  }

  if (features.tvPlatforms) {
    if (filter != null) {
      filter = filter + ";TVPLAT:" + features.tvPlatforms;
    } else {
      filter = "TVPLAT:" + features.tvPlatforms;
    }
  }

  if (features.tvHdFormats) {
    if (filter != null) {
      filter = filter + ";TVHDFO:" + features.tvHdFormats;
    } else {
      filter = "TVHDFO:" + features.tvHdFormats;
    }
  }

  if (features.tvModelYears) {
    if (filter != null) {
      filter = filter + ";TVMODE:" + features.tvModelYears;
    } else {
      filter = "TVMODE:" + features.tvModelYears;
    }
  }

  if (features.tvConnectivity) {
    if (filter != null) {
      filter = filter + ";TVCONN:" + features.tvConnectivity;
    } else {
      filter = "TVCONN:" + features.tvConnectivity;
    }
  }

  if (features.cpuType) {
    if (filter != null) {
      filter = filter + ";CPUTYP:" + features.cpuType;
    } else {
      filter = "CPUTYP:" + features.cpuType;
    }
  }

  if (features.usage) {
    if (filter != null) {
      filter = filter + ";USAG:" + features.usage;
    } else {
      filter = "USAG:" + features.usage;
    }
  }

  if (features.os) {
    if (filter != null) {
      filter = filter + ";OS:" + features.os;
    } else {
      filter = "OS:" + features.os;
    }
  }

  if (features.memorySize) {
    if (filter != null) {
      filter = filter + ";MEMSIZ:" + features.memorySize;
    } else {
      filter = "MEMSIZ:" + features.memorySize;
    }
  }

  if (features.brand) {
    if (filter != null) {
      filter = filter + ";BRND:" + features.brand;
    } else {
      filter = "BRND:" + features.brand;
    }
  }

  if (filter != null) {
    url = url + "&filter=" + encodeURIComponent(filter);
  }

  if (url.indexOf("?") > -1) {
    url = url + "&ps=" + features.ps + "&sidx=" + features.sidx;
  } else {
    url = url + "?ps=" + features.ps + "&sidx=" + features.sidx;
  }

  return axios.get(url);
};

export const getProductsCountByUser = (userId, keyword, catId, features) => {
  let url = API_URL + "getCountByUserId";

  if (userId) {
    url = url + "?userId=" + userId;
  } else {
    url = url + "?userId=";
  }

  if (keyword) {
    url = url + "&keyword=" + keyword;
  }

  if (catId) {
    if (url.indexOf("?") > -1) {
      url = url + "&catId=" + catId;
    } else {
      url = url + "?catId=" + catId;
    }
  }

  let filter = null;

  if (features && features.omax) {
    filter = "OMIN:" + features.omin + ";OMAX:" + features.omax;
  }

  if (features && features.mainCatId) {
    if (url.indexOf("?") > -1) {
      url = url + "&mainCatId=" + features.mainCatId;
    } else {
      url = url + "?mainCatId=" + features.mainCatId;
    }
  }

  if (features && features.subCatId) {
    if (url.indexOf("?") > -1) {
      url = url + "&subCatId=" + features.subCatId;
    } else {
      url = url + "?subCatId=" + features.subCatId;
    }
  }

  if (features && features.childCatId) {
    if (url.indexOf("?") > -1) {
      url = url + "&childCatId=" + features.childCatId;
    } else {
      url = url + "?childCatId=" + features.childCatId;
    }
  }

  if (features && (features.pmax || features.pmin)) {
    if (filter != null) {
      filter = filter + ";PMIN:" + features.pmin;

      if (features.pmax) {
        filter = filter + ";PMAX:" + features.pmax;
      }
    } else {
      filter = "PMIN:" + features.pmin;

      if (features.pmax) {
        filter = filter + ";PMAX:" + features.pmax;
      }
    }
  }

  if (features.color) {
    if (filter != null) {
      filter = filter + ";COL:" + features.color;
    } else {
      filter = "COL:" + features.color;
    }
  }

  if (features.caseCover) {
    if (filter != null) {
      filter = filter + ";CCO:" + features.caseCover;
    } else {
      filter = "CCO:" + features.caseCover;
    }
  }

  if (features.caseCoverMatType) {
    if (filter != null) {
      filter = filter + ";CCCMT:" + features.caseCoverMatType;
    } else {
      filter = "CCCMT:" + features.caseCoverMatType;
    }
  }

  if (features.caseFeature) {
    if (filter != null) {
      filter = filter + ";CAFEA:" + features.caseFeature;
    } else {
      filter = "CAFEA:" + features.caseFeature;
    }
  }

  if (features.screenProtector) {
    if (filter != null) {
      filter = filter + ";SCPR:" + features.screenProtector;
    } else {
      filter = "SCPR:" + features.screenProtector;
    }
  }

  if (features.batteryType) {
    if (filter != null) {
      filter = filter + ";SCPBATTYPR:" + features.batteryType;
    } else {
      filter = "BATTYP:" + features.batteryType;
    }
  }

  if (features.tvPlatforms) {
    if (filter != null) {
      filter = filter + ";TVPLAT:" + features.tvPlatforms;
    } else {
      filter = "TVPLAT:" + features.tvPlatforms;
    }
  }

  if (features.tvHdFormats) {
    if (filter != null) {
      filter = filter + ";TVHDFO:" + features.tvHdFormats;
    } else {
      filter = "TVHDFO:" + features.tvHdFormats;
    }
  }

  if (features.tvModelYears) {
    if (filter != null) {
      filter = filter + ";TVMODE:" + features.tvModelYears;
    } else {
      filter = "TVMODE:" + features.tvModelYears;
    }
  }

  if (features.tvConnectivity) {
    if (filter != null) {
      filter = filter + ";TVCONN:" + features.tvConnectivity;
    } else {
      filter = "TVCONN:" + features.tvConnectivity;
    }
  }

  if (features.cpuType) {
    if (filter != null) {
      filter = filter + ";CPUTYP:" + features.cpuType;
    } else {
      filter = "CPUTYP:" + features.cpuType;
    }
  }

  if (features.usage) {
    if (filter != null) {
      filter = filter + ";USAG:" + features.usage;
    } else {
      filter = "USAG:" + features.usage;
    }
  }

  if (features.os) {
    if (filter != null) {
      filter = filter + ";OS:" + features.os;
    } else {
      filter = "OS:" + features.os;
    }
  }

  if (features.memorySize) {
    if (filter != null) {
      filter = filter + ";MEMSIZ:" + features.memorySize;
    } else {
      filter = "MEMSIZ:" + features.memorySize;
    }
  }

  if (features.brand) {
    if (filter != null) {
      filter = filter + ";BRND:" + features.brand;
    } else {
      filter = "BRND:" + features.brand;
    }
  }

  if (filter != null) {
    url = url + "&filter=" + encodeURIComponent(filter);
  }

  return axios.get(url);
};

export const save = (productObj) => {
  let url = API_URL + "save";
  return axios.post(url, productObj, { headers: authHeader() });
};

export const deleteP = (productObj) => {
  let url = API_URL + "delete";
  return axios.post(url, { id: productObj._id }, { headers: authHeader() });
};

export const getProductsByUserMerchant = (userId, keyword, catId, features) => {
  let url = API_URL + "getListByUserIdMerchant";
  if (userId) {
    url = url + "?userId=" + userId;
  } else {
    url = url + "?userId=";
  }

  if (keyword) {
    url = url + "&keyword=" + keyword;
  }

  if (catId) {
    if (url.indexOf("?") > -1) {
      url = url + "&catId=" + catId;
    } else {
      url = url + "?catId=" + catId;
    }
  }

  let filter = null;

  if (features && features.omax) {
    filter = "OMIN:" + features.omin + ";OMAX:" + features.omax;
  }

  if (features && features.mainCatId) {
    if (url.indexOf("?") > -1) {
      url = url + "&mainCatId=" + features.mainCatId;
    } else {
      url = url + "?mainCatId=" + features.mainCatId;
    }
  }

  if (features && features.subCatId) {
    if (url.indexOf("?") > -1) {
      url = url + "&subCatId=" + features.subCatId;
    } else {
      url = url + "?subCatId=" + features.subCatId;
    }
  }

  if (features && features.childCatId) {
    if (url.indexOf("?") > -1) {
      url = url + "&childCatId=" + features.childCatId;
    } else {
      url = url + "?childCatId=" + features.childCatId;
    }
  }

  if (features && (features.pmax || features.pmin)) {
    if (filter != null) {
      filter = filter + ";PMIN:" + features.pmin;

      if (features.pmax) {
        filter = filter + ";PMAX:" + features.pmax;
      }
    } else {
      filter = "PMIN:" + features.pmin;

      if (features.pmax) {
        filter = filter + ";PMAX:" + features.pmax;
      }
    }
  }

  if (features.color) {
    if (filter != null) {
      filter = filter + ";COL:" + features.color;
    } else {
      filter = "COL:" + features.color;
    }
  }

  if (features.caseCover) {
    if (filter != null) {
      filter = filter + ";CCO:" + features.caseCover;
    } else {
      filter = "CCO:" + features.caseCover;
    }
  }

  if (features.caseCoverMatType) {
    if (filter != null) {
      filter = filter + ";CCCMT:" + features.caseCoverMatType;
    } else {
      filter = "CCCMT:" + features.caseCoverMatType;
    }
  }

  if (features.caseFeature) {
    if (filter != null) {
      filter = filter + ";CAFEA:" + features.caseFeature;
    } else {
      filter = "CAFEA:" + features.caseFeature;
    }
  }

  if (features.screenProtector) {
    if (filter != null) {
      filter = filter + ";SCPR:" + features.screenProtector;
    } else {
      filter = "SCPR:" + features.screenProtector;
    }
  }

  if (features.batteryType) {
    if (filter != null) {
      filter = filter + ";SCPBATTYPR:" + features.batteryType;
    } else {
      filter = "BATTYP:" + features.batteryType;
    }
  }

  if (features.tvPlatforms) {
    if (filter != null) {
      filter = filter + ";TVPLAT:" + features.tvPlatforms;
    } else {
      filter = "TVPLAT:" + features.tvPlatforms;
    }
  }

  if (features.tvHdFormats) {
    if (filter != null) {
      filter = filter + ";TVHDFO:" + features.tvHdFormats;
    } else {
      filter = "TVHDFO:" + features.tvHdFormats;
    }
  }

  if (features.tvModelYears) {
    if (filter != null) {
      filter = filter + ";TVMODE:" + features.tvModelYears;
    } else {
      filter = "TVMODE:" + features.tvModelYears;
    }
  }

  if (features.tvConnectivity) {
    if (filter != null) {
      filter = filter + ";TVCONN:" + features.tvConnectivity;
    } else {
      filter = "TVCONN:" + features.tvConnectivity;
    }
  }

  if (features.cpuType) {
    if (filter != null) {
      filter = filter + ";CPUTYP:" + features.cpuType;
    } else {
      filter = "CPUTYP:" + features.cpuType;
    }
  }

  if (features.usage) {
    if (filter != null) {
      filter = filter + ";USAG:" + features.usage;
    } else {
      filter = "USAG:" + features.usage;
    }
  }

  if (features.os) {
    if (filter != null) {
      filter = filter + ";OS:" + features.os;
    } else {
      filter = "OS:" + features.os;
    }
  }

  if (features.memorySize) {
    if (filter != null) {
      filter = filter + ";MEMSIZ:" + features.memorySize;
    } else {
      filter = "MEMSIZ:" + features.memorySize;
    }
  }

  if (features.brand) {
    if (filter != null) {
      filter = filter + ";BRND:" + features.brand;
    } else {
      filter = "BRND:" + features.brand;
    }
  }

  if (filter != null) {
    url = url + "&filter=" + encodeURIComponent(filter);
  }

  url = url + "&ps=" + features.ps + "&sidx=" + features.sidx;

  return axios.get(url, { headers: authHeader() });
};

export const getSearchLists = (name, features) => {
  let url = API_URL + "searchList";
  url = url + "?name=" + name;
  url = url + "&ps=12&sidx=0";
  return axios.get(url, { headers: authHeader() });
};

export const getProductsByKeyword = (keyword, features) => {
  let url = API_URL + "searchList";
  url = url + "?name=" + keyword;
  url = url + "&ps=" + features.ps + "&sidx=" + features.sidx;
  return axios.get(url, { headers: authHeader() });
};

export const getProductsCountBykeyword = (keyword, features) => {
  let url = API_URL + "searchListCount";

  if (keyword) {
    url = url + "?name=" + keyword;
  }
  return axios.get(url, { headers: authHeader() });
};
