import { useState } from "react";
import WishCartProductCard from "./WishCartProductCard";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { setDirectCheckout } from "./BuyNow";
import { Redirect } from "react-router-dom";

const WishCartList = (props) => {
  const headerState = useSelector((state) => state.header);
  const [isRedirect, setRedirect] = useState(0);

  const totalPrice = () => {
    let totalPrice = 0;
    headerState.cartsList.forEach((cart) => {
      if (cart.productId != null) {
        totalPrice +=
          cart.productId.stock > 0
            ? cart.stock *
              (cart.productId.price -
                (cart.productId.price * cart.productId.offer) / 100)
            : 0;
      }
    });

    return totalPrice.toFixed(2);
  };

  const btnCheckoutHandler = () => {
    setDirectCheckout(0);
    setRedirect(1);
  };

  if (isRedirect) {
    let redirectTo = "/checkout";
    return <Redirect to={redirectTo} />;
  }

  const item =
    props.items != null && props.items.length > 0
      ? props.items.map((item) => {
          return (
            <tr key={item._id}>
              {props.addToCart ? (
                <WishCartProductCard
                  item={item}
                  addToCart={props.addToCart}
                  deleteWishFromList={props.deleteWishFromList}
                ></WishCartProductCard>
              ) : (
                <WishCartProductCard
                  item={item}
                  showQuantity={true}
                  updateCartObj={props.updateCartObj}
                  deleteCartFromList={props.deleteCartFromList}
                ></WishCartProductCard>
              )}
            </tr>
          );
        })
      : null;

  return (
    <>
      <div className="container">
        <div className="row">
          <div className="cart-table-wrap">
            <table
              className={props.showAddToCart ? "cart-table" : "wishlist-table"}
            >
              <thead>
                <tr>
                  <th colSpan="2">Product</th>
                  <th>Price</th>
                  <th>{props.showAddToCart ? "Quantity" : ""}</th>
                  {props.showAddToCart ? <th>Subtotal</th> : null}
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>{item}</tbody>
            </table>
          </div>
        </div>
        {props.showAddToCart && props.items && props.items.length > 0 && (
          <>
            <div className="row mt-2">
              <div className="col-md-6"></div>
              <div className="col-md-6 pt-3">
                <div className="cart-total float-right">
                  <table>
                    <tbody>
                      <tr>
                        <td>Total</td>
                        <td>
                          {" "}
                          <span className="total-price">
                            Rs. {totalPrice()}
                          </span>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <div className="row mt-2">
              <div className="col-md-12 checkout-action text-right mt-5">
                {/* {headerState.user ? (
                <button className="btn btn-primary" onClick={() => props.proceed()}>
                  Proceed to Buy {" (" + props.items.length + " items)"}
                </button>
              ) : (
                <Link to="/login?redirectTo=/cart">                
                  <button className="btn btn-primary">
                    Please login to checkout
                  </button>
                </Link>
              )} */}
                {headerState.user ? (
                  // <Link to="/checkout">
                  //   <button className="btn btn-primary">
                  //     Checkout
                  //   </button>
                  // </Link>
                  <button
                    className="btn btn-primary btn-action"
                    onClick={btnCheckoutHandler}
                  >
                    Checkout
                  </button>
                ) : (
                  <Link to="/login?redirectTo=/checkout">
                    <button className="btn btn-primary  btn-action">
                      Please login to checkout
                    </button>
                  </Link>
                )}
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default WishCartList;
