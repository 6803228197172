import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { register, login, verify } from "../services/auth.service";
import { updateUser, removeDeliveryAddress, getUser } from "../services/user.service";

const user = JSON.parse(localStorage.getItem("user"));

export const registration = createAsyncThunk(
  "auth/registration",
  async (userParams, { getState, rejectWithValue }) => {
    try {
      const firstName = userParams.firstName;
      const lastName = userParams.lastName;
      const email = userParams.email;
      const phone = userParams.phone;

      const response = await register(
        firstName,
        lastName,
        email,
        phone,
        userParams.roles
      );

      return response.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const signin = createAsyncThunk(
  "auth/signin",
  async (userParams, { getState, rejectWithValue }) => {
    try {
      const email = userParams.email;

      const response = await login(email);

      return response.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const verifyUser = createAsyncThunk(
  "auth/verifyUser",
  async (userParams, { getState, rejectWithValue }) => {
    try {
      const otp = userParams.otp;
      const id = userParams.id;

      const response = await verify(otp, id);

      return response.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const getCurrentUser = () => {
  return JSON.parse(localStorage.getItem("user"));
};

// export const signin = createAsyncThunk(
//   "auth/getGeneralToken",
//   async (userId, { getState, rejectWithValue }) => {
//     try {

//       const response = await generalToken(userId);

//       return response.data;
//     } catch (err) {
//       return rejectWithValue(err);
//     }
//   }
// );

export const updateUs = createAsyncThunk(
  "auth/updateUs",
  async (params, { getState, rejectWithValue }) => {
    try {
      const response = await updateUser(params);

      return response.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const getProfile = createAsyncThunk(
  "auth/getProfile",
  async (userId, { getState, rejectWithValue }) => {
    try {

      const response = await getUser(userId);

      return response.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const removeDeliveryAddressObj = createAsyncThunk(
  "auth/removeDeliveryAddressObj",
  async (addressId, { getState, rejectWithValue }) => {
    
    const state = getState().auth;

    try {
      const response = await removeDeliveryAddress(state.user._id, addressId);
      return response.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

const initialState = user
  ? {
      isLoggedIn: true,
      loginError: null,
      otpFailed:null,
      user,
      successful: false,
      loading: false,
      existingUser: false,
      addressLoaded: false,
    }
  : {
      isLoggedIn: false,
      loginError: null,
      otpFailed:null,
      user: null,
      successful: false,
      loading: false,
      existingUser: false,
      addressLoaded: false,
    };

const authSlice = createSlice({
  name: "Auth",
  initialState: initialState,
  reducers: {
    update: (state, action) => {
      const user = localStorage.getItem("user");
      if (user != null) {
        state.user = JSON.parse(user);
      }
      state.city = localStorage.getItem("city");
    },
    updateAuth: (state, action) => {
      state.city = localStorage.getItem("city");
      const user = localStorage.getItem("user");
      if (user != null) {
        state.user = JSON.parse(user);
      } else {
        state.user = null;
      }
    },
    updateExistingUser: (state, action) => {
      state.existingUser = false;
    },
    setAddressLoaded: (state, action) => {
      state.addressLoaded = action.payload;
    },
    updateLoginError: (state, action) => {
      state.loginError = null;
    },
    updateOtpFailed: (state, action) => {
      state.otpFailed = null;
    },    
    logout: (state, action)  => {
      for (var key in initialState ){
        state[key] = initialState[key];
      }
      state.user = null;
    },
  },
  extraReducers: (builder) =>
    builder
      .addCase(registration.pending, (state, action) => {
        state.successful = false;
      })
      .addCase(registration.fulfilled, (state, action) => {
        state.isLoggedIn = false;

        if (action.payload && action.payload.existingUser) {
          state.existingUser = action.payload.existingUser;
        } else if (action.payload) {
          state.otpSent = action.payload.sentOtp;
          state.registeredUserId = action.payload.id;
          state.successful = true;
        }
      })
      .addCase(signin.pending, (state, action) => {
        state.successful = false;
        state.loading = true;
      })
      .addCase(signin.fulfilled, (state, action) => {
        state.isLoggedIn = false;
        state.loading = false;
        if (action.payload) {
          state.successful = true;
          state.registeredUserId = action.payload.id;
        }
      })
      .addCase(signin.rejected, (state, action) => {
        state.isLoggedIn = false;
        state.loading = false;
        let response = JSON.parse(action.payload.request.response);
        state.loginError = response.message;
      })
      .addCase(verifyUser.fulfilled, (state, action) => {
        state.isLoggedIn = true;
        state.successful = false;
        if (action.payload._id) {
          localStorage.setItem("user", JSON.stringify(action.payload));
          localStorage.setItem(
            "accessToken",
            JSON.stringify(action.payload.accessToken)
          );
          state.user = action.payload;
        }else{
          state.isLoggedIn = false;
          state.otpFailed = action.payload.message;
        }
      })
      .addCase(updateUs.fulfilled, (state, action) => {
        localStorage.setItem("user", JSON.stringify(action.payload));
        if (action.payload) {
          state.user = { ...state.user, ...action.payload };
          console.log('update profile');
        }
      })
      .addCase(getProfile.fulfilled, (state, action) => {
        
        if (action.payload) {
          state.user = { ...state.user, ...action.payload.result };
          localStorage.setItem("user", JSON.stringify(action.payload.result));
        }
      })
      .addCase(removeDeliveryAddressObj.fulfilled, (state, action) => {
        if (action.payload) {
          state.user.deliveryAddress = action.payload.deliveryAddress;
        }
      }),
});

export default authSlice.reducer;
export const { 
  LOGOUT, 
  updateAuth, 
  updateExistingUser, 
  setAddressLoaded, 
  updateLoginError, 
  updateOtpFailed,
  logout, 
} = authSlice.actions;
