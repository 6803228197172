import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { getProducts, getProductsCount, updatePriceFilter, updatePagination } from "../../slices/subcategorySlice";
import ProductCard from './ProductCard';
import { Range } from 'rc-slider';
import 'rc-slider/assets/index.css';
import Pagination from 'react-js-pagination';
import axios from 'axios';
import MapContainer from '../map/MapComponent';

const Subcategory = (props) => {
    const subcategoryState = useSelector(state => state.subcategory);
    const dispatch = useDispatch();
    const [lower, setLower] = useState(0);
    const [higher, setHigher] = useState(300000);

    useEffect(()=> {
        // dispatch(getProducts(props.match.params.id));
        // dispatch(getProductsCount(props.match.params.id));
    }, [props.match.params.id, dispatch]);

    const productList = subcategoryState.list.map(item => {
        return <ProductCard 
                key={item._id} 
                item={item} 
                
                addToWishlist={props.addToWishlist}
                userObj={props.userObj}
                addToCart={props.addToCart}
                ></ProductCard>
    });

    const handleChange = (value) => {
        setLower(value[0]);
        setHigher(value[1]);
    };

    const applyFilter = () => {
        dispatch(updatePriceFilter({'pmin': lower, 'pmax':higher}));
        dispatch(getProducts(props.match.params.id));
        dispatch(getProductsCount(props.match.params.id));
    };

    const handlePageChange = (pageNumber) => {
        dispatch(updatePagination(pageNumber));
        dispatch(getProducts(props.match.params.id));
    }

    const uploadHandler = (event) => {
        let imageFormData = new FormData();
        
        imageFormData.append('imageName', "image 1");
        imageFormData.append('imageData', event.target.files[0]);

        axios.post('http://localhost:8082/api/image/upload', imageFormData)
          .then((res) => {
              console.log("data == " , res.data);
        });
    };
    
    return(
        <div className="container-fluid">
            <div className="row">
                <div className="col-md-3">
                    <Range min={0} max={300000} allowCross={false} value={[lower, higher]} onChange={handleChange}/>
                    <button className="btn btn-primary" onClick={() => applyFilter()}>Apply Filter</button>

                    <input type="file" name="file" onChange={(e) => uploadHandler(e)}/>
                </div>
                <div className="col-md-9">
                    <header className="jumbotron">
                        <h3>Store Name</h3>
                    </header>
                    <div className="col-md-12">
                        <div className="row">
                            {subcategoryState.list ? productList : ( <div className="col-md-12">No products found</div>) }
                            <div>
                                <Pagination
                                activePage={subcategoryState.features.page}
                                itemsCountPerPage={subcategoryState.features.ps}
                                totalItemsCount={subcategoryState.count}
                                pageRangeDisplayed={5}
                                onChange={handlePageChange.bind(this)}
                                itemClass="page-item"
                                linkClass="page-link"
                                innerClass="pagination ml-auto mr-auto"
                                prevPageText="Prev"
                                nextPageText="Next"
                                hideFirstLastPages={true}
                                />
                            </div>
                        </div>

                    </div>
                </div>
                <div className="col-md-9">
                    {/* <MapContainer></MapContainer> */}
                    {/* <AutocompleteComponent></AutocompleteComponent> */}
                </div>

            </div>
        </div>
    );
}

export default Subcategory;
