import { useEffect, useState } from "react";
import Pagination from "react-js-pagination";
import { useDispatch, useSelector } from "react-redux";
import {
  getProducts,
  getProductsCount,
  bannerList,
  updatePagination,
  updateOfferFilter,
  getOffersByCategory,
  getOffersByCategory1,
  getOffersByCategory2,
  getOffersByCategory3,
  getOffersByCategory4,
  updateMainCatId,
  resetFeatures,
  getMainCategories,
} from "../../slices/OfferSlice";
import StaticHomeBanner from "../home/StaticHomeBanner";
import StoreHome from "../home/StoreHome";
import OfferCatList from "./offerCatList";
import "../../App.scss";
import OfferProductList from "../../helpers/OfferProductList";
import ReactLoading from "react-loading";
import LeftSidebar from "../shop/left_sidebar";
import Fillter from "../shop/common/filter";
import { Col, Row } from "reactstrap";
import Sliders from "../common/Blog/blog1";

const OfferList = (props) => {
  const offerState = useSelector((state) => state.offer);
  const dispatch = useDispatch();
  const [mainCatId, setMainCatId] = useState(null);
  const [max, setMax] = useState(null);
  const [min, setMin] = useState(null);

  useEffect(() => {
    // console.log('run')
    if (props.match.params.max && props.match.params.min) {
      setMax(props.match.params.max);
      setMin(props.match.params.min);
      dispatch(
        updateOfferFilter({
          omax: props.match.params.max,
          omin: props.match.params.min,
        })
      );
      dispatch(getProducts());
      dispatch(getProductsCount());
    }

    dispatch(bannerList("OFFER"));
    dispatch(getMainCategories());
  }, [dispatch, props.match.params.max, props.match.params.min]);

  useEffect(() => {
    if (offerState.top5Cat != null && mainCatId == null) {
      if (offerState.top5Cat.CAT0 != null) {
        let param = {
          name: offerState.top5Cat.CAT0.header,
        };

        dispatch(getOffersByCategory(param));
      }

      if (offerState.top5Cat.CAT1 != null) {
        let param = {
          name: offerState.top5Cat.CAT1.header,
        };
        dispatch(getOffersByCategory1(param));
      }

      if (offerState.top5Cat.CAT2 != null) {
        let param = {
          name: offerState.top5Cat.CAT2.header,
        };
        dispatch(getOffersByCategory2(param));
      }

      if (offerState.top5Cat.CAT3 != null) {
        let param = {
          name: offerState.top5Cat.CAT3.header,
        };
        dispatch(getOffersByCategory3(param));
      }

      if (offerState.top5Cat.CAT4 != null) {
        let param = {
          name: offerState.top5Cat.CAT4.header,
        };
        dispatch(getOffersByCategory4(param));
      }
    }
  }, [dispatch, offerState.top5Cat, mainCatId]);

  const handlePageChange = (pageNumber) => {
    dispatch(updatePagination(pageNumber));
    dispatch(getProducts());
  };

  const dispatchUpdateSelected = (cat) => {
    setMainCatId(cat._id);
    dispatch(updateMainCatId(cat._id));
    dispatch(getProducts());
    dispatch(getProductsCount());
  };

  const clearSelectedFilter = () => {
    setMainCatId(null);
    setMax(null);
    setMin(null);
    dispatch(resetFeatures());
  };
  const [sidebarView, setSidebarView] = useState(false);

  const openCloseSidebar = () => {
    if (sidebarView) {
      setSidebarView(!sidebarView);
    } else {
      setSidebarView(!sidebarView);
    }
  };
  return (
    <>
      <LeftSidebar storeName="Offer">
        <div className="col-md-12">
          <div className="row">
            <div className="col-md-3  sidebar ONM_left-filter">
              {offerState.loading && (
                <div className="ONM_loaders text-center">
                  {/* <FaSpinner className="ONM_loader_icon" size={100}></FaSpinner> */}
                  <ReactLoading
                    type={"spinningBubbles"}
                    color={"#fff"}
                    height={"10%"}
                    width={"10%"}
                    className="ONM_react_loader"
                  />
                </div>
              )}
              <Fillter
                sidebarView={sidebarView}
                closeSidebar={() => openCloseSidebar(sidebarView)}
              >
                <OfferCatList
                  items={offerState.mainCat}
                  onCategoryUpdate={dispatchUpdateSelected}
                  onCategoryClear={clearSelectedFilter}
                  selectedCatId={mainCatId}
                  max={max}
                  min={min}
                ></OfferCatList>
              </Fillter>
            </div>
            <div className="col-md-9">
              <Row>
                <Col xl="12">
                  <div
                    className="filter-main-btn"
                    onClick={() => openCloseSidebar()}
                  >
                    <span className="filter-btn btn btn-theme">
                      <i className="fa fa-filter" aria-hidden="true"></i> Filter
                    </span>
                  </div>
                </Col>
              </Row>
              <div className="row">
                {mainCatId == null &&
                offerState.offers0List &&
                offerState.top5Cat &&
                offerState.top5Cat.CAT0 ? (
                  <Sliders
                    type="fashion"
                    title={offerState.top5Cat.CAT0.headerName}
                    inner="title-inner1"
                    items={offerState.offers0List}
                    heading={offerState.top5Cat.CAT0.headerName}
                    model="offer"
                  />
                ) : null}
                {mainCatId == null && offerState.row2Banner ? (
                  <div className="col-md-12 p-0">
                    <StaticHomeBanner
                      items={offerState.row2Banner}
                    ></StaticHomeBanner>
                  </div>
                ) : null}
                {mainCatId == null &&
                offerState.offers1List &&
                offerState.top5Cat &&
                offerState.top5Cat.CAT1 ? (
                  <Sliders
                    type="fashion"
                    title={offerState.top5Cat.CAT1.headerName}
                    inner="title-inner1"
                    items={offerState.offers1List}
                    heading={offerState.top5Cat.CAT1.headerName}
                    model="offer"
                  />
                ) : null}
                {mainCatId == null && offerState.row3Banner ? (
                  <div className="col-md-12 p-0">
                    <StaticHomeBanner
                      items={offerState.row3Banner}
                    ></StaticHomeBanner>
                  </div>
                ) : null}

                {mainCatId == null &&
                offerState.offers2List &&
                offerState.top5Cat &&
                offerState.top5Cat.CAT2 ? (
                  <Sliders
                    type="fashion"
                    title={offerState.top5Cat.CAT2.headerName}
                    inner="title-inner1"
                    items={offerState.offers2List}
                    heading={offerState.top5Cat.CAT2.headerName}
                    model="offer"
                  />
                ) : null}
                {mainCatId == null && offerState.row4Banner ? (
                  <div className="col-md-12 p-0">
                    <StaticHomeBanner
                      items={offerState.row4Banner}
                    ></StaticHomeBanner>
                  </div>
                ) : null}
                {mainCatId == null &&
                offerState.offers3List &&
                offerState.top5Cat &&
                offerState.top5Cat.CAT3 ? (
                  <Sliders
                    type="fashion"
                    title={offerState.top5Cat.CAT3.headerName}
                    inner="title-inner1"
                    items={offerState.offers3List}
                    heading={offerState.top5Cat.CAT3.headerName}
                    model="offer"
                  />
                ) : null}
                {mainCatId == null && offerState.row4Banner ? (
                  <div className="col-md-12 p-0">
                    <StaticHomeBanner
                      items={offerState.row4Banner}
                    ></StaticHomeBanner>
                  </div>
                ) : null}
                {mainCatId == null &&
                offerState.offers4List &&
                offerState.top5Cat &&
                offerState.top5Cat.CAT4 ? (
                  <Sliders
                    type="fashion"
                    title={offerState.top5Cat.CAT4.headerName}
                    inner="title-inner1"
                    items={offerState.offers4List}
                    heading={offerState.top5Cat.CAT4.headerName}
                    model="offer"
                  />
                ) : null}
                {mainCatId == null && offerState.row4Banner ? (
                  <div className="col-md-12 p-0">
                    <StaticHomeBanner
                      items={offerState.row4Banner}
                    ></StaticHomeBanner>
                  </div>
                ) : null}

                {mainCatId != null ? (
                  <div className="col-md-12 ">
                    <div className="row">
                      <div className="col-md-6">
                        <h1>Products</h1>
                      </div>

                      {offerState.list ? (
                        <OfferProductList
                          items={offerState.list}
                        ></OfferProductList>
                      ) : (
                        <div className="col-md-12">No products found</div>
                      )}
                      <div className="col-md-12 mt-5">
                        <Pagination
                          activePage={offerState.features.page}
                          itemsCountPerPage={offerState.features.ps}
                          totalItemsCount={offerState.count}
                          pageRangeDisplayed={5}
                          onChange={handlePageChange.bind(this)}
                          itemClass="page-item"
                          linkClass="page-link"
                          innerClass="pagination ml-auto mr-auto"
                          prevPageText="Prev"
                          nextPageText="Next"
                          hideFirstLastPages={true}
                        />
                      </div>
                    </div>
                  </div>
                ) : null}
              </div>
            </div>
          </div>
        </div>
      </LeftSidebar>
    </>
  );
};

export default OfferList;
