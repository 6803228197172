import Layout from "./shop/common-layout";
import { Container, Row, Col } from "reactstrap";
const NotFound = () => {
  return (
    <>
      <Layout title="not found" parent="home">
        <section className="p-0">
          <Container>
            <Row>
              <Col sm="12">
                <div className="error-section">
                  <h1>404</h1>
                  <h2>page not found</h2>
                  <a href="/" className="btn btn-solid">
                    back to home
                  </a>
                </div>
              </Col>
            </Row>
          </Container>
        </section>
      </Layout>
    </>
  );
};

export default NotFound;
