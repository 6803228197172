import React, { Component } from "react";
import { GoogleMap, LoadScript, Marker } from "@react-google-maps/api";

const MapContainer = (props) => {
  const containerStyle = {
    width: "600px",
    height: "300px",
  };

  return (
    <LoadScript googleMapsApiKey="AIzaSyBhhIhmyHFUgqagBmIVaXO5IYmmkmLhLuM">
      <GoogleMap
        mapContainerStyle={containerStyle}
        center={{ lat: props.item[0], lng: props.item[1] }}
        zoom={15}
      >
        <Marker
          key="marker_1"
          position={{
            lat: props.item[0],
            lng: props.item[1],
          }}
        />
        <></>
      </GoogleMap>
    </LoadScript>
  );
};

export default MapContainer;
