import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { Router, Switch, Route } from "react-router-dom";
// import {
//   getGeneralToken,
// } from "../slices/authSlice";
import { generalToken } from "./services/auth.service";
import { getUniqueId } from "./helpers/LocalCart";

import "./App.scss";
import "./assets/scss/app.scss";

import Login from "./components/Login";
import Home from "./components/home/Home";
import Profile from "./components/Profile";
import Header from "./components/headers/header-one";
import Category from "./components/category/category";
import ThankYou from "./components/order/ThankYou";
import Checkout from "./components/order/Checkout";
import NotFound from "./components/NotFound";

// import { signout } from "./slices/authSlice";

import { history } from "./helpers/history";
import Subcategory from "./components/subcategory/subcategory";
import Merchant from "./components/merchant/Merchant";
import MerchantLogin from "./components/merchant/MerchantLogin";
import MerchantRegister from "./components/merchant/MerchantRegister";
import MerchantMain from "./components/merchant/MerchantMain";
import ProductDetails from "./components/product/ProductDetails";
import Store from "./components/store/store";
import Search from "./components/search/Search";

import Footer from "./components/footers/common/MasterFooter";

import { usePosition } from "use-position";
import OfferList from "./components/offer/OfferList";
import Geocode from "react-geocode";
import { update } from "./slices/headerSlice";
import AdminMain from "./components/admin/adminMain";
import AdminDash from "./components/admin/AdminDash";
import Cart from "./components/header/cart";
import Wishlist from "./components/header/wishlist";
import PrivacyPolicy from "./components/PrivacyPolicy";
import AboutUs from "./components/AboutUs";
import Order from "./components/header/Order";
import TermsOfUse from "./components/TermsOfUse";
import TermsOfUseSeller from "./components/TermsOfUseSeller";
import { ToastContainer } from "react-toastify";
import ReturnPolicy from "./components/ReturnPolicy";
const App = () => {
  // const { user: currentUser } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const watch = true;

  const { latitude, longitude } = usePosition(watch, {
    enableHighAccuracy: true,
    maximumAge: 10000,
    timeout: 5000,
    distanceFilter: 1,
  });

  useEffect(() => {
    if (latitude && longitude) {
      let lat1 = latitude;
      let lng1 = longitude;
      if (lat1 && lng1) {
        let lat = localStorage.getItem("latitude");
        let lng = localStorage.getItem("longitude");
        if (
          !lat ||
          lat === undefined ||
          // lat !== "" + lat1 ||
          !lng ||
          lng === undefined
          // ||
          // lng !== "" + lng1
        ) {
          localStorage.setItem("latitude", lat1);
          localStorage.setItem("longitude", lng1);
          Geocode.setApiKey("AIzaSyBhhIhmyHFUgqagBmIVaXO5IYmmkmLhLuM");
          Geocode.setLanguage("en");
          Geocode.setLocationType("ROOFTOP");
          Geocode.fromLatLng(latitude, longitude).then(
            (response) => {
              // console.log('app geo')
              // console.log(response);
              const address = response.results[0].formatted_address;
              let city = null;
              let zip_code = "";
              let area1 = "";
              /*for (
                let i = 0;
                i < response.results[0].address_components.length;
                i++
              ) {
                for (
                  let j = 0;
                  j < response.results[0].address_components[i].types.length;
                  j++
                ) {
                  if (
                    response.results[0].address_components[i].types[j] ===
                    "sublocality" || response.results[0].address_components[i].types[j] === 'administrative_area_level_1'
                  ) {
                    city = response.results[0].address_components[i].long_name;
                  }
                }
              }*/
              city = response.results[0].formatted_address;

              if (zip_code == "") {
                for (
                  let i = 0;
                  i < response.results[0].address_components.length;
                  i++
                ) {
                  for (
                    let j = 0;
                    j < response.results[0].address_components[i].types.length;
                    j++
                  ) {
                    if (
                      response.results[0].address_components[i].types[j] ===
                      "postal_code"
                    ) {
                      zip_code =
                        response.results[0].address_components[i].long_name;
                    }
                  }
                }
              }
              if (area1 == "") {
                for (
                  let i = 0;
                  i < response.results[0].address_components.length;
                  i++
                ) {
                  for (
                    let j = 0;
                    j < response.results[0].address_components[i].types.length;
                    j++
                  ) {
                    if (
                      response.results[0].address_components[i].types[j] ===
                      "sublocality"
                    ) {
                      area1 =
                        response.results[0].address_components[i].long_name;
                    }
                  }
                }
              }
              localStorage.setItem("address", address);
              localStorage.setItem("city", city);

              localStorage.setItem("zip_code", zip_code);
              localStorage.setItem("area", area1);

              dispatch(update());
            },
            (error) => {
              console.error(error);
            }
          );
        }
      }
    }
  }, [latitude, longitude, dispatch]);

  useEffect(() => {
    let accessToken = localStorage.getItem("accessToken");

    if (!accessToken) {
      let userId = getUniqueId();
      generalToken(userId).then((res) => {
        // console.log( res.data );
        let accessToken = res.data.accessToken;
        localStorage.setItem("accessToken", JSON.stringify(accessToken));
      });
    }
  });

  return (
    <Router history={history}>
      <div className="ONM_relative">
        <Header />
        <Switch>
          <Route exact path={["/", "/home"]} component={Home} />
          <Route exact path="/category/:id" component={Category} />
          <Route exact path="/subcategory/:id" component={Subcategory} />
          <Route exact path="/search/:key" component={Search} />
          <Route exact path="/login" component={Login} />
          <Route exact path="/profile" component={Profile} />
          <Route exact path="/product/:id" component={ProductDetails} />
          <Route exact path="/store/:id" component={Store} />
          <Route exact path="/offer/:max/:min" component={OfferList} />
          <Route exact path="/offer" component={OfferList} />
          <Route exact path="/merchant" component={Merchant} />
          <Route exact path="/merchant/main" component={MerchantMain} />
          <Route exact path="/merchant/login" component={MerchantLogin} />
          <Route exact path="/merchant/register" component={MerchantRegister} />
          <Route exact path="/admin" component={AdminMain} />
          <Route exact path="/admin/dashboard" component={AdminDash} />
          <Route exact path="/cart" component={Cart} />
          <Route exact path="/wishlist" component={Wishlist} />
          <Route exact path="/orders" component={Order} />
          <Route exact path="/privacy-policy" component={PrivacyPolicy} />
          <Route exact path="/return-policy" component={ReturnPolicy} />
          <Route exact path="/about" component={AboutUs} />
          <Route exact path="/terms-of-use" component={TermsOfUse} />
          <Route
            exact
            path="/terms-of-useseller"
            component={TermsOfUseSeller}
          />
          <Route exact path="/thank-you/:id" component={ThankYou} />
          <Route exact path="/checkout" component={Checkout} />
          <Route component={NotFound} />
        </Switch>
        <Footer
          footerClass={`footer-light `}
          footerSection={"small-section border-section border-top-0"}
          belowSection={"light-layout"}
        />
      </div>
      <ToastContainer autoClose={1500} />
    </Router>
  );
};

export default App;
