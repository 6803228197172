import React from "react";
import { Link } from "react-router-dom";
import { BASE_URL, BASE_API_URL } from "../../services/constants";

const MainBanner = (props) => {
  let baseUri = BASE_URL;

  const imgPath = (img) => {
    let path = img;

    if (path.indexOf("https://") > -1) {
      return path;
    } else {
      return BASE_API_URL + "/" + path;
    }
  };

  const adItem =
    props.banners &&
    props.banners[0] &&
    props.banners[0].storeId &&
    props.banners[0].subCategoryId ? (
      <Link
        key={props.banners[0]._id}
        to={
          "/category/" +
          props.banners[0].mainCategoryId +
          "?subCatId=" +
          props.banners[0].subCategoryId +
          "&uId=" +
          props.banners[0].storeId._id +
          "&childCatId=" +
          props.banners[0].childCategoryId
        }
      >
        {props.banners[0].imageId ? (
          <img
            className="left-banner"
            src={imgPath(props.banners[0].imageId.path)}
            alt="ads"
          />
        ) : null}
      </Link>
    ) : (
      props.banners &&
      props.banners[0] && (
        <>
          {props.banners[0].imageId ? (
            <Link to="/category/6077ea9d3a92cd14d834cffb">
              <img
                className="box-banner"
                src={imgPath(props.banners[0].imageId.path)}
                alt="ads"
                key={props.banners[0]._id}
              />
            </Link>
          ) : null}
        </>
      )
    );

  const adItem1 =
    props.banners &&
    props.banners[1] &&
    props.banners[1].storeId &&
    props.banners[1].subCategoryId ? (
      <Link
        key={props.banners[1]._id}
        to={
          "/category/" +
          props.banners[1].mainCategoryId +
          "?subCatId=" +
          props.banners[1].subCategoryId +
          "&uId=" +
          props.banners[1].storeId._id +
          "&childCatId=" +
          props.banners[1].childCategoryId
        }
      >
        {props.banners[1].imageId ? (
          <img
            className="box-banner"
            src={imgPath(props.banners[1].imageId.path)}
            alt="ads"
          />
        ) : null}
      </Link>
    ) : (
      props.banners &&
      props.banners[1] && (
        <>
          {props.banners[1].imageId ? (
            <Link to="/category/6077eaa53a92cd14d834cffc">
              <img
                className="box-banner"
                src={imgPath(props.banners[1].imageId.path)}
                alt="ads"
                key={props.banners[1]._id}
              />
            </Link>
          ) : null}
        </>
      )
    );

  const adItem2 =
    props.banners &&
    props.banners[2] &&
    props.banners[2].storeId &&
    props.banners[2].subCategoryId ? (
      <Link
        key={props.banners[2]._id}
        to={
          "/category/" +
          props.banners[2].mainCategoryId +
          "?subCatId=" +
          props.banners[2].subCategoryId +
          "&uId=" +
          props.banners[2].storeId._id +
          "&childCatId=" +
          props.banners[2].childCategoryId
        }
      >
        {props.banners[2].imageId ? (
          <img
            className="box-banner"
            src={imgPath(props.banners[2].imageId.path)}
            alt="ads"
          />
        ) : null}
      </Link>
    ) : (
      props.banners &&
      props.banners[2] && (
        <>
          {props.banners[2].imageId ? (
            <Link to="/category/6077eaae3a92cd14d834cffd">
              <img
                className="box-banner"
                src={imgPath(props.banners[2].imageId.path)}
                alt="ads"
                key={props.banners[2]._id}
              />
            </Link>
          ) : null}
        </>
      )
    );

  const adItem3 =
    props.banners &&
    props.banners[3] &&
    props.banners[3].storeId &&
    props.banners[3].subCategoryId ? (
      <Link
        key={props.banners[3]._id}
        to={
          "/category/" +
          props.banners[3].mainCategoryId +
          "?subCatId=" +
          props.banners[3].subCategoryId +
          "&uId=" +
          props.banners[3].storeId._id +
          "&childCatId=" +
          props.banners[3].childCategoryId
        }
      >
        {props.banners[3].imageId ? (
          <img
            className="box-banner"
            src={imgPath(props.banners[3].imageId.path)}
            alt="ads"
          />
        ) : null}
      </Link>
    ) : (
      props.banners &&
      props.banners[3] && (
        <>
          {props.banners[3].imageId ? (
            <Link to="/category/6077eac23a92cd14d834d000">
              <img
                className="box-banner"
                src={imgPath(props.banners[3].imageId.path)}
                alt="ads"
                key={props.banners[3]._id}
              />
            </Link>
          ) : null}
        </>
      )
    );

  const adItem4 =
    props.banners &&
    props.banners[4] &&
    props.banners[4].storeId &&
    props.banners[4].subCategoryId ? (
      <Link
        key={props.banners[4]._id}
        to={
          "/category/" +
          props.banners[4].mainCategoryId +
          "?subCatId=" +
          props.banners[4].subCategoryId +
          "&uId=" +
          props.banners[4].storeId._id +
          "&childCatId=" +
          props.banners[4].childCategoryId
        }
      >
        {props.banners[4].imageId ? (
          <img
            className="box-banner"
            src={imgPath(props.banners[4].imageId.path)}
            alt="ads"
          />
        ) : null}
      </Link>
    ) : (
      props.banners &&
      props.banners[4] && (
        <>
          {props.banners[4].imageId ? (
            <Link to="category/6077eabc3a92cd14d834cfff">
              <img
                className="box-banner"
                src={imgPath(props.banners[4].imageId.path)}
                alt="ads"
                key={props.banners[4]._id}
              />
            </Link>
          ) : null}
        </>
      )
    );

  const adItem5 =
    props.banners &&
    props.banners[5] &&
    props.banners[5].storeId &&
    props.banners[5].subCategoryId ? (
      <Link
        key={props.banners[5]._id}
        to={
          "/category/" +
          props.banners[5].mainCategoryId +
          "?subCatId=" +
          props.banners[5].subCategoryId +
          "&uId=" +
          props.banners[5].storeId._id +
          "&childCatId=" +
          props.banners[5].childCategoryId
        }
      >
        {props.banners[5].imageId ? (
          <img
            className="box-banner"
            src={imgPath(props.banners[5].imageId.path)}
            alt="ads"
          />
        ) : null}
      </Link>
    ) : (
      props.banners &&
      props.banners[5] && (
        <>
          {props.banners[5].imageId ? (
            <Link to="/category/6077ea9d3a92cd14d834cffb">
              <img
                className="right-banner"
                src={imgPath(props.banners[5].imageId.path)}
                alt="ads"
                key={props.banners[5]._id}
              />
            </Link>
          ) : null}
        </>
      )
    );

  return (
    <div className="container main-banners mt-5">
      <div className="row">
        <div className="col-md-3 mb-4">{adItem}</div>
        <div className="col-md-6">
          <div className="row">
            <div className="col-md-6 mb-4">{adItem1}</div>
            <div className="col-md-6 mb-4">{adItem2}</div>
            <div className="col-md-6 mb-4">{adItem3}</div>
            <div className="col-md-6 mb-4">{adItem4}</div>
          </div>
        </div>
        <div className="col-md-3 mb-4">{adItem5}</div>
      </div>
    </div>
  );
};

export default MainBanner;
