import Pagination from "react-js-pagination";
import StoreList from "../../helpers/StoreList";
import StaticHomeBanner from "../home/StaticHomeBanner";
import FilterList from "./FilterList";
import StoreProductList from "../../helpers/StoreProductList";
import { FaSpinner } from "react-icons/fa";
import ReactLoading from "react-loading";
import Fillter from "../shop/common/filter";
import { useState } from "react";
import { Col, Row } from "reactstrap";
import ProductItem from "../common/product-box/ProductBox1";

const SubCategoryPage = (props) => {
  console.log("childcat--->", props.sub);
  const [sidebarView, setSidebarView] = useState(false);

  const openCloseSidebar = () => {
    if (sidebarView) {
      setSidebarView(!sidebarView);
    } else {
      setSidebarView(!sidebarView);
    }
  };
  return (
    <div className="col-md-12">
      {props.loading && (
        <div className="ONM_loaders text-center">
          {/* <FaSpinner className="ONM_loader_icon" size={100}></FaSpinner> */}
          <ReactLoading
            type={"spinningBubbles"}
            color={"#fff"}
            height={"10%"}
            width={"10%"}
            className="ONM_react_loader"
          />
        </div>
      )}

      <div className="row">
        <div className="col-lg-3 mt-2 sidebar ">
          <>
            <Fillter
              sidebarView={sidebarView}
              closeSidebar={() => openCloseSidebar(sidebarView)}
            >
              <FilterList
                mainCatId={props.mainCatId}
                subObj={props.sub}
                childCats={props.sub.childCategories}
                catFilterClicked={props.catFilterClicked}
                childFilter={props.childFilter}
                onOfferUpdate={props.onOfferUpdate}
                offerFilter={props.offerFilter}
                onCategoryClear={props.onCategoryClear}
                onPriceUpdate={props.onPriceUpdate}
                priceFilter={props.priceFilter}
                handleColorCheckbox={props.handleColorCheckbox}
                colorCheckboxes={props.colorCheckboxes}
                filters={props.filters}
                handleCaseCheckbox={props.handleCaseCheckbox}
                caseCheckboxes={props.caseCheckboxes}
                checkboxes={props.checkboxes}
                handleCheckbox={props.handleCheckbox}
                userFilter={props.userFilter}
                brandsList={props.brandsList}
                handleBrandCheckbox={props.handleBrandCheckbox}
                brandCheckbox={props.brandCheckbox}
                banner={props.banner}
                allCats={props.allCats}
                closeSidebar={() => openCloseSidebar(sidebarView)}
              ></FilterList>
            </Fillter>
          </>
        </div>
        <div className="col-lg-9 mt-3">
          {props.subBanner ? (
            <div className="col-md-12">
              <StaticHomeBanner items={props.subBanner}></StaticHomeBanner>
            </div>
          ) : null}

          <Row>
            <Col xl="12">
              <div
                className="filter-main-btn"
                onClick={() => openCloseSidebar()}
              >
                <span className="filter-btn btn btn-theme">
                  <i className="fa fa-filter" aria-hidden="true"></i> Filter
                </span>
              </div>
            </Col>
          </Row>
          <div className="col-md-12 mt-0">
            <div className="row">
              {props.items && !props.userFilter ? (
                <StoreList
                  items={props.items}
                  onStoreUpdate={props.onStoreUpdate}
                  addToWishlist={props.addToWishlist}
                  addToCart={props.addToCart}
                  userObj={props.userObj}
                ></StoreList>
              ) : null}

              {props.items && props.userFilter ? (
                <StoreProductList
                  items={props.items}
                  addToWishlist={props.addToWishlist}
                  userObj={props.userObj}
                  addToCart={props.addToCart}
                ></StoreProductList>
              ) : null}

              {!props.items && (
                <div className="col-md-12">No products found</div>
              )}

              <div className="col-md-12 mt-5">
                <Pagination
                  activePage={props.page}
                  itemsCountPerPage={props.ps}
                  totalItemsCount={props.count}
                  pageRangeDisplayed={10}
                  onChange={props.handlePageChange.bind(this)}
                  itemClass="page-item"
                  linkClass="page-link"
                  innerClass="pagination ml-auto mr-auto"
                  prevPageText="Prev"
                  nextPageText="Next"
                  hideFirstLastPages={true}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SubCategoryPage;
