import "./product.scss";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import DetailTab from "./DetailTab";
import {
  addReview,
  getReviews,
  getReviewsCount,
  productById,
  recents,
  updateReviewAdded,
  updatePagination,
  resetFeatures,
  saveWishlist,
  updateCartAdded,
  updateWishlistAdded,
  saveToCart,
} from "../../slices/productSlice";
import ProductOnmeeCarousel from "../../helpers/ProductOnmeeCarousel";
import {
  FaFacebook,
  FaInstagram,
  FaTwitter,
  FaLinkedin,
  FaStar,
  FaHeart,
  FaCartPlus,
  FaRegCreditCard,
} from "react-icons/fa";
import Recents from "./recents";
import ModalMap from "../map/modalMap";
import Modal from "react-modal";
import { useAlert } from "react-alert";
import ReviewList from "./ReviewList";
import { getCartsCount, getWishCount } from "../../slices/headerSlice";
import { localSaveToCart, getUniqueId } from "../../helpers/LocalCart";
import { setDirectCheckout, setDirectCheckoutItem } from "../../helpers/BuyNow";
import { Redirect } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import classes from "./Table.module.css";

const ProductDetails = (props) => {
  const productState = useSelector((state) => state.product);
  const dispatch = useDispatch();

  // const alert = useAlert();
  const [isRedirect, setRedirect] = useState(0);

  useEffect(() => {
    dispatch(productById(props.match.params.id));
    dispatch(getReviewsCount(props.match.params.id));
    dispatch(getReviews(props.match.params.id));
    dispatch(recents());
  }, [props.match.params.id, dispatch]);

  useEffect(() => {
    if (productState.reviewAdded) {
      // alert.show("Review added successfully !!!");
      toast.success("Review added successfully !!!");
      dispatch(updateReviewAdded());
      dispatch(resetFeatures());
      dispatch(getReviewsCount(productState.details._id));
      dispatch(getReviews(productState.details._id));
      dispatch(productById(productState.details._id));
    }
  }, [dispatch, productState.reviewAdded]);

  const [tab, setTab] = useState("SPEC");

  const changeTab = (tabName) => {
    setTab(tabName);

    if (tabName === "REVIEW") {
      dispatch(resetFeatures());
      dispatch(getReviewsCount(productState.details._id));
      dispatch(getReviews(productState.details._id));
    }
  };

  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      zIndex: "9999",
      width: "80%",
    },
  };

  Modal.setAppElement("#root");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [description, setDescription] = useState(null);
  const [stars, setStars] = useState(null);
  const [starError, setStarError] = useState(false);

  const openModal = () => {
    setIsModalOpen(true);
    setStars(0);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setStars(null);
    setDescription(null);
    setStarError(false);
  };

  const saveReview = () => {
    if (stars > 0) {
      let reviewObj = {};
      reviewObj.userId = productState.user._id;
      reviewObj.productId = productState.details._id;
      reviewObj.stars = stars;
      reviewObj.description = description;
      dispatch(addReview(reviewObj));
      setStars(null);
      setDescription(null);
      setStarError(false);
      setIsModalOpen(false);
    } else {
      setStarError(true);
    }
  };

  const onChangeDescription = (e) => {
    setDescription(e.target.value);
  };

  const onClickStars = (num) => {
    setStars(num);
  };

  const handlePageChange = (pageNumber) => {
    dispatch(updatePagination(pageNumber));
    dispatch(getReviews(productState.details._id));
  };

  const roundItOff = (num) => {
    return Math.round(num);
  };

  const calculatePrice = () => {
    let price =
      productState.details.price -
      productState.details.price * (productState.details.offer / 100);
    return price.toFixed(2);
  };

  const addToWishlist = (prod) => {
    let userId =
      productState.user != null ? productState.user._id : getUniqueId();
    const wish = {
      productId: prod._id,
      userId: userId,
    };

    dispatch(saveWishlist(wish));
  };

  useEffect(() => {
    if (productState.wishAdded === true) {
      let userId =
        productState.user != null ? productState.user._id : getUniqueId();

      // alert.show("Product added to wishlist !!!");
      toast.success("Product added to wishlist !!!");
      dispatch(getWishCount(userId));
      dispatch(updateWishlistAdded());
    }
  }, [productState.wishAdded]);

  useEffect(() => {
    if (productState.savedToCart) {
      // alert.show("Added to cart !!!");
      toast.success("Added to cart !!!");
      let id =
        productState.user != null ? productState.user._id : getUniqueId();
      dispatch(getCartsCount(id));
      dispatch(updateCartAdded());
    }
  }, [productState.savedToCart]);

  const addToCart = (prod) => {
    let userId =
      productState.user != null ? productState.user._id : getUniqueId();

    if (userId) {
      const cartObj = {
        productId: prod._id,
        userId: userId,
        stock: 1,
      };

      dispatch(saveToCart(cartObj));
    }
  };

  const buyNow = (prod) => {
    setDirectCheckout(1);
    setDirectCheckoutItem(prod._id);
    setRedirect(1);
  };

  if (isRedirect) {
    let redirectTo = "";
    if (productState.user != null) {
      redirectTo = "/checkout";
    } else {
      redirectTo = "/login?redirectTo=/checkout";
    }
    return <Redirect to={redirectTo} />;
  }

  if (productState.details && !productState.details.userId) {
    return (
      <div className="col-md-12 mt-5">
        <div className="content-container container">
          <h2>Page Not Found</h2>
        </div>
      </div>
    );
  }

  return (
    <div className="col-md-12 mt-5">
      <Modal isOpen={isModalOpen} style={customStyles}>
        <div className="col-md-12">
          <div className="row">
            <div className="col-md-12">
              <div className="row">
                <div className="col-md-12">
                  <h3>Add Review</h3>
                </div>
                <div className="col-md-12">
                  {starError ? (
                    <div className="col-md-12">
                      <div className="alert alert-danger">
                        Please click on star to add rating
                      </div>
                    </div>
                  ) : null}
                  <div className="form-group col-md-12">
                    <FaStar
                      className={stars >= 1 ? "ONM_icon_color mr-2" : "mr-2"}
                      onClick={() => onClickStars(1)}
                    ></FaStar>
                    <FaStar
                      className={stars >= 2 ? "ONM_icon_color mr-2" : "mr-2"}
                      onClick={() => onClickStars(2)}
                    ></FaStar>
                    <FaStar
                      className={stars >= 3 ? "ONM_icon_color mr-2" : "mr-2"}
                      onClick={() => onClickStars(3)}
                    ></FaStar>
                    {/* <FaStar
                      className={stars === 4 ? "ONM_icon_color" : ""}
                      onClick={() => onClickStars(4)}
                    ></FaStar> */}
                  </div>
                  <div className="form-group col-md-12">
                    <label htmlFor="planId">Review</label>
                    <textarea
                      name="description"
                      value={description}
                      onChange={onChangeDescription}
                      className="form-control"
                    ></textarea>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-12 mt-2">
              <div className="col-md-12">
                <button
                  className="btn btn-primary ml-2 float-right"
                  onClick={() => saveReview()}
                >
                  Add
                </button>
                <button
                  className="btn btn-dark ml-2 float-right"
                  onClick={() => closeModal()}
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>
      </Modal>
      <div className="content-container container">
        {productState.details ? (
          <div className="row">
            <div className="col-md-4">
              <ProductOnmeeCarousel
                items={productState.details.imageId}
                showThumbs={true}
              ></ProductOnmeeCarousel>
            </div>
            <div className="col-md-8">
              <h6 className="storename">
                {productState.details.userId.storeName}
              </h6>
              <hr className="border__space" />
              <h1 className="product-title">{productState.details.name}</h1>
              <div className="col-md-12 p-0 mb-2">
                <div className="row">
                  <div className="col-md-6">
                    <FaStar
                      className={
                        roundItOff(productState.details.star || 0) >= 1
                          ? "OMN-star-active mr-2"
                          : "ONM_icon_color-empty mr-2"
                      }
                    ></FaStar>
                    <FaStar
                      className={
                        roundItOff(productState.details.star || 0) >= 2
                          ? "OMN-star-active mr-2"
                          : "ONM_icon_color-empty mr-2"
                      }
                    ></FaStar>
                    <FaStar
                      className={
                        roundItOff(productState.details.star || 0) >= 3
                          ? "OMN-star-active mr-2"
                          : "ONM_icon_color-empty mr-2"
                      }
                    ></FaStar>
                    {/* <FaStar
                      className={
                        roundItOff(productState.details.star || 0) === 4
                          ? "OMN-star-active mr-2"
                          : "ONM_icon_color-empty mr-2"
                      }
                    ></FaStar> */}
                  </div>
                </div>
              </div>
              <div className="mb-2 product-pricing">
                <span className="ONM_price">Rs {calculatePrice()}</span>
                <span className="ONM_strikethrough ml-3">
                  Rs {productState.details.price}
                </span>
                <span className="ml-3 ONM_offers">
                  {productState.details.offer}%
                </span>
              </div>
              <div className="product-action">
                {/* <a className="ONM_clear-filter float-right ml-3">Call Now</a> */}
                {/* {productState.user &&
                  productState.user.roles.includes("ROLE_USER") ? ( */}
                {1 ? (
                  <>
                    {productState.details.stock > 0 && (
                      <>
                        <div className="cart-action product-buttons">
                          <a
                            className="btn btn-solid"
                            onClick={() => addToCart(productState.details)}
                          >
                            <FaCartPlus className={classes.bell} /> Add to Cart
                          </a>

                          <a
                            className={`${classes.buy__now} btn buy-now ml-2`}
                            onClick={() => buyNow(productState.details)}
                          >
                            <FaRegCreditCard /> Buy Now
                          </a>
                        </div>
                        <div className="wishlist-action">
                          <a
                            className="add-wishlist"
                            onClick={() => addToWishlist(productState.details)}
                          >
                            <FaHeart></FaHeart>
                            Add to Wishlist
                          </a>
                        </div>
                      </>
                    )}

                    {/* <a
                        className="ONM_clear-filter float-right ml-3"
                        onClick={() => openModal()}
                      >
                        Add Reviews
                      </a> */}
                  </>
                ) : (
                  <>
                    {/* {productState.details.stock > 0 && (
                      <div className="cart-action">
                        <a
                          className="btn add-to-cart"
                          onClick={() => addToCart(productState.details)}
                        >
                          <FaCartPlus /> Add to Cart
                        </a>
                      </div>
                    )} */}
                  </>
                )}
                <ModalMap
                  size={14}
                  item={productState.details}
                  button="link"
                ></ModalMap>
              </div>

              <div className="product-meta">
                <div className="mb-2 sku">
                  {productState.details.skuNumber &&
                    "SKU: " + productState.details.skuNumber}
                </div>
                <div className="border border-top-0 border-right-0 border-left-0 mb-2"></div>
              </div>
              <div className="product-desc">
                {productState.details.description}
              </div>
            </div>
            <div className="col-md-4 p-3 text-center">
              {/* <FaFacebook className="mr-2" size={24}></FaFacebook>
              <FaInstagram className="mr-2" size={24}></FaInstagram>
              <FaTwitter className="mr-2" size={24}></FaTwitter>
              <FaLinkedin size={24}></FaLinkedin> */}
            </div>
            <div className="col-md-8"></div>
            <div className="col-md-3 mt-3 mb-3">
              <div className="ONM_review-box">
                <div className="mb-2">
                  <span className="ONM_review-box-first">
                    {roundItOff(productState.details.star || 0)}
                  </span>
                  <span className="">/3</span>
                </div>
                <div className="col-md-12">
                  <FaStar
                    className={
                      roundItOff(productState.details.star || 0) >= 1
                        ? "OMN-star-active mr-2"
                        : "mr-2"
                    }
                  ></FaStar>
                  <FaStar
                    className={
                      roundItOff(productState.details.star || 0) >= 2
                        ? "OMN-star-active mr-2"
                        : "mr-2"
                    }
                  ></FaStar>
                  <FaStar
                    className={
                      roundItOff(productState.details.star || 0) >= 3
                        ? "OMN-star-active mr-2"
                        : "mr-2"
                    }
                  ></FaStar>
                  {/* <FaStar
                    className={
                      roundItOff(productState.details.star || 0) === 4
                        ? "OMN-star-active mr-2"
                        : "mr-2"
                    }
                  ></FaStar> */}
                </div>
                <div className="ONM_review-count">
                  {productState.reviewsCount} Reviews
                </div>
              </div>
            </div>

            <div className="col-md-9 mb-3">
              <DetailTab
                productState={productState}
                handlePageChange={handlePageChange}
                openModal={openModal}
                changeTab={changeTab}
              />
            </div>

            <div className="col-md-12 p-0">
              <div className="container">
                <h1>Recently Viewed</h1>
                <div className="row">
                  <Recents
                    items={productState.recents}
                    addToCart={addToCart}
                    addToWishlist={addToWishlist}
                    userObj={productState.user}
                  ></Recents>
                </div>
              </div>
            </div>
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default ProductDetails;
