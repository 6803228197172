import React, { Fragment } from "react";
import { Container, Row, Col, Media } from "reactstrap";
import visa from "../../../assets/images/icon/paypal.png";
import mastercard from "../../../assets/images/icon/paypal.png";
import paypal from "../../../assets/images/icon/paypal.png";
import americanexpress from "../../../assets/images/icon/paypal.png";
import discover from "../../../assets/images/icon/paypal.png";

const CopyRight = ({ layout, fluid }) => {
  return (
    <Fragment>
      <div className={`sub-footer ${layout}`}>
        <Container fluid={fluid}>
          <Row>
            <Col xl="6" md="6" sm="12">
              <div className="footer-end">
                <p>
                  <i className="fa fa-copyright" aria-hidden="true"></i>
                  2021-2022 | Cimani innovation pvt ltd
                </p>
              </div>
            </Col>
            {/* <Col xl="6" md="6" sm="12">
              <div className="payment-card-bottom">
                <ul>
                  <li>
                    <a href="#">
                      <Media src={visa} alt="" />
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <Media src={mastercard} alt="" />
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <Media src={paypal} alt="" />
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <Media src={americanexpress} alt="" />
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <Media src={discover} alt="" />
                    </a>
                  </li>
                </ul>
              </div>
            </Col> */}
          </Row>
        </Container>
      </div>
    </Fragment>
  );
};

export default CopyRight;
