const TermsOfUseSeller = () => {
  return (
    <div className="content-container container">
      <h2>Privacy Policy</h2>
      <p>
        SERVICE TERMS AND CONDITIONS Welcome to ONMEE. This document is an
        electronic record in terms of Information Technology Act, 2000 (“IT Act,
        2000”), the applicable rules thereunder and the provisions pertaining to
        electronic records in various statutes as amended by the Information
        Technology Act, 2000. This document is published in accordance with the
        provisions of Rule 3 of the Information Technology (Intermediaries
        Guidelines) Rules, 2011 that require publishing the rules and
        regulations, privacy policy and Terms of Use for access to or usage of
        ONMEE marketplace platform - www.onmee.in website. The domain name
        www.onmee.in, including its website and mobile application as well as
        the seller portal (hereinafter referred to as “Platform/
        Website/Application”) is owned and operated by M/s.Cimani Innovation
        Pvt. Ltd. (hereinafter referred to as “ONMEE”), a company incorporated
        under the Companies Act, 1956, with its registered office at Nanjareddy
        Colonyhal, 3rd Stage, Bangalore - 560071 . For the purpose of this Terms
        of Use, wherever the context so requires, ‘You’ or ‘Your’ or “User”
        shall mean any natural or legal person who has agreed to become a seller
        on the Platform by providing registration data while registering on the
        Platform (“Seller”). The terms “ONMEE","We","Us","Our" shall mean
        M/s.Cimani Innovation Pvt. Ltd and its affiliates. Your use of the
        Platform and the features therein is governed by the following terms and
        conditions ("Terms of Use") including applicable policies available on
        the Platform, notifications and communications sent to you on the
        Platform which are incorporated herein by way of reference. If you
        transact on the Platform, you shall be subject to the policies that are
        applicable to the Platform for such a transaction. YOUR AGREEMENT TO
        THESE TERMS OF USE SHALL OPERATE AS A BINDING AGREEMENT BETWEEN YOU AND
        M/s.CIMANI INNOVATION PVT. LTD., IN RESPECT OF THE USE OF THE PLATFORM.
        Your acceptance of the Terms of Use shall be deemed to include your
        acceptance of the privacy policy and all other operating rules,
        policies, and procedures that may be published/amended/updated on the
        Platform. When you use any current or future services provided by us
        through the Platform you will be subject to the rules, guidelines,
        policies, terms and conditions applicable to such services and they
        shall be deemed incorporated into the Terms of Use and considered a part
        and parcel of the Terms of Use. We reserve the right, at our sole
        discretion, to change, modify, add, or remove portions of the Terms of
        Use at any time. We will notify you through any of the communication
        modes as mentioned in this Terms of Use in case of any changes or
        updates to the Terms of Use that materially impact your use of the
        Platform.  Your continued use of the Platform following the changes or
        updates will mean that you accept and agree to the revisions. As long as
        you comply with the Terms of Use, we grant you a non-exclusive,
        non-transferable, and limited privilege to enter and use the Platform.
        ACCESSING, BROWSING, OR OTHERWISE USING THE PLATFORM INDICATES YOUR
        AGREEMENT WITH ALL THE TERMS AND CONDITIONS UNDER THE TERMS OF USE.
        PLEASE READ THE TERMS OF USE CAREFULLY BEFORE PROCEEDING. By impliedly
        or expressly accepting the Terms of Use, you also accept and agree to be
        bound by all of OnMee’s policies applicable to you, as amended, from
        time to time. 1. DEFINITIONS All the defined and capitalized terms in
        these Terms of Use will have the meaning assigned to them here below: i.
        "Account" shall mean the account created by You/the Seller on the
        Application/Platform for availing the Services provided by ONMEE. ii.
        "Applicable Laws" shall mean the laws of India and include all
        applicable statutes, enactments, acts of legislature or parliament,
        laws, ordinances, rules, by-laws, regulations, notifications,
        guidelines, policies, directions, directives and orders of any
        governmental authority, tribunal, or a court of India. iii.
        "Application" shall mean the application named "ONMEE", updated by
        M/s.Cimani Innovation Pvt. Ltd from time to time. iv. "You" means a
        person/user who is a registered seller and has an Account on the
        Application/Website. v. "Force Majeure Event" shall mean any event
        arising due to any cause beyond the reasonable control of ONMEE. vi.
        "ONMEE" or "Us" or "We" or "Our" shall mean M/s.Cimani Innovation Pvt.
        Ltd.., a company incorporated under the Companies Act 2013, and having
        its registered office at Nanjareddy Colonyhal, 3rd Stage, Bangalore -
        560071 , which expression shall, unless it be repugnant to the context
        or meaning thereof, be deemed to mean and include all its successors,
        affiliates and permitted assigns. vii. "Registration Data" shall mean
        and include providing and maintaining the present, valid, true,
        complete, current and updated, accurate name, email ID, phone number and
        such other information, as may be required by ONMEE from the Seller from
        time to time for registration on the Platform. viii. "Service(s)" means
        the services offered by ONMEE through the Platform/Website/Application.
        ix. "Website" shall mean the Application and the website www.onmee.in
        operated by ONMEE or any other software that enables the use of the
        Application or such other URL as may be specifically provided by ONMEE.
        x. “Terms of Use" shall mean these Seller terms and conditions. 1. ABOUT
        ONMEE ONMEE is a marketplace/ ecommerce platform that allows
        Sellers/retailers to market and showcase their Store details, provide
        offers, discounts, advertisements, sell Products and/or provide services
        in various locations. The actual contract for sale is directly between
        the seller and buyer. ONMEE is not a party to the transaction. ONMEE is
        only a facilitator and is not a party to or control in any manner any
        transactions on ONMEE. 2. SELLER ELIGIBILITY You shall be "Eligible" to
        use the Platform only when You fulfill all of the following conditions:
        i. You have attained at least 18 (eighteen) years of age. ii. You are a
        legal person duly registered under law or as an entity or a person have
        the legal capacity/ are competent to enter into a contract under the
        Applicable Laws. iii. If You reside in a jurisdiction that restricts the
        use of the Service because of age, or restricts the ability to enter
        into contracts such as this Terms of Use due to age, then You shall
        abide by the same and you are not authorized to use the Services.. ONMEE
        reserves the right to terminate Your registration and/or refuse Your
        access to the Platform if it is brought to ONMEE’s notice or discovered
        that you are under the age of 18 years. 3. ACCOUNT REGISTRATION AND
        REGISTRATION OBLIGATIONS In the course of your use of the Platform, you
        agree to furnish your details and information as requested by us from
        time to time and You shall ensure that the Registration Data ( name,
        email ID, phone number and such other information including as may be
        required by ONMEE) provided by You is accurate, complete, current, valid
        and true and is updated from time to time. You shall remain responsible
        for maintaining confidentiality of this information, as well as your
        display name, login and password details. We shall bear no liability for
        false, incomplete, old or incorrect registration data provided by You.
        You agree that if you provide any information which is untrue,
        inaccurate, not current, or incomplete or we have reasonable grounds to
        suspect that such information is untrue, inaccurate, not current,
        incomplete, or not in accordance with the Terms of Use, we shall have
        the right to suspend or terminate your account on the Platform with
        immediate effect and /or indefinitely block you from accessing the
        Platform. You agree that ONMEE shall not be liable to You or to any
        third party for any modification, suspension, interruption or
        discontinuance of the Service offered under the Platform. Seller Account
        Deactivation: As per our policy, if a seller requests for a closure of
        the account, the account will be put on hold for a period of 90 days to
        ensure smooth closure of transactions which have been made prior to his
        closure request. After 90 days, the Seller would be required to reach
        out to us confirming that he has completed all the transactions and
        requesting for a deactivation again. Upon receiving this confirmation,
        the seller’s account will be deactivated provided there are no
        outstanding payments due from the seller, with certain information being
        retained by ONMEE at all times, such as registered mobile number,
        registered email ID, GSTIN and other transaction related information.
        Such information is being retained for audit purposes and to prevent
        fraudulent acts by the sellers in the future. If a seller decides to
        commence his business with ONMEE again, he/she will not be able to
        create a new account but the older account can be restored if it is
        required. Seller Obligations: You shall open only one Account in
        association with the registration data provided by You. In case of any
        unauthorized use of Your Account please immediately reach Us
        at ________________ In connection with using or accessing our Platform
        you agree to comply with this Terms of Use, our policies, and all
        applicable laws, rules, and regulations, and you/Seller shall not: •
        post, list or upload content or offers/products/items in inappropriate
        categories on our Platform; • breach or circumvent any laws,
        regulations, third-party rights or our systems, Services, policies, or
        determinations of your account status; • use our Services if you are not
        able to form legally binding contracts (for example, if you are under 18
        years old), or your account is temporarily or indefinitely suspended
        from using our Services; • fail to comply with the terms posted in the
        listing or fail to deliver items sold by you. • manipulate the price of
        any item or interfere with any other user's listings; • post false,
        inaccurate, misleading, deceptive, defamatory, or libelous content; •
        take any action that may undermine the feedback or ratings systems of
        ONMEE. • transfer your ONMEE account to another party without our
        consent; • distribute or post spam, unsolicited or bulk electronic
        communications, chain letters, or pyramid schemes; • distribute viruses
        or any other technologies that may harm ONMEE or the interests or
        property of users; • use any robot, spider, scraper, data mining tools,
        data gathering and extraction tools, or other automated means to access
        our Services for any purpose, except with the prior express permission
        of ONMEE; • interfere with the functioning of our Services, such as by
        imposing an unreasonable or disproportionately large load on our
        infrastructure; • infringe the copyright, trademark, patent, publicity,
        moral, database, and/or other intellectual property rights
        (collectively, "Intellectual Property Rights") that belong to or are
        licensed to ONMEE. Some, but not all, actions that may constitute
        infringement are reproducing, performing, displaying, distributing,
        copying, reverse engineering, decompiling, disassembling, or preparing
        derivative works from content that belongs to ONMEE or someone else; •
        infringe any Intellectual Property Rights that belong to third parties
        affected by your use of our Services or post content that does not
        belong to you; • harvest or otherwise collect information about users
        without their consent; or • circumvent any technical measures used to
        provide our Services. Further, we may cancel unconfirmed accounts or
        accounts that have been inactive for a substantial period of time.
        Additionally, we reserve the right to refuse, modify, or terminate all
        or part of our Services to anyone for any reason at our sole discretion.
        4. PLATFORM FOR TRANSACTION AND COMMUNICATION The Platform is a platform
        that users utilize to independently meet and interact with one another
        for their transactions. ONMEE is not a party to any transaction or
        dispute between users/ buyers and sellers on the Platform. Consequently:
        1.  All commercial/contractual terms are offered by you and agreed upon
        between you and buyers alone. The commercial/contractual terms include
        (without limitation) price, shipping costs, payment methods and terms,
        date, period, and mode of delivery, and warranties and after-sales
        services related to products and services. ONMEE does not determine,
        advice, have any control, or in any way involve itself in the offering
        or acceptance of such commercial/contractual terms between you and
        buyers. All discounts and offers posted are by the Sellers and not by
        ONMEE. 2.  ONMEE does not make any representations or warranties
        regarding specifics (such as quality, value, and salebility) of the
        products or services proposed to be sold, offered to be sold or
        purchased on the Platform. ONMEE does not implicitly or explicitly
        support or endorse the sale or purchase of any products and services on
        the Platform. ONMEE accepts no liability for any errors or omissions of
        third parties in relation to the products and services. 3.  ONMEE is not
        responsible for any non-performance or breach of any contract between
        you and buyers. ONMEE cannot and does not guarantee that you and buyers
        concerned will perform transaction(s) concluded on the Platform. ONMEE
        shall not and is not required to mediate or resolve disputes or
        disagreements between you and buyers. 4.  ONMEE does not make any
        representations or warranties regarding product/service-specifics (such
        as legal title, creditworthiness, identity, etc.) of any of its users.
        You are advised to independently verify the bona fides of any particular
        buyer you choose to deal with on the Platform and use your best judgment
        in that regard. 5.  ONMEE does not at any point in time during a
        transaction between you and a buyer on the Platform come into or take
        possession of any of the products or services offered by you, gain title
        to or have any rights or claims over the products or services offered by
        you to the buyer. 6.  At no time shall ONMEE hold any right/title to or
        interest in the products/servicesnor have any obligations or liabilities
        with respect to such a contract. ONMEE is not responsible for
        unsatisfactory or delayed performance of services, damages, or delays as
        a result of items which are out of stock, unavailable, or back-ordered.
        7.  The Platform is only a platform that can be utilized by you to reach
        a larger customer base to post offers, sell products/items or services.
        ONMEE only provides a platform for communication and it is agreed that
        the contract for sale of any products or services shall be a strictly
        bipartite contract between you and the buyer. 8.  You release and
        indemnify ONMEE and/or any of its officers and representatives from any
        cost, damage, liability or other consequence of any of the actions of
        the users on the Platform and specifically waive any claims that you may
        have in this behalf under any applicable law. Notwithstanding its
        reasonable efforts on that behalf, ONMEE cannot control the information
        provided by other users which is made available on the Platform. You may
        find other user's information to be offensive, harmful, inaccurate or
        deceptive. Please use caution and practice safe trading when using the
        Platform. Please note that there may be risks in dealing with underage
        persons or people acting under false pretence. Listing Conditions on the
        Platform As a registered seller, when listing a Product/item for sale on
        our Platform, you agree to comply with the following: You assume full
        responsibility for the Product/Services offered and the accuracy and
        content of the listing, All listed products/items/offers shall be kept
        in stock/valid for successful fulfillment of sales. The listing
        description of the product/item /offer must not be misleading and must
        describe the actual condition of the product/item and also the period of
        such offer . If the product/item description does not match the actual
        condition of the product/item, You agree to refund any amounts that you
        may have received from the Buyer. You agree not to list a single product
        in multiple quantities across various categories on the Platform. ONMEE
        reserves the right to delete such multiple listings of the same product
        listed by you in various categories. Your listing may not be immediately
        searchable by keyword or category for several hours (or up to 24 hours
        in some circumstances). ONMEE doesnot guarantee any exact listing
        duration, Content that violates any of ONMEE 's policies may be
        modified, obfuscated or deleted at ONMEE 's sole discretion, We may
        revise Product data associated with listings to supplement, remove, or
        correct information, We strive to create a marketplace where buyers find
        offers and the products they are looking for. Therefore, the appearance
        or placement of listings in search and browse results will depend on a
        variety of factors, including, but not limited to: • buyer's location,
        search query, browsing site, and history; • Product 's location, listing
        format, price and shipping cost, terms of service, history, and
        relevance to the user query; • seller's history, including listing
        practices, Seller Ratings, ONMEE policy compliance, Feedback, and defect
        rate; and • number of listings matching the buyer's query, To drive a
        positive user experience, a listing may not appear in some search and
        browse results regardless of the sort order chosen by the buyer,
        Metatags and URL links that are included in a listing may be removed or
        altered so as to not affect third-party search engine results, You agree
        that we may display the sales and performance history of your individual
        listings to other sellers, You shall not sell and agree to promptly
        remove all listings for any product recalled by a manufacturer or
        governmental agency if the sale of the product is prohibited by law or
        regulation or the product poses a health or safety hazard as specified
        by any governmental agency. ONMEE has no responsibility or liability for
        the safety or performance of any product that you offer for sale, list
        or sell through the Platform, including any product that is subject to a
        recall. You are solely responsible for any non-conformity or defect in,
        or compliance with any public or private recall of any product you list
        or sell through the Services. 5. USE OF THE PLATFORM i. You agree and
        acknowledge that ONMEE and the Platform merely provide hosting services
        to its registered users and persons browsing/visiting the Platform. All
        Products advertised / listed, including Store details, offers/discounts,
        advertisements etc. and the contents therein are advertised and listed
        by registered users and are third party user generated contents. ONMEE
        shall bear no responsibility or liability in relation to or arising out
        of third party user generated content. ONMEE neither originates nor
        initiates the transmission nor selects the sender and receiver of the
        transmission nor selects nor modifies the information contained in the
        transmission.  ONMEE is merely an intermediary and does not interfere in
        the transaction between buyers and sellers. ii. You agree, undertake and
        confirm that your use of the Platform shall be strictly governed by the
        following binding principles: You shall not host, display, upload,
        modify, publish, transmit, update or share any information or image
        which: (a) belongs to another person and over which you have no right;
        (b) is grossly harmful, harassing, blasphemous, defamatory, bigotry,
        obscene, pornographic, pedophilic, libelous, invasive of another’s
        privacy, hateful, or racially, ethnically objectionable, disparaging,
        relating to or encouraging money laundering or gambling, or otherwise
        unlawful in any manner whatever, or unlawfully threatening or harassing,
        including but not limited to ‘indecent representation of women’ within
        the meaning of the Indecent Representation of Women (Prohibition) Act,
        1986; (c) is false, inaccurate  or misleading in any way; (d) is
        patently offensive to the online community, such as sexually explicit
        content or content that promotes obscenity, pedophilia, racism, bigotry,
        hatred, or physical harm of any kind against any group or individual;
        (e) harasses or advocates harassment of another person; (f) involves the
        transmission of ‘junk mail’, ‘chain letters’, unsolicited mass mailing,
        or ‘spamming’; including spamming by ways of unrelated feedbacks on
        surveys (g) promotes illegal activity or conduct that is abusive,
        threatening, obscene, defamatory, or libelous; (h) infringes upon or
        violates any third party's rights [including but not limited to
        intellectual property rights, rights of privacy (including without
        limitation unauthorized disclosure of a person's name, email address,
        physical address, or phone number) or rights of publicity]; (i) promotes
        an illegal or unauthorized copy of another person's copyrighted work
        (see “Copyright complaint" below for instructions on how to lodge a
        complaint about uploaded copyrighted material) such as providing pirated
        computer programs or links, information to circumvent
        manufacturer-installed copy-protect devices, or pirated music or links
        to pirated music files; (j) contains restricted or password-only access
        pages, hidden pages or images or URLs leading to any other pages (those
        not linked to or from another accessible page); (k) provides material
        that exploits people in a sexual, violent or otherwise inappropriate
        manner or solicits personal information from anyone;  (l) provides
        instructional information about illegal activities such as making or
        buying illegal weapons, violating someone's privacy, providing or
        creating computer viruses; (m) contains unauthorized videos, photographs
        or images of another person (whether a minor or an adult); (n) tries to
        gain unauthorized access or exceeds the scope of authorized access to
        the Platform, profiles, blogs, communities, account information,
        bulletins, friend requests, or other areas of the Platform, or solicits
        passwords or personal identifying information for commercial or unlawful
        purposes from other users on the Platform; (o) engages in commercial
        activities and/or sales such as contests, sweepstakes, barter,
        advertising, pyramid schemes, or the buying or selling of ‘virtual’
        items related to the Platform without our prior written consent.
        Throughout the Terms of Use, ONMEE’s prior written consent means a
        communication coming from ONMEE in response to your request and
        specifically addressing the activities or conduct for which you have
        sought authorization; (p) solicits gambling or engages in any gambling
        activity which we, at our sole discretion, believe is or could be
        construed as being illegal; (q) interferes with another’s use and
        enjoyment of the Platform; (r) refers to any website/URL which, at our
        sole discretion, contains material that is inappropriate for the
        Platform or any other website and content that is prohibited or violates
        the letter and spirit of Terms of Use; (s) harms minors in any way; (t)
        infringes any patent, trademark, copyright, proprietary rights,
        third-party’s trade secrets, rights of publicity, or privacy, is
        fraudulent, or involves the sale of counterfeit or stolen items; (u)
        violates any law for the time being in force; (v) deceives or misleads
        the addressee/ users about the origin of messages or communicates any
        information which is grossly offensive or menacing in nature; (w)
        impersonates another person; (x) contains software viruses or any other
        computer codes, files, or programs designed to interrupt, destroy, or
        limit the functionality of any computer resource; or contains any trojan
        horses, worms, time bombs, cancelbots, easter eggs, or other computer
        programming routines that may damage, detrimentally interfere with,
        diminish value of, surreptitiously intercept, or expropriate any system,
        data, or personal information; (y) threatens the unity, integrity,
        defense, security or sovereignty of India, friendly relations with
        foreign states, or public order or causes incitement to the commission
        of any offence or prevents investigation of any offence or is insulting
        any other nation; offends the religious and national sentiments of the
        nation.  (z) shall, directly or indirectly, offer or attempt to offer
        trade or attempt to trade in any item which is prohibited or restricted
        in any manner under the provisions of any applicable law, rule,
        regulation or guideline for the time being in force; (aa) shall create
        liability for us or cause us to lose (in whole or part) the services of
        our Internet Service Provider (“ISPs”) or other suppliers. iii. You
        shall not use any ‘deep-link’, ‘page-scrape’, ‘robot’, ‘spider’,
        automatic device, program, algorithm, methodology, or any similar or
        equivalent manual process to access, acquire, copy, monitor any portion
        of the Platform or content or in any way reproduce, or circumvent the
        navigational structure, presentation of the Platform, or any content to
        obtain or attempt to obtain any material, documents, or information
        through any means not purposely made available through the Platform. We
        reserve our right to bar any such activities. iv. You shall not attempt
        to gain unauthorized access to any portion or feature of the Platform,
        other systems, networks connected to the Platform, server, computer,
        network, or the services offered on or through the Platform by hacking,
        password ‘mining’, or any other illegitimate means. v. You shall not
        probe, scan or test the vulnerability of the Platform or any network
        connected to the Platform or breach the security, authentication
        measures on the Platform or any network connected to the Platform. You
        may not reverse look-up, trace or seek to trace information on any other
        user of or visitor to Platform (including any account on the Platform
        that is not owned by you) or to its source or exploit the Platform, any
        service, information made available, or offered by or through the
        Platform in any way where the purpose is to reveal any information
        (including but not limited to personal identification or information
        other than your own information) provided by the Platform. vi. You shall
        not make any negative, denigrating, or defamatory
        statement(s)/comment(s) about us, the brand name or domain name used by
        us, including the terms ONMEE or otherwise engage in any conduct or
        action that might tarnish the image or reputation of ONMEE or sellers on
        the platform or otherwise tarnish or dilute any ONMEE trademark, service
        marks, trade name and/or goodwill associated with such trade, service
        marks or trade name as may be owned or used by us. You agree that you
        will not take any action that imposes an unreasonable or
        disproportionately large load on the infrastructure of the Platform or
        ONMEE’s systems, networks, or any systems or networks connected to
        ONMEE. vii. You agree not to use any device, software or routine to
        interfere or attempt to interfere with the proper working of the
        Platform, any transaction being conducted on the Platform or any other
        person’s use of the Platform. viii. You shall not forge headers or
        otherwise manipulate identifiers in order to disguise the origin of any
        message, transmittal you send to us on or through the Platform, or any
        service offered on or through the Platform. You may not pretend that you
        are or represent someone else or impersonate any other individual or
        entity. ix. You may not use the Platform or any content for any purpose
        that is unlawful or prohibited by the Terms of Use or to solicit the
        performance of any illegal activity or other activity which infringes
        the rights of ONMEE and/or others. x. You shall at all times ensure full
        compliance with the applicable provisions of the Information Technology
        Act, 2000, and the other rules thereunder as applicable and amended from
        time to time and also all applicable laws, rules and regulations
        (including the provisions of any applicable Exchange Control Laws or
        Regulations in force) and International Laws, Foreign Exchange Laws,
        Statutes, Ordinances and Regulations (including, but not limited to
        Central Goods and Services Tax Act, Food Safety and Standards Authority
        of India (FSSAI), Custom Duty, Local Levies as may be applicable) and
        obtain the necessary licences and permits regarding your use of our
        Platform, service and/or tools and your listing, purchase, solicitation
        of offers to purchase, and sale of Products/items or services. You shall
        not engage in any transaction in an item or service, which is prohibited
        by the provisions of any applicable law including exchange control laws
        or regulations for the time being in force. xi. Solely to enable us to
        use the information you provide us with and so that we do not violate
        any rights you might have in your information, you agree to grant us a
        non-exclusive, worldwide, perpetual, irrevocable, royalty-free, and
        sub-licensable (through multiple-tiers) right to exercise the copyright,
        publicity or database rights or any other rights you have in your
        information for any current or future promotional or advertising related
        activities and any business purposes of ONMEE.      xii. From time to
        time you shall be responsible for providing information relating to the
        Products/items, offers/discounts, or services proposed to be sold by you
        on the Platform. In this connection, you undertake that all such
        information shall be accurate in all respects. You shall not exaggerate
        or over emphasize the attributes of such items or services so as to
        mislead other users on the Platform in any manner. xiii. You shall not
        engage in advertising or solicitation of other sellers on the Platform
        to buy or sell any products or services, including but not limited to
        products or services related to what is displayed on the Platform. You
        may not transmit any chain letters or unsolicited commercial or junk
        email to other users acquired/via the Platform. It shall be a violation
        of the Terms of Use to use any information obtained from the Platform in
        order to harass, abuse, or harm others or contact, advertise and sell to
        or solicit persons other than those who have chosen to buy from you. You
        understand that we have the right at all times to disclose any
        information (including the identity of the persons who have provided
        information or content on the Platform) as necessary to satisfy any law,
        regulation, or valid governmental request. This may include, without
        limitation, disclosure of the information in connection with the
        investigation of an alleged illegal activity or its solicitation and/or
        response to a lawful court order or subpoena. In addition, we may (and
        you hereby expressly authorized us to) disclose any information about
        you to law enforcement or other government officials as we, at our sole
        discretion, deem necessary or appropriate in connection with the
        investigation and/or resolution of possible crimes, especially those
        that may involve personal injury. We reserve the right, but have no
        obligation, to monitor the content posted on the Platform. ONMEE shall
        have the right, at its sole discretion, to remove any content that
        violates or is alleged to violate any applicable law or either the
        spirit or letter of the Terms of Use. Notwithstanding this right, YOU
        REMAIN SOLELY RESPONSIBLE FOR THE CONTENT YOU POST ON THE PLATFORM AND
        YOUR INDEPENDENT COMMUNICATION WITH THE BUYERS AND OTHER SELLERS,
        REGARDLESS OF FORM. Please be advised that such content posted does not
        reflect ONMEE’s views. In no event shall ONMEE assume or have any
        responsibility or liability for any content posted on the Platform or
        claims, damages, or losses resulting from its use and/or appearance of
        it on the Platform. You hereby represent and warrant that you have
        necessary rights to all the content you provide and all information it
        contains and that such content shall not infringe any proprietary or
        other rights of third parties or contain any misleading, libelous,
        tortious, or otherwise unlawful information. xiv. Your correspondence or
        business dealings with or participation in the promotion of advertisers
        on or through the Platform (including payment and delivery of related
        products or services, any other terms, conditions, warranties, or
        representations associated with such dealings) are solely between you
        and such advertisers. We shall not be responsible or liable for any loss
        or damage of any sort incurred as a result of such dealings or the
        presence of such advertisers on the Platform. xv. It is possible that
        other users (including unauthorized persons or ‘hackers’) may post or
        transmit offensive or obscene content on the Platform and that you may
        be involuntarily exposed to such content. It is also possible for others
        to obtain personal information about you due to your use of the Platform
        and use such information to harass or injure you. We do not approve of
        such unauthorized uses but by using the Platform, you acknowledge and
        agree that we are not responsible for the use of any personal
        information that you publicly disclose or share with others on the
        Platform. Please carefully select the type of information that you
        publicly disclose or share with others on the Platform. xvi. ONMEE shall
        have all the rights to take necessary action and claim damages that may
        occur due to your involvement/participation in any way on your own or
        through group(s) of people, intentionally or unintentionally, in denial
        of service. 6. SELLING As a registered seller, you shall list
        Products/item(s) for sale on the Platform in accordance with the
        policies which are incorporated by way of reference in this Terms of
        Use. You represent and warrant that You are legally able to sell the
        item(s) you list for sale on our Platform and have all the necessary
        licences and permits required for such sale. You shall also ensure that
        the listed items do not infringe upon the intellectual property, trade
        secret or other proprietary rights or rights of publicity or privacy
        rights of third parties. Listings may only include text descriptions,
        graphics, pictures or videos that describe your products for sale. All
        products/items must be listed in an appropriate category on the
        Platform. All listed items must be kept in stock for successful
        fulfilment of sales. The listing description of the product/item must
        not be misleading and must describe the actual condition of the product.
        If the product/item description does not match the actual condition of
        the product/item, you agree to refund any amount that you may have
        received from the buyer. You agree not to list a single product in
        multiple quantities across various categories on the Platform. ONMEE
        reserves the right to delete such multiple listings of the same product
        listed by you in various categories. ONMEE reserves the right to
        restrict the selling of products originating from certain countries. For
        Food category, seller shall adhere to the minimum balance shelf life
        norms. 7. CONTENT POSTED ON THE PLATFORM All text, graphics, seller
        interfaces, visual interfaces, photographs, trademarks, logos, sounds,
        music and artwork, notes, messages, emails, billboard postings,
        drawings, profiles, opinions, ideas, images, videos, audio files, other
        material or information (collectively ‘Content’) are third-party
        generated Content and ONMEE has no responsibility or liability over such
        third-party generated Content as ONMEE is merely an intermediary for the
        purposes of this Terms of Use. Except as expressly provided in the Terms
        of Use, no part of the Platform including the Content may be copied,
        reproduced, republished, uploaded, posted, publicly displayed, encoded,
        translated, transmitted or distributed in any way (including
        ‘mirroring’) to any other computer, server, website or other medium for
        publication, distribution or any commercial enterprise without ONMEE’s
        prior written consent. You may use the information on the products and
        services made available on the Platform for downloading provided you:
        (1) do not remove any proprietary notice language in all copies of such
        Content; (2)  use such Content only for your personal, non-commercial
        informational purpose and do not  copy or post such information on any
        networked computer or broadcast it to any media; (3) make no
        modifications to any Content; and (4) do not make any additional
        representations or warranties relating to the Content. You shall be
        responsible for the Content posted or transmitted on the Platform by
        You. The Content will become our property and you grant us the
        worldwide, perpetual, royalty free and transferable rights in such
        content. We shall be entitled to, consistent with our privacy policy as
        adopted in accordance with applicable law,  use the Content or any of
        its elements for any type of purpose forever, including, but not limited
        to, promotional and advertising purposes in any media, whether now known
        or hereafter devised or the creation of derivative work. You agree that
        any Content you post may be used by us, consistent with this TERMS OF
        USE, and you are not entitled to any payment or other compensation for
        such use. 8. PAYMENT 1. Transactions, transaction price and all
        commercial terms such as delivery, dispatch of products  and/or services
        are as per principal to principal bipartite contractual obligations
        between sellers and buyers and the payment facility is merely used by
        sellers and buyers to facilitate the completion of transactions. Use of
        the payment facility shall not render ONMEE liable or responsible for
        non–delivery, non-receipt, non-payment, damage, breach of
        representations and warranties, non-provision of after-sales or warranty
        services or fraud as regards the products and/or services listed on the
        Platform. 2. You have specifically authorized ONMEE or its service
        providers to collect, process, facilitate, and remit payments and/or the
        transaction price electronically or through cash on delivery (CoD) to
        and from buyers in respect of transactions through payment facility.
        Your relationship with ONMEE is on a principal to principal basis and by
        accepting the Terms of Use, you agree that ONMEE is an independent
        contractor for all purposes and does not have control of or liability
        for the products or services that are listed on the Platform and paid
        for by using the payment facility. ONMEE does not guarantee the identity
        of any User nor does it ensure that a buyer or a seller will complete a
        transaction. 3. You understand, accept, and agree that the payment
        facility provided by ONMEE is neither a banking nor financial service,
        but merely a facilitator providing payment processing facility for for
        transactions on the Platform. Further, by providing payment facility,
        ONMEE neither acts as a trustee nor fiduciary with respect to
        transaction or transaction price. It is hereby clarified that cash on
        delivery (COD) option may not be available for certain products or
        categories, at ONMEE’s sole discretion. 9. DISPATCH OF PRODUCTS AND/OR
        PROVISION OF SERVICES 1.  You, as a seller, shall be required to
        dispatch the products and/or services for every transaction to the buyer
        within the time period as provided in the TERMS OF USE to ensure that
        the products and/or services are delivered in a timely manner. Further,
        you will solely be responsible for undertaking transit insurance for
        products sold by You on the Platform. For avoidance of doubt, ONMEE will
        not be responsible for undertaking any insurance(s) for products sold by
        sellers on the Platform. 2.  Seller shall provide dispatch details and
        details of after-sales services related to products and services listed
        by it on the Platform to ONMEE, failing which the transaction shall
        stand cancelled. 3.  The Seller acknowledges and agrees to use ONMEE 's
        delivery channel/delivery partner (logistic service provider as approved
        by ONMEE) who is authorized to deliver the Product on behalf of ONMEE
        and Seller, which provides appropriate ‘proof of dispatch’ & ‘proof of
        delivery’ (PoDs) documentation. Such PoD documentation relating to
        delivery shall be maintained by a Seller for a period of 3 (three) years
        from the date of dispatch. The PoDs should be furnished to ONMEE on
        demand within the time frame as notified from time to time. In case
        there is no delivery option at Seller’s location, then the Seller may
        dispatch/deliver the Product at its/his/her own risk and responsibility
        and with no obligation on part of ONMEE. 4.  Seller agrees that the
        dispatch details shall be true, correct, and duly authorized and shall
        not be misleading, fraudulent, false, unauthorized, illegal and shall
        not contain any misrepresentation of facts. 5.  In case a seller fails
        to provide dispatch details or provides dispatch details not complying
        with its terms and policies, it shall result in consequences as more
        specifically stated in the TERMS OF USE and may lead to suspension
        and/or termination of seller account. 10. SERVICE FEE/CHARGES
        Registration on the Platform is free. ONMEE does not charge any fee for
        browsing/registering on the Platform, listing a Product or service for
        sale, posting offers/discounts, displaying Store details through the
        Platform. However, for publishing advertisements on the Platform, ONMEE
        shall charge You a fee (“Service Fee/Fee”). ONMEE reserves the right to
        revise the Fee from time to time . In particular, ONMEE may, at its sole
        discretion, introduce new services and modify some or all of the
        existing services offered on the Platform. In such an event, ONMEE
        reserves the right to introduce fees for the new services offered or
        amend/introduce fees for existing services, as the case may be.. You
        shall be solely responsible for compliance with all Applicable Laws for
        making payments to ONMEE. 11. GST /TAXES:  You are responsible for
        paying all fees associated with the use of the Platform and charges
        collected in respect of such usage.  You agree to bear any and all
        applicable taxes, charges, cesses levied thereon (including GST cess as
        may be applicable to the transaction). In case of any chargebacks levied
        by the bank, ONMEE shall have the right to deduct such chargebacks from
        seller remittances, present and future, and a seller’s only remedy will
        be to discuss and resolve the same with the bank. A seller hereby agrees
        to extend full co-operation in resolving the chargeback disputes raised
        by a buyer through the bank and shall provide necessary documentation
        regarding the transaction to the complete satisfaction of the bank. In
        case the chargeback is ruled against a seller, ONMEE shall be entitled
        and authorized to recover the same from the seller to its fullest extent
        and the bank’s decision shall be final and binding in this regard. In
        the event ONMEE has made any excess payment to the seller inadvertently,
        such excess payments shall be set-off from any future payments payable
        by ONMEE to the seller. ONMEE may delay notifying the payment
        confirmation, i.e. informing the seller to dispatch if ONMEE deems
        suspicious or a buyer conducts high transaction volumes to ensure safety
        of the transaction and transaction price. In addition, ONMEE may hold
        transaction price and not inform seller to dispatch or remit transaction
        price to law enforcement officials (instead of refunding the same to a
        buyer) at the request of law enforcement officials or in the event of a
        buyer being engaged in any form of illegal activity. Sellers acknowledge
        that ONMEE will not be liable for any damages, interests, claims etc.
        resulting from not processing a transaction/transaction price or any
        delay in processing a transaction/transaction price that is beyond the
        control of ONMEE. ONMEE shall make payments into the bank account
        provided by a seller during the seller registration process. Once ONMEE
        has made payments into such a bank account number, ONMEE shall be
        discharged of any/all liabilities towards the seller and the seller
        shall not be eligible for any claims whatsoever. 12. COMPLIANCE WITH
        LAWS: The Sellers shall always be in compliance with applicable laws The
        Seller shall maintain in force all the necessary licenses, permissions,
        authorizations, and permits needed to distribute, market, supply and
        sell the Products/items on the Platform under applicable laws, from time
        to time, including, the Food Safety and Standards Act, 2006, Food safety
        and standards (licensing and registration of food business),
        Regulations, 2011 and all applicable legislations under FoodSafety and
        Standards Regulations, in each case, as amended from time to time.
        Seller shall be required to enter the mandatory attributes while listing
        products on the platform (including but not limited to the country of
        origin of the product) as provided under the Legal Metrology Packaged
        Commodity Rules and the related amendments.   In the event of sale of
        jewellery, the seller shall provide a hallmark certificate (in
        accordance with applicable laws) along with the product at the time of
        delivery. Further, it shall be sole responsibility of the Seller to
        comply with hallmarking or other similar provisions applicable for the
        sale of jewellery and ONMEE shall not be liable whatsoever for any
        non-compliance in this regard. In the event of sale of jewellery, Seller
        shall ensure conducting buyer KYC in accordance with the prevention of
        anti-money laundering laws and other applicable laws. ONMEE hereby
        disclaims any responsibility for conducting Buyer KYC. Seller shall
        comply with all laws and regulations applicable, including but not
        limited to anti-money laundering (including ‘know your customer’ and
        ‘customer due diligence’), and economic sanctions laws and regulations.
        Neither party will engage in a transaction pursuant to this Terms of Use
        that will cause the other party to violate such regulations.     If and
        to the extent that you collect, access, use, store, record, or otherwise
        process (collectively “Process”) any personally identified or
        identifiable information such as name, age, gender, email address,
        physical address, phone number, in any form that can be linked to a
        specific individual (“Personal Information”) received by you from or on
        behalf of ONMEE (or any ONMEE group company) employees, contractors,
        users, partners, or other third parties or otherwise obtained in
        connection with performing your obligations under this Terms of Use
        (“ONMEE’s Personal Information"), you agree to:  a. comply with
        applicable data protection laws, rules and regulations governing the
        collection, use, protection, breach notification, retention, disclosure
        of Personal Information including but not limited to Information
        Technology Act, 2000 and the Information Technology (Reasonable Security
        practices and procedures and sensitive personal data or information)
        Rules, 2011 (“Applicable Data Protection Law”), including any
        requirements applicable to storage or cross-border transfer of Personal
        Information outside India; b. keep and maintain all ONMEE’s Personal
        Information in strict confidence and the obligation to protect Personal
        Information shall survive in perpetuity; and c. process ONMEE’s Personal
        Information solely to fulfil Your obligations under this Terms of Use
        and not sell, rent, lease or otherwise make an unauthorized disclosure
        of ONMEE’s Personal Information to any third party.  If and to the
        extent that you provide yours or others’ Personal Information to ONMEE
        for processing, you warrant that such Personal Information was collected
        lawfully, and there is no restriction on you under the Applicable Data
        Protection Law, from providing such Personal Information to ONMEE or any
        processing by ONMEE under this Terms of Use. We will only use
        information provided by you in accordance with this Terms of Use and our
        Privacy Policy.   Products must adhere to the legal metrology laws and
        Indian Labelling requirements by ensuring that the indicated weights and
        measurement indicated in the Products and/or its components are
        commensurate with the mandatory and legal requirements of the same which
        make the product and/or components accurate, authentic and secure for
        consumption/utilisation by the customer. ONMEE does not assume any
        responsibility for the proceedings undertaken by an aggrieved
        customer/consumer  against the seller in the event of a non-adherence.
        However, in the event of a complaint from a consumer/customer, the
        seller is obligated to answer queries on the non-adherence to the Legal
        Metrology Laws, failing which ONMEE can temporarily/indefinitely
        suspend/terminate/block/with-hold your account at its own discretion.  
        13. PRODUCT DESCRIPTION ONMEE does not warrant that product description
        or other content (including Store details, offers, discounts,
        advertisement) on the Platform is accurate, complete, reliable, current,
        or error-free and assumes no liability in this regard. 14. AUDITS ONMEE
        shall have the right to inspect and audit Your/seller’s records and
        premises / place of business through itself or through ONMEE approved
        third party agencies. The cost of such an audit shall solely be borne by
        ONMEE unless the audit reflects discrepancy in seller accounts /
        non-compliance with ONMEE’s seller policies, in which case the cost of
        audit shall be borne by the Seller. 15. VIOLATION OF TERMS OF USE/BREACH
        Without limiting other remedies, we may limit your activity, immediately
        remove your information, warn other users of your actions immediately,
        temporarily/indefinitely suspend/terminate/block your account and/or
        refuse you access to the Platform, or put your account on hold, in the
        event of, including but not limited to, the following: 1. if you breach
        the Terms of Use, privacy policy or other policies (if any); 2. if we
        are unable to verify or authenticate any information you provide;  3. if
        it is believed that your actions may cause legal liability for you,
        other users, or us; or 4. if you do not produce the legal requirement
        documents such as, the documents required for product sales in the Drugs
        and Cosmetics category, the BIS license documents, the Brand
        Authorisation letter, or a Trademark registration proof, as may be
        required by ONMEE.  We may at any time, at our sole discretion,
        reinstate suspended sellers. A seller that has been suspended or blocked
        may not register or attempt to register with us or use the Platform
        (through itself or any other entity or legal form) in any manner
        whatsoever until such time that such a seller is reinstated by us.
        Notwithstanding the foregoing, if you breach the Terms of Use or other
        rules and policies, we reserve the right to recover any amounts due and
        owed by you to us and take strict legal action, including but not
        limited to reporting You to the law enforcement authorities or otherwise
        taking appropriate legal action against you. 16. INDEMNITY You shall
        indemnify and hold harmless ONMEE and their affiliates, subsidiaries,
        group companies (as applicable) and each and any of its officers, ,
        licensors, and their respective officers, directors, agents, and
        employees from any claim, demand, or actions including reasonable
        attorneys' fees made by any third party or penalty imposed due to or
        arising out of (i) Your breach of the Terms of Use, privacy policy and
        other policies or your violation of any law, rules, regulations or the
        rights (including infringement of intellectual property rights) of a
        third party(ii) Your use or misuse of the Platform. 17. TRADEMARK
        COMPLAINT ONMEE respects the intellectual property of others. In case
        you feel that your trademark has been infringed, you can write to ONMEE
        at _____________________ 18. COPYRIGHT COMPLAINT ONMEE respects the
        intellectual property of others. In case you feel that your work has
        been copied in any way that constitutes copyright infringement you can
        write to ONMEE at _______________________________. 19. TRADEMARK,
        COPYRIGHT AND RESTRICTION The Platform is controlled and operated by
        ONMEE and products, offers, discounts are sold and displayed by the
        respective registered sellers. All contenton the Platform, including
        images, illustrations, audio clips, and video clips, are protected by
        copyrights, trademarks and other intellectual property rights. You shall
        not copy, reproduce, republish, upload, post, transmit, or distribute
        ONMEE’s or other sellers’ contentin any way, including by email or other
        electronic means and whether, directly or indirectly, you must not
        assist any other person to do so. Without the prior written consent of
        the owner, modification or use of the content on any other
        website/networked computer environment or for any purpose other than
        personal, non-commercial use is a violation of the copyrights,
        trademarks, and other proprietary rights and the same is prohibited. Any
        use for which you receive any remuneration, whether money or otherwise,
        is a commercial use for the purposes of this clause. 20. LIMITATION OF
        LIABILITY ONMEE shall not be liable for any damages resulting from the
        use of or inability to use the Website/Application, including damages
        caused by wrong usage of the Platform, error in call centre number,
        network issues, malware, viruses or any incorrectness or incompleteness
        of the Information on the Application/Website. IN NO EVENT SHALL ONMEE
        BE LIABLE FOR ANY SPECIAL, INCIDENTAL, INDIRECT, OR CONSEQUENTIAL
        DAMAGES OF ANY KIND IN CONNECTION WITH THE TERMS OF USE, EVEN IF ONMEE
        HAS BEEN INFORMED IN ADVANCE OF THE POSSIBILITY OF SUCH DAMAGES. 21.
        INVALIDITY OF ONE OR MORE PROVISIONS The invalidity of any term of these
        Terms of Use shall not affect the validity of the other provisions of
        these Terms of Use. If and to the extent that any provision of these
        Terms of Use is invalid, or is unacceptable in the given circumstances,
        a provision shall apply between the parties instead that is acceptable
        considering all the circumstances, taking into account the content and
        the purpose of these Terms of Use. 22. APPLICABLE LAW& DISPUTE
        RESOLUTION The Terms of Use shall be governed, interpreted, and
        construed in accordance with the laws of India. The place of
        jurisdiction shall exclusively be Bangalore. Any dispute, claim or
        controversy arising out of or relating to these Terms of Use or the
        breach, termination, enforcement, interpretation or validity thereof or
        the use of the Website, the Service or the Application
        (collectively, "Disputes") the parties shall attempt to settle the same
        amicably, through negotiation and consultation at such offices of ONMEE
        as ONMEE may designate. In the event the dispute is not resolved
        internally between after at least 30 (thirty) days of negotiation, in
        good faith, the same shall be subject to binding and final arbitration
        in accordance with the Arbitration and Conciliation Act, 1996 as amended
        from time to time or in case the Arbitration and Conciliation Act, 1996
        is no longer in force, as per any law relating to arbitration in force
        at the time of such reference. The reference shall be made to a sole
        arbitrator mutually appointed by ONMEE and You. The place of the
        arbitration shall be Bengaluru, Karnataka, unless otherwise mutually
        agreed by ONMEE and You in writing. Subject to the above, any Dispute
        will be subject to the exclusive jurisdiction of courts in Bangalore,
        India. 23. NOTICE ONMEE may give notice by means of a general notice on
        the Service or Application, or by electronic mail to Your email address
        or a message on Your registered mobile number, or by written
        communication sent by regular mail to Your address on record in ONMEE’s
        account information. You may contact ONMEE by electronic mail at Our
        email address __________________or by written communication sent by
        regular mail to Our address at
        ___________________________________________________. 24. ASSIGNMENT You
        shall not assign Your rights/obligations under these Terms of Use
        without prior written approval of ONMEE. ONMEE can assign its rights and
        obligations under the Terms of Use to any third party. 25. CONFLICT In
        the event of any contradiction or inconsistency between this Terms of
        Use and any other agreement executed between You and ONMEE, the terms of
        the Terms of Use shall prevail unless the exception has been expressly
        agreed to in writing by making reference to the relevant Clause sought
        to be modified under this Terms of Use. 26. MODIFICATION OF THE SERVICE
        AND TERMS OF USE ONMEE reserves the right, at its sole discretion, to
        modify or replace, in part or full, any of these Terms of Use, or
        change, suspend, block, discontinue or restrict your use to all or any
        feature of the Service or Application at any time. ONMEE shall not be
        required to notify You of any changes made to these Terms of Use. The
        revised Terms of Use shall be made available on the Website. You are
        requested to regularly visit the Website to view the most current Terms
        of Use. You can determine when ONMEE last modified the Terms of Use by
        referring to the "Last Updated" legend above. It shall be Your
        responsibility to check these Terms of Use periodically for changes.
        ONMEE may require You to provide Your consent to the updated Terms of
        Use in a specified manner prior to any further use of the Website and
        the Services. If no such separate consent is sought, Your continued use
        of the Website, following the changes to the Terms of Use, will
        constitute Your acceptance of those changes. Your use of the Website and
        the Services is subject to the most current version of the Terms of Use
        made available on the Website at the time of such use. 27. FORCE MAJEURE
        ONMEE shall not be liable for any failure or delay to perform any
        obligations under this Terms of Use, if the performance is prevented,
        hindered or delayed by a Force Majeure Event such as strike, riot, fire,
        epidemic, flood, natural disaster, or other similar cause beyond ONMEE's
        control and in such case our obligations under this Terms of Use shall
        be suspended for so long as the Force Majeure Event continues. 28.
        CONTACT US Please send us any comments or queries, suggestions,
        questions, including all enquiries by mailing us at __________________.
        29. GRIEVANCE OFFICER In accordance with the IT Act, 2000, and the rules
        thereunder, the name and contact details of the grievance officer are
        provided below:
      </p>
    </div>
  );
};

export default TermsOfUseSeller;
