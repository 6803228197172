import { FaPhone, FaStar } from "react-icons/fa";
import { Link } from "react-router-dom";
import ModalMap from "../map/modalMap";
import { BASE_URL } from "../../services/constants";
import StoreCard from "../../helpers/StoreCard";
import ProductCard from "../../helpers/ProductCard";

const Recents = (props) => {
  let baseuri = BASE_URL;

  const platItem =
    props.items != null && props.items.length > 0
      ? props.items.map((item) => {
          return (
            <div key={item._id} className="col-md-3">
              {/* <StoreCard item={item.productId}></StoreCard> */}
              <ProductCard 
                item={item.productId} 
                addToWishlist={props.addToWishlist}
                userObj={props.userObj}
                addToCart={props.addToCart} />
            </div>
          );
        })
      : null;

  return (
    <div className="container">
      <div className="row">{platItem}</div>
    </div>
  );
};

export default Recents;
