import React, { useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";

import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import CheckButton from "react-validation/build/button";
import { isEmail } from "validator";

import { registration, verifyUser } from "../slices/authSlice";
import { Redirect } from "react-router";
import { update } from "../slices/headerSlice";

const required = (value) => {
  if (!value) {
    return (
      <div className="alert alert-danger" role="alert">
        This field is required!
      </div>
    );
  }
};

const validEmail = (value) => {
  if (!isEmail(value)) {
    return (
      <div className="alert alert-danger" role="alert">
        This is not a valid email.
      </div>
    );
  }
};

const vusername = (value) => {
  if (value.length < 3 || value.length > 20) {
    return (
      <div className="alert alert-danger" role="alert">
        The entered must be between 3 and 20 characters.
      </div>
    );
  }
};

const Register = (props) => {
  const form = useRef();
  const checkBtn = useRef();
  const verify = useRef();
  const checkVerifyBtn = useRef();

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [otp, setOtp] = useState("");

  const authState = useSelector((state) => state.auth);

  const dispatch = useDispatch();

  const onChangeFirstname = (e) => {
    const firstName = e.target.value;
    setFirstName(firstName);
  };

  const onChangeLastname = (e) => {
    const lastName = e.target.value;
    setLastName(lastName);
  };

  const onChangeEmail = (e) => {
    const email = e.target.value;
    setEmail(email);
  };

  const onChangePhone = (e) => {
    const phone = e.target.value;
    setPhone(phone);
  };

  const onChangeOtp = (e) => {
    const otp = e.target.value;
    setOtp(otp);
  };

  const handleRegister = (e) => {
    e.preventDefault();
    form.current.validateAll();

    if (checkBtn.current.context._errors.length === 0) {
      let userObj = {
        firstName: firstName,
        lastName: lastName,
        email: email,
        phone: phone,
      };

      if (props.item) {
        userObj.roles = props.item;
      }

      dispatch(registration(userObj));
    }
  };

  const { isLoggedIn, user } = useSelector((state) => state.auth);

  if (isLoggedIn && user.roles && user.roles.includes("ROLE_USER")) {
    dispatch(update());
    return <Redirect to="/" />;
  }

  if (isLoggedIn && user.roles && user.roles.includes("ROLE_MERCHANT")) {
    return <Redirect to="/merchant/main" />;
  }

  const handleVerification = (e) => {
    e.preventDefault();
    verify.current.validateAll();

    if (checkVerifyBtn.current.context._errors.length === 0) {
      dispatch(verifyUser({ otp: otp, id: authState.registeredUserId }));
    }
  };

  return (
    <div className="col-md-12 mt-3 mb-3">
      <div className="content-container container">
        <div className="row">
          <div className="card col-md-3 align-content-center">
            <img
              src="//ssl.gstatic.com/accounts/ui/avatar_2x.png"
              alt="profile-img"
              className="profile-img-card"
            />
            {!authState.successful && (
              <Form onSubmit={handleRegister} ref={form}>
                <div>
                  <div className="form-group">
                    <label htmlFor="firstName">First Name</label>
                    <Input
                      type="text"
                      className="form-control"
                      name="firstName"
                      value={firstName}
                      onChange={onChangeFirstname}
                      validations={[required, vusername]}
                    />
                  </div>

                  <div className="form-group">
                    <label htmlFor="lastName">Last Name</label>
                    <Input
                      type="text"
                      className="form-control"
                      name="lastName"
                      value={lastName}
                      onChange={onChangeLastname}
                      validations={[required, vusername]}
                    />
                  </div>

                  <div className="form-group">
                    <label htmlFor="email">Email</label>
                    <Input
                      type="text"
                      className="form-control"
                      name="email"
                      value={email}
                      onChange={onChangeEmail}
                      validations={[required, validEmail]}
                    />
                  </div>

                  <div className="form-group">
                    <label htmlFor="phone">Phone</label>
                    <Input
                      type="text"
                      className="form-control"
                      name="password"
                      value={phone}
                      onChange={onChangePhone}
                      validations={[required, vusername]}
                    />
                  </div>

                  <div className="form-group">
                    <button className="btn btn-primary btn-block">
                      Sign Up
                    </button>
                  </div>
                </div>
                <CheckButton style={{ display: "none" }} ref={checkBtn} />
              </Form>
            )}

            {authState.successful && (
              <div className="form-group mt-2">
                <div
                  className={
                    authState.successful
                      ? "alert alert-success"
                      : "alert alert-danger"
                  }
                >
                  User Registered Successfully
                </div>

                <div className="col-md-12">
                  <Form onSubmit={handleVerification} ref={verify}>

                    <div className="form-group">
                      <span>Otp code sent to your email</span>
                    </div>

                    <div className="form-group">
                      <label htmlFor="otp">Enter Otp</label>
                      <Input
                        type="text"
                        name="otp"
                        value={otp}
                        onChange={onChangeOtp}
                        validations={[required]}
                      />
                    </div>

                    <div className="form-group">
                      <button className="btn btn-primary btn-block">
                        Verify
                      </button>
                    </div>

                    <CheckButton
                      style={{ display: "none" }}
                      ref={checkVerifyBtn}
                    />
                  </Form>
                  <br />
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Register;
