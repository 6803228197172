import React, { useState, useRef, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Redirect } from "react-router-dom";

import CommonLayout from "../components/shop/common-layout";
import {
  Container,
  Row,
  Form as form,
  Label,
  Input as input,
  Col,
} from "reactstrap";
import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import CheckButton from "react-validation/build/button";
import { isEmail } from "validator";
import { Link } from "react-router-dom";

import {
  registration,
  signin,
  updateExistingUser,
  verifyUser,
  updateLoginError,
  updateOtpFailed,
} from "../slices/authSlice";
import {
  update,
  moveCart,
  moveWish,
  getWishCount,
  getCartsCount,
} from "../slices/headerSlice";
import { updateMerchant } from "../slices/merchantSlice";
import { updateLoggedInUser } from "../slices/categorySlice";
import { updateLoggedInUserHome } from "../slices/homeSlice";
import { updateLoggedInUserOffer } from "../slices/OfferSlice";
import { updateLoggedInUserProduct } from "../slices/productSlice";
import { updateAdmin } from "../slices/adminSlice";
import Modal from "react-modal";
import { useAlert } from "react-alert";
import { getUniqueId } from "../helpers/LocalCart";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { logout as resetMerchantState } from "../slices/merchantSlice";
import { logout as resetAdminState } from "../slices/adminSlice";
import { logout as resetAuthState } from "../slices/authSlice";

const required = (value) => {
  if (!value) {
    return (
      <div className="alert alert-danger" role="alert">
        This field is required!
      </div>
    );
  }
};

const validEmail = (value) => {
  if (!isEmail(value)) {
    return (
      <div className="alert alert-danger" role="alert">
        This is not a valid email.
      </div>
    );
  }
};

const Login = (props) => {
  const form = useRef();
  const checkBtn = useRef();
  const form1 = useRef();
  const check1Btn = useRef();
  const verify = useRef();
  const checkVerifyBtn = useRef();

  const [otp, setOtp] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [emailRegister, setEmailRegister] = useState("");
  const queryParams = new URLSearchParams(window.location.search);
  const redirectTo = queryParams.get("redirectTo");

  const {
    isLoggedIn,
    loading,
    user,
    successful,
    registeredUserId,
    existingUser,
    loginError,
    otpFailed,
  } = useSelector((state) => state.auth);

  // const alert = useAlert();

  const dispatch = useDispatch();

  const onFirstNameChange = (e) => {
    setFirstName(e.target.value);
  };

  const onLastNameChange = (e) => {
    setLastName(e.target.value);
  };

  const onChangeEmail = (e) => {
    const email = e.target.value;
    setEmail(email);
  };

  const onPhoneChange = (e) => {
    setPhone(e.target.value);
  };

  const onChangeEmailRegister = (e) => {
    const email = e.target.value;
    setEmailRegister(email);
  };

  const handleLogin = (e) => {
    e.preventDefault();

    form.current.validateAll();

    if (checkBtn.current.context._errors.length === 0) {
      dispatch(signin({ email: email }));
    }
  };

  const handleRegister = (e) => {
    e.preventDefault();
    form1.current.validateAll();

    if (check1Btn.current.context._errors.length === 0) {
      let userObj = {
        firstName: firstName,
        lastName: lastName,
        email: emailRegister,
        phone: phone,
      };

      if (props.item) {
        userObj.roles = props.item;
      }

      dispatch(registration(userObj));
      dispatch(updateExistingUser());
    }
  };

  const onChangeOtp = (e) => {
    const otp = e.target.value;
    setOtp(otp);
  };

  const handleVerification = (e) => {
    e.preventDefault();
    verify.current.validateAll();

    if (checkVerifyBtn.current.context._errors.length === 0) {
      dispatch(verifyUser({ otp: otp, id: registeredUserId }));
      // closeModal();
    }
  };

  Modal.setAppElement("#root");
  const [isModalOpen1, setIsModalOpen1] = useState(false);

  const openModal = (merchantObj, name) => {
    setIsModalOpen1(true);
    setOtp("");
  };

  const closeModal = () => {
    setIsModalOpen1(false);
  };

  const resetState = () => {
    if (user && user.roles.includes("ROLE_USER")) {
      dispatch(resetAdminState());
      dispatch(resetMerchantState());
    } else if (user && user.roles.includes("ROLE_MERCHANT")) {
      dispatch(resetAdminState());
      dispatch(resetAuthState());
    } else if (user && user.roles.includes("ROLE_ADMIN")) {
      dispatch(resetMerchantState());
      dispatch(resetAuthState());
    }
  };

  useEffect(() => {
    if (isLoggedIn) {
      resetState();
    }
  }, [isLoggedIn]);

  useEffect(() => {
    if (successful) {
      openModal();
    }
  }, [successful]);

  // useEffect(() => {
  //   if (existingUser) {
  //     alert.error(
  //       "A user with this email id already exists. Please try to login or signup with a different email"
  //     );
  //     dispatch(updateExistingUser());
  //   }
  // }, [existingUser, dispatch]);

  useEffect(() => {
    if (loginError) {
      let toastId = toast.error(loginError);
      dispatch(updateLoginError());
    }
  }, [loginError, dispatch]);

  useEffect(() => {
    if (otpFailed) {
      let toastId = toast.error(otpFailed);
      dispatch(updateOtpFailed());
    }
  }, [otpFailed, dispatch]);

  if (isLoggedIn && user && user.roles && user.roles.includes("ROLE_USER")) {
    dispatch(update());
    dispatch(updateLoggedInUser());
    dispatch(updateLoggedInUserHome());
    dispatch(updateLoggedInUserOffer());
    dispatch(updateLoggedInUserProduct());

    dispatch(getCartsCount(user._id));
    dispatch(getWishCount(user._id));

    dispatch(
      moveCart({
        fromUser: getUniqueId(),
        toUser: user._id,
      })
    );

    dispatch(
      moveWish({
        fromUser: getUniqueId(),
        toUser: user._id,
      })
    );

    if (redirectTo) {
      return <Redirect to={redirectTo} />;
    } else {
      return <Redirect to="/" />;
    }
  }

  if (
    isLoggedIn &&
    user &&
    user.roles &&
    user.roles.includes("ROLE_MERCHANT")
  ) {
    dispatch(update());
    dispatch(updateMerchant("ADDPRODUCT"));
    return <Redirect to="/merchant/main" />;
  }

  if (isLoggedIn && user && user.roles && user.roles.includes("ROLE_ADMIN")) {
    dispatch(update());
    dispatch(updateAdmin());
    return <Redirect to="/admin" />;
  }

  const customStyles50 = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      zIndex: "9999",
      width: "100%",
      maxWidth: "450px",
    },
  };

  return (
    <div className="">
      <Modal isOpen={isModalOpen1} style={customStyles50}>
        <div className="col-md-12">
          <div className="close-modal">
            <button onClick={() => closeModal()}>&times;</button>
          </div>
          <div className="row">
            <div className="col-md-12 p-0 ONM_modal_header">
              <h3>Verify Details</h3>
            </div>
            <div className="col-md-12 align-content-center p-0">
              <div className="form-group mt-2">
                <div className="col-md-12">
                  <Form onSubmit={handleVerification} ref={verify}>
                    <div className="form-group">
                      <span>Otp code sent to your email</span>
                    </div>

                    <div className="form-group">
                      <label htmlFor="otp">Enter Otp</label>
                      <Input
                        type="text"
                        name="otp"
                        value={otp}
                        className="form-control col-md-12"
                        onChange={onChangeOtp}
                        validations={[required]}
                      />
                    </div>

                    <div className="form-group">
                      <button
                        className="btn btn-primary btn-block"
                        style={{ padding: "12px" }}
                      >
                        Verify
                      </button>
                    </div>

                    <CheckButton
                      style={{ display: "none" }}
                      ref={checkVerifyBtn}
                    />
                  </Form>
                  <br />
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
      <CommonLayout parent="home" subTitle="login">
        <section className="login-page section-b-space">
          <Container>
            <Row>
              <Col lg="6">
                <h3>Login</h3>
                <div className="theme-card">
                  <Form
                    className="theme-form"
                    onSubmit={handleLogin}
                    ref={form}
                  >
                    <div className="form-group">
                      <Label for="email">Email</Label>
                      <Input
                        autoFocus
                        type="text"
                        className="form-control"
                        name="email"
                        value={email}
                        onChange={onChangeEmail}
                        validations={[required, validEmail]}
                      />
                    </div>
                    <button className="btn btn-solid" disabled={loading}>
                      {loading && (
                        <span className="spinner-border spinner-border-sm"></span>
                      )}
                      <span>Login</span>
                    </button>
                    <small class="form-text text-muted">
                      By continuing, you agree to Onmee's{" "}
                      <Link to={"/terms-of-use"}>Term of Use</Link> and{" "}
                      <Link to={"/privacy-policy"}>Privacy Policy</Link>.{" "}
                    </small>
                    <CheckButton style={{ display: "none" }} ref={checkBtn} />
                  </Form>
                </div>
              </Col>
              <Col lg="6" className="right-login">
                <h3>New Customer</h3>
                <div className="theme-card authentication-right">
                  <h6 className="title-font">Create A Account</h6>
                  <Form
                    onSubmit={handleRegister}
                    ref={form1}
                    className="theme-form"
                  >
                    <div className="form-group">
                      <Label for="email">First Name</Label>
                      <Input
                        type="text"
                        className="form-control"
                        name="firstName"
                        value={firstName}
                        onChange={onFirstNameChange}
                        validations={[required]}
                      />
                    </div>
                    <div className="form-group">
                      <label htmlFor="lastName">Last Name</label>
                      <Input
                        type="text"
                        className="form-control"
                        name="lastName"
                        value={lastName}
                        onChange={onLastNameChange}
                        validations={[required]}
                      />
                    </div>
                    <div className="form-group">
                      <label htmlFor="emailRegister">Email</label>
                      <Input
                        type="text"
                        className="form-control"
                        name="emailRegister"
                        value={emailRegister}
                        onChange={onChangeEmailRegister}
                        validations={[required, validEmail]}
                      />
                    </div>
                    <div className="form-group">
                      <label htmlFor="phone">Phone</label>
                      <Input
                        type="text"
                        className="form-control"
                        name="phone"
                        value={phone}
                        onChange={onPhoneChange}
                        validations={[required]}
                      />
                    </div>
                    <div className="form-group">
                      <button href="#" className="btn btn-solid">
                        Create an Account
                      </button>
                    </div>
                    {existingUser == true ? (
                      <p className="mt-2 alert alert-danger">
                        A user with this email id already exists. Please try to
                        login or signup with a different email
                      </p>
                    ) : null}
                    <CheckButton style={{ display: "none" }} ref={check1Btn} />
                  </Form>
                </div>
              </Col>
            </Row>
          </Container>
        </section>
      </CommonLayout>
    </div>
  );
};

export default Login;
