import React from "react";
import { Link } from "react-router-dom";
import {
  svgFreeShipping,
  svgservice,
  svgoffer,
} from "../../../services/script";
import { Container, Row, Col } from "reactstrap";
import MasterServiceContent from "./MasterServiceConternt";

const Data = [
  {
    icon: svgoffer,
    link: "/offer/80/60",
    title: "60-80%",
    service: "Offer",
  },
  {
    icon: svgoffer,
    link: "/offer/60/40",
    title: "40-60%",
    service: "Offer",
  },
  {
    icon: svgoffer,
    link: "/offer/40/20",
    title: "20-40% ",
    service: "Offer",
  },
  {
    icon: svgoffer,
    link: "/offer/20/0",
    title: "0-20%",
    service: "Offer",
  },
];

const ServiceLayout = ({ sectionClass, tittle }) => {
  return (
    <Container>
      <section className={sectionClass}>
        <Row>
          <Col md="12">
            <div className="title1">
              <h2 className="title-inner1">{tittle} </h2>
            </div>
          </Col>
          {Data.map((data, index) => {
            return (
              <Col md="3" className="service-block" key={index}>
                <MasterServiceContent
                  link={data.link}
                  title={data.title}
                  service={data.service}
                  icon={data.icon}
                />
              </Col>
            );
          })}
        </Row>
        <Row>
          <Col md="12" style={{ textAlign: "center" }} className="mt-4">
            <span className="ONM_view_offer">
              <Link to="/offer">View Offers</Link>
            </span>
          </Col>
        </Row>
      </section>
    </Container>
  );
};

export default ServiceLayout;
