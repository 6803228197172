/* eslint-disable jsx-a11y/anchor-is-valid */
import { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { BASE_URL, BASE_API_URL } from "../../services/constants";
import {
  bannerList,
  clearImage,
  getAllCategories,
  getMerchantCount,
  getMerchantList,
  resetFeatures,
  updatePagination,
  uploadImage,
  updateImages,
  saveBannerObj,
  updateBannerSaved,
  deleteBannerObj,
  resetUploadError,
} from "../../slices/adminSlice";
import Form from "react-validation/build/form";
import Select from "react-validation/build/select";
import CheckButton from "react-validation/build/button";
import Modal from "react-modal";
import { FaTimes, FaPlusSquare } from "react-icons/fa";
import Pagination from "react-js-pagination";
import { useAlert } from "react-alert";
import { deleteBanner } from "../../services/home.service";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const required = (value) => {
  if (!value) {
    return (
      <div className="alert alert-danger" role="alert">
        This field is required!
      </div>
    );
  }
};

const AdminAdsManagement = (props) => {
  const adminState = useSelector((state) => state.admin);
  const dispatch = useDispatch();
  const [page, setPage] = useState(null);
  const [row, setRow] = useState(1);
  const [selectedRows, setSelectedRows] = useState(null);
  const [ads, setAds] = useState(null);
  const [mainCat, setMainCat] = useState(null);
  // const alert = useAlert();

  const changeRow = (row) => {
    setRow(row);

    if (adminState.bannerList != null && adminState.bannerList.length > 0) {
      let sRows = [];
      for (let i = 0; i < adminState.bannerList.length; i++) {
        let item = adminState.bannerList[i];

        if (item.row === row) {
          sRows.push(item);
        }
      }

      setSelectedRows(sRows);
    } else {
      setSelectedRows(null);
    }
  };

  useEffect(() => {
    dispatch(getAllCategories());
  }, [dispatch]);

  useEffect(() => {
    if (page != null) {
      setRow(1);
      dispatch(bannerList(page));
      setAds(false);
    }
  }, [dispatch, page]);

  useEffect(() => {
    // console.log(adminState.imageUploadError);
    if (adminState.imageUploadError) {
      toast.error(adminState.imageUploadError);
      dispatch(resetUploadError());
    }
  }, [adminState.imageUploadError]);

  useEffect(() => {
    if (adminState.bannerSaved) {
      // alert.show("Banner was saved successfully !!!");
      toast.success("Banner was saved successfully !!!")
      setRow(1);
      dispatch(bannerList(page));
      setAds(false);
      dispatch(updateBannerSaved());
    }
  }, [adminState.bannerSaved, dispatch]);

  useEffect(() => {
    if (adminState.bannerList != null && adminState.bannerList.length > 0) {
      let sRows = [];
      for (let i = 0; i < adminState.bannerList.length; i++) {
        let item = adminState.bannerList[i];

        if (item.row === row) {
          sRows.push(item);
        }
      }

      // console.log(adminState.bannerList);

      setSelectedRows(sRows);
    } else {
      setSelectedRows(null);
    }
  }, [adminState.bannerList]);

  const pageClicked = (page, mainCat) => {
    setPage(page);
    setMainCat(mainCat);
  };

  const backToList = () => {
    setPage(null);
    setMainCat(null);
  };

  const backFromAds = () => {
    setAds(false);
  };

  let baseUri = BASE_URL;

  const imgPath = (img) => {
    let path = img;

    if (path.indexOf("https://") > -1) {
      return path;
    } else {
      return BASE_API_URL + '/' + path;
    }
  };

  const hiddenFileInput = useRef();

  const handleClick = (event) => {
    hiddenFileInput.current.click();
  };

  const uploadHandler = (event) => {
    let imageFormData = new FormData();
    imageFormData.append("imageData", event.target.files[0]);
    dispatch(uploadImage(imageFormData));
  };

  const handleRemove = (event) => {
    // let bannerObj = {
    //   mainCategoryId: mainCategoryId,
    //   categoryId: categoryId,
    //   subCategoryId: subCategoryId,
    //   childCategoryId: childCategoryId,
    //   storeId: storeId,
    //   row: row,
    //   page: page,
    // };

    dispatch(deleteBannerObj(id));

    backFromAds();
  };

  const [catList, setCatList] = useState(null);
  const [subCatList, setSubCatList] = useState(null);
  const [childCatList, setChildCatList] = useState(null);
  const [mainCategoryId, setMainCategoryId] = useState(null);
  const [categoryId, setCategoryId] = useState(null);
  const [subCategoryId, setSubCategoryId] = useState(null);
  const [childCategoryId, setChildCategoryId] = useState(null);
  const [storeId, setStoreId] = useState(null);
  const [storeName, setStoreName] = useState(null);
  const [id, setId] = useState(null);
  const form = useRef();
  const checkBtn = useRef();

  const onChangeMainCatId = (e) => {
    setMainCategoryId(e.target.value);
    setCategoryId(null);
    setSubCategoryId(null);
    setChildCategoryId(null);
    setSubCatList(null);
    setChildCatList(null);
    if (adminState.categories != null && adminState.categories.length > 0) {
      let found = false;
      for (let i = 0; i < adminState.categories.length && !found; i++) {
        let item = adminState.categories[i];

        if (item._id === e.target.value) {
          found = true;
          setCatList(item.categories);
        }
      }
    }
  };

  const onChangeCatId = (e) => {
    setCategoryId(e.target.value);
    if (catList != null && catList.length > 0) {
      let found = false;
      for (let i = 0; i < catList.length && !found; i++) {
        let a = catList[i];

        if (a._id === e.target.value) {
          found = true;
          setSubCatList(a.subCategories);
        }
      }
    }
  };

  const onChangeSubCatId = (e) => {
    setSubCategoryId(e.target.value);
    if (catList != null && catList.length > 0) {
      let found = false;
      for (let i = 0; i < catList.length && !found; i++) {
        let a = catList[i];

        for (let j = 0; j < a.subCategories.length && !found; j++) {
          let b = a.subCategories[j];

          if (b._id === e.target.value) {
            found = true;
            setChildCatList(b.childCategories);
          }
        }
      }
    }
  };

  const onChangeChildCatId = (e) => {
    setChildCategoryId(e.target.value);
  };

  const handleSave = (e) => {
    e.preventDefault();
    form.current.validateAll();
    if (checkBtn.current.context._errors.length === 0) {
      // if (storeId === null) {
      //   alert.show("Please select a store before you save", { type: "error" });
      //   toast.error("Please select a store before you save");
      //   return;
      // }

      if (adminState.image === null) {
        // alert.show("Please upload an image before you save", { type: "error" });
        toast.error("Please upload an image before you save");
        return;
      }

      let bannerObj = {
        mainCategoryId: mainCategoryId,
        categoryId: categoryId,
        subCategoryId: subCategoryId,
        childCategoryId: childCategoryId,
        storeId: storeId,
        imageId: adminState.image ? adminState.image._id : null,
        row: row,
        page: page,
      };

      if (id != null) {
        bannerObj.id = id;
      }

      dispatch(saveBannerObj(bannerObj));
    }
  };

  const addAds = () => {
    setMainCategoryId(null);
    setCategoryId(null);
    setCatList(null);
    setSubCatList(null);
    setChildCatList(null);
    if (page && mainCat && page !== "HOME") {
      setCategoryId(page);
      setMainCategoryId(mainCat);
      if (adminState.categories != null && adminState.categories.length > 0) {
        let found = null;
        for (let i = 0; i < adminState.categories.length && !found; i++) {
          let item = adminState.categories[i];

          if (item._id === mainCat) {
            found = item;
          }
        }
        if (found != null) {
          setCatList(found.categories);
          if (page != null) {
            let cat = null;
            for (let j = 0; j < found.categories.length && !cat; j++) {
              let b = found.categories[j];
              if (b._id === page) {
                cat = b;
              }
            }

            if (cat != null) {
              setSubCatList(cat.subCategories);
            }
          }
        }
      }
    } else if (page !== "HOME") {
      setMainCategoryId(page);
      if (adminState.categories != null && adminState.categories.length > 0) {
        let found = null;
        for (let i = 0; i < adminState.categories.length && !found; i++) {
          let item = adminState.categories[i];

          if (item._id === page) {
            found = item;
          }
        }

        if (found != null) {
          setCatList(found.categories);
        }
      }
    }

    setSubCategoryId(null);
    setChildCategoryId(null);
    setStoreId(null);
    setStoreName(null);
    setId(null);
    dispatch(clearImage());
    setAds(true);
  };

  const editPage = (banner) => {
    setAds(true);
    setMainCategoryId(banner.mainCategoryId);
    setCategoryId(banner.categoryId);
    setSubCategoryId(banner.subCategoryId);
    setChildCategoryId(banner.childCategoryId);

    if (banner.storeId != null) {
      setStoreId(banner.storeId._id);
      setStoreName(banner.storeId.storeName);
    }

    setId(banner._id);
    setCatList(null);
    setSubCatList(null);
    setChildCatList(null);
    dispatch(updateImages(banner.imageId));
    if (adminState.categories != null && adminState.categories.length > 0) {
      let found = null;
      for (let i = 0; i < adminState.categories.length && !found; i++) {
        let item = adminState.categories[i];

        if (item._id === banner.mainCategoryId) {
          found = item;
        }
      }

      if (found != null) {
        setCatList(found.categories);
        if (banner.categoryId != null) {
          let cat = null;
          for (let j = 0; j < found.categories.length && !cat; j++) {
            let b = found.categories[j];
            if (b._id === banner.categoryId) {
              cat = b;
            }
          }

          if (cat != null) {
            setSubCatList(cat.subCategories);
            if (banner.subCategoryId) {
              let subCat = null;
              for (let k = 0; k < cat.subCategories.length && !subCat; k++) {
                let c = cat.subCategories[k];

                if (c._id === banner.subCategoryId) {
                  subCat = c;
                }
              }

              if (subCat != null) {
                setChildCatList(subCat.childCategories);
              }
            }
          }
        }
      }
    }
  };

  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      zIndex: "999",
      width: "80%",
      height: "90%",
    },
  };

  Modal.setAppElement("#root");
  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = () => {
    dispatch(resetFeatures());
    let query = {};
    query.active = "T";
    query.rejected = "F";
    query.inactive = "F";
    dispatch(getMerchantList(query));
    dispatch(getMerchantCount(query));
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const handlePageChange = (pageNumber) => {
    dispatch(updatePagination(pageNumber));
    dispatch(getMerchantList(true));
  };

  const selectStoreName = (store) => {
    setStoreId(store._id);
    setStoreName(store.storeName);
    setIsModalOpen(false);
  };

  return (
    <div className="col-md-12">
      <ToastContainer />
      <Modal isOpen={isModalOpen} style={customStyles}>
        <div className="col-md-12">
          <div className="row">
            <div className="col-md-11">Merchant List</div>
            <FaTimes
              onClick={() => closeModal()}
              className="col-md-1 mb-2"
            ></FaTimes>
          </div>
        </div>

        <div className="col-md-12">
          <div className="row">
            <div className="table-responsive mt-2">
              <table className="table">
                <thead>
                  <tr>
                    <th>Name</th>
                    <th>Store Name</th>
                    <th>Address</th>
                    <th>Email</th>
                  </tr>
                </thead>
                <tbody>
                  {adminState.merchantList != null
                    ? adminState.merchantList.map((merchant) => {
                        return (
                          <tr key={merchant._id}>
                            <td>
                              <a onClick={() => selectStoreName(merchant)}>
                                {merchant.firstName} {merchant.lastName}
                              </a>
                            </td>
                            <td>{merchant.email}</td>
                            <td>{merchant.storeName}</td>
                            <td>{merchant.address}</td>
                          </tr>
                        );
                      })
                    : null}
                </tbody>
              </table>
            </div>
            <div className="mt-2 float-right">
              <Pagination
                activePage={adminState.features.page}
                itemsCountPerPage={adminState.features.ps}
                totalItemsCount={adminState.merchantCount}
                pageRangeDisplayed={5}
                onChange={handlePageChange.bind(this)}
                itemClass="page-item"
                linkClass="page-link"
                innerClass="pagination ml-auto mr-auto"
                prevPageText="Prev"
                nextPageText="Next"
                hideFirstLastPages={true}
              />
            </div>
          </div>
        </div>
      </Modal>

      <div className="row">
        <h5 className="col-md-6">Ads Management</h5>
        {page != null && !ads && (
          <a
            className="btn btn-primary text-right"
            onClick={() => backToList()}
          >
            Back
          </a>
        )}
        {ads && (
          <a
            className="btn btn-primary text-right"
            onClick={() => backFromAds()}
          >
            Back
          </a>
        )}
        {!page && !ads ? (
          <>
            <div
              className="col-md-12 ONM_banner_design"
              onClick={() => pageClicked("HOME")}
            >
              Home Page
            </div>
            {adminState.categories != null && adminState.categories.length > 0
              ? adminState.categories.map((cat) => {
                  return (
                    <div
                      key={cat._id}
                      className="col-md-12 mt-3 ONM_banner_design"
                    >
                      <span onClick={() => pageClicked(cat._id)}>
                        {cat.name}
                      </span>
                      {cat.categories != null && cat.categories.length > 0
                        ? cat.categories.map((subCat) => {
                            return (
                              <div
                                className="col-md-12 mt-2"
                                key={subCat._id}
                                onClick={() => pageClicked(subCat._id, cat._id)}
                              >
                                {subCat.name}
                              </div>
                            );
                          })
                        : null}
                    </div>
                  );
                })
              : null}
          </>
        ) : null}
        {page != null && !ads ? (
          <div className="col-md-12">
            <div className="row">
              <div className="col-md-12 mt-2">
                <div className="row">
                  <div
                    className={
                      row === 1
                        ? "col-md-2 ONM_row_design active"
                        : "col-md-2 ONM_row_design"
                    }
                    onClick={() => changeRow(1)}
                  >
                    Row 1
                  </div>
                  <div
                    className={
                      row === 2
                        ? "col-md-2 ONM_row_design active"
                        : "col-md-2 ONM_row_design"
                    }
                    onClick={() => changeRow(2)}
                  >
                    Row 2
                  </div>
                  <div
                    className={
                      row === 3
                        ? "col-md-2 ONM_row_design active"
                        : "col-md-2 ONM_row_design"
                    }
                    onClick={() => changeRow(3)}
                  >
                    Row 3
                  </div>
                  <div
                    className={
                      row === 4
                        ? "col-md-2 ONM_row_design active"
                        : "col-md-2 ONM_row_design"
                    }
                    onClick={() => changeRow(4)}
                  >
                    Row 4
                  </div>
                  <div
                    className={
                      row === 5
                        ? "col-md-2 ONM_row_design active"
                        : "col-md-2 ONM_row_design"
                    }
                    onClick={() => changeRow(5)}
                  >
                    Row 5
                  </div>
                  <div
                    className={
                      row === 6
                        ? "col-md-2 ONM_row_design active"
                        : "col-md-2 ONM_row_design"
                    }
                    onClick={() => changeRow(6)}
                  >
                    Row 6
                  </div>
                </div>
              </div>
              <div className="col-md-12 mt-2">
                {row === 1 ? (
                  <div className="row">
                    {selectedRows != null &&
                    selectedRows.length > 0 &&
                    selectedRows[0] ? (
                      <div
                        className="col-md-12"
                        onClick={() => editPage(selectedRows[0])}
                      >
                        <img src={imgPath(selectedRows[0].imageId.path)}></img>
                      </div>
                    ) : (
                      <div
                        className="col-md-12 ONM_dotted_border mt-2"
                        onClick={() => addAds()}
                      >
                        <FaPlusSquare
                          className="ml-2 ONM_cursor-pointer"
                          size={24}
                        ></FaPlusSquare>
                      </div>
                    )}
                    {selectedRows != null &&
                    selectedRows.length > 1 &&
                    selectedRows[1] ? (
                      <div
                        className="col-md-12 mt-2"
                        onClick={() => editPage(selectedRows[1])}
                      >
                        <img src={imgPath(selectedRows[1].imageId.path)}></img>
                      </div>
                    ) : (
                      <div
                        className="col-md-12 ONM_dotted_border mt-2"
                        onClick={() => addAds()}
                      >
                        <FaPlusSquare
                          className="ml-2 ONM_cursor-pointer"
                          size={24}
                        ></FaPlusSquare>
                      </div>
                    )}
                    {selectedRows != null &&
                    selectedRows.length > 2 &&
                    selectedRows[2] ? (
                      <div
                        className="col-md-12 mt-2"
                        onClick={() => editPage(selectedRows[2])}
                      >
                        <img src={imgPath(selectedRows[2].imageId.path)}></img>
                      </div>
                    ) : (
                      <div
                        className="col-md-12 ONM_dotted_border mt-2"
                        onClick={() => addAds()}
                      >
                        <FaPlusSquare
                          className="ml-2 ONM_cursor-pointer"
                          size={24}
                        ></FaPlusSquare>
                      </div>
                    )}
                    {selectedRows != null &&
                    selectedRows.length > 3 &&
                    selectedRows[3] ? (
                      <div
                        className="col-md-12 mt-2"
                        onClick={() => editPage(selectedRows[3])}
                      >
                        <img src={imgPath(selectedRows[3].imageId.path)}></img>
                      </div>
                    ) : (
                      <div
                        className="col-md-12 ONM_dotted_border mt-2"
                        onClick={() => addAds()}
                      >
                        <FaPlusSquare
                          className="ml-2 ONM_cursor-pointer"
                          size={24}
                        ></FaPlusSquare>
                      </div>
                    )}
                    {selectedRows != null &&
                    selectedRows.length > 4 &&
                    selectedRows[4] ? (
                      <div
                        className="col-md-12 mt-2"
                        onClick={() => editPage(selectedRows[4])}
                      >
                        <img src={imgPath(selectedRows[4].imageId.path)}></img>
                      </div>
                    ) : (
                      <div
                        className="col-md-12 ONM_dotted_border mt-2"
                        onClick={() => addAds()}
                      >
                        <FaPlusSquare
                          className="ml-2 ONM_cursor-pointer"
                          size={24}
                        ></FaPlusSquare>
                      </div>
                    )}
                  </div>
                ) : null}

                {row === 2 ? (
                  <div className="row">

                    <div className="col-md-3 mb-4">
                      {selectedRows != null &&
                      selectedRows.length > 0 &&
                      selectedRows[0] ? (
                        <div className="banner"
                          onClick={() => editPage(selectedRows[0])}
                        >
                          <img src={imgPath(selectedRows[0].imageId.path)}></img>
                        </div>
                      ) : (
                        <div
                          className="banner ONM_dotted_border"
                          onClick={() => addAds()}
                        >
                          <FaPlusSquare
                            className="ONM_cursor-pointer"
                            size={24}
                          ></FaPlusSquare>
                        </div>
                      )}
                    </div>
                    <div className="col-md-6">
                        <div className="row">
                            <div className="col-md-6 mb-4">
                              {selectedRows != null &&
                              selectedRows.length > 1 &&
                              selectedRows[1] ? (
                                <div
                                  className="banner"
                                  onClick={() => editPage(selectedRows[1])}
                                >
                                  <img src={imgPath(selectedRows[1].imageId.path)}></img>
                                </div>
                              ) : (
                                <div
                                  className="banner ONM_dotted_border"
                                  onClick={() => addAds()}
                                >
                                  <FaPlusSquare
                                    className="ONM_cursor-pointer"
                                    size={24}
                                  ></FaPlusSquare>
                                </div>
                              )}
                            </div>
                            <div className="col-md-6 mb-4">
                              {selectedRows != null &&
                              selectedRows.length > 1 &&
                              selectedRows[2] ? (
                                <div
                                  className="banner"
                                  onClick={() => editPage(selectedRows[2])}
                                >
                                  <img src={imgPath(selectedRows[2].imageId.path)}></img>
                                </div>
                              ) : (
                                <div
                                  className="banner ONM_dotted_border"
                                  onClick={() => addAds()}
                                >
                                  <FaPlusSquare
                                    className="ONM_cursor-pointer"
                                    size={24}
                                  ></FaPlusSquare>
                                </div>
                              )}
                            </div>
                            <div className="col-md-6 mb-4">
                             {selectedRows != null &&
                              selectedRows.length > 1 &&
                              selectedRows[3] ? (
                                <div
                                  className="banner"
                                  onClick={() => editPage(selectedRows[3])}
                                >
                                  <img src={imgPath(selectedRows[3].imageId.path)}></img>
                                </div>
                              ) : (
                                <div
                                  className="banner ONM_dotted_border"
                                  onClick={() => addAds()}
                                >
                                  <FaPlusSquare
                                    className="ONM_cursor-pointer"
                                    size={24}
                                  ></FaPlusSquare>
                                </div>
                              )}
                            </div>
                            <div className="col-md-6 mb-4">
                             {selectedRows != null &&
                              selectedRows.length > 1 &&
                              selectedRows[4] ? (
                                <div
                                  className="banner"
                                  onClick={() => editPage(selectedRows[4])}
                                >
                                  <img src={imgPath(selectedRows[4].imageId.path)}></img>
                                </div>
                              ) : (
                                <div
                                  className="banner ONM_dotted_border"
                                  onClick={() => addAds()}
                                >
                                  <FaPlusSquare
                                    className="ONM_cursor-pointer"
                                    size={24}
                                  ></FaPlusSquare>
                                </div>
                              )}
                            </div>
                        </div>                
                    </div>
                    <div className="col-md-3 mb-4">
                     {selectedRows != null &&
                      selectedRows.length > 1 &&
                      selectedRows[5] ? (
                        <div
                          className="banner"
                          onClick={() => editPage(selectedRows[5])}
                        >
                          <img src={imgPath(selectedRows[5].imageId.path)}></img>
                        </div>
                      ) : (
                        <div
                          className="banner ONM_dotted_border"
                          onClick={() => addAds()}
                        >
                          <FaPlusSquare
                            className="ONM_cursor-pointer"
                            size={24}
                          ></FaPlusSquare>
                        </div>
                      )}
                    </div>                    
                  </div>
                ) : null}

                {row === 3 ? (
                  <div className="row">
                    {selectedRows != null &&
                    selectedRows.length > 0 &&
                    selectedRows[0] ? (
                      <div
                        className="col-md-12"
                        onClick={() => editPage(selectedRows[0])}
                      >
                        <img src={imgPath(selectedRows[0].imageId.path)}></img>
                      </div>
                    ) : (
                      <div
                        className="col-md-12 ONM_dotted_border mt-2"
                        onClick={() => addAds()}
                      >
                        <FaPlusSquare
                          className="ml-2 ONM_cursor-pointer"
                          size={24}
                        ></FaPlusSquare>
                      </div>
                    )}
                  </div>
                ) : null}
                {row === 4 ? (
                  <div className="row">
                    {selectedRows != null &&
                    selectedRows.length > 0 &&
                    selectedRows[0] ? (
                      <div
                        className="col-md-12"
                        onClick={() => editPage(selectedRows[0])}
                      >
                        <img src={imgPath(selectedRows[0].imageId.path)}></img>
                      </div>
                    ) : (
                      <div
                        className="col-md-12 ONM_dotted_border mt-2"
                        onClick={() => addAds()}
                      >
                        <FaPlusSquare
                          className="ml-2 ONM_cursor-pointer"
                          size={24}
                        ></FaPlusSquare>
                      </div>
                    )}
                  </div>
                ) : null}
                {row === 5 ? (
                  <div className="row">
                    {selectedRows != null &&
                    selectedRows.length > 0 &&
                    selectedRows[0] ? (
                      <div
                        className="col-md-12"
                        onClick={() => editPage(selectedRows[0])}
                      >
                        <img src={imgPath(selectedRows[0].imageId.path)}></img>
                      </div>
                    ) : (
                      <div
                        className="col-md-12 ONM_dotted_border mt-2"
                        onClick={() => addAds()}
                      >
                        <FaPlusSquare
                          className="ml-2 ONM_cursor-pointer"
                          size={24}
                        ></FaPlusSquare>
                      </div>
                    )}
                  </div>
                ) : null}
                {row === 6 ? (
                  <div className="row">
                    {selectedRows != null &&
                    selectedRows.length > 0 &&
                    selectedRows[0] ? (
                      <div
                        className="col-md-12"
                        onClick={() => editPage(selectedRows[0])}
                      >
                        <img src={imgPath(selectedRows[0].imageId.path)}></img>
                      </div>
                    ) : (
                      <div
                        className="col-md-12 ONM_dotted_border mt-2"
                        onClick={() => addAds()}
                      >
                        <FaPlusSquare
                          className="ml-2 ONM_cursor-pointer"
                          size={24}
                        ></FaPlusSquare>
                      </div>
                    )}
                  </div>
                ) : null}
              </div>
            </div>
          </div>
        ) : null}
        {ads ? (
          <div className="row">
            {adminState.image ? (
              <div className="col-md-12 mt-2">
                <img className="banner-preview" src={imgPath(adminState.image.path)}></img>
                {adminState.imageUploading ? (
                  <>
                    <div className="spinner-grow text-muted"></div>
                    <div className="spinner-grow text-muted"></div>
                    <div className="spinner-grow text-muted"></div>
                  </>
                ) : null}
              </div>
            ) : (
              <div className="col-md-12 ONM_dotted_border mt-2">
                Banner Placeholder
                {adminState.imageUploading ? (
                  <>
                    <div className="spinner-grow text-muted"></div>
                    <div className="spinner-grow text-muted"></div>
                    <div className="spinner-grow text-muted"></div>
                  </>
                ) : null}
              </div>
            )}
            <div className="col-md-12 text-right mt-2">
              {adminState.image ? (
                <button className="btn btn-dark mr-2" onClick={() => handleRemove()}>
                  Remove Banner
                </button>
              ) : null }
              <button className="btn btn-dark" onClick={() => handleClick()}>
                {adminState.image ? "Change Banner" : "Add Banner"}
              </button>
              <input
                ref={hiddenFileInput}
                type="file"
                name="file"
                style={{ display: "none" }}
                onChange={(e) => uploadHandler(e)}
              />
            </div>
            <Form onSubmit={handleSave} ref={form}>
              <div className="col-md-12">
                <div className="row">
                  <div className="form-group col-md-6">
                    <button
                      className="btn btn-dark ml-2"
                      type="button"
                      onClick={() => openModal()}
                    >
                      Select Store
                    </button>
                    <div className="mt-2">{storeName}</div>
                  </div>
                  <div className="form-group col-md-6">
                    <label htmlFor="mainCategoryId">Main Category</label>

                    <Select
                      name="mainCategoryId"
                      value={mainCategoryId}
                      onChange={onChangeMainCatId}
                      validations={[required]}
                      className="form-control"
                    >
                      <option value="">Choose your main category</option>
                      {adminState.categories != null &&
                        adminState.categories.map((cat) => {
                          return (
                            <option key={cat._id} value={cat._id}>
                              {cat.name}
                            </option>
                          );
                        })}
                    </Select>
                  </div>

                  <div className="form-group col-md-6">
                    <label htmlFor="categoryId">Category</label>

                    <Select
                      name="categoryId"
                      value={categoryId}
                      onChange={onChangeCatId}
                      validations={[required]}
                      className="form-control"
                    >
                      <option value="">Choose your category</option>
                      {catList != null &&
                        catList.map((cat) => {
                          return (
                            <option key={cat._id} value={cat._id}>
                              {cat.name}
                            </option>
                          );
                        })}
                    </Select>
                  </div>

                  <div className="form-group col-md-6">
                    <label htmlFor="subCategoryId">Sub Category</label>

                    <Select
                      name="subCategoryId"
                      value={subCategoryId}
                      onChange={onChangeSubCatId}
                      validations={[required]}
                      className="form-control"
                    >
                      <option value="">Choose your sub-category</option>
                      {subCatList != null &&
                        subCatList.map((cat) => {
                          return (
                            <option key={cat._id} value={cat._id}>
                              {cat.name}
                            </option>
                          );
                        })}
                    </Select>
                  </div>

                  <div className="form-group col-md-6">
                    <label htmlFor="subCategoryId">Child Category</label>

                    <Select
                      name="childCategoryId"
                      value={childCategoryId}
                      onChange={onChangeChildCatId}
                      className="form-control"
                    >
                      <option value="">Choose your child-category</option>
                      {childCatList != null &&
                        childCatList.map((cat) => {
                          return (
                            <option key={cat._id} value={cat._id}>
                              {cat.name}
                            </option>
                          );
                        })}
                    </Select>
                  </div>
                  <div className="col-md-12 float-right">
                    <button className="btn btn-primary btn-block-xs">
                      <span>Save</span>
                    </button>
                  </div>
                  <CheckButton style={{ display: "none" }} ref={checkBtn} />
                </div>
              </div>
            </Form>
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default AdminAdsManagement;
