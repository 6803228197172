import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import { BASE_URL } from "../../../services/constants";

const LogoImage = ({ logo }) => {
  let baseUri = BASE_URL;
  return (
    <Fragment>
      <Link to={"/"} className="">
        <img
          id="logo"
          href="/"
          src={baseUri + "files/onmee_logo.png"}
          alt="Onmee"
        />
      </Link>
    </Fragment>
  );
};

export default LogoImage;
