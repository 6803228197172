import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Sliders from "../common/Blog/blog1";
import "./category.scss";
import LeftSidebar from "../shop/left_sidebar";
import {
  bannerList,
  getAllCategories,
  getOffersByCategory,
  getOffersByCategory1,
  getOffersByCategory2,
  getOffersByCategory3,
  getOffersByCategory4,
  updatePagination,
  getProducts,
  getProductsCount,
  resetFeatures,
  offerFor1SubCat,
  catBannerList,
  getChildOffer,
  offerFor2SubCat,
  offerFor3SubCat,
  offerFor4SubCat,
  offerFor5SubCat,
  updateSubCatId,
  updateChildCatId,
  updateOfferFilter,
  updatePriceFilter,
  resetCatAndSubCat,
  getAllFilters1,
  subBannerList,
  saveWishlist,
  updateOtherFilters,
  getBrandsByCat,
  updateBrand,
  saveToCart,
  updateWishlistAdded,
  updateCartAdded,
  updatemainCatId,
} from "../../slices/categorySlice";
import StaticHomeBanner from "../home/StaticHomeBanner";
import StoreHome from "../home/StoreHome";
import CatHeader from "./catHeader";
import OnmeeCarousel from "../../helpers/OnmeeCarousel";
import BannerCat from "../../helpers/BannerCat";
import FashionHome from "../../helpers/FashionHome";
import SubCategoryPage from "./SubCategoryPage";
import { useLocation } from "react-router";
import { localSaveToCart, getUniqueId } from "../../helpers/LocalCart";

import { useAlert } from "react-alert";
import {
  getCartsCount,
  getWishCount,
  updateLocation,
} from "../../slices/headerSlice";
import MainBanner from "../home/MainBanner";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Category = (props) => {
  const dispatch = useDispatch();
  const categoryState = useSelector((state) => state.category);
  const headerState = useSelector((state) => state.header);
  const [mainCatId, setMainCatId] = useState(props.match.params.id);
  const [catId, setCatId] = useState(null);
  const [subCatNames, setSubCatNames] = useState({});
  const [subCat, setSubCat] = useState("");
  const { query, search } = useLocation();
  const [userId, setUserId] = useState(null);
  const [banner, setBanner] = useState(false);
  const [Storename, setStorename] = useState(null);
  const [storeName, setStoreName] = useState(null);

  // const alert = useAlert();
  const allCats =
    localStorage.getItem("allCats") != null
      ? JSON.parse(localStorage.getItem("allCats"))
      : null;
  useEffect(() => {
    dispatch(getAllFilters1());
  }, [dispatch]);

  useEffect(() => {
    if (categoryState.wishAdded === true) {
      // alert.show("Product added to wishlist !!!");
      toast.success("Product added to wishlist !!!");
      let userId =
        categoryState.user != null ? categoryState.user._id : getUniqueId();
      dispatch(getWishCount(userId));
      dispatch(updateWishlistAdded());
    }
  }, [categoryState.wishAdded]);

  // useEffect(() => {
  // if (query == null) {
  // dispatch(resetFeatures());
  // setUserId(null);
  // setStoreName(null);
  // setCatId(null);
  // setSubCat(null);
  // setMainCatId(props.match.params.id);
  // dispatch(resetCatAndSubCat());
  // dispatch(getAllCategories(props.match.params.id));
  // dispatch(bannerList(props.match.params.id));
  // }
  // else if (query != null) {
  //   dispatch(resetFeatures());
  //   dispatch(getAllCategories(props.match.params.id));
  //   let qIds = query.idString.split("#");
  //   setCatId(null);
  //   setMainCatId(props.match.params.id);
  //   if (qIds.length > 1) {
  //     if (categoryState.list != null) {
  //       let found = false;
  //       for (let i = 0; i < categoryState.list.length && !found; i++) {
  //         let temp = categoryState.list[i];
  //         if (temp.subCategories != null && temp.subCategories.length > 0) {
  //           for (let j = 0; j < temp.subCategories.length; j++) {
  //             let subTemp = temp.subCategories[j];

  //             if (subTemp._id === qIds[1]) {
  //               found = true;
  //               setSubCat(subTemp);
  //             }
  //           }
  //         }
  //       }
  //     }

  //     if (qIds.length >= 4) {
  //       setStoreName(qIds[3]);
  //     }

  //     setUserId(qIds[0]);
  //     dispatch(updateSubCatId(qIds[1]));
  //     dispatch(subBannerList(qIds[1]));
  //     dispatch(getProducts(qIds[0]));
  //     dispatch(getProductsCount(qIds[0]));
  //   }
  // }
  // }, [props.match.params.id, dispatch]);

  const refreshProducts = () => {
    if (search != null && search !== "") {
      let params = new URLSearchParams(search);
      const subCatId = params.get("subCatId");
      const childCatId = params.get("childCatId");
      const userId = params.get("uId");
      const banner = params.get("banner");
      const Storename = params.get("sname");

      if (subCatId == null && userId == null && childCatId == null) {
        dispatch(resetFeatures());
        setUserId(null);
        setStoreName(null);
        setCatId(null);
        setSubCat(null);
        setMainCatId(props.match.params.id);
        dispatch(resetCatAndSubCat());
        dispatch(getAllCategories(props.match.params.id));
        dispatch(bannerList(props.match.params.id));
      } else {
        dispatch(resetFeatures());
        dispatch(resetCatAndSubCat());
        dispatch(getAllCategories(props.match.params.id));
        setCatId(null);

        let brandsFired = false;
        if (!banner) {
          if (subCatId) {
            dispatch(updateSubCatId(subCatId));
            dispatch(subBannerList(subCatId));
            dispatch(getBrandsByCat({ subCatId: subCatId }));
            brandsFired = true;
          }
        }

        if (banner) {
          setBanner(true);
          setStoreName(Storename);
          dispatch(updatemainCatId(mainCatId));
        } else {
          setBanner(false);
          setStoreName(null);
          dispatch(updatemainCatId(null));
        }

        if (userId != null) {
          setUserId(userId);
        }

        if (childCatId) {
          dispatch(updateChildCatId(childCatId));
          if (!brandsFired) {
            dispatch(getBrandsByCat({ childCatId: childCatId }));
          }
        }

        if (allCats != null && allCats.length > 0 && subCatId != null) {
          let found = false;
          for (let i = 0; i < allCats.length && !found; i++) {
            let temp = allCats[i];
            if (temp.categories != null && temp.categories.length > 0) {
              for (let j = 0; j < temp.categories.length && !found; j++) {
                let subTemp = temp.categories[j];

                for (
                  let k = 0;
                  k < subTemp.subCategories.length && !found;
                  k++
                ) {
                  let sTemp = subTemp.subCategories[k];
                  if (sTemp._id === subCatId) {
                    found = true;
                    setSubCat(sTemp);
                  }
                }
              }
            }
          }
        }
        if (userId != null && banner) {
          dispatch(getProducts(userId));
          dispatch(getProductsCount(userId));
        } else if (userId != null) {
          dispatch(getProducts(userId));
          dispatch(getProductsCount(userId));
        } else {
          setUserId(null);
          setStoreName(null);
          dispatch(getProducts());
          dispatch(getProductsCount());
        }
      }
    } else {
      dispatch(resetFeatures());
      setUserId(null);
      setStoreName(null);
      setCatId(null);
      setSubCat(null);
      setMainCatId(props.match.params.id);
      dispatch(resetCatAndSubCat());
      dispatch(getAllCategories(props.match.params.id));
      dispatch(bannerList(props.match.params.id));
    }
  };

  useEffect(() => {
    refreshProducts();
  }, [search, dispatch, props.match.params.id]);

  useEffect(() => {
    // console.log('update: ' + headerState.updateLocation);
    if (headerState.updateLocation) {
      refreshStores();
      refreshProducts();
      dispatch(updateLocation(0));
    }
  }, [headerState.updateLocation]);

  const refreshStores = () => {
    if (
      categoryState.top5Cat != null &&
      mainCatId != null &&
      catId == null &&
      subCat == null
    ) {
      if (categoryState.top5Cat.CAT0 != null) {
        let param = {
          name: categoryState.top5Cat.CAT0.header,
          mainCatId: mainCatId,
        };
        dispatch(getOffersByCategory(param));
      }

      if (categoryState.top5Cat.CAT1 != null) {
        let param = {
          name: categoryState.top5Cat.CAT1.header,
          mainCatId: mainCatId,
        };
        dispatch(getOffersByCategory1(param));
      }

      if (categoryState.top5Cat.CAT2 != null) {
        let param = {
          name: categoryState.top5Cat.CAT2.header,
          mainCatId: mainCatId,
        };
        dispatch(getOffersByCategory2(param));
      }

      if (categoryState.top5Cat.CAT3 != null) {
        let param = {
          name: categoryState.top5Cat.CAT3.header,
          mainCatId: mainCatId,
        };
        dispatch(getOffersByCategory3(param));
      }

      if (categoryState.top5Cat.CAT4 != null) {
        let param = {
          name: categoryState.top5Cat.CAT4.header,
          mainCatId: mainCatId,
        };
        dispatch(getOffersByCategory4(param));
      }
    }
  };

  useEffect(() => {
    refreshStores();
  }, [dispatch, categoryState.top5Cat, mainCatId, catId, subCat]);

  const dispatchUpdateSelected = (category) => {
    setCatId(category._id);
    setSubCat(null);
    setUserId(null);
    setStoreName(null);
    dispatch(catBannerList(category._id));
    if (category.subCategories && category.subCategories.length > 0) {
      let subCatNames1 = {};
      dispatch(getChildOffer(category.subCategories[0]._id));
      subCatNames1.cat1Name =
        "Best offer's on " + category.subCategories[0].name;
      dispatch(offerFor1SubCat(category.subCategories[0]._id));
      if (category.subCategories && category.subCategories.length > 1) {
        subCatNames1.cat2Name =
          "Best offer's on " + category.subCategories[1].name;
        dispatch(offerFor2SubCat(category.subCategories[1]._id));
      }
      if (category.subCategories && category.subCategories.length > 3) {
        subCatNames1.cat3Name =
          "Best offer's on " + category.subCategories[2].name;
        dispatch(offerFor3SubCat(category.subCategories[2]._id));
      }

      if (category.subCategories && category.subCategories.length > 4) {
        subCatNames1.cat4Name =
          "Best offer's on " + category.subCategories[3].name;
        dispatch(offerFor4SubCat(category.subCategories[3]._id));
      }

      if (category.subCategories && category.subCategories.length >= 5) {
        subCatNames1.cat5Name =
          "Best offer's on " + category.subCategories[4].name;
        dispatch(offerFor5SubCat(category.subCategories[4]._id));
      }
      setSubCatNames(subCatNames1);
    }
  };

  const updateSubCat = (subCatObj) => {
    setCatId(null);
    setUserId(null);
    setStoreName(null);
    setSubCat(subCatObj);
    dispatch(resetFeatures());
    dispatch(updateSubCatId(subCatObj._id));
    dispatch(subBannerList(subCatObj._id));
    dispatch(getProducts());
    dispatch(getProductsCount());
  };

  const catFilterClicked = (item) => {
    dispatch(updateChildCatId(item._id));

    if (subCat != null && item && userId) {
      dispatch(getBrandsByCat({ childCatId: item }));
    }

    dispatch(getProducts(userId));
    dispatch(getProductsCount(userId));
  };

  const clearSelectedFilter = () => {
    setCheckboxes({});
    setBrandCheckbox([]);
    setUserId(null);
    setStoreName(null);
    dispatch(resetFeatures());
    dispatch(getProducts(userId));
    dispatch(getProductsCount(userId));
  };

  const handlePageChange = (pageNumber) => {
    dispatch(updatePagination(pageNumber));
    if (userId != null && banner) {
      dispatch(updatemainCatId(mainCatId));
      dispatch(getProducts(userId));
    } else {
      dispatch(getProducts());
    }
  };

  const onOfferUpdate = (item) => {
    dispatch(updateOfferFilter(item));
    dispatch(getProducts(userId));
    dispatch(getProductsCount(userId));
  };

  const onPriceUpdate = (item) => {
    dispatch(updatePriceFilter(item));
    dispatch(getProducts(userId));
    dispatch(getProductsCount(userId));
  };

  const onStoreUpdate = (item) => {
    setStoreName(item.userId.storeName);
    setUserId(item.userId._id);
    dispatch(getProducts(item.userId._id));
    dispatch(getProductsCount(item.userId._id));

    if (subCat != null && !categoryState.features.childCatId) {
      dispatch(getBrandsByCat({ subCatId: subCat._id }));
    } else if (subCat != null && categoryState.features.childCatId) {
      dispatch(
        getBrandsByCat({ childCatId: categoryState.features.childCatId })
      );
    }
  };

  const updateChildCat = (subCatObj, childCatObj) => {
    setCatId(null);
    setUserId(null);
    setStoreName(null);
    setSubCat(subCatObj);
    dispatch(resetFeatures());
    dispatch(updateChildCatId(childCatObj._id));
    dispatch(updateSubCatId(subCatObj._id));
    dispatch(subBannerList(subCatObj._id));
    dispatch(getProducts());
    dispatch(getProductsCount());
  };

  const [checkboxes, setCheckboxes] = useState({});

  const handleCheckbox = (e, item, path) => {
    const cbs = { ...checkboxes };

    if (!cbs[path]) {
      cbs[path] = [];
    }

    const checkedBoxes = cbs[path];

    if (e.target.checked) {
      checkedBoxes.push(item);
    } else {
      const index = checkedBoxes.findIndex((ch) => ch.code === item.code);
      checkedBoxes.splice(index, 1);
    }

    cbs[path] = checkedBoxes;
    setCheckboxes(cbs);
    dispatch(updateOtherFilters(cbs));
    dispatch(getProducts());
    dispatch(getProductsCount());
  };

  const [brandCheckbox, setBrandCheckbox] = useState([]);

  const handleBrandCheckbox = (e, item) => {
    const cbs = [...brandCheckbox];

    if (!cbs) {
      cbs = [];
    }

    if (e.target.checked) {
      cbs.push(item);
    } else {
      const index = cbs.findIndex((ch) => ch.name === item.code);
      cbs.splice(index, 1);
    }

    setBrandCheckbox(cbs);
    let b = null;

    if (cbs != null) {
      cbs.forEach((c) => {
        b = b == null ? c._id : b + "," + c._id;
      });
    }

    dispatch(updateBrand(b));
    dispatch(getProducts(userId));
    dispatch(getProductsCount(userId));
  };

  const addToWishlist = (prod) => {
    let userId =
      categoryState.user != null ? categoryState.user._id : getUniqueId();

    const wish = {
      productId: prod._id,
      userId: userId,
    };

    dispatch(saveWishlist(wish));
  };

  useEffect(() => {
    if (categoryState.savedToCart) {
      let userId =
        categoryState.user != null ? categoryState.user._id : getUniqueId();
      // alert.show("Added to cart !!!");
      toast.success("Added to cart !!!");
      dispatch(getCartsCount(userId));
      dispatch(updateCartAdded());
    }
  }, [categoryState.savedToCart, dispatch]);

  const addToCart = (prod) => {
    let userId =
      categoryState.user != null ? categoryState.user._id : getUniqueId();

    if (userId) {
      const cartObj = {
        productId: prod._id,
        userId: userId,
        stock: 1,
      };

      dispatch(saveToCart(cartObj));
      dispatch(getCartsCount(userId));
      dispatch(updateCartAdded());
    }
  };

  return (
    <div className="col-md-12">
      <div className="">
        {/* //here one row */}
        {/* <CatHeader
          mainCatId={mainCatId}
          cats={categoryState.list}
          updateCat={dispatchUpdateSelected}
          updateSubCat={updateSubCat}
          updateChildCat={updateChildCat}
        ></CatHeader> */}
        {subCat == null && catId == null ? (
          <>
            <div className="container mt-5">
              {categoryState.row1Banner ? (
                <OnmeeCarousel
                  showThumbs={false}
                  items={categoryState.row1Banner}
                ></OnmeeCarousel>
              ) : null}
            </div>
            {categoryState.row2Banner ? (
              // <BannerCat items={categoryState.row2Banner}></BannerCat>
              <MainBanner banners={categoryState.row2Banner} />
            ) : null}
            {categoryState.offers0List &&
            categoryState.top5Cat &&
            categoryState.top5Cat.CAT0 ? (
              <Sliders
                type="fashion"
                title="title1"
                inner="title-inner1"
                items={categoryState.offers0List}
                heading={categoryState.top5Cat.CAT0.headerName}
                model="category"
              />
            ) : null}
            {categoryState.row3Banner ? (
              <div className="col-md-12 p-0">
                <StaticHomeBanner
                  items={categoryState.row3Banner}
                ></StaticHomeBanner>
              </div>
            ) : null}
            {categoryState.offers1List &&
            categoryState.top5Cat &&
            categoryState.top5Cat.CAT1 ? (
              <Sliders
                type="fashion"
                title="title1"
                inner="title-inner1"
                items={categoryState.offers1List}
                heading={categoryState.top5Cat.CAT1.headerName}
                model="category"
              />
            ) : null}
            {categoryState.row4Banner ? (
              <div className="col-md-12 p-0">
                <StaticHomeBanner
                  items={categoryState.row4Banner}
                ></StaticHomeBanner>
              </div>
            ) : null}
            {categoryState.offers2List &&
            categoryState.top5Cat &&
            categoryState.top5Cat.CAT2 ? (
              <Sliders
                type="fashion"
                title="title1"
                inner="title-inner1"
                items={categoryState.offers2List}
                heading={categoryState.top5Cat.CAT2.headerName}
                model="category"
              />
            ) : null}
            {categoryState.row5Banner ? (
              <div className="col-md-12 p-0">
                <StaticHomeBanner
                  items={categoryState.row5Banner}
                ></StaticHomeBanner>
              </div>
            ) : null}
            {categoryState.offers3List &&
            categoryState.top5Cat &&
            categoryState.top5Cat.CAT3 ? (
              <Sliders
                type="fashion"
                title="title1"
                inner="title-inner1"
                items={categoryState.offers3List}
                heading={categoryState.top5Cat.CAT3.headerName}
                model="category"
              />
            ) : null}
            {categoryState.row6Banner ? (
              <div className="col-md-12 p-0">
                <StaticHomeBanner
                  items={categoryState.row6Banner}
                ></StaticHomeBanner>
              </div>
            ) : null}
            {categoryState.offers4List &&
            categoryState.top5Cat &&
            categoryState.top5Cat.CAT4 ? (
              <Sliders
                type="fashion"
                title="title1"
                inner="title-inner1"
                items={categoryState.offers4List}
                heading={categoryState.top5Cat.CAT4.headerName}
                model="category"
              />
            ) : null}
          </>
        ) : null}

        {catId != null ? (
          <>
            {categoryState.catBannerList &&
            categoryState.catBannerList.row1Banner ? (
              <div className="container mt-5">
                <OnmeeCarousel
                  showThumbs={false}
                  items={categoryState.catBannerList.row1Banner}
                ></OnmeeCarousel>
              </div>
            ) : null}
            {categoryState.catBannerList &&
            categoryState.catBannerList.row2Banner ? (
              <div className="col-md-12 mt-3">
                <FashionHome
                  banners={categoryState.catBannerList.row2Banner}
                  items={categoryState.childCatOffer}
                  name="Exciting offer's on Clothing"
                ></FashionHome>
              </div>
            ) : null}
            {categoryState.subOfferList && categoryState.subOfferList.offer1 ? (
              <div className="col-md-12 mt-3">
                <Sliders
                  type="fashion"
                  title="title1"
                  inner="title-inner1"
                  items={categoryState.subOfferList.offer1}
                  heading={subCatNames.cat1Name}
                  model="category"
                />
              </div>
            ) : null}
            {categoryState.catBannerList &&
            categoryState.catBannerList.row3Banner ? (
              <div className="col-md-12 mt-3">
                <StaticHomeBanner
                  items={categoryState.catBannerList.row3Banner}
                ></StaticHomeBanner>
              </div>
            ) : null}
            {categoryState.subOfferList && categoryState.subOfferList.offer2 ? (
              <div className="col-md-12 mt-3">
                <StoreHome
                  items={categoryState.subOfferList.offer2}
                  headerName={subCatNames.cat2Name}
                />
              </div>
            ) : null}
            {categoryState.catBannerList &&
            categoryState.catBannerList.row4Banner ? (
              <div className="col-md-12 mt-3">
                <StaticHomeBanner
                  items={categoryState.catBannerList.row4Banner}
                ></StaticHomeBanner>
              </div>
            ) : null}
            {categoryState.subOfferList && categoryState.subOfferList.offer3 ? (
              <div className="col-md-12 mt-3">
                <StoreHome
                  items={categoryState.subOfferList.offer3}
                  headerName={subCatNames.cat3Name}
                />
              </div>
            ) : null}
            {categoryState.catBannerList &&
            categoryState.catBannerList.row5Banner ? (
              <div className="col-md-12 mt-3">
                <StaticHomeBanner
                  items={categoryState.catBannerList.row5Banner}
                ></StaticHomeBanner>
              </div>
            ) : null}
            {categoryState.subOfferList && categoryState.subOfferList.offer4 ? (
              <div className="col-md-12 mt-3">
                <StoreHome
                  items={categoryState.subOfferList.offer4}
                  headerName={subCatNames.cat4Name}
                />
              </div>
            ) : null}
            {categoryState.catBannerList &&
            categoryState.catBannerList.row6Banner ? (
              <div className="col-md-12 mt-3">
                <StaticHomeBanner
                  items={categoryState.catBannerList.row6Banner}
                ></StaticHomeBanner>
              </div>
            ) : null}
            {categoryState.subOfferList && categoryState.subOfferList.offer5 ? (
              <div className="col-md-12 mt-3">
                <StoreHome
                  items={categoryState.subOfferList.offer5}
                  headerName={subCatNames.cat5Name}
                />
              </div>
            ) : null}
          </>
        ) : null}

        {subCat != null ? (
          <>
            <LeftSidebar storeName={storeName} BannerStorename={Storename}>
              <SubCategoryPage
                mainCatId={mainCatId}
                subBanner={categoryState.subBanner}
                page={categoryState.features.page}
                ps={categoryState.features.ps}
                count={categoryState.count}
                handlePageChange={handlePageChange}
                items={categoryState.prodList}
                sub={subCat}
                catFilterClicked={catFilterClicked}
                childFilter={categoryState.features.childCatId}
                onOfferUpdate={onOfferUpdate}
                offerFilter={categoryState.features.omax}
                onCategoryClear={clearSelectedFilter}
                onPriceUpdate={onPriceUpdate}
                priceFilter={categoryState.features.pmin}
                onStoreUpdate={onStoreUpdate}
                userFilter={userId}
                filters={categoryState.filters}
                checkboxes={checkboxes}
                handleCheckbox={handleCheckbox}
                storeName={storeName}
                addToWishlist={addToWishlist}
                addToCart={addToCart}
                userObj={categoryState.user}
                brandsList={categoryState.brandsList}
                handleBrandCheckbox={handleBrandCheckbox}
                brandCheckbox={brandCheckbox}
                loading={categoryState.loading}
                banner={banner}
                allCats={allCats}
                Storename={Storename}
              ></SubCategoryPage>
            </LeftSidebar>
          </>
        ) : null}
      </div>
    </div>
  );
};

export default Category;
