import ProductCard from "./ProductCard";

const StoreProductList = (props) => {
  console.log("productsitems", props.items);
  const platItem =
    props.items != null && props.items.length > 0
      ? props.items.map((item) => {
          return (
            <div key={item._id} className="col-md-4 ">
              <ProductCard
                item={item}
                addToWishlist={props.addToWishlist}
                userObj={props.userObj}
                addToCart={props.addToCart}
              ></ProductCard>
            </div>
          );
        })
      : null;

  return (
    <div className="container">
      <div className="row">{platItem}</div>
    </div>
  );
};

export default StoreProductList;
