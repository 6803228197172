import axios from "axios";
import authHeader from "./auth-header";
import { BASE_API_URL } from "./constants";

const API_URL = BASE_API_URL + "/api/activity/";

export const getActivities = (features) => {
  let url = API_URL + "list";

  url = url + "?ps=" + features.ps + "&sidx=" + features.sidx;
  return axios.get(url, { headers: authHeader() });
};

export const getActivitiesCount = () => {
    let url = API_URL + "listCount";
    return axios.get(url, { headers: authHeader() });
  };

export const saveActivity = (activityObj) => {
  let url = API_URL + "save";
  return axios.post(url, activityObj, { headers: authHeader() });
};

export const deleteActivity = (id) => {
  let url = API_URL + "delete";
  return axios.post(url, { id: id }, { headers: authHeader() });
};