import axios from "axios";
import authHeader from "./auth-header";
import { BASE_API_URL } from "./constants";

const API_URL = BASE_API_URL + "/api/test/";
const API_URL_1 = BASE_API_URL + "/api/user/";
const API_URL_AUTH = BASE_API_URL + "/api/auth/";
const API_URL_BRANDS = BASE_API_URL + "/api/brands/";

export const getPublicContent = () => {
  return axios.get(API_URL + "all");
};

export const getUserBoard = () => {
  return axios.get(API_URL + "user", { headers: authHeader() });
};

export const getModeratorBoard = () => {
  return axios.get(API_URL + "mod", { headers: authHeader() });
};

export const getAdminBoard = () => {
  return axios.get(API_URL + "admin", { headers: authHeader() });
};

export const updateUser = (obj) => {
  return axios.post(API_URL_1 + "update", obj, { headers: authHeader() });
};

export const merchantSignup = (obj) => {
  return axios.post(API_URL_AUTH + "signupMerchant", obj);
};

export const signupMerchantAndProceedToPay = (obj) => {
  for (var pair of obj.entries()) {
    console.log(pair[1],pair[0]); 
  }
  // return axios.post(API_URL_AUTH + "signupMerchantAndProceedToPay", obj);

  return axios({
    method: 'post',
    url: API_URL_AUTH + "signupMerchantAndProceedToPay",
    data: obj,
    headers: { 'Content-Type': 'multipart/form-data' }
  });
};

export const paymentSuccessForMerchant = (obj) => {
  return axios.post(API_URL_AUTH + "paymentSuccess", obj);
};

export const merchantList = (params, features) => {
  let url = API_URL_1 + "getMerchantList";
  if (params.active) {
    url = url + "?active=" + params.active;
  }

  if (params.rejected) {
    if (url.indexOf("?") > -1) {
      url = url + "&rejected=" + params.rejected;
    } else {
      url = url + "?rejected=" + params.rejected;
    }
  }

  if (params.inactive) {
    if (url.indexOf("?") > -1) {
      url = url + "&inactive=" + params.inactive;
    } else {
      url = url + "?inactive=" + params.inactive;
    }
  }
  
  if (url.indexOf("?") > -1) {
    url = url + "&ps=" + features.ps + "&sidx=" + features.sidx;
  } else {
    url = url + "?ps=" + features.ps + "&sidx=" + features.sidx;
  }

  return axios.get(url, { headers: authHeader() });
};

export const merchantCount = (params, features) => {
  let url = API_URL_1 + "getMerchantCount";

  if (params.active) {
    url = url + "?active=" + params.active;
  }

  if (params.rejected) {
    if (url.indexOf("?") > -1) {
      url = url + "&rejected=" + params.rejected;
    } else {
      url = url + "?rejected=" + params.rejected;
    }
  }

  if (params.inactive) {
    if (url.indexOf("?") > -1) {
      url = url + "&inactive=" + params.inactive;
    } else {
      url = url + "?inactive=" + params.inactive;
    }
  }

  if (url.indexOf("?") > -1) {
    url = url + "&ps=" + features.ps + "&sidx=" + features.sidx;
  } else {
    url = url + "?ps=" + features.ps + "&sidx=" + features.sidx;
  }

  return axios.get(url, { headers: authHeader() });
};

export const markMerchantActive = (id) => {
  return axios.get(API_URL_1 + "markmerchantActive?id=" + id, {
    headers: authHeader(),
  });
};

export const customerList = (params, features) => {
  let url = API_URL_1 + "getCustomerList";
  
  if (url.indexOf("?") > -1) {
    url = url + "&ps=" + features.ps + "&sidx=" + features.sidx;
  } else {
    url = url + "?ps=" + features.ps + "&sidx=" + features.sidx;
  }

  return axios.get(url, { headers: authHeader() });
};

export const customerCount = (params) => {
  let url = API_URL_1 + "getCustomerCount";

  return axios.get(url, { headers: authHeader() });
};

export const getBrandList = (name, features) => {
  let url = API_URL_BRANDS + "list";
  url = url + "?ps=" + features.ps + "&sidx=" + features.sidx;
  if (name) {
    url = url + "&name=" + name;
  }

  return axios.get(url, { headers: authHeader() });
};

export const getBrandCount = (name) => {
  let url = API_URL_BRANDS + "count";
  if (name) {
    url = url + "?name=" + name;
  }

  return axios.get(url, { headers: authHeader() });
};

export const saveBrands = (obj) => {
  return axios.post(API_URL_BRANDS + "save", obj, { headers: authHeader() });
};

export const deleteBrand = (obj) => {
  return axios.post(API_URL_BRANDS + "delete", obj, { headers: authHeader() });
};

export const getBrandsByCategory = (params) => {
  let url = API_URL_BRANDS + "getBrandsBycategory";

  if (params.catId) url = url + "?catId=" + params.catId;
  else if (params.subCatId) url = url + "?subCatId=" + params.subCatId;
  else if (params.childCatId) url = url + "?childCatId=" + params.childCatId;

  return axios.get(url, { headers: authHeader() });
};

export const markMerchantInactive = (id) => {
  return axios.get(API_URL_1 + "markmerchantInactive?id=" + id, {
    headers: authHeader(),
  });
};

export const markMerchantRejected = (id) => {
  return axios.get(API_URL_1 + "markmerchantRejected?id=" + id, {
    headers: authHeader(),
  });
};

export const getAllPlans = (id) => {
  return axios.get(API_URL_1 + "getAllPlans", { headers: authHeader() });
};

export const saveDeliveryAddress = (obj) => {
  return axios.post(API_URL_1 + "saveShipping", obj, { headers: authHeader() });
}

export const removeDeliveryAddress = (userId, addressId) => {

  return axios.post(API_URL_1 + "deleteShipping", { userId: userId, addressId: addressId }, { headers: authHeader() });
};

export const getUser = (userId) => {
  return axios.get(API_URL_1 + "fetchById?id="+userId, { headers: authHeader() });
}

export const deleteUserById = (userId) => {

  // console.log(API_URL_1 + "deleteUser", { _id: userId })
  return axios.post(API_URL_1 + "deleteUser", { _id: userId }, { headers: authHeader() });
}
