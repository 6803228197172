import Checkbox from "rc-checkbox";
import { useState } from "react";
import { Collapse, Input } from "reactstrap";
import classes from "../category/Fillter.module.css";
const ColourItems = (props) => {
  const [isOpencolor, setIsOpencolor] = useState(true);
  const toggleColor = () => setIsOpencolor(!isOpencolor);
  return (
    <div className="collection-collapse-block open">
      <h3 className="collapse-block-title" onClick={toggleColor}>
        {props.headerName}
      </h3>
      <Collapse isOpen={isOpencolor} style={{ marginTop: "20px" }}>
        <ul className={`ONM_left-filters  ${classes.fillter__page}`}>
          {props.filters != null &&
          props.filters[props.path] != null &&
          props.filters[props.path].length > 0
            ? props.filters[props.path].map((filt) => {
                return (
                  <li key={filt.code} className="">
                    <Checkbox
                      checked={
                        props.colorCheckboxes != null &&
                        props.colorCheckboxes[props.path] != null &&
                        props.colorCheckboxes[props.path].find((ch) => {
                          return ch.code === filt.code;
                        })
                      }
                      onChange={(e) => {
                        props.handleColorCheckbox(e, filt, props.path);
                      }}
                    ></Checkbox>
                    <span className="ml-2">{filt.value}</span>
                  </li>
                );
              })
            : null}
        </ul>
      </Collapse>
    </div>
  );
};

export default ColourItems;
