import { useState } from "react";
import { FaTimes, FaPhone } from "react-icons/fa";
import Modal from "react-modal";

const PhoneContainer = (props) => {
  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      zIndex: "999",
      width: "40%",
    },
  };

  Modal.setAppElement("#root");
  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  return (
    <>
      {!props.button && (
        <FaPhone
          style={{ cursor: "pointer" }}
          size={14}
          onClick={() => openModal()}
        ></FaPhone>
      )}
      <Modal isOpen={isModalOpen} style={customStyles}>
        <FaTimes onClick={closeModal} className="float-right mb-2"></FaTimes>
        {props.item && props.item.userId && props.item.userId.phone
          ? props.item.userId.phone
          : null}
      </Modal>
    </>
  );
};

export default PhoneContainer;
