import axios from "axios";
import { BASE_API_URL } from "./constants";

const API_URL = BASE_API_URL + "/api/category/";
const API_URL_1 = BASE_API_URL + "/api/maincategory/";
const API_URL_SUB = BASE_API_URL + "/api/subcategory/";
const API_URL_CHILD = BASE_API_URL + "/api/childcategory/";
const API_URL_FILTER = BASE_API_URL + "/api/filter/";

export const getCategories = (mainCatId) => {
  let url = API_URL + "list";
  if(mainCatId)
  {
    url = url + "?mainCatId="+ mainCatId;
  }
  return axios.get(url);
};

export const getBestOffersByMainCatFashion = (name) => {
  let url = API_URL_1 + "getBestOffersByMainCatFashion";

  let lat = localStorage.getItem("latitude");
  let lng = localStorage.getItem("longitude");

  if(name)
  {
    url = url + "?name="+ encodeURIComponent(name);
  }

  if( lat && lng ){
    url = url + "&lat="+ encodeURIComponent(lat) + "&long="+ encodeURIComponent(lng);
  }

  return axios.get(url);
};

export const getBestOffersByMainCatGen = (name, features) => {
  let url = API_URL_1 + "getBestOffersByMainCatGen";

  let lat = localStorage.getItem("latitude");
  let lng = localStorage.getItem("longitude");

  if(name)
  {
    url = url + "?name="+ encodeURIComponent(name);
  }

  if( lat && lng ){
    url = url + "&lat="+ encodeURIComponent(lat) + "&long="+ encodeURIComponent(lng);
  }

  if(features != null && features.omax)
  {
    if(url.indexOf("?") > -1)
    {
      url = url + "&filter=OMIN:"+features.omin + ";OMAX:" + features.omax;
    }
    else
    {
      url = url + "?filter=OMIN:"+features.omin + ";OMAX:" + features.omax;
    }
  }

  return axios.get(url);
};

export const getBestOffersByCat = (name, mainCatId) => {
  let url = API_URL + "getBestOffersByCat";

  let lat = localStorage.getItem("latitude");
  let lng = localStorage.getItem("longitude");

  url = url + "?name="+ encodeURIComponent(name.name) + "&mainCatId="+ mainCatId;

  if( lat && lng ){
    url = url + "&lat="+ encodeURIComponent(lat) + "&long="+ encodeURIComponent(lng);
  }

  return axios.get(url);
}  

export const getListByUserId = (userId, mainCatId) => {
  let url = API_URL + "getListByUserId";
  url = url + "?userId="+ userId;
  return axios.get(url);
}

export const getSubs = (mainCatId) => {
  let url = API_URL + "list";
  if(mainCatId)
  {
    url = url + "?mainCatId="+ mainCatId;
  }
  return axios.get(url);
};

export const getAll = (mainCatId) => {
  let url = API_URL + "getAll";
  if(mainCatId)
  {
    url = url + "?mainCatId="+ mainCatId;
  }
  return axios.get(url);
};

export const getBestOffersBySubCat = (subCatId) => {
  let url = API_URL_SUB + "getBestOffers";

  let lat = localStorage.getItem("latitude");
  let lng = localStorage.getItem("longitude");
  
  url = url + "?subCatId="+ subCatId;

  if( lat && lng ){
    url = url + "&lat="+ encodeURIComponent(lat) + "&long="+ encodeURIComponent(lng);
  }

  return axios.get(url);
}

export const getBestChildOffers = (subId) => {
  let url = API_URL_SUB + "get6BestOffers";
  
  let lat = localStorage.getItem("latitude");
  let lng = localStorage.getItem("longitude");

  if(subId)
  {
    url = url + "?subId="+ subId;
  }

  if( lat && lng ){
    url = url + "&lat="+ encodeURIComponent(lat) + "&long="+ encodeURIComponent(lng);
  }

  return axios.get(url);
};


export const getAllFilters = (subId) => {
  let url = API_URL_FILTER + "getAll";
  return axios.get(url);
};

export const getEverything = () => {
  let url = API_URL_1 + "getEverything";
  return axios.get(url); 
}