import { FaEdit, FaTrash } from "react-icons/fa";

const MerchantProductList = (props) => {
  return (
    <div className="table-responsive ">
      <table className="table">
        <thead>
          <tr>
            <th>Name</th>
            <th>Main Category</th>
            <th>Category</th>
            <th>Sub category</th>
            <th>Child category</th>
            <th>Stock</th>
            <th>Price</th>
            <th>Offer</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {props.prodList != null && props.prodList.length > 0
            ? props.prodList.map((prod) => {
                return (
                  <tr key={prod._id}>
                    <td>{prod.name}</td>
                    <td>
                      {prod.mainCategoryId ? prod.mainCategoryId.name : "NA"}
                    </td>
                    <td>{prod.categoryId ? prod.categoryId.name : "NA"}</td>
                    <td>
                      {prod.subCategoryId ? prod.subCategoryId.name : null}
                    </td>
                    <td>
                      {prod.childCategoryId ? prod.childCategoryId.name : null}
                    </td>
                    <td>{prod.stock}</td>
                    <td>{prod.price}</td>
                    <td>{prod.offer}</td>
                    <td className="action__row">
                      <FaEdit
                        className="ONM_icons icon__edit"
                        onClick={() => props.editPage(prod)}
                      ></FaEdit>
                      <FaTrash
                        onClick={() => props.deleteProduct(prod)}
                        className="ONM_icons icon__delete"
                      ></FaTrash>
                    </td>
                  </tr>
                );
              })
            : null}
          <tr></tr>
        </tbody>
      </table>
    </div>
  );
};

export default MerchantProductList;
