import axios from "axios";
import authHeader from "./auth-header";
import { BASE_API_URL } from "./constants";

const API_URL = BASE_API_URL + "/api/order/";

export const save = (obj) => {
  return axios.post(API_URL + "save", obj, { headers: authHeader() });
};

export const getOrderList = (userId, features) => {
  let url = API_URL + "list?userId=" + userId + "&paid=1";
  url = url + "&ps=" + features.ps + "&sidx=" + features.sidx;
  return axios.get(url, { headers: authHeader() });
};

export const getOrderCount = (userId) => {
  let url = API_URL + "count?userId=" + userId + "&paid=1";
  return axios.get(url, { headers: authHeader() });
};

export const deleteWishlist = (obj) => {
  return axios.post(API_URL + "delete", obj, { headers: authHeader() });
};

export const saveAndProceed = (obj) => {
  return axios.post(API_URL + "saveAndProceed", obj, { headers: authHeader() });
};

export const paymentSuccess = (obj) => {
  return axios.post(API_URL + "paymentSuccess", obj, { headers: authHeader() });
};

export const getOrderById = (id) => {
  let url = API_URL + "fetchById?id=" + id;

  return axios.get(url, { headers: authHeader() });
};

export const getShippingTrack = (awb) => {
  let url = API_URL + "shippingTrack?awb=" + awb;

  return axios.get(url, { headers: authHeader() });
};



export const adminOrderList = (params, features) => {
  let url = API_URL + "getAdminOrderList?paid=1";
  url = url + "&ps=" + features.ps + "&sidx=" + features.sidx;

  return axios.get(url, { headers: authHeader() });
};

export const adminOrderCount = () => {
  let url = API_URL + "getAdminOrderCount?paid=1";
  return axios.get(url, { headers: authHeader() });
};

export const merchantOrderList = (params, features) => {
  let url = API_URL + "getMerchantOrderList?paid=1";
  url = url + "&ps=" + features.ps + "&sidx=" + features.sidx;

  return axios.get(url, { headers: authHeader() });
};

export const merchantOrderCount = () => {
  let url = API_URL + "getMerchantOrderCount?paid=1";
  return axios.get(url, { headers: authHeader() });
};
