import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import MerchantDashboard from "./MerchantDashboard";
import MerchantProduct from "./MerchantProduct";
import MerchantOrder from "./MerchantOrder";
import MerchantProfile from "./MerchantProfile";
import { updatePanel } from "../../slices/merchantSlice";
import { Redirect } from "react-router";
import { FaExclamationCircle } from "react-icons/fa";

// signout
import { getCartsCount, getWishCount, update } from "../../slices/headerSlice";
import { updateLoggedInUser } from "../../slices/categorySlice";
import { updateLoggedInUserHome } from "../../slices/homeSlice";
import { updateLoggedInUserOffer } from "../../slices/OfferSlice";
import { updateMerchant } from "../../slices/merchantSlice";
import { updateAdmin } from "../../slices/adminSlice";
// import { logout } from "../../slices/merchantSlice";
import { logout as resetMerchantState } from "../../slices/merchantSlice";
import { logout as resetAdminState } from "../../slices/adminSlice";
import { logout as resetAuthState } from "../../slices/authSlice";
import { updateAuth } from "../../slices/authSlice";
import { updateLoggedInUserProduct } from "../../slices/productSlice";
import { getUniqueId } from "../../helpers/LocalCart";
import MerchantLogin from "./MerchantLogin";
import { generalToken } from "../../services/auth.service";
import Layout from "../shop/common-layout";

const MerchantMain = (props) => {
  const merchantState = useSelector((state) => state.merchant);
  const dispatch = useDispatch();
  const [isSignOut, setIsSignOut] = useState(0);

  const panelClicked = (value) => {
    dispatch(updatePanel(value));
  };

  const signout = () => {
    localStorage.removeItem("user");
    // dispatch(logout());
    dispatch(resetAdminState());
    dispatch(resetMerchantState());
    dispatch(resetAuthState());
    dispatch(update());
    dispatch(updateLoggedInUser());
    dispatch(updateLoggedInUserHome());
    dispatch(updateLoggedInUserOffer());
    dispatch(updateMerchant());
    dispatch(updateAdmin());
    dispatch(updateAuth());
    dispatch(updateLoggedInUserProduct());
    dispatch(getCartsCount(getUniqueId()));
    dispatch(getWishCount(getUniqueId()));
    localStorage.removeItem("accessToken");

    setIsSignOut(1);

    // generate unanimouse accessToken
    let userId = getUniqueId();
    generalToken(userId).then((res) => {
      // console.log( res.data );
      let accessToken = res.data.accessToken;
      localStorage.setItem("accessToken", JSON.stringify(accessToken));
    });
  };

  useEffect(() => {
    setIsSignOut(0);
  }, [isSignOut]);

  const isProfileAlert = () => {
    let pickupName = merchantState.user.pickupName;
    let pickupAddressLine1 = merchantState.user.pickupAddressLine1;
    let pickupAddressLine2 = merchantState.user.pickupAddressLine2;
    let pickupPincode = merchantState.user.pickupPincode;
    let pickupPhone = merchantState.user.pickupPhone;

    if (
      !pickupName ||
      !pickupAddressLine1 ||
      !pickupAddressLine2 ||
      !pickupPincode ||
      !pickupPhone
    ) {
      return (
        <>
          <FaExclamationCircle color="#d90000" size={14}></FaExclamationCircle>
        </>
      );
    } else {
      return <></>;
    }
  };

  if (isSignOut) {
    return <Redirect to="/" />;
  }

  if (
    !merchantState.user ||
    (merchantState.user && !merchantState.user.roles.includes("ROLE_MERCHANT"))
  ) {
    return <Redirect to="/merchant/login" />;
    // return <MerchantLogin></MerchantLogin>;
  }

  let title = merchantState.user
    ? merchantState.user.firstName + " " + merchantState.user.lastName
    : "Merchant";

  return (
    <Layout parent="home" title={title}>
      <div className="container">
        <div className=" mt-4">
          {merchantState.user.planCode && merchantState.user.planId && (
            <>
              <header className="page-header">
                <div className="container">
                  <div className="row" style={{ justifyContent: "center" }}>
                    <ul className="ONM_merchant_profile">
                      {/* <li className={merchantState.panel === 'DASHBOARD'? 'active': null} onClick={() => panelClicked("DASHBOARD")}>Dashboard</li> */}
                      <li
                        className={
                          merchantState.panel === "ADDPRODUCT"
                            ? "merchant_active"
                            : null
                        }
                        onClick={() => panelClicked("ADDPRODUCT")}
                      >
                        Products
                      </li>
                      <li
                        className={
                          merchantState.panel === "ORDER"
                            ? "merchant_active"
                            : null
                        }
                        onClick={() => panelClicked("ORDER")}
                      >
                        Orders
                      </li>
                      {/* <li className={merchantState.panel === 'MEMBERSHIP'? 'active': null} onClick={() => panelClicked("MEMBERSHIP")}>Membership</li> */}
                      <li
                        className={
                          merchantState.panel === "MERCHANTPROFILE"
                            ? "merchant_active"
                            : null
                        }
                        onClick={() => panelClicked("MERCHANTPROFILE")}
                      >
                        Profile {isProfileAlert()}
                      </li>
                      {/* <li className={merchantState.panel === 'ADBANNER'? 'active': null} onClick={() => panelClicked("ADBANNER")}>AD Banner</li> */}
                      <li className={null} onClick={() => signout()}>
                        Sign Out
                      </li>
                    </ul>
                  </div>
                </div>
              </header>
            </>
          )}

          {merchantState.user.planCode && merchantState.user.planId && (
            <div className="row">
              <div className="col-md-12">
                {merchantState.panel === "DASHBOARD" ? (
                  <MerchantDashboard></MerchantDashboard>
                ) : null}
                {merchantState.panel === "ADDPRODUCT" ? (
                  <MerchantProduct></MerchantProduct>
                ) : null}
                {merchantState.panel === "ORDER" ? (
                  <MerchantOrder></MerchantOrder>
                ) : null}
                {merchantState.panel === "MERCHANTPROFILE" ? (
                  <MerchantProfile></MerchantProfile>
                ) : null}
              </div>
            </div>
          )}
          {!merchantState.user.planCode && !merchantState.user.planId && (
            <div className="row d-flex justify-content-center">
              <div className="ONM_login_otp col-md-4 ">
                <div className="col-md-12 align-content-center">
                  Please contact the admin to activate your plan.
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </Layout>
  );
};

export default MerchantMain;
