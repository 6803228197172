import React from "react";
import { Container, Row, Col } from "reactstrap";
import { Link } from "react-router-dom";

const TopBarDark = ({ topClass, fluid, headerstate, modelopen, signout }) => {
  const openmodal = () => {
    modelopen();
  };
  const signOut = (e) => {
    signout();
  };
  return (
    <div className="top-header">
      <Container fluid={fluid}>
        <Row>
          <Col md="6" sm="7">
            <div className="header-contact">
              <ul>
                {headerstate.city != null && headerstate.city !== "null" ? (
                  <li onClick={() => openmodal()} style={{ cursor: "Pointer" }}>
                    <i className="fa fa-map-marker" aria-hidden="true"></i>
                    <span title={headerstate.city}>{headerstate.city}</span>
                  </li>
                ) : (
                  <li onClick={() => openmodal()}>
                    <i className="fa fa-map-marker" aria-hidden="true"></i>
                    Select Address
                  </li>
                )}
              </ul>
            </div>
          </Col>
          <Col md="6" sm="5" className="text-right">
            <ul className="header-dropdown">
              <li className="mobile-wishlist">
                <Link to="/wishlist">
                  <a>
                    <i className="fa fa-heart top__icon" aria-hidden="true"></i>
                  </a>
                  <sup>
                    <span className="whisbadge badge-dark">
                      {headerstate.wishCount || 0}
                    </span>
                  </sup>
                </Link>
              </li>
              <li className="onhover-dropdown mobile-account">
                <i className="fa fa-user top__icon" aria-hidden="true"></i> My
                Account
                <ul className="onhover-show-div">
                  {!headerstate.user ? (
                    <>
                      <li>
                        <Link to="/login">Login</Link>
                      </li>
                    </>
                  ) : (
                    <>
                      {headerstate.user.roles &&
                        headerstate.user.roles.includes("ROLE_USER") && (
                          <>
                            <li>
                              <Link to="/profile">Profile</Link>
                            </li>
                            <li>
                              <Link to="/orders"> My Orders</Link>
                            </li>
                            <li>
                              <Link to="/wishlist">My Wishlist</Link>
                            </li>
                            <li>
                              <Link to="/" onClick={() => signOut()}>
                                {" "}
                                Sign Out
                              </Link>
                            </li>
                          </>
                        )}
                      {headerstate.user.roles &&
                        headerstate.user.roles.includes("ROLE_MERCHANT") && (
                          <>
                            <li>
                              <Link to="/merchant/main">Merchant Account</Link>
                            </li>
                            <li>
                              <Link to="/merchant/main"> Dashboard </Link>
                            </li>

                            <li>
                              <Link to="/" onClick={() => signOut()}>
                                {" "}
                                Sign Out
                              </Link>
                            </li>
                          </>
                        )}
                      {headerstate.user.roles &&
                        headerstate.user.roles.includes("ROLE_ADMIN") && (
                          <>
                            <li>
                              <Link to="/admin/dashboard">Admin Account</Link>
                            </li>
                            <li>
                              <Link to="/admin/dashboard"> Dashboard </Link>
                            </li>

                            <li>
                              <Link to="/" onClick={() => signOut()}>
                                {" "}
                                Sign Out
                              </Link>
                            </li>
                          </>
                        )}
                    </>
                  )}
                </ul>
              </li>
            </ul>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default TopBarDark;
