import Layout from "./shop/common-layout";

const PrivacyPolicy = () => {
  return (
    <Layout title="privacy policy" parent="home">
      <div className="content-container container">
        <section className="page-content">
          <p className="privacy__policy">
            Your privacy matters to M/s.Cimani Innovation Pvt. Ltd.
            (the "Company", "we", "ONMEE", or "Us"). ONMEE is committed to the
            ethical collection, retention and use of information that you
            provide to us about yourself. This Privacy Policy sets forth our
            understanding with You on the collection, process, use, share and
            protection of information about you. At ONMEE, we collect and
            process the information necessary for us to render and complete the
            Services undertaken by You with ONMEE. It also tells you how you can
            access and update your information and make certain choices about
            how your information is used. <br />
            The Privacy Policy covers both "online" (e.g., web and mobile
            services, including any websites operated by us or Mobile
            Application, however accessed and/or used, whether via personal
            computers, mobile devices or otherwise) and "offline" (e.g.,
            collection of data through mailings, telephone, or in person)
            activities owned, operated, provided, or made available by the
            Company. Our "online" and "offline" activities, in relation to
            facilitation of services wherein the users can avail services listed
            on the Platform, hereinafter collectively referenced as
            the "Services". The word ‘You’, ‘User’ shall collectively imply a
            seller, a buyer, and any visitor on the Platform. This Privacy
            Policy also applies to your use of interactive features or downloads
            that: (i) we own or control; (ii) are available through the
            Services; or (iii) interact with the Services.
            <br />
            <br /> It is stated that any information that you provide to ONMEE
            is subject to our Privacy Policy which governs our collection and
            use of your information. You understand and agree that through the
            use of the Services you consent to the collection and use (as set
            forth in the Privacy Policy) of this information, processing and use
            by ONMEE. As part of providing the Services to you, we may need to
            provide you with certain communications, such as service
            announcements, notifications, and administrative messages. These
            communications are considered part of the Services and your ONMEE
            user experience, which you may not be able to opt-out from
            receiving. However, You may choose to opt-out of certain
            communications from ONMEE including our standard newsletter or
            advertising messages, but you will continue to receive other
            communications messages from ONMEE.
            <br />
            <br /> We respect your right to privacy. At ONMEE we collect and
            process only the personal information necessary for us to render and
            complete any services undertaken by you with ONMEE. The information
            we collect includes the following:
            <br />
            <br /> • Identity Data includes first name, maiden name, last name,
            username or similar identifier, date of birth, the language you use,
            and gender.
            <br /> • Contact Data includes your phone number and email address.
            <br /> • Financial Data includes your payment information and card
            details.
            <br /> • Transaction Data includes details about payments to and
            from you and other details of services you may have purchased from
            us.
            <br /> • Technical & Location Data includes your geo location, your
            login data, browser type and version, time zone setting and
            location, browser plug-in types and versions, operating system, IP
            address and platform and other technology on the devices you use to
            access the Platform.
            <br /> • Profile Data includes your username and password, purchases
            or orders made by you, your interests, preferences, feedback and
            survey responses.
            <br /> • Usage Data includes information about how you use the
            Platform.
            <br /> • Marketing and Communications Data includes your preferences
            in receiving marketing from us and our third parties and your
            communication preferences. We collect these data when you –<br />
            <br /> • Register or create an account on our Website/Platform or
            avail our services.
            <br /> • Voluntarily complete a survey or provide feedback on any of
            our message boards or via email.
            <br /> • Use or view our website via your browser’s cookies.
            <br /> • subscribe to our publications, newsletters;
            <br />
            <br /> ONMEE securely stores your data in our servers which are
            secure. We follow strict security measures, protecting the
            information under our possession from unauthorized access. ONMEE
            will keep your data only for the time period required for rendering
            the Services. And thereafter shall delete the data. You can also ask
            for deletion of the data.
            <br />
            <br /> <b>LINKS TO OTHER WEBSITE:</b>
            <br />
            <br /> ONMEE may contain links to third-party websites, plug-ins and
            applications which may not subject to this privacy policy. Clicking
            on those links or enabling those connections may allow third parties
            to collect or share personally identifiable information/ data about
            you. We do not control these third-party websites and are not
            responsible for their privacy statements and business practices.
            When you leave our Platform, we encourage you to read the privacy
            notice of every website you visit.
            <br />
            ONMEE utilizes “cookies” to help recognize you as a repeat visitor
            and to track traffic patterns on our website. This information is
            completely anonymous. We use this information only to improve the
            user-friendliness and functionality of the ONMEE website. <br />
            ONMEE reserves the right to update this privacy policy at any time.
            Updates to our privacy policy will be sent to the email address
            available with us.
            <br />
            <br /> <b>COLLECTION OF PERSONAL INFORMATION:</b>
            <br />
            <br /> We collect “personal” information from you when you provide
            it to us. We will only use your personal data when the law allows us
            to. Most commonly, we will use your personal data in the following
            circumstances:
            <br /> a. Where we need to perform the Services we offer. <br />
            b. Where it is necessary for our legitimate interests (or those of a
            third party) and your interests and fundamental rights do not
            override those interests.
            <br /> c. Where we need to comply with a legal or regulatory
            obligation.
            <br />
            <br /> <b>DISCLOSURE OF PERSONAL INFORMATION:</b>
            <br />
            <br /> Under no circumstances do we rent, trade or share your
            Personal Information that we have collected with any other company
            for their marketing purposes without your consent.We will not
            disclose your name, address and other information which identifies
            you personally to any third party without your consent. We reserve
            the right to collect general demographic and other anonymous
            information that does not personally identify you. This information
            is not associated with your personally identifiable information and
            cannot be linked to you personally. However, in case of a situation
            wherein we are obliged to comply with the law or a statutory
            obligation or a legal process, we would be compelled to provide
            information about a customer but personally identifiable information
            will be disclosed with your consent.
            <br />
            <br /> <b>ADVERTISEMENTS ON ONMEE PLATFORM</b>
            <br />
            <br /> We may use third-party advertising companies to serve ads
            when you visit our Website. These companies may use information (not
            including your name, address, email address, or telephone number)
            about your visits to this and other websites in order to provide
            advertisements about goods and services of interest to you.
            <br />
            <br /> <b>OPT-OUT OF FURTHER USAGE OF PERSONAL INFORMATION:</b>
            <br />
            <br /> If you are no longer interested in receiving e-mail
            announcements and other promotional/marketing information from us,
            or you want us to remove any PII that we have collected about you,
            please e-mail your request to ONMEE.
            <br />
            <br /> <b>WHY ARE COOKIES IMPORTANT:</b>
            <br />
            <br /> We use cookies and other technologies such as pixel tags and
            clear gifs to store certain types of information each time you visit
            any page on our website. Cookies enable this website to recognize
            the information you have consented to give to this website and help
            us determine what portions of this website are most appropriate for
            your professional needs. We may also use cookies to serve
            advertising banners to you. These banners may be served by us or by
            a third party on our behalf. These cookies will not contain any
            personal information. Additionally, you may encounter "cookies" or
            other similar devices on certain pages of the Website that are
            placed by third parties. We do not control the use of cookies by
            third parties.
            <br /> OPT-OUT of setting website cookie on users’ browsers: Whether
            you want your web browser to accept cookies or not is up to you. If
            you haven’t changed your computer’s settings, most likely your
            browser already accepts cookies. If you choose to decline cookies,
            you may not be able to fully experience all features of the website.
            You can also delete your browser cookies or disable them entirely.
            But this may significantly impact your experience with our website
            and may make parts of our website non- functional or inaccessible.
            We recommend that you leave them turned on.
            <br />
            <br /> <b>WHAT ARE YOUR DATA PROTECTION RIGHTS?</b>
            <br />
            <br /> ONMEE would like to make sure you are fully aware of all of
            your data protection rights. Every user is entitled to the
            following: i. The right to access - You have the right to request
            ONMEE for copies of your personal data. We may charge you a small
            fee for this service. ii. The right to rectification - You have the
            right to request that ONMEE correct any information you believe is
            inaccurate. You also have the right to request ONMEE to complete
            information you believe is incomplete.
            <br />
            <br /> <b>ONMEE’S SECURE PAYMENT PROCESS</b>
            <br /> <br /> Your online transaction on ONMEE is secure with the
            highest levels of transaction security currently available on the
            Internet. ONMEE uses a secure encryption technology to protect your
            card information while securely transmitting it to the respective
            banks for payment processing.
            <br /> All credit card and debit card payments on ONMEE are
            processed through secure and trusted payment gateways managed by
            leading banks. Banks nowadays use the 3D Secure password service for
            online transactions, providing an additional layer of security
            through identity verification.
            <br />
            <br /> <b>CONTESTS AND PROMOTIONS</b>
            <br />
            <br />
            We may offer Promotions through the Services that may require
            registration. By participating in a Promotion, you are agreeing to
            official rules that govern that Promotion, which may contain
            specific requirements of you, including, allowing the sponsor of the
            Promotion to use your name, voice and/or likeness in advertising or
            marketing associated with the Promotion. If you choose to enter a
            Promotion, you agree that your Protected Information may be
            disclosed to third parties or the public in connection with the
            administration of such Promotion, including, in connection with
            winner selection, prize fulfillment, and as required by law or
            permitted by the Promotion’s official rules, such as on a winners
            list.
            <br />
            <br /> <b>ADMINISTRATIVE AND LEGAL REASONS</b>
            <br />
            <br />
            We cooperate with Government and law enforcement officials and
            private parties to enforce and comply with the law. Thus, we may
            access, use, preserve, transfer and disclose your information
            (including Protected Information, IP address, Device Information or
            geo-location data), to Government or law enforcement officials or
            private parties as we reasonably determine is necessary and
            appropriate: (i) to satisfy any applicable law, regulation,
            subpoenas, Governmental requests or legal process; (ii) to protect
            and/or defend the Terms and Conditions for Services or other
            policies applicable to any online and mobile Services, including
            investigation of potential violations thereof; (iii) to protect the
            safety, rights, property or security of the Company, our Services or
            any third party; (iv) to protect the safety of the public for any
            reason; (v) to detect, prevent or otherwise address fraud, security
            or technical issues; and /or (vi) to prevent or stop activity we may
            consider to be, or to pose a risk of being, an illegal, unethical,
            or legally actionable activity.
            <br />
            <br />
            <b> BUSINESS TRANSFER</b>
            <br />
            <br />
            We may share your information, including your Protected Information
            and Usage Information with our parent, subsidiaries and affiliates
            for internal reasons. We also reserve the right to disclose and
            transfer all such information: (i) to a subsequent owner, co-owner
            or operator of the Services or applicable database; or (ii) in
            connection with a corporate merger, consolidation, restructuring,
            the sale of substantially all of our membership interests and/or
            assets or other corporate change, including, during the course of
            any due diligence process.
            <br />
            <br /> <b>GRIEVANCE OFFICER</b>
            <br />
            <br /> ONMEE hereby appoints a grievance officer for the purposes of
            the rules drafted under the Information Technology Act, 2000, who
            may be contacted at _____________________. You may address any
            grievances you may have in respect of this privacy policy or usage
            of your Protected Information or other data to him.
          </p>
        </section>
      </div>
    </Layout>
  );
};

export default PrivacyPolicy;
