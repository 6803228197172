import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { updatePanel } from "../../slices/adminSlice";
import AdminMerchant from "./AdminMerchant";
import "./admin.scss";
import AdminBrands from "./AdminBrands";
import AdminAdsManagement from "./AdminAdsManagement";
import MerchantProduct from "../merchant/MerchantProduct";
import MerchantActivities from "./MerchantActivities";
import AdminCustomer from "./AdminCustomer";
import AdminOrder from "./AdminOrder";
import { Redirect } from "react-router";
import Layout from "../shop/common-layout";

// signout
import { getCartsCount, getWishCount, update } from "../../slices/headerSlice";
import { updateLoggedInUser } from "../../slices/categorySlice";
import { updateLoggedInUserHome } from "../../slices/homeSlice";
import { updateLoggedInUserOffer } from "../../slices/OfferSlice";
import { updateMerchant } from "../../slices/merchantSlice";
import { updateAdmin } from "../../slices/adminSlice";
import { logout as resetMerchantState } from "../../slices/merchantSlice";
import { logout as resetAdminState } from "../../slices/adminSlice";
import { logout as resetAuthState } from "../../slices/authSlice";
import { updateAuth } from "../../slices/authSlice";
import { updateLoggedInUserProduct } from "../../slices/productSlice";
import { getUniqueId } from "../../helpers/LocalCart";
import { generalToken } from "../../services/auth.service";

const AdminDash = (props) => {
  const adminState = useSelector((state) => state.admin);
  const dispatch = useDispatch();
  const [isSignOut, setIsSignOut] = useState(0);

  const panelClicked = (value) => {
    dispatch(updatePanel(value));
  };

  const signout = () => {
    localStorage.removeItem("user");
    // dispatch(logout());
    dispatch(resetAdminState());
    dispatch(resetMerchantState());
    dispatch(resetAuthState());
    dispatch(update());
    dispatch(updateLoggedInUser());
    dispatch(updateLoggedInUserHome());
    dispatch(updateLoggedInUserOffer());
    dispatch(updateMerchant());
    dispatch(updateAdmin());
    dispatch(updateAuth());
    dispatch(updateLoggedInUserProduct());
    dispatch(getCartsCount(getUniqueId()));
    dispatch(getWishCount(getUniqueId()));
    localStorage.removeItem("accessToken");

    setIsSignOut(1);

    // generate unanimouse accessToken
    let userId = getUniqueId();
    generalToken(userId).then((res) => {
      // console.log( res.data );
      let accessToken = res.data.accessToken;
      localStorage.setItem("accessToken", JSON.stringify(accessToken));
    });
  };

  useEffect(() => {
    setIsSignOut(0);
  }, [isSignOut]);

  if (isSignOut) {
    return <Redirect to="/" />;
  }

  if (
    !adminState.user ||
    (adminState.user && !adminState.user.roles.includes("ROLE_ADMIN"))
  ) {
    return <Redirect to="/admin" />;
  }

  return (
    <Layout title="Admin" parent="home">
      <div className="container mt-4 mb-4">
        <div className="row">
          <div className="col-md-3">
            <ul className="ONM_admin_left">
              <li
                className={
                  adminState.panel === "MERCHANTS" ? "admin__active" : null
                }
                onClick={() => panelClicked("MERCHANTS")}
              >
                Merchants
              </li>
              <li
                className={
                  adminState.panel === "ACTIVITIES" ? "admin__active" : null
                }
                onClick={() => panelClicked("ACTIVITIES")}
              >
                Merchant Activities
              </li>
              <li
                className={
                  adminState.panel === "CUSTOMERS" ? "admin__active" : null
                }
                onClick={() => panelClicked("CUSTOMERS")}
              >
                Customers
              </li>
              <li
                className={
                  adminState.panel === "PRODUCTS" ? "admin__active" : null
                }
                onClick={() => panelClicked("PRODUCTS")}
              >
                Products
              </li>
              <li
                className={
                  adminState.panel === "ADDBRANDS" ? "admin__active" : null
                }
                onClick={() => panelClicked("ADDBRANDS")}
              >
                Brands
              </li>
              <li
                className={
                  adminState.panel === "ADSMANAGEMENT" ? "admin__active" : null
                }
                onClick={() => panelClicked("ADSMANAGEMENT")}
              >
                Ads Management
              </li>
              <li
                className={
                  adminState.panel === "ORDER" ? "admin__active" : null
                }
                onClick={() => panelClicked("ORDER")}
              >
                Orders
              </li>
              <li className={null} onClick={() => signout()}>
                Sign Out
              </li>
            </ul>
          </div>
          <div className="col-md-9">
            {adminState.panel === "CUSTOMERS" ? (
              <AdminCustomer></AdminCustomer>
            ) : null}
            {adminState.panel === "MERCHANTS" ? (
              <AdminMerchant></AdminMerchant>
            ) : null}
            {adminState.panel === "ACTIVITIES" ? (
              <MerchantActivities></MerchantActivities>
            ) : null}
            {adminState.panel === "PRODUCTS" ? (
              <MerchantProduct></MerchantProduct>
            ) : null}
            {adminState.panel === "ADDBRANDS" ? (
              <AdminBrands></AdminBrands>
            ) : null}
            {adminState.panel === "ADSMANAGEMENT" ? (
              <AdminAdsManagement></AdminAdsManagement>
            ) : null}
            {adminState.panel === "ORDER" ? <AdminOrder></AdminOrder> : null}
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default AdminDash;
