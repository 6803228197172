import {
  FaHeart,
  FaPhone,
  FaShoppingCart,
  FaCartPlus,
  FaStar,
} from "react-icons/fa";
import { Link } from "react-router-dom";
import ModalMap from "../components/map/modalMap";
import { BASE_URL, BASE_API_URL } from "../services/constants";
import PhoneContainer from "./PhoneContainer";

const ProductCard = (props) => {
  let baseUri = BASE_URL;
  let product = props.item;

  const shortString = (name) => {
    var string = name;
    var count = 28;
    var result = string.slice(0, count) + (string.length > count ? "..." : "");
    return result;
  };

  const imgPath = (img) => {
    let path = img;

    if (path.indexOf("https://") > -1) {
      return path;
    } else if (path) {
      return BASE_API_URL + "/" + path;
    } else {
      return baseUri + "files/noimg.png";
    }
  };
  function checkIfImageExists(url) {
    const img = new Image();
    img.src = url;
    console.log(url, "image url");

    if (img.complete) {
      return true;
    } else {
      img.onload = () => {
        return true;
      };

      img.onerror = () => {
        return false;
      };
    }
  }

  const roundItOff = (num) => {
    return Math.round(num);
  };

  const calculatePrice = () => {
    let price = product.price - product.price * (product.offer / 100);
    return price.toFixed(2);
  };
  const productoffer = () => {
    let normalprice = calculatePrice();
    let normalregular_price = product.price;
    var offer_price = 0;
    if (normalprice != normalregular_price) {
      offer_price = normalprice;
    }

    if (offer_price)
      return (
        <>
          <div className="lable-block">
            <span className="lable3">{product.offer + "%"}</span>
          </div>
        </>
      );
  };
  const item_price = () => {
    let price = calculatePrice();
    let regular_price = product.price;
    var sale_price = 0;
    if (price != regular_price) {
      sale_price = price;
    }

    return (
      <>
        {sale_price ? (
          <>
            <span className="sale-price">Rs. {sale_price}</span>
            <span className="regular-price small-cap">Rs. {regular_price}</span>
            {/* <span className="discount">({product.offer + "%"})</span> */}
          </>
        ) : (
          <span className="regular-price">Rs. {price}</span>
        )}
      </>
    );
  };

  return (
    <>
      <div className="card product">
        <div className="product-act">
          {props.item &&
          props.item.stock &&
          props.item.stock != null &&
          props.item.stock > 0 ? (
            <FaHeart
              className="add-wishlist"
              onClick={() => props.addToWishlist(props.item)}
            ></FaHeart>
          ) : null}
        </div>
        <div className="ONM_img">
          <Link to={"/product/" + props.item._id}>
            {props.item && props.item.imageId ? (
              <img
                className="card-img-top"
                src={
                  imgPath(props.item.imageId[0].path)
                    ? imgPath(props.item.imageId[0].path)
                    : baseUri + "files/noimg.png"
                }
                alt="Card"
              />
            ) : null}
          </Link>
        </div>
        <div className=" ONM_review-star-product">
          <FaStar
            className={
              roundItOff(props.item.star || 0) >= 1
                ? "OMN-star-active mr-2"
                : "ONM_icon_color-empty mr-2"
            }
          ></FaStar>
          <FaStar
            className={
              roundItOff(props.item.star || 0) >= 2
                ? "OMN-star-active mr-2"
                : "ONM_icon_color-empty mr-2"
            }
          ></FaStar>
          <FaStar
            className={
              roundItOff(props.item.star || 0) >= 3
                ? "OMN-star-active mr-2"
                : "ONM_icon_color-empty mr-2"
            }
          ></FaStar>
          {/* <FaStar
            className={
              roundItOff(props.item.star || 0) === 4
                ? "OMN-star-active mr-2"
                : "ONM_icon_color-empty mr-2"
            }
          ></FaStar>{" "} */}
        </div>
        <div className="card-body row">
          <h3 className="card-title-product col-md-12 ">
            <Link to={"/product/" + props.item._id}>
              {shortString(props.item.name)}
            </Link>
          </h3>
          <span className="card-price">{item_price()}</span>
          {/* <p className="card-text col-md-12">
          {props.item.userId ? props.item.userId.address : ""}
        </p> */}
          {/* <div className="ONM_icon_color ONM_card-icons-container">
          <ModalMap size={14} item={props.item}></ModalMap>
          <PhoneContainer item={props.item}></PhoneContainer>
        </div> */}
          <div className="ONM_offer-container col-md-12">
            <div className="row">
              <div className="col-md-12 ">
                {/* <span className="ONM_offer-text">
                {props.item.offer ? props.item.offer + "% OFF" : "0"}
              </span> */}
              </div>
              {/* {props.userObj && props.userObj.roles.includes("ROLE_USER") ? ( */}
              {1 ? (
                <div className="col-md-12 mt-2">
                  {props.item.stock > 0 ? (
                    <button
                      className="btn add-to-cart"
                      onClick={() => props.addToCart(props.item)}
                    >
                      <FaCartPlus size={20}></FaCartPlus>
                      Add to Cart
                    </button>
                  ) : (
                    <span className="sold-out">Sold Out</span>
                  )}
                </div>
              ) : null}
            </div>
          </div>
        </div>
        {productoffer()}
      </div>
    </>
  );
};

export default ProductCard;
