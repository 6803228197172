import axios from "axios";
import { BASE_API_URL } from "./constants";

const API_URL = BASE_API_URL + "/api/auth/";

export const register = (firstName, lastName , email, phone, roles) => {
  return axios.post(API_URL + "signup", {
    firstName,
    lastName,
    email,
    phone,
    roles
  });
};

export const login = (email) => {
  return axios
    .post(API_URL + "signin", {
      email
    });
};

export const merchantLogin = (email) => {
  return axios
    .post(API_URL + "signin", {
      email,
      role: 'merchant',
    });
};

export const adminLogin = (email) => {
  return axios
    .post(API_URL + "signin", {
      email,
      role: 'admin',
    });
};

export const verify = (otp, id) => {
  return axios
    .post(API_URL + "verify", {
      otp,
      id,
    });
};

export const generalToken = (id) => {
  return axios
    .post(API_URL + "getGeneralToken", {
      id
    });
};