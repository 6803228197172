import { useEffect } from "react";
import "./cart.scss";
import { useDispatch, useSelector } from "react-redux";
import WishCartList from "../../helpers/WishCartList";
import {
  deleteWishlist,
  getCartsCount,
  getWishCount,
  getWishes,
  saveToCart,
  updateSavedToCart,
  updateWishItem,
} from "../../slices/headerSlice";
import { useAlert } from "react-alert";
import { localSaveToCart, getUniqueId } from "../../helpers/LocalCart";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { BASE_URL } from "../../services/constants";
import Layout from "../shop/common-layout";

const Wishlist = (props) => {
  const headerState = useSelector((state) => state.header);
  const dispatch = useDispatch();
  // const alert = useAlert();

  useEffect(() => {
    let userId =
      headerState.user != null ? headerState.user._id : getUniqueId();

    dispatch(getWishes(userId));
    dispatch(getWishCount(userId));
  }, [dispatch, headerState.user]);

  useEffect(() => {
    if (headerState.savedToCart) {
      let userId =
        headerState.user != null ? headerState.user._id : getUniqueId();

      // alert.show("Added to cart !!!");
      toast.success("Added to cart !!!");
      dispatch(getWishes(userId));
      dispatch(getWishCount(userId));
      dispatch(getCartsCount(userId));
      dispatch(updateSavedToCart());
    }
  }, [headerState.savedToCart]);

  useEffect(() => {
    if (headerState.wishItemRemoved) {
      let userId =
        headerState.user != null ? headerState.user._id : getUniqueId();

      // alert.show("Product removed from your wishlist !!!");
      toast.success("Product removed from your wishlist !!!");
      dispatch(updateWishItem());
      dispatch(getWishCount(userId));
      dispatch(getWishes(userId));
    }
  }, [headerState.wishItemRemoved]);

  const deleteWishFromList = (wish) => {
    dispatch(deleteWishlist(wish._id));
  };

  const addToCart = (wish) => {
    let userId = wish.userId != null ? wish.userId._id : getUniqueId();

    if (userId) {
      const cartObj = {
        productId: wish.productId._id,
        userId: userId,
        wishListId: wish._id,
      };
      // console.log(cartObj)
      dispatch(saveToCart(cartObj));
    }
  };

  return (
    <Layout title="Whislist" parent="Home">
      <div className="content-container container mt-5 mb-5">
        <div className="row">
          <div className="col-md-12 ">
            {headerState.wishlists && headerState.wishlists.length > 0 ? (
              <WishCartList
                items={headerState.wishlists}
                addToCart={addToCart}
                deleteWishFromList={deleteWishFromList}
              ></WishCartList>
            ) : (
              <div className="row">
                <div className="col-12 col-md-12 col-lg-8">
                  <div className="row">
                    <div className="col-4 col-md-4 col-lg-7 text-right">
                      <img
                        className=""
                        style={{ maxWidth: "80px" }}
                        src={BASE_URL + "files/wish-list.png"}
                      />
                    </div>
                    <div
                      style={{ display: "flex", alignItems: "center" }}
                      className="col-8 col-md-8 col-lg-5"
                    >
                      <span style={{ fontSize: "16px", fontWeight: "bold" }}>
                        Your Onmee Wishlist is empty
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Wishlist;
