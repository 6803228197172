import { useEffect, useState } from "react";

const ReviewOrder = (props) => {

  const [isStockEmpty, setStockEmpty] = useState(0);

  const totalPrice = () =>{
    let totalPrice = 0;
    props.items.forEach((cart) => {
      if( cart.productId != null ){
        totalPrice += cart.productId.stock > 0
          ? cart.stock * ( cart.productId.price - ( cart.productId.price * cart.productId.offer / 100 ) )
          : 0;   
      }
    });

    return totalPrice.toFixed(2);
  }

  const itemTotal = (item) => {
    let price =
        item.productId.price -
        item.productId.price * (item.productId.offer / 100);

    let qty = item.stock;
    let subtotal = ( price * qty );
  
    return subtotal.toFixed(2);
  }

  const markStockEmptyError = () => {
    setStockEmpty(1);
  }

  useEffect(() => {    
    if( props.items != null && props.items.length > 0 ){
      props.items.map((item) => {
        if( item.productId == null || item.productId.stock <= 0 || ! item.productId.hasOwnProperty('stock') ){
          markStockEmptyError();
        }
      })
    }
  }, [props.items])

  const cartItems =
    props.items != null && props.items.length > 0
      ? props.items.map((item) => {
          return (
            <tr key={item._id}>
                <td>{item.productId != null ? item.productId.name : <>product removed</>} <span className="qty">x {item.stock}</span></td>
                <td>{item.productId != null && item.productId.stock > 0 ? (
                  <>
                    Rs. {itemTotal(item)}
                  </>
                ): (
                  <>
                  {/* {markStockEmptyError()} */}
                  <span className="stock-empty text-danger font-weight-bold">No Stocks Available</span>
                  </>
                )}</td>
            </tr>
          );
        })
      : null;

  return (
    <>
      {/* {console.log(props.items.length)} */}
      {props.items != null && props.items.length ? (
        <>
            <div className="review-order-wrap">
                <div className="review-order">
                    <table className="review-order-table">
                    <thead>
                        <tr>
                        <th>Product</th>
                        <th>Subtotal</th>
                        </tr>
                    </thead>          
                    <tbody>
                        {cartItems}
                        <tr className="total-wrap">
                            <th>Total</th>
                            <td className="total-price">Rs. {totalPrice()}</td>
                        </tr>
                    </tbody>
                    </table>
                </div>
                {props.proceed && (
                <div className="review-order-action">
                    <button 
                        className="btn btn-primary btn-action mb-2 mt-3" 
                        onClick={() => props.proceed()}
                        disabled={props.isError || isStockEmpty}
                    >
                        {/* Proceed to Buy {" (" + props.items.length + " items)"} */}
                        Pay the Order
                    </button>
                    {isStockEmpty ? <p className="mt-2 alert alert-danger">Please remove empty stock items from cart before pay order</p> : null }
                </div>
                )}
            </div>
        </>
      ) : null }
    </>
  );
};

export default ReviewOrder;
