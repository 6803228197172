import axios from "axios";
import authHeader from "./auth-header";
import { BASE_API_URL } from "./constants";

const API_URL = BASE_API_URL + "/api/wishlist/";

export const save = (obj) => {
  return axios.post(API_URL + "save", obj, { headers: authHeader() });
};

export const getList = (userId, features) => {
  let url = API_URL + "list?userId=" + userId;
  // url = url + "&ps="+ features.ps + "&sidx=" + features.sidx;
  return axios.get(url, { headers: authHeader() });
};

export const getCount = (userId) => {
  let url = API_URL + "count?userId=" + userId;
  return axios.get(url, { headers: authHeader() });
};

export const deleteWishlistObj = (obj) => {
  return axios.post(API_URL + "delete", obj, { headers: authHeader() });
};

export const moveWishObj = (obj) => {

  // let obj = {
  //   fromUser,
  //   toUser
  // };
  return axios.post(API_URL + "moveWish", obj, { headers: authHeader() });
};
