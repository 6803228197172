import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getBanners, getSliders } from "../services/home.service";
import { getBestOffersByMainCatFashion, getBestOffersByMainCatGen } from "../services/category.service";

const user = JSON.parse(localStorage.getItem("user"));
const latitude = JSON.parse(localStorage.getItem("latitude"));
const longitude = JSON.parse(localStorage.getItem("longitude"));

export const bannerList = createAsyncThunk(
  "home/bannerList",
  async (page, { getState, rejectWithValue }) => {
    try {
      const response = await getBanners(page);
      return response.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const sliderList = createAsyncThunk(
  "home/sliderList",
  async (page, { getState, rejectWithValue }) => {
    try {
      const response = await getSliders();
      return response.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const bestOfferPercategory = createAsyncThunk(
  "home/bestOfferPercategory",
  async (name, { getState, rejectWithValue }) => {
    try {
      const response = await getBestOffersByMainCatGen(name);
      return response.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const bestOfferPercategoryAppliances = createAsyncThunk(
  "home/bestOfferPercategoryAppliances",
  async (name, { getState, rejectWithValue }) => {
    try {
      const response = await getBestOffersByMainCatGen(name);
      return response.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const bestOfferPercategoryGrocery = createAsyncThunk(
  "home/bestOfferPercategoryGrocery",
  async (name, { getState, rejectWithValue }) => {
    try {
      const response = await getBestOffersByMainCatGen(name);
      return response.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const bestOfferPercategoryKids = createAsyncThunk(
  "home/bestOfferPercategoryKids",
  async (name, { getState, rejectWithValue }) => {
    try {
      const response = await getBestOffersByMainCatGen(name);
      return response.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const bestOfferPercategorySwadeshi = createAsyncThunk(
  "home/bestOfferPercategorySwadeshi",
  async (name, { getState, rejectWithValue }) => {
    try {
      const response = await getBestOffersByMainCatGen(name);
      return response.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const bestOfferPercategoryFashion = createAsyncThunk(
  "home/bestOfferPercategoryFashion",
  async (name, { getState, rejectWithValue }) => {
    try {
      const response = await getBestOffersByMainCatFashion(name);
      return response.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

const initialState = {
    sliders: [],
    banners: [],
    bannersRow1: [],
    bannersRow3: [],
    bannersRow4: [],
    bannersRow5: [],
    bannersRow6: [],
    electronicList: [],
    fashionList: [],
    groceryList: [],
    appliancesList: [],
    kidsList: [],
    swadeshiList: [],
    selected: null,
    user: user,
    loading: false,
    latitude: latitude,
    longitude: longitude,
    userLocNotFound: 0,
};

const homeSlice = createSlice({
  name: "Home",
  initialState: initialState,
  reducers: {
    updateLoggedInUserHome: (state, action) => {
      state.city = localStorage.getItem("city");
      const user = localStorage.getItem("user");
      if(user != null)
      {
        state.user = JSON.parse(user);
      }
      else
      {
        state.user = null;       
      }
    },
    resetUserLocNotFound: (state, action) => {
      state.userLocNotFound = 0;
    },
  },
  extraReducers: (builder) =>
    builder
    .addCase(bestOfferPercategory.fulfilled, (state, action) => {
      if (action.payload) 
      {
        state.electronicList = action.payload.result;
        state.userLocNotFound = action.payload.userNotFound;
      }
    })
    .addCase(bestOfferPercategoryAppliances.fulfilled, (state, action) => {
      if (action.payload) 
      {
        state.appliancesList = action.payload.result;
        state.userLocNotFound = action.payload.userNotFound;
      }
    })
    .addCase(bestOfferPercategoryGrocery.fulfilled, (state, action) => {
      if (action.payload) 
      {
        state.groceryList = action.payload.result;
        state.userLocNotFound = action.payload.userNotFound;
      }
    })
    .addCase(bestOfferPercategoryKids.fulfilled, (state, action) => {
      if (action.payload) 
      {
        state.kidsList = action.payload.result;
        state.userLocNotFound = action.payload.userNotFound;
      }
    })
    .addCase(bestOfferPercategorySwadeshi.fulfilled, (state, action) => {
      if (action.payload) 
      {
        state.swadeshiList = action.payload.result;
        state.userLocNotFound = action.payload.userNotFound;
      }
    })
    .addCase(bestOfferPercategoryFashion.fulfilled, (state, action) => {
      if (action.payload) 
      {
        state.fashionList = action.payload.result;
        state.userLocNotFound = action.payload.userNotFound;
      }
    })
    .addCase(bannerList.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(bannerList.fulfilled, (state, action) => {
        state.loading = false;
        if (action.payload) 
        {
          state.banners = action.payload.result;          

          if(state.banners.length > 0)
          {
            state.fashionBanner = [];
            state.bannersRow1 = [];
            
            state.banners.forEach((banner) => {
              if(banner.row === 1)
              {                  
                state.bannersRow1.push(banner);
              }
              
              if(banner.row === 2)
              {                  
                state.fashionBanner.push(banner);
              }

              if(banner.row === 3)
              {                  
                state.bannersRow3 = banner;
              }

              if(banner.row === 4)
              {                  
                state.bannersRow4 = banner;
              }

              if(banner.row === 5)
              {                  
                state.bannersRow5 = banner;
              }

              if(banner.row === 6)
              {                  
                state.bannersRow6 = banner;
              }

            });
          }          
        }
      })
      .addCase(sliderList.pending, (state, action) => {
          state.loading = true;
        })
        .addCase(sliderList.fulfilled, (state, action) => {
          state.loading = false;
          if (action.payload) 
          {
            state.sliders = action.payload.result;     
          }
      })
});

export default homeSlice.reducer;
export const {updateSelected, updateLoggedInUserHome, resetUserLocNotFound} = homeSlice.actions;