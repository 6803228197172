import React, { useState } from "react";
import ColourItems from "../filters/Colors";
import Checkbox from "rc-checkbox";
import { Link } from "react-router-dom";
import Category from "../shop/common/category";
import { Collapse, Input } from "reactstrap";
import classes from "./Fillter.module.css";

const FilterList = (props) => {
  console.log("allcats in fillter -->", props.allCats);
  const [isOpen, setIsOpen] = useState(true);
  const toggleDiscount = () => setIsOpen(!isOpen);
  const [isOpenprice, setIsOpenprice] = useState(true);
  const togglePrice = () => setIsOpenprice(!isOpenprice);
  const [isOpenbrand, setIsOpenbrand] = useState(true);
  const toggleBrand = () => setIsOpenbrand(!isOpenbrand);

  // let items;
  // if (props.banner) {
  //   items = props.allCats
  //     .filter((item) => item._id === props.mainCatId)
  //     .map((cat) => cat.categories);
  // } else {
  //   items = props.childCats;
  // }
  // console.log("itemss------>", items);
  return (
    <div className="">
      <div className="product-filter" style={{ marginTop: "20px" }}>
        {!props.banner &&
        props.childCats != null &&
        props.childCats.length > 0 ? (
          <Category
            items={props.childCats}
            acher={props.childFilter}
            maincatid={props.mainCatId}
            subObj={props.subObj._id}
            banner={props.banner}
          />
        ) : null}

        <div className="collection-collapse-block open">
          <h3 className="collapse-block-title" onClick={toggleDiscount}>
            Discount
          </h3>
          <Collapse isOpen={isOpen}>
            <div className="row" style={{ marginTop: "20px" }}>
              <div
                className="col-md-6 mb-3"
                onClick={() => props.onOfferUpdate({ omax: 80, omin: 60 })}
              >
                <div
                  className={
                    props.offerFilter === 80
                      ? "ONM_offer-filter fillteractive"
                      : "ONM_offer-filter"
                  }
                >
                  60-80%
                </div>
              </div>
              <div
                className="col-md-6 mb-3"
                onClick={() => props.onOfferUpdate({ omax: 60, omin: 40 })}
              >
                <div
                  className={
                    props.offerFilter === 60
                      ? "ONM_offer-filter fillteractive"
                      : "ONM_offer-filter"
                  }
                >
                  40-60%
                </div>
              </div>
              <div
                className="col-md-6 mb-3"
                onClick={() => props.onOfferUpdate({ omax: 40, omin: 20 })}
              >
                <div
                  className={
                    props.offerFilter === 40
                      ? "ONM_offer-filter fillteractive"
                      : "ONM_offer-filter"
                  }
                >
                  20-40%
                </div>
              </div>
              <div
                className="col-md-6 mb-3"
                onClick={() => props.onOfferUpdate({ omax: 20, omin: 0 })}
              >
                <div
                  className={
                    props.offerFilter === 20
                      ? "ONM_offer-filter fillteractive"
                      : "ONM_offer-filter"
                  }
                >
                  0-20%
                </div>
              </div>
            </div>
          </Collapse>
        </div>
        {props.userFilter ? (
          <>
            <div className="collection-collapse-block open">
              <h3 className="collapse-block-title" onClick={togglePrice}>
                Price
              </h3>
              <Collapse isOpen={isOpenprice}>
                <ul
                  className={`ONM_left-filters  ${classes.fillter__page}`}
                  style={{ marginTop: "20px" }}
                >
                  <li>
                    <a
                      onClick={() =>
                        props.onPriceUpdate({ pmin: 0, pmax: 500 })
                      }
                      className={
                        props.priceFilter === 0 ? "active_anchor" : null
                      }
                    >
                      Under 500
                    </a>
                  </li>
                  <li>
                    <a
                      onClick={() =>
                        props.onPriceUpdate({ pmin: 500, pmax: 3000 })
                      }
                      className={
                        props.priceFilter === 500 ? "active_anchor" : null
                      }
                    >
                      500 - 3000
                    </a>
                  </li>
                  <li>
                    <a
                      onClick={() =>
                        props.onPriceUpdate({ pmin: 3000, pmax: 8000 })
                      }
                      className={
                        props.priceFilter === 3000 ? "active_anchor" : null
                      }
                    >
                      3000 - 8000
                    </a>
                  </li>
                  <li>
                    <a
                      onClick={() => props.onPriceUpdate({ pmin: 12000 })}
                      className={
                        props.priceFilter === 12000 ? "active_anchor" : null
                      }
                    >
                      over 12000
                    </a>
                  </li>
                </ul>
              </Collapse>
            </div>
          </>
        ) : null}

        {props.brandsList && props.brandsList.length > 0 ? (
          <div className="collection-collapse-block open">
            <h3 className="collapse-block-title" onClick={toggleBrand}>
              Brand
            </h3>
            <Collapse isOpen={isOpenbrand} style={{ marginTop: "20px" }}>
              <ul className={`ONM_left-filters  ${classes.fillter__page}`}>
                {props.brandsList != null && props.brandsList.length > 0
                  ? props.brandsList.map((brand) => {
                      return (
                        <li key={brand._id} className="">
                          <Checkbox
                            checked={
                              props.brandCheckbox != null &&
                              props.brandCheckbox.find((ch) => {
                                return ch.name === brand.name;
                              })
                            }
                            onChange={(e) => {
                              props.handleBrandCheckbox(e, brand);
                            }}
                          ></Checkbox>
                          <span className="ml-2">{brand.name}</span>
                        </li>
                      );
                    })
                  : null}
              </ul>
            </Collapse>
          </div>
        ) : null}
        {props.userFilter &&
        props.subObj != null &&
        (props.subObj.name === "Mobiles" ||
          props.subObj.name === "Clothing" ||
          props.subObj.name === "Tablets" ||
          props.subObj.name === "Wearable Devices" ||
          props.subObj.name === "Smart TV" ||
          props.subObj.name === "32 inches TV's" ||
          props.subObj.name === "Full HD TV's" ||
          props.subObj.name === "Android Based TV's" ||
          props.subObj.name === "Large Screen TV's" ||
          props.subObj.name === "Desktops" ||
          props.subObj.name === "Monitors" ||
          props.subObj.name === "Mouses" ||
          props.subObj.name === "Keyboards" ||
          props.subObj.name === "Laptop Bags" ||
          props.subObj.name === "Monitors" ||
          props.subObj.name === "Mixers & Grinders" ||
          props.subObj.name === "Juicers" ||
          props.subObj.name === "Rice & Pasta Cookers" ||
          props.subObj.name === "Induction Cooktops" ||
          props.subObj.name === "Expresso Machine" ||
          props.subObj.name === "Oven Toaster Grills" ||
          props.subObj.name === "Kettles" ||
          props.subObj.name === "Toasters" ||
          props.subObj.name === "Irons" ||
          props.subObj.name === "Water Purifiers" ||
          props.subObj.name === "Air Purifiers" ||
          props.subObj.name === "Vacuum Cleaners" ||
          props.subObj.name === "Air Cooler" ||
          props.subObj.name === "Water Heaters" ||
          props.subObj.name === "Humidifiers") ? (
          <ColourItems
            handleColorCheckbox={props.handleCheckbox}
            colorCheckboxes={props.checkboxes}
            filters={props.filters}
            path="COLORS"
            headerName="Colours"
          ></ColourItems>
        ) : null}

        {props.userFilter &&
        props.subObj != null &&
        props.subObj.name === "Cases & Covers" ? (
          <ColourItems
            handleColorCheckbox={props.handleCheckbox}
            colorCheckboxes={props.checkboxes}
            filters={props.filters}
            path="CASECOVERS"
            headerName="Cases & Covers"
          ></ColourItems>
        ) : null}

        {props.userFilter &&
        props.subObj != null &&
        props.subObj.name === "Cases & Covers" ? (
          <ColourItems
            handleColorCheckbox={props.handleCheckbox}
            colorCheckboxes={props.checkboxes}
            filters={props.filters}
            path="CASECOVERMATERIALTYPES"
            headerName="Material Types"
          ></ColourItems>
        ) : null}

        {props.userFilter &&
        props.subObj != null &&
        props.subObj.name === "Cases & Covers" ? (
          <ColourItems
            handleColorCheckbox={props.handleCheckbox}
            colorCheckboxes={props.checkboxes}
            filters={props.filters}
            path="CASEFEATURES"
            headerName="Case Features"
          ></ColourItems>
        ) : null}

        {props.userFilter &&
        props.subObj != null &&
        props.subObj.name === "Screen Protectors" ? (
          <ColourItems
            handleColorCheckbox={props.handleCheckbox}
            colorCheckboxes={props.checkboxes}
            filters={props.filters}
            path="SCREENPROTECTORMATERIALTYPE"
            headerName="Material Types"
          ></ColourItems>
        ) : null}

        {props.userFilter &&
        props.subObj != null &&
        props.subObj.name === "Power Banks" ? (
          <ColourItems
            handleColorCheckbox={props.handleCheckbox}
            colorCheckboxes={props.checkboxes}
            filters={props.filters}
            path="BATTERYTYPE"
            headerName="Battery Type"
          ></ColourItems>
        ) : null}

        {props.userFilter &&
        props.subObj != null &&
        (props.subObj.name === "Smart TV" ||
          props.subObj.name === "32 inches TV's" ||
          props.subObj.name === "Full HD TV's" ||
          props.subObj.name === "Android Based TV's" ||
          props.subObj.name === "Large Screen TV's") ? (
          <ColourItems
            handleColorCheckbox={props.handleCheckbox}
            colorCheckboxes={props.checkboxes}
            filters={props.filters}
            path="TVPLATFORMS"
            headerName="TV Platform"
          ></ColourItems>
        ) : null}

        {props.userFilter &&
        props.subObj != null &&
        (props.subObj.name === "Smart TV" ||
          props.subObj.name === "32 inches TV's" ||
          props.subObj.name === "Full HD TV's" ||
          props.subObj.name === "Android Based TV's" ||
          props.subObj.name === "Large Screen TV's") ? (
          <ColourItems
            handleColorCheckbox={props.handleCheckbox}
            colorCheckboxes={props.checkboxes}
            filters={props.filters}
            path="TVDISPLAYTECH"
            headerName="Display Technology"
          ></ColourItems>
        ) : null}

        {props.userFilter &&
        props.subObj != null &&
        (props.subObj.name === "Smart TV" ||
          props.subObj.name === "32 inches TV's" ||
          props.subObj.name === "Full HD TV's" ||
          props.subObj.name === "Android Based TV's" ||
          props.subObj.name === "Large Screen TV's") ? (
          <ColourItems
            handleColorCheckbox={props.handleCheckbox}
            colorCheckboxes={props.checkboxes}
            filters={props.filters}
            path="TVHDFORMATS"
            headerName="HD Formats"
          ></ColourItems>
        ) : null}

        {props.userFilter &&
        props.subObj != null &&
        (props.subObj.name === "Smart TV" ||
          props.subObj.name === "32 inches TV's" ||
          props.subObj.name === "Full HD TV's" ||
          props.subObj.name === "Android Based TV's" ||
          props.subObj.name === "Large Screen TV's") ? (
          <ColourItems
            handleColorCheckbox={props.handleCheckbox}
            colorCheckboxes={props.checkboxes}
            filters={props.filters}
            path="TVMODELYEARS"
            headerName="TV Model Years"
          ></ColourItems>
        ) : null}

        {props.userFilter &&
        props.subObj != null &&
        (props.subObj.name === "Smart TV" ||
          props.subObj.name === "32 inches TV's" ||
          props.subObj.name === "Full HD TV's" ||
          props.subObj.name === "Android Based TV's" ||
          props.subObj.name === "Large Screen TV's") ? (
          <ColourItems
            handleColorCheckbox={props.handleCheckbox}
            colorCheckboxes={props.checkboxes}
            filters={props.filters}
            path="TVCONNECTIVITY"
            headerName="Connectivity"
          ></ColourItems>
        ) : null}

        {props.userFilter &&
        props.subObj != null &&
        props.subObj.name === "Laptops" ? (
          <ColourItems
            handleColorCheckbox={props.handleCheckbox}
            colorCheckboxes={props.checkboxes}
            filters={props.filters}
            path="CPUTYPE"
            headerName="CPU type"
          ></ColourItems>
        ) : null}

        {props.userFilter &&
        props.subObj != null &&
        props.subObj.name === "Laptops" ? (
          <ColourItems
            handleColorCheckbox={props.handleCheckbox}
            colorCheckboxes={props.checkboxes}
            filters={props.filters}
            path="USAGE"
            headerName="Usage"
          ></ColourItems>
        ) : null}

        {props.userFilter &&
        props.subObj != null &&
        props.subObj.name === "Laptops" ? (
          <ColourItems
            handleColorCheckbox={props.handleCheckbox}
            colorCheckboxes={props.checkboxes}
            filters={props.filters}
            path="OPERATINGSYSTEM"
            headerName="Operating System"
          ></ColourItems>
        ) : null}

        {props.userFilter &&
        props.subObj != null &&
        props.subObj.name === "Laptops" ? (
          <ColourItems
            handleColorCheckbox={props.handleCheckbox}
            colorCheckboxes={props.checkboxes}
            filters={props.filters}
            path="MEMORYSIZE"
            headerName="Memory Size"
          ></ColourItems>
        ) : null}

        <div className={`row ${classes.clear__fillters}`}>
          <div className="col-md-12">
            <div
              className={classes.clear_button}
              onClick={() => props.onCategoryClear()}
            >
              Clear Filters
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FilterList;
