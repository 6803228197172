import React, { useState } from "react";
import CommonLayout from "./common-layout";

import { Container, Row } from "reactstrap";

const LeftSidebar = (props) => {
  const [sidebarView, setSidebarView] = useState(false);

  const openCloseSidebar = () => {
    if (sidebarView) {
      setSidebarView(!sidebarView);
    } else {
      setSidebarView(!sidebarView);
    }
  };

  const heading = props.storeName ? props.storeName : "Stores";
  return (
    <CommonLayout title={heading} parent="home">
      <section className="section-b-space ratio_asos">
        <div className="collection-wrapper">
          <Container>
            <Row>{props.children}</Row>
          </Container>
        </div>
      </section>
    </CommonLayout>
  );
};

export default LeftSidebar;
