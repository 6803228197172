import React, { useState, useEffect } from "react";
import { Link, Redirect } from "react-router-dom";
import {
  Button,
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  Row,
} from "reactstrap";
import Autocomplete from "react-autocomplete";
import { useDispatch, useSelector } from "react-redux";
import { searchList, clearSearchSelectors } from "../../../slices/headerSlice";
import { updateSearchKeyword } from "../../../slices/searchSlice";
import "./overlay.css";
const closeSearch = () => {
  document.getElementById("search-overlay").style.display = "none";
};

const SearchOverlay = () => {
  const dispatch = useDispatch();
  const headerState = useSelector((state) => state.header);
  const [searchVal, setSearchVal] = useState("");
  const [doSearch, setDoSearch] = useState(0);
  const [redirectTo, setRedirectTo] = useState("");
  const autoUrl = (item) => {
    let urlToClick = "/product/" + item._id;

    return urlToClick;
  };
  const onChangeSearch = (e) => {
    setSearchVal(e.target.value);
    if (e.target.value != null && e.target.value.length > 2) {
      dispatch(searchList(e.target.value));
    }
  };

  const onSelect = (val, item) => {
    if (item) {
      setSearchVal("");
      dispatch(clearSearchSelectors());
    }
  };
  const menuStyle = {
    background: "rgba(255, 255, 255, 0.9)",
    padding: "2px 0",
    fontSize: "90%",
    position: "fixed",
    overflow: "auto",
    zIndex: 100,
    maxHeight: "50%", // TODO: don't cheat, let it flow to the bottom
    width: "90%",
    maxWidth: "350px",
    top: "55%",
    bottom: "0",
  };
  const renderInput = (props) => {
    return (
      <form onSubmit={searchSubmitHandler}>
        <input
          className="form-control ONM_search"
          {...props}
          placeholder="Search product..."
          value={searchVal}
        />
      </form>
    );
  };
  const searchSubmitHandler = (e) => {
    e.preventDefault();
    if (searchVal) {
      setDoSearch(1);
      dispatch(updateSearchKeyword(searchVal));
    }
  };
  const sanitizeUrl = (url) => {
    // return url.replace(/[^-A-Za-z0-9+&@#/%?=~_|!:,.;\(\)]/g, "");
    return encodeURIComponent(url);
  };
  useEffect(() => {
    if (doSearch) {
      setDoSearch(0);
    }
  }, [doSearch]);

  useEffect(() => {
    if (redirectTo) {
      setRedirectTo("");
    }
  }, [redirectTo]);

  if (doSearch) {
    var url = "/search/" + sanitizeUrl(searchVal);

    // setSearchVal('');
    // console.log(url);
    return <Redirect to={url} />;
  }

  const redirect = (url) => {
    setRedirectTo(url);
  };

  if (redirectTo) {
    return <Redirect to={redirectTo} />;
  }

  return (
    <div id="search-overlay" className="search-overlay">
      <div>
        <span className="closebtn" onClick={closeSearch} title="Close Overlay">
          ×
        </span>
        <div className="overlay-content">
          <Container>
            <Row>
              <Col xl="12">
                <Form>
                  <FormGroup>
                    <Autocomplete
                      getItemValue={(item) => item.name}
                      items={headerState.searchList}
                      renderItem={(item, isHighlighted) => (
                        <div
                          style={{
                            background: isHighlighted ? "#f9f9f9" : "white",
                            padding: 5,
                            width: "100%",
                          }}
                        >
                          <Link
                            to={autoUrl(item)}
                            style={{ color: "#000" }}
                            onClick={closeSearch}
                          >
                            {item.name}
                          </Link>
                        </div>
                      )}
                      value={searchVal}
                      onChange={(e) => onChangeSearch(e)}
                      onSelect={(val, item) => onSelect(val, item)}
                      menuStyle={menuStyle}
                      className="form-control"
                      renderInput={renderInput}
                    />
                  </FormGroup>
                  <Button
                    type="button"
                    className="btn btn-primary"
                    onClick={searchSubmitHandler}
                  >
                    <i className="fa fa-search"></i>
                  </Button>
                </Form>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    </div>
  );
};

export default SearchOverlay;
