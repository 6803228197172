import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import "../category/category.scss";

import {
  updatePagination,
  getProducts,
  getProductsCount,
  saveWishlist,
  saveToCart,
  updateWishlistAdded,
  updateCartAdded,
  updateSearchKeyword,
} from "../../slices/searchSlice";
import { localSaveToCart, getUniqueId } from "../../helpers/LocalCart";
import SearchProduct from "./SearchProduct";

import { useAlert } from "react-alert";
import { getCartsCount, getWishCount } from "../../slices/headerSlice";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Layout from "../shop/common-layout";
const Search = (props) => {
  const dispatch = useDispatch();
  const searchState = useSelector((state) => state.search);
  const [isLoaded, setLoaded] = useState(0);
  // const alert = useAlert();

  useEffect(() => {
    if (!isLoaded) {
      let keyword = decodeURI(props.match.params.key);

      dispatch(getProducts(keyword));
      dispatch(getProductsCount(keyword));
      dispatch(updateSearchKeyword(""));
      setLoaded(1);
    } else if (searchState.searchKeyword) {
      let keyword = searchState.searchKeyword;

      dispatch(getProducts(keyword));
      dispatch(getProductsCount(keyword));
      dispatch(updateSearchKeyword(""));
    }
    // console.log(searchState)
  }, [dispatch, searchState.searchKeyword]);

  useEffect(() => {
    if (searchState.wishAdded === true) {
      let userId =
        searchState.user != null ? searchState.user._id : getUniqueId();

      // alert.show("Product added to wishlist !!!");
      toast.success("Product added to wishlist !!!");
      dispatch(getWishCount(userId));
      dispatch(updateWishlistAdded());
    }
  }, [searchState.wishAdded]);

  const handlePageChange = (pageNumber) => {
    let keyword = decodeURI(props.match.params.key);
    dispatch(updatePagination(pageNumber));
    dispatch(getProducts(keyword));
  };

  const addToWishlist = (prod) => {
    let userId =
      searchState.user != null ? searchState.user._id : getUniqueId();

    const wish = {
      productId: prod._id,
      userId: userId,
    };

    dispatch(saveWishlist(wish));
  };

  useEffect(() => {
    if (searchState.savedToCart) {
      let userId =
        searchState.user != null ? searchState.user._id : getUniqueId();
      // alert.show("Added to cart !!!");
      toast.success("Added to cart !!!");
      dispatch(getCartsCount(userId));
      dispatch(updateCartAdded());
    }
  }, [searchState.savedToCart, dispatch]);

  const addToCart = (prod) => {
    let userId =
      searchState.user != null ? searchState.user._id : getUniqueId();

    if (userId) {
      const cartObj = {
        productId: prod._id,
        userId: userId,
        stock: 1,
      };

      dispatch(saveToCart(cartObj));
    }
  };

  return (
    <Layout title={"Search : " + props.match.params.key} parent="Home">
      <div className="content-container container">
        <div className="row">
          <SearchProduct
            keyWord={props.match.params.key}
            page={searchState.features.page}
            ps={searchState.features.ps}
            count={searchState.count}
            handlePageChange={handlePageChange}
            items={searchState.prodList}
            addToWishlist={addToWishlist}
            addToCart={addToCart}
            userObj={searchState.user}
            loading={searchState.loading}
          ></SearchProduct>
        </div>
      </div>
    </Layout>
  );
};

export default Search;
