import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import "./store.scss";
import {
  bannerList,
  getCatList,
  getProducts,
  getProductsCount,
  updatePagination,
  updateCatId,
  updateOfferFilter,
  resetFeatures,
  updatePriceFilter,
  updateColorFilter
} from "../../slices/storeSlice";
import StoreCatList from "./storeCatList";
// import ProductList from "./ProductList";
import StoreList from "../../helpers/StoreList";
import Pagination from "react-js-pagination";
import StoreBanner from "./StoreBanner";

const Store = (props) => {
  const storeState = useSelector((state) => state.store);
  const dispatch = useDispatch();
  const [userId, setUserId] = useState(props.match.params.id);

  useEffect(() => {
    setUserId(props.match.params.id);
    dispatch(getProducts({ userId: props.match.params.id }));
    dispatch(getProductsCount({ userId: props.match.params.id }));
    dispatch(getCatList(props.match.params.id));
    dispatch(bannerList(props.match.params.id));
  }, [dispatch, props.match.params.id]);

  const handlePageChange = (pageNumber) => {
    dispatch(updatePagination(pageNumber));
    dispatch(getProducts({ userId: userId }));
  };

  const dispatchUpdateSelected = (id) => {
    dispatch(updateCatId(id));
    dispatch(getProducts({ userId: userId }));
    dispatch(getProductsCount({ userId: userId }));
  };

  const onOfferUpdate = (item) => {
    dispatch(updateOfferFilter(item));
    dispatch(getProducts({ userId: userId }));
    dispatch(getProductsCount({ userId: userId }));
  };

  const clearSelectedFilter = () => {
    dispatch(resetFeatures());
    dispatch(getProducts({ userId: userId }));
    dispatch(getProductsCount({ userId: userId }));
  };

  const onPriceUpdate = (item) => {
    dispatch(updatePriceFilter(item));
    dispatch(getProducts({ userId: userId }));
    dispatch(getProductsCount({ userId: userId }));
  };

  const onColorUpdate = (color) => {
    dispatch(updateColorFilter(color));
    dispatch(getProducts({ userId: userId }));
    dispatch(getProductsCount({ userId: userId }));
  };

  return (
    <div className="col-md-12">
      <div className="row">
        <div className="col-md-3 mt-3 ONM_left-filter">
          {storeState.catList ? (
            <StoreCatList
              userId={userId}
              onCategoryUpdate={dispatchUpdateSelected}
              items={storeState.catList}
              onCategoryClear={clearSelectedFilter}
              onOfferUpdate={onOfferUpdate}
              isCatSelected={storeState.features.catId != null ? true : false}
              selectedId={storeState.features.catId}
              offerFilter={storeState.features.omax}
              priceFilter={storeState.features.pmin}
              onPriceUpdate={onPriceUpdate}
              onColorUpdate={onColorUpdate}
              colorFilter={storeState.features.color}
            ></StoreCatList>
          ) : null}
        </div>
        <div className="col-md-9">
          <div className="row">
              {storeState.selectedBanner ? (
                <StoreBanner
                  items={storeState.selectedBanner}
                ></StoreBanner>
              ) : null}
            <div className="col-md-6">
              <h1>Products</h1>
            </div>
            <div className="col-md-6">
              <Pagination
                activePage={storeState.features.page}
                itemsCountPerPage={storeState.features.ps}
                totalItemsCount={storeState.count}
                pageRangeDisplayed={5}
                onChange={handlePageChange.bind(this)}
                itemClass="page-item"
                linkClass="page-link"
                innerClass="pagination ml-auto mr-auto"
                prevPageText="Prev"
                nextPageText="Next"
                hideFirstLastPages={true}
              />
            </div>
            {storeState.list ? (
              <StoreList items={storeState.list}></StoreList>
            ) : (
              "No products found"
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Store;
