import { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getAllBrands,
  getAllCategories,
  getBrandsCount,
  updateBrandsPagination,
  saveBrandsObj,
  toBeDeleted,
  deleteBrandObj,
} from "../../slices/adminSlice";

import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import Select from "react-validation/build/select";

import CheckButton from "react-validation/build/button";
import { FaTimes, FaEdit, FaTrash } from "react-icons/fa";
import Pagination from "react-js-pagination";

import Modal from "react-modal";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const required = (value) => {
  if (!value) {
    return (
      <div className="alert alert-danger" role="alert">
        This field is required!
      </div>
    );
  }
};

const AdminBrands = (props) => {
  const adminState = useSelector((state) => state.admin);
  const dispatch = useDispatch();

  const [addBrand, setAddBrand] = useState(false);
  const form = useRef();
  const checkBtn = useRef();
  const [catList, setCatList] = useState(null);
  const [subCatList, setSubCatList] = useState(null);
  const [childCatList, setChildCatList] = useState(null);
  const [name, setName] = useState(null);
  const [mainCategoryId, setMainCategoryId] = useState(null);
  const [categoryId, setCategoryId] = useState(null);
  const [subCategoryId, setSubCategoryId] = useState(null);
  const [childCategoryId, setChildCategoryId] = useState(null);
  const [id, setId] = useState(null);

  const [deleteBrand, setDeleteBrand] = useState(null);

  useEffect(() => {
    dispatch(getAllCategories());
    dispatch(getAllBrands());
    dispatch(getBrandsCount());
  }, [dispatch]);

  const openAddBrand = () => {
    setName(null);
    setMainCategoryId(null);
    setCategoryId(null);
    setSubCategoryId(null);
    setChildCategoryId(null);
    setCatList(null);
    setSubCatList(null);
    setChildCatList(null);
    setAddBrand(true);
  };

  const backToList = () => {
    setAddBrand(false);
  };

  const onChangeName = (e) => {
    setName(e.target.value);
  };

  const onChangeMainCatId = (e) => {
    setMainCategoryId(e.target.value);
    setCategoryId(null);
    setSubCategoryId(null);
    setChildCategoryId(null);
    setSubCatList(null);
    setChildCatList(null);
    if (adminState.categories != null && adminState.categories.length > 0) {
      let found = false;
      for (let i = 0; i < adminState.categories.length && !found; i++) {
        let item = adminState.categories[i];

        if (item._id === e.target.value) {
          found = true;
          setCatList(item.categories);
        }
      }
    }
  };

  const onChangeCatId = (e) => {
    setCategoryId(e.target.value);
    if (catList != null && catList.length > 0) {
      let found = false;
      for (let i = 0; i < catList.length && !found; i++) {
        let a = catList[i];

        if (a._id === e.target.value) {
          found = true;
          setSubCatList(a.subCategories);
        }
      }
    }
  };

  const onChangeSubCatId = (e) => {
    setSubCategoryId(e.target.value);
    if (catList != null && catList.length > 0) {
      let found = false;
      for (let i = 0; i < catList.length && !found; i++) {
        let a = catList[i];

        for (let j = 0; j < a.subCategories.length && !found; j++) {
          let b = a.subCategories[j];

          if (b._id === e.target.value) {
            found = true;
            setChildCatList(b.childCategories);
          }
        }
      }
    }
  };

  const onChangeChildCatId = (e) => {
    setChildCategoryId(e.target.value);
  };

  const handleSave = (e) => {
    e.preventDefault();
    form.current.validateAll();

    if (checkBtn.current.context._errors.length === 0) {
      let brandObj = {
        name: name,
        mainCategoryId: mainCategoryId,
        categoryId: categoryId,
        subCategoryId: subCategoryId,
        childCategoryId: childCategoryId,
      };

      if (id != null) {
        brandObj.id = id;
      }
      dispatch(saveBrandsObj(brandObj));
      setAddBrand(false);

      toast.success("brand was saved!");
    }
  };

  const handlePageChange = (pageNumber) => {
    dispatch(updateBrandsPagination(pageNumber));
    dispatch(getAllBrands());
  };

  const editPage = (brand) => {
    setName(brand.name);
    setMainCategoryId(brand.mainCategoryId._id);
    setCategoryId(brand.categoryId._id);
    setSubCategoryId(brand.subCategoryId._id);
    setChildCategoryId(
      brand.childCategoryId ? brand.childCategoryId._id : null
    );
    setId(brand._id);
    setAddBrand(true);
    setCatList(null);
    setSubCatList(null);
    setChildCatList(null);

    if (adminState.categories != null && adminState.categories.length > 0) {
      let found = null;
      for (let i = 0; i < adminState.categories.length && !found; i++) {
        let item = adminState.categories[i];

        if (item._id === brand.mainCategoryId._id) {
          found = item;
        }
      }

      if (found != null) {
        setCatList(found.categories);
        if (brand.subCategoryId != null) {
          let cat = null;
          for (let j = 0; j < found.categories.length && !cat; j++) {
            let b = found.categories[j];
            if (b._id === brand.categoryId._id) {
              cat = b;
            }
          }

          if (cat != null) {
            setSubCatList(cat.subCategories);
            if (brand.childCategoryId) {
              let subCat = null;
              for (let k = 0; k < cat.subCategories.length && !subCat; k++) {
                let c = cat.subCategories[k];

                if (c._id === brand.subCategoryId._id) {
                  subCat = c;
                }
              }

              if (subCat != null) {
                setChildCatList(subCat.childCategories);
              }
            }
          }
        }
      }
    }
  };

  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      zIndex: "999",
    },
  };

  Modal.setAppElement("#root");
  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const deleteBrObj = (brand) => {
    openModal();
    setDeleteBrand(brand);
    dispatch(toBeDeleted(brand));
  };

  const deleteAndCloseModal = () => {
    dispatch(deleteBrandObj(deleteBrand));
    setDeleteBrand(null);
    closeModal();
  };

  return (
    <div className="col-md-12">
      <Modal isOpen={isModalOpen} style={customStyles}>
        <FaTimes
          onClick={() => closeModal()}
          className="float-right mb-2"
        ></FaTimes>
        <div className="col-md-12">
          <div className="row">
            <div className="col-md-12">
              Are you sure you want to delete the brand ?
            </div>
            <div className="col-md-12 mt-2">
              <button
                className="btn btn-danger ml-2 float-right"
                onClick={() => closeModal()}
              >
                No
              </button>
              <button
                className="btn btn-primary float-right"
                onClick={() => deleteAndCloseModal()}
              >
                Yes
              </button>
            </div>
          </div>
        </div>
      </Modal>

      {!addBrand && (
        <div className="row">
          <div className="col-md-12">
            <div className="row">
              <div className="col-md-6">
                <button
                  className="btn btn-primary"
                  onClick={() => openAddBrand()}
                >
                  Add Brands
                </button>
              </div>
              <div className="col-md-6 text-right">
                <Pagination
                  activePage={adminState.brandsFeatures.page}
                  itemsCountPerPage={adminState.brandsFeatures.ps}
                  totalItemsCount={adminState.brandsCount}
                  pageRangeDisplayed={5}
                  onChange={handlePageChange.bind(this)}
                  itemClass="page-item"
                  linkClass="page-link"
                  innerClass="pagination ml-auto mr-auto"
                  prevPageText="Prev"
                  nextPageText="Next"
                  hideFirstLastPages={true}
                />
              </div>
            </div>
          </div>
          <div className="table-responsive mt-2">
            <table className="table">
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Main Category</th>
                  <th>Category</th>
                  <th>Sub category</th>
                  <th>Child category</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {adminState.brandsList != null &&
                adminState.brandsList.length > 0
                  ? adminState.brandsList.map((brand) => {
                      return (
                        <tr key={brand._id}>
                          <td>{brand.name}</td>
                          <td>{brand.mainCategoryId.name}</td>
                          <td>{brand.categoryId.name}</td>
                          <td>{brand.subCategoryId.name}</td>
                          <td>
                            {brand.childCategoryId
                              ? brand.childCategoryId.name
                              : null}
                          </td>
                          <td>
                            <FaEdit
                              className="ONM_icons"
                              onClick={() => editPage(brand)}
                            ></FaEdit>
                            <FaTrash
                              onClick={() => deleteBrObj(brand)}
                              className="ONM_icons"
                            ></FaTrash>
                          </td>
                        </tr>
                      );
                    })
                  : null}
              </tbody>
            </table>
          </div>
        </div>
      )}

      {addBrand && (
        <div className="row">
          <div className="col-md-6">
            <h1>{id != null ? "Edit Brand" : "Add Brand"}</h1>
          </div>
          <div className="col-md-6">
            <FaTimes
              onClick={() => backToList()}
              className="float-right mb-2"
            ></FaTimes>
          </div>

          <Form onSubmit={handleSave} ref={form}>
            <div className="col-md-12">
              <div className="row">
                <div className="form-group col-md-6">
                  <label htmlFor="name">Name</label>
                  <Input
                    type="text"
                    className="form-control"
                    name="name"
                    value={name}
                    onChange={onChangeName}
                    validations={[required]}
                  />
                </div>

                <div className="form-group col-md-6">
                  <label htmlFor="mainCategoryId">Main Category</label>

                  <Select
                    name="mainCategoryId"
                    value={mainCategoryId}
                    onChange={onChangeMainCatId}
                    validations={[required]}
                    className="form-control"
                  >
                    <option value="">Choose your main category</option>
                    {adminState.categories != null &&
                      adminState.categories.map((cat) => {
                        return (
                          <option key={cat._id} value={cat._id}>
                            {cat.name}
                          </option>
                        );
                      })}
                  </Select>
                </div>

                <div className="form-group col-md-6">
                  <label htmlFor="categoryId">Category</label>

                  <Select
                    name="categoryId"
                    value={categoryId}
                    onChange={onChangeCatId}
                    validations={[required]}
                    className="form-control"
                  >
                    <option value="">Choose your category</option>
                    {catList != null &&
                      catList.map((cat) => {
                        return (
                          <option key={cat._id} value={cat._id}>
                            {cat.name}
                          </option>
                        );
                      })}
                  </Select>
                </div>

                <div className="form-group col-md-6">
                  <label htmlFor="subCategoryId">Sub Category</label>

                  <Select
                    name="subCategoryId"
                    value={subCategoryId}
                    onChange={onChangeSubCatId}
                    validations={[required]}
                    className="form-control"
                  >
                    <option value="">Choose your sub-category</option>
                    {subCatList != null &&
                      subCatList.map((cat) => {
                        return (
                          <option key={cat._id} value={cat._id}>
                            {cat.name}
                          </option>
                        );
                      })}
                  </Select>
                </div>

                <div className="form-group col-md-6">
                  <label htmlFor="subCategoryId">Child Category</label>

                  <Select
                    name="childCategoryId"
                    value={childCategoryId}
                    onChange={onChangeChildCatId}
                    className="form-control"
                  >
                    <option value="">Choose your child-category</option>
                    {childCatList != null &&
                      childCatList.map((cat) => {
                        return (
                          <option key={cat._id} value={cat._id}>
                            {cat.name}
                          </option>
                        );
                      })}
                  </Select>
                </div>
              </div>
              <div className="">
                <button className="btn btn-dark btn-block-xs">
                  <span>Save</span>
                </button>
              </div>
              <CheckButton style={{ display: "none" }} ref={checkBtn} />
            </div>
          </Form>
        </div>
      )}
    </div>
  );
};

export default AdminBrands;
