import StoreCardCat from "./StoreCardCat";
import classes from "../components/common/Blog/Card.module.css";
// import ProductCard from "./ProductCard";

const StoreList = (props) => {
  const platItem =
    props.items != null && props.items.length > 0
      ? props.items.map((item) => {
          return (
            <div key={item._id} className={`col-md-4  ${classes.on__hover}`}>
              <StoreCardCat
                item={item}
                onStoreUpdate={props.onStoreUpdate}
                userObj={props.userObj}
              ></StoreCardCat>
              {/* <ProductCard 
              item={item} 
              onStoreUpdate={props.onStoreUpdate}
              userObj={props.userObj}

              addToWishlist={props.addToWishlist}
              addToCart={props.addToCart}>
            </ProductCard> */}
            </div>
          );
        })
      : null;

  return (
    <div className="container">
      <div className="row">{platItem}</div>
    </div>
  );
};

export default StoreList;
