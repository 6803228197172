import { useSelector } from "react-redux";

const OrderDetail = (props) => {

  const totalPrice = () =>{
    let totalPrice = 0;
    props.items.forEach((cart) => {
        totalPrice += cart.stock * ( cart.amount - ( cart.amount * cart.offer / 100 ) );    
    });

    return totalPrice.toFixed(2);
  }

  const itemTotal = (item) => {
    let price =
        item.productId.price -
        item.productId.price * (item.productId.offer / 100);

    let qty = item.stock;
    let subtotal = ( price * qty );
  
    return subtotal.toFixed(2);
  }

  const cartItems =
    props.items != null && props.items.length > 0
      ? props.items.map((item) => {
          return (
            <tr key={item._id}>
                <td>{item.productId.name} <span className="qty">x {item.stock}</span></td>
                <td> Rs. {itemTotal(item)}</td>
            </tr>
          );
        })
      : null;

  return (
    <>
      {/* {console.log(props.items.length)} */}
      {props.items != null && props.items.length ? (
        <>
            <div className="review-order-wrap">
                <div className="review-order">
                    <table className="review-order-table">
                    <thead>
                        <tr>
                        <th>Product</th>
                        <th>Subtotal</th>
                        </tr>
                    </thead>          
                    <tbody>
                        {cartItems}
                        <tr className="total-wrap">
                            <th>Total</th>
                            <td className="total-price">Rs. {totalPrice()}</td>
                        </tr>
                    </tbody>
                    </table>
                </div>
            </div>
        </>
      ) : null }
    </>
  );
};

export default OrderDetail;
