import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { saveCarts } from "../services/cart.service";
import {
  getProductsByKeyword,
  getProductsCountBykeyword,
} from "../services/product.service";
import { save } from "../services/wishlist.service";

const user = JSON.parse(localStorage.getItem("user"));

export const getProducts = createAsyncThunk(
  "search/getProducts",
  async (keyword, { getState, rejectWithValue }) => {
    try {
      const state = getState().search;
      const response = await getProductsByKeyword(
        keyword,
        state.features
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const getProductsCount = createAsyncThunk(
  "search/getProductsCount",
  async (keyword, { getState, rejectWithValue }) => {
    try {
      const state = getState().search;
      const response = await getProductsCountBykeyword(
        keyword,
        state.features
      );

      return response.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const saveWishlist = createAsyncThunk(
  "search/saveWishlist",
  async (wishObj, { getState, rejectWithValue }) => {
    try {
      const response = await save(wishObj);
      return response.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const saveToCart = createAsyncThunk(
  "search/saveToCart",
  async (cartObj, { getState, rejectWithValue }) => {
    try {
      const response = await saveCarts(cartObj);
      return response.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

const initialState = {
  list: [],
  prodList: null,
  selected: null,
  user: user,
  loading: false,
  count: 0,
  totalListCount: 0,
  features: { ps: 12, sidx: 0, page: 1, catId: null, brandsList: null },  
  wishAdded: false,
  savedToCart: false,
  searchKeyword: '',
};

const searchSlice = createSlice({
  name: "Search",
  initialState: initialState,
  reducers: {
    updateSelected: (state, action) => {
      state.selected = action.payload;
    },
    updatePagination: (state, action) => {
      state.features.page = action.payload;
      state.features.sidx = (state.features.page - 1) * state.features.ps;
    },    
    updateWishlistAdded: (state, action) => {
      state.wishAdded = false;
    },
    updateLoggedInUser: (state, action) => {
      state.city = localStorage.getItem("city");
      const user = localStorage.getItem("user");
      if (user != null) {
        state.user = JSON.parse(user);
      } else {
        state.user = null;
      }
    },
    updateLoggedInCategory: (state, action) => {
      state.city = localStorage.getItem("city");
      const user = localStorage.getItem("user");
      if (user != null) {
        state.user = JSON.parse(user);
      } else {
        state.user = null;
      }
    },
    updateCartAdded: (state, action) => {
      state.savedToCart = false;
    },
    updateSearchKeyword: (state, action) => {
      state.searchKeyword = action.payload;
    },
  },
  extraReducers: (builder) =>
    builder
      .addCase(getProducts.pending, (state, action) => {
        state.loading = true;
        // state.prodList = null;
      })
      .addCase(getProducts.fulfilled, (state, action) => {
        state.loading = false;

        if (action.payload && action.payload.result != null) {
          state.prodList = action.payload.result;
        } else {
          state.prodList = null;
        }
      })
      .addCase(getProductsCount.pending, (state, action) => {
        state.count = 0;
      })
      .addCase(getProductsCount.fulfilled, (state, action) => {
        if (action.payload) {
          state.count = action.payload.result;
        } else {
          state.count = 0;
        }
      })
      .addCase(saveWishlist.pending, (state, action) => {
        state.wishAdded = false;
      })
      .addCase(saveWishlist.fulfilled, (state, action) => {
        state.wishAdded = true;
      })
      .addCase(saveToCart.pending, (state, action) => {
        state.savedToCart = false;
      })
      .addCase(saveToCart.fulfilled, (state, action) => {
        state.savedToCart = true;
      }),
});

export default searchSlice.reducer;
export const {
  updateSelected,
  updatePagination,
  updateWishlistAdded,
  updateLoggedInUser,
  updateLoggedInCategory,
  updateCartAdded,
  updateSearchKeyword,
} = searchSlice.actions;
