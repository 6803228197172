import { BASE_URL } from "../../services/constants";

const StoreBanner = (props) => {
  let baseUri = BASE_URL;

  const adsBanner = props.items && props.items.imageId ? (
    <img src={baseUri + props.items.imageId.path} alt="banner"/>
    // props.items.name
  ) : null;

  return (
    <div className="col-md-12 mt-3">
      <div className="row p-2">
        <div className="col-md-8 p-0">
          {adsBanner}
        </div>
        <div className="col-md-4 p-0">
          <img src={baseUri + "files/1617165513634Map adi.PNG"} alt="Store Banner"></img>
        </div>
      </div>
    </div>
  );
};

export default StoreBanner;
