import axios from "axios";
import authHeader from "./auth-header";
import { BASE_API_URL } from "./constants";

const API_URL = BASE_API_URL + "/api/image/";

export const upload = (formData) => {
  // formData.append( 'test1', 'value1');
  // formData.append( 'test2', 'value2');
  // return axios.post(API_URL + "upload", formData, { headers: authHeader() });

  let headers = authHeader();
  headers['Content-Type']= 'multipart/form-data';
  return axios({
    method: 'post',
    url: API_URL + "upload",
    data: formData,
    headers: headers
  });
};