import { createSlice } from '@reduxjs/toolkit';

const initialState = {};

const messageSlice = createSlice({
    name: 'Messages',
    initialState: initialState,
    reducers: {
      SET_MESSAGE : (state, action) => {
        state.message = action.payload;
      },
      CLEAR_MESSAGE: (state, action) => {
        state.message = "";
      }
    },
});

export default messageSlice.reducer;
export const { SET_MESSAGE, CLEAR_MESSAGE } = messageSlice.actions;