import "./profile.scss";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import CheckButton from "react-validation/build/button";
import GooglePlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-google-places-autocomplete";
import { updateUs } from "../slices/authSlice";
import { removeDeliveryAddressObj, getProfile } from "../slices/authSlice";
import { Redirect } from "react-router";
import { FaTrash } from "react-icons/fa";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Layout from "../components/shop/common-layout";
import { Container, Row, Label, Col } from "reactstrap";
const required = (value) => {
  if (!value) {
    return (
      <div className="alert alert-danger" role="alert">
        This field is required!
      </div>
    );
  }
};

const Profile = (props) => {
  const authState = useSelector((state) => state.auth);
  const form = useRef();
  const checkBtn = useRef();
  const dispatch = useDispatch();
  const [profileLoaded, setProfileLoaded] = useState(0);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [phone, setPhone] = useState("");
  const [gstno, setGstno] = useState("");
  const [storeName, setStoreName] = useState("");
  const [address, setAddress] = useState("");
  const [deliveryAddresses, setDeliveryAddresses] = useState([]);
  const [lat, setLat] = useState(null);
  const [lng, setLng] = useState(null);

  useEffect(() => {
    if (authState.user != null && !profileLoaded) {
      dispatch(getProfile(authState.user._id));
      setProfileLoaded(1);
    }
  }, [authState.user, dispatch]);

  useEffect(() => {
    if (authState.user != null && profileLoaded) {
      setFirstName(authState.user.firstName);
      setLastName(authState.user.lastName);
      setPhone(authState.user.phone);
      setGstno(authState.user.gstno || "");
      setStoreName(authState.user.storeName || "");
      setAddress(authState.user.address || "");
      setDeliveryAddresses(authState.user.deliveryAddress);
      console.log("profile loaded");
      setProfileLoaded(0);
    }
  }, [authState.user]);

  const onFirstNameChange = (e) => {
    setFirstName(e.target.value);
  };

  const onLastNameChange = (e) => {
    setLastName(e.target.value);
  };

  const onPhoneChange = (e) => {
    setPhone(e.target.value);
  };

  const onGstChange = (e) => {
    setGstno(e.target.value);
  };

  const onStoreNameChange = (e) => {
    setStoreName(e.target.value);
  };

  const handleSave = (e) => {
    e.preventDefault();

    form.current.validateAll();

    if (checkBtn.current.context._errors.length === 0) {
      let userObj = {};
      userObj.firstName = firstName;
      userObj.lastName = lastName;
      userObj.phone = phone;
      userObj.gstno = gstno;
      userObj.storeName = storeName;
      userObj.address = address;
      userObj._id = authState.user._id;
      if (lat != null && lng != null) {
        userObj.loc = { type: "Point", coordinates: [lat, lng] };
      }

      dispatch(updateUs(userObj));
      toast.success("Profile saved!");
      // setProfileLoaded(0);
      // dispatch(getProfile(authState.user._id));
    }
  };

  const handleChange = async (value) => {
    const results = await geocodeByAddress(value.label);
    const latLng = await getLatLng(results[0]);
    setAddress(value.label);
    setLat(latLng.lat);
    setLng(latLng.lng);
  };

  const formatAddress = (address) => {
    let name = address.firstName + " " + address.lastName;
    let company = address.company;
    let address1 = address.address;
    let address2 = address.address2;
    let phone = address.phone;
    let state = address.state;
    let city = address.city;
    let country = address.country;
    let postalCode = address.postalCode;

    let printCompany = company ? `(${company}) ` : "";
    let printAdress2 = address2 ? `, ${address2}` : "";

    return `${name} - ${printCompany}${address1}${printAdress2}, ${city}, ${state}, ${country} ${postalCode}`;
    // return address1;
  };

  const removeDeliveryAddressHandler = (addressId) => {
    dispatch(removeDeliveryAddressObj(addressId));
    toast.success("Address removed!");
  };

  if (authState.user == null) {
    return <Redirect to="/login"></Redirect>;
  }

  return (
    <Layout title="profile" parent="home">
      <div className="col-md-12 mt-2 mb-2 contact-page register-page section-b-space mt-5">
        <div className="content-container container">
          <Form className="row theme-form" onSubmit={handleSave} ref={form}>
            <div className="col-md-12">
              <h3>PERSONAL DETAILS</h3>
              <div className="row">
                <div className="form-group col-md-6">
                  <label htmlFor="firstName">First Name</label>
                  <Input
                    type="text"
                    className="form-control"
                    name="firstName"
                    value={firstName}
                    onChange={onFirstNameChange}
                    validations={[required]}
                  />
                </div>
                <div className="form-group col-md-6">
                  <label htmlFor="lastName">Last Name</label>
                  <Input
                    type="text"
                    className="form-control"
                    name="lastName"
                    value={lastName}
                    onChange={onLastNameChange}
                    validations={[required]}
                  />
                </div>
                <div className="form-group col-md-6">
                  <label htmlFor="phone">Phone</label>
                  <Input
                    type="text"
                    className="form-control"
                    name="phone"
                    value={phone}
                    onChange={onPhoneChange}
                    validations={[required]}
                  />
                </div>

                {/* <div className="form-group col-md-6">
                <label htmlFor="gstno">Gst #</label>
                <Input
                  type="text"
                  className="form-control"
                  name="gstno"
                  value={gstno}
                  onChange={onGstChange}
                />
              </div> */}

                {/* <div className="form-group col-md-6">
                <label htmlFor="storeName">Store Name</label>
                <Input
                  type="text"
                  className="form-control"
                  name="storeName"
                  value={storeName}
                  onChange={onStoreNameChange}
                />
              </div> */}

                {/* <div className="form-group col-md-6">
                <label htmlFor="address">Store Address</label>
                <div>
                  <GooglePlacesAutocomplete
                    apiKey="AIzaSyBhhIhmyHFUgqagBmIVaXO5IYmmkmLhLuM"
                    minLengthAutocomplete="5"
                    selectProps={{
                      address,
                      onChange: handleChange,
                    }}
                  />
                </div>                  
                <p>{address}</p>
              </div> */}

                <div className="form-group col-md-12">
                  <label htmlFor="">Delivery Addresses</label>
                  {deliveryAddresses != null &&
                    deliveryAddresses.map((address) => (
                      <p className="delivery-address" key={address._id}>
                        {formatAddress(address)}
                        <FaTrash
                          onClick={() =>
                            removeDeliveryAddressHandler(address._id)
                          }
                        ></FaTrash>
                      </p>
                    ))}
                </div>
              </div>
              <div className="">
                <button className="btn btn-solid ">
                  <span>Save Changes</span>
                </button>
              </div>
            </div>
            <CheckButton style={{ display: "none" }} ref={checkBtn} />
          </Form>
        </div>
      </div>
    </Layout>
  );
};

export default Profile;
