import React from "react";
import { Link } from "react-router-dom";
const MasterServiceContent = ({
  link,
  title,
  service,
  marijuana,
  lastChild,
  icon,
}) => {
  return (
    <Link to={link}>
      <div
        className={`${!marijuana ? "media" : ""} ${
          lastChild ? "border-0 m-0" : ""
        }`}
      >
        <div dangerouslySetInnerHTML={{ __html: icon }} />
        <div className="media-body">
          <h4>{title}</h4>
          <p>{service}</p>
        </div>
      </div>
    </Link>
  );
};

export default MasterServiceContent;
