import axios from "axios";
import authHeader from "./auth-header";
import { BASE_API_URL } from "./constants";

const BANNER_API_URL = BASE_API_URL + "/api/banner/";
const SLIDER_API_URL = BASE_API_URL + "/api/slider/";

export const getBanners = (page) => {
  return axios.get(BANNER_API_URL + "list?page="+page, { headers: authHeader() });
};

export const saveBanners = (obj) => {
  return axios.post(BANNER_API_URL + "save", obj, { headers: authHeader() });
};

export const deleteBanner = (id) =>{
  let obj = {
    id: id
  }
  return axios.post(BANNER_API_URL + "delete", obj, { headers: authHeader() });
}

export const getSliders = () => {
  return axios.get(SLIDER_API_URL + "list", { headers: authHeader() });
};

export const saveSliders = (obj) => {
  return axios.post(SLIDER_API_URL + "save", obj, { headers: authHeader() });
};