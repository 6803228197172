import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import { BASE_URL, BASE_API_URL } from "../services/constants";
import { FaTimes } from "react-icons/fa";

const ProductOnmeeCarousel = (props) => {
  let baseUri = BASE_URL;

  const imgPath = (img) => {
    let path = img;

    if (path.indexOf("https://") > -1) {
      return path;
    } else if (path) {
      return BASE_API_URL + "/" + path;
    } else {
      return baseUri + "files/noimg.png";
    }
  };

  function checkIfImageExists(url) {
    const img = new Image();
    img.src = url;

    if (img.complete) {
      return url;
    } else {
      img.onload = () => {
        return url;
      };

      img.onerror = () => {
        return baseUri + "files/noimg.png";
      };
    }
  }

  const cItem =
    props.items != null
      ? props.items.map((item) => {
          return (
            <div key={item._id}>
              {props.enableRemove && (
                <FaTimes
                  style={{ cursor: "pointer", fontSize: "22px" }}
                  onClick={() => props.removeProductImage(item._id)}
                  className="mb-2"
                ></FaTimes>
              )}
              <img
                alt="<no image>"
                src={
                  checkIfImageExists(imgPath(item.path)) ??
                  baseUri + "files/noimg.png"
                }
              />
              {/* <p className="legend">{item.name}</p> */}
            </div>
          );
        })
      : null;

  return (
    <Carousel
      autoPlay={false}
      centerMode={false}
      showStatus={false}
      showThumbs={props.showThumbs}
      infiniteLoop={true}
    >
      {props.items ? cItem : null}
    </Carousel>
  );
};

export default ProductOnmeeCarousel;
