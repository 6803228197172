import React from "react";

const StoreCatList = (props) => {
  const listItems =
    props.items != null
      ? props.items.map((item) => {
          return (
            <li
              className="p-0"
              key={item._id}
              onClick={() => props.onCategoryUpdate(item._id)}
            >
              <a
                className={
                  item._id === props.selectedId ? "active_anchor" : null
                }
              >
                {item.name}
              </a>
            </li>
          );
        })
      : null;

  return (
    <div>
      <div>
        <h2>Filter by Categories</h2>
        <ul>{listItems}</ul>
      </div>
      <div className="col-md-12">
        <div className="row">
          <h2 className="col-md-12">Filter by Offers</h2>
          <div
            className="col-md-6 mb-3"
            onClick={() => props.onOfferUpdate({ omax: 80, omin: 60 })}
          >
            <div
              className={
                props.offerFilter === 80
                  ? "ONM_offer-filter active"
                  : "ONM_offer-filter"
              }
            >
              60-80%
            </div>
          </div>
          <div
            className="col-md-6 mb-3"
            onClick={() => props.onOfferUpdate({ omax: 60, omin: 40 })}
          >
            <div
              className={
                props.offerFilter === 60
                  ? "ONM_offer-filter active"
                  : "ONM_offer-filter"
              }
            >
              40-60%
            </div>
          </div>
          <div
            className="col-md-6 mb-3"
            onClick={() => props.onOfferUpdate({ omax: 40, omin: 20 })}
          >
            <div
              className={
                props.offerFilter === 40
                  ? "ONM_offer-filter active"
                  : "ONM_offer-filter"
              }
            >
              20-40%
            </div>
          </div>
          <div
            className="col-md-6 mb-3"
            onClick={() => props.onOfferUpdate({ omax: 20, omin: 0 })}
          >
            <div
              className={
                props.offerFilter === 20
                  ? "ONM_offer-filter active"
                  : "ONM_offer-filter"
              }
            >
              0-20%
            </div>
          </div>
        </div>
      </div>
      <div className="col-md-12">
        <h2 className="col-md-12">Price</h2>
        <ul className="ONM_left-filters">
          <li>
            <a
              onClick={() => props.onPriceUpdate({ pmin: 0, pmax: 500 })}
              className={props.priceFilter === 0 ? "active" : null}
            >
              Under 500
            </a>
          </li>
          <li>
            <a
              onClick={() => props.onPriceUpdate({ pmin: 500, pmax: 3000 })}
              className={props.priceFilter === 500 ? "active" : null}
            >
              500 - 3000
            </a>
          </li>
          <li>
            <a
              onClick={() => props.onPriceUpdate({ pmin: 3000, pmax: 8000 })}
              className={props.priceFilter === 3000 ? "active" : null}
            >
              3000 - 8000
            </a>
          </li>
          <li>
            <a
              onClick={() => props.onPriceUpdate({ pmin: 12000 })}
              className={props.priceFilter === 12000 ? "active" : null}
            >
              over 12000
            </a>
          </li>
        </ul>
      </div>
      <div className="col-md-12">
        <h2 className="col-md-12">Price</h2>
        <ul className="ONM_left-filters">
          <li>
            <a
              onClick={() => props.onColorUpdate('Red')}
              className={props.colorFilter === 'Red' ? "active" : null}
            >
              Red
            </a>
          </li>
        </ul>
      </div>
      <a
        className="ONM_clear-filter float-right"
        onClick={() => props.onCategoryClear()}
      >
        Clear Filters
      </a>
    </div>
  );
};

export default StoreCatList;
