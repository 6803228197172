import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getProductsBySubcategory, getProductsCountBySubcategory } from "../services/product.service";

const user = JSON.parse(localStorage.getItem("user"));

export const getProducts = createAsyncThunk(
  "subcategory/getProducts",
  async (subCatId, { getState, rejectWithValue }) => {
    try {
      const state = getState().subcategory;
      const response = await getProductsBySubcategory(subCatId, state.features);

      return response.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const getProductsCount = createAsyncThunk(
  "subcategory/getProductsCount",
  async (subCatId, { getState, rejectWithValue }) => {
    try {
      const state = getState().subcategory;
      const response = await getProductsCountBySubcategory(subCatId, state.features);

      return response.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

const initialState = {
    list: [],
    selected: null,
    user: user,
    loading: false,
    count: 0,
    totalListCount: 0,
    features: {ps: 2, sidx: 0, page: 1}
};

const subcategorySlice = createSlice({
  name: "SubCategory",
  initialState: initialState,
  reducers: {
    updatePriceFilter: (state, action) => {
      state.features.pmin = action.payload.pmin;
      state.features.pmax = action.payload.pmax;
    },
    updatePagination: (state, action) => {
      state.features.page = action.payload;
      state.features.sidx = (state.features.page -1) * state.features.ps;
    }
  },
  extraReducers: (builder) =>
    builder
      .addCase(getProducts.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(getProducts.fulfilled, (state, action) => {
        state.loading = false;
        
        if (action.payload && action.payload.result != null) 
        {
            state.list = action.payload.result;
        }
      })
      .addCase(getProductsCount.fulfilled, (state, action) => {
        state.loading = false;

        if (action.payload) 
        {
            state.count = action.payload.result;
        }
      })
});

export default subcategorySlice.reducer;
export const { updatePriceFilter, updatePagination } = subcategorySlice.actions;