import { useEffect, useState } from "react";
import "./checkout.scss";
import { useDispatch, useSelector } from "react-redux";
import { useAlert } from "react-alert";
import {
  getCarts,
  getCartsCount,
  productById,
  saveAndProceedForPayment,
  updatePaymentId,
  paymentSucceeded,
  resetOderPaid,
} from "../../slices/headerSlice";
import { getProfile } from "../../slices/authSlice";
import { getUniqueId } from "../../helpers/LocalCart";
import Modal from "react-modal";
import { BASE_URL } from "../../services/constants";
import { Redirect } from "react-router-dom";
import ReviewOrder from "../../helpers/ReviewOrder";
import ShippingForm from "./ShippingForm";
import { getDirectCheckoutItem, isDirectCheckout } from "../../helpers/BuyNow";
import { getProductsById } from "../../services/product.service";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Layout from "../shop/common-layout";
const Checkout = (props) => {
  const headerState = useSelector((state) => state.header);
  const [isRedirect, setRedirect] = useState(0);
  const [paidOrderId, setPaidOrderId] = useState(0);
  const [cartItems, setCartItems] = useState([]);
  const dispatch = useDispatch();
  // const alert = useAlert();
  let baseUri = BASE_URL;

  const loadScript = (src) => {
    return new Promise((resolve) => {
      const script = document.createElement("script");
      script.src = src;
      script.onload = () => {
        resolve(true);
      };
      script.onerror = () => {
        resolve(false);
      };
      document.body.appendChild(script);
    });
  };

  useEffect(() => {
    if (headerState.user == null) {
      dispatch(getCartsCount(getUniqueId()));
      dispatch(getCarts(getUniqueId()));
    } else {
      dispatch(getCartsCount(headerState.user._id));
      dispatch(getCarts(headerState.user._id));
    }
  }, [dispatch]);

  useEffect(() => {
    if (headerState.orderPaid) {
      // alert.show("Payment Successful !!!");
      // console.log('refresh cart');
      toast.success("Payment Successful !!!");
      if (headerState.user == null) {
        // dispatch(getCartsCount());
        // dispatch(getCarts());
      } else {
        dispatch(getCartsCount(headerState.user._id));
        dispatch(getCarts(headerState.user._id));
        dispatch(getProfile(headerState.user._id));
      }
      setRedirect(1);
      dispatch(resetOderPaid());
    }
  }, [headerState.orderPaid]);

  async function displayRazorpay() {
    const res = await loadScript(
      "https://checkout.razorpay.com/v1/checkout.js"
    );

    if (!res) {
      alert("Razorpay SDK failed to load. Are you online?");
      return;
    }

    if (headerState.toBePaidOrder != null) {
      var options = {
        key: "rzp_test_HCImT64Pg77Nrx", // Enter the Key ID generated from the Dashboard
        amount: headerState.toBePaidOrder.amount.toString(), // Amount is in currency subunits. Default currency is INR. Hence, 50000 refers to 50000 paise
        currency: "INR",
        name: "Onmee",
        description: "Transaction",
        image: baseUri + "files/onmee_logo.png",
        order_id: headerState.toBePaidOrder.rzpOrdId, //This is a sample Order ID. Pass the `id` obtained in the response of Step 1
        handler: function (response) {
          let orderObj = {
            _id: headerState.toBePaidOrder._id,
            products: headerState.toBePaidOrder.products,
            amount: headerState.toBePaidOrder.amount,
            rzpOrdId: headerState.toBePaidOrder.rzpOrdId,
            razorpayPaymentId: response.razorpay_payment_id,
            razorpaySignature: response.razorpay_signature,
          };
          dispatch(paymentSucceeded(orderObj));

          setPaidOrderId(headerState.toBePaidOrder._id);
        },
        theme: {
          color: "#3399cc",
        },
      };
      var rzp1 = new window.Razorpay(options);
      rzp1.open();
      rzp1.on("payment.failed", function (response) {
        console.log(response);
      });
    }
  }

  useEffect(() => {
    if (headerState.toBePaidOrder != null) {
      displayRazorpay();
    }
  }, [headerState.toBePaidOrder]);

  const initCartItems = () => {
    // if( ! cartItems.length ){
    if (isDirectCheckout()) {
      getProductsById(getDirectCheckoutItem()).then((res) => {
        let cartObj = [
          {
            productId: res.data.result,
            stock: 1,
          },
        ];

        // console.log(cartObj);
        setCartItems(cartObj);
      });
    } else {
      if (headerState.cartsList && headerState.cartsList.length) {
        setCartItems(headerState.cartsList);
        // console.log(headerState.cartsList);
      }
    }
  };

  const [deliveryAddress, setDeliveryAddress] = useState({});
  const [error, setError] = useState(0);
  const [form, setForm] = useState(null);
  const [checkBtn, setCheckBtn] = useState(null);

  const validateForm = () => {
    form.current.validateAll();
    if (checkBtn.current.context._errors.length === 0) {
      setError(false);
    } else {
      setError(true);
    }
  };

  const validateAndSubmit = () => {
    form.current.validateAll();
    if (checkBtn.current.context._errors.length === 0) {
      setError(false);
      proceedTOPayment();
    } else {
      setError(true);
    }
  };

  const proceedTOPayment = () => {
    if (Object.keys(deliveryAddress).length) {
      const orderObj = {
        deliveryAddress: deliveryAddress,
        userId: headerState.user._id,
      };
      let pIds = cartItems.map((cart) => {
        return cart.productId != null && cart.productId.stock > 0
          ? {
              productId: cart.productId._id,
              stock: cart.stock,
              amount: cart.productId.price,
              offer: cart.productId.offer,
              weight: cart.productId.weight,
              length: cart.productId.length,
              height: cart.productId.height,
            }
          : null;
      });

      orderObj.products = pIds;
      console.log(orderObj);
      dispatch(saveAndProceedForPayment(orderObj));
    }
  };

  if (!cartItems.length) {
    initCartItems();
  }

  if (
    (!isDirectCheckout() &&
      headerState.cartsList &&
      headerState.cartsList.length <= 0) || // normal checkout - empty cart
    !headerState.user // user not logged in
  ) {
    let redirectTo = "/cart/";
    return <Redirect to={redirectTo} />;
  }

  if (isRedirect && paidOrderId) {
    let redirectTo = "/thank-you/" + paidOrderId;
    return <Redirect to={redirectTo} />;
  }

  return (
    <Layout title="Checkout" parent="home">
      <div className="content-container container mt-5 contact-page register-page ">
        <div className="row">
          <div className="col-md-5">
            <ShippingForm
              userId={headerState.user._id}
              setDeliveryAddress={setDeliveryAddress}
              setError={setError}
              setForm={setForm}
              setCheckBtn={setCheckBtn}
              validateForm={validateForm}
            />
          </div>
          <div className="col-md-7">
            <ReviewOrder
              items={cartItems}
              proceed={validateAndSubmit}
              isError={error}
            ></ReviewOrder>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Checkout;
