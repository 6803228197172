import React from "react";
import Layout from "./shop/common-layout";
import { Table } from "reactstrap";
const ReturnPolicy = () => {
  return (
    <>
      <Layout title="return policy" parent="home">
        <div className="content-container container">
          <section className="page-content">
            <p className="return__policy">
              Returns is a scheme provided by respective sellers/merchant
              directly under this policy in terms of which the option of
              exchange, replacement and/ or refund is offered by the respective
              sellers/merchants to you. All products listed under a particular
              category may not have the same returns policy. For all products,
              the returns/replacement policy provided on the page shall prevail
              over the general returns policy. Do refer the respective item's
              applicable return/replacement policy on the product page for any
              exceptions to this returns policy and the table below
              <br />
              <br />
              The return policy is divided into three parts; Do read all
              sections carefully to understand the conditions and cases under
              which returns will be accepted.
              <br />
              <br />
              <h5>Part 1</h5>
              <br />
              <h5>Refund Timelines:</h5>
              The refund time period for different modes of payments is provided
              by merchant or seller .
              <br />
              <br />
              <Table striped style={{ border: "1px solid #f1f1f1" }} responsive>
                <thead>
                  <tr>
                    <th>Available refund method</th>
                    <th>Refund Time-frame</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td></td>
                    <td>
                      FBA orders (After the return is received by merchant or
                      seller)
                    </td>
                    <td>
                      Seller-Fulfilled orders (After notification seller
                      receives the receipt of return)
                    </td>
                  </tr>
                  <tr>
                    <td colSpan={3} style={{ textAlign: "center" }}>
                      Prepaid Orders
                    </td>
                  </tr>
                  <tr>
                    <td></td>
                    <td></td>
                    <td></td>
                  </tr>
                  <tr>
                    <td>Credit Card/ Debit Card</td>
                    <td>2-4 Business Days</td>
                    <td>3-5 Business Days</td>
                  </tr>
                  <tr>
                    <td>Net Banking Account (Credited to Bank Account)</td>
                    <td></td>
                    <td></td>
                  </tr>
                  <tr>
                    <td>UPI Linked Bank Account</td>
                    <td colSpan={2} style={{ textAlign: "center" }}>
                      2-4 business days
                    </td>
                  </tr>
                  <tr>
                    <td colSpan={3} style={{ textAlign: "center" }}>
                      Pay on Delivery Orders
                    </td>
                  </tr>
                  <tr>
                    <td>NEFT to Bank Account</td>
                    <td>2-4 Business Days</td>
                    <td>3-5 Business Days</td>
                  </tr>
                  <tr>
                    <td></td>
                    <td></td>
                    <td></td>
                  </tr>
                  <tr>
                    <td>Paper Cheque</td>
                    <td colSpan={2} style={{ textAlign: "center" }}>
                      8-10 business days
                    </td>
                  </tr>
                </tbody>
              </Table>
              <br />
              If the standard time-frame as mentioned in the above table has
              expired and you have still not received the refund, please contact
              your credit or debit card issuer or your bank for more
              information. Refunds will not be processed in cash. Refunds can be
              processed via paper cheque only in exceptional cases.
              <br />
              <strong>Note :</strong> International customers would need to
              reach out to our Customer Service team to get refunds for their
              purchase. The refund would be made to the original payment
              method/instrument used to make the initial purchase.
              <br />
              <br />
              <h5>For Fulfilled by merchants orders:</h5>
              1. If the payment method, which you used to make the payment (such
              as credit/ debit card) at the time of purchase, is no longer
              valid, refund will be issued as per merchants notification.
              <br />
              2. If merchant receive a payment failure notice from your bank,
              refund will be issued as per merchants notification
              <br />
              merchant will send you an e-mail asking you to call us and provide
              your name and address where you want the demand draft to be sent.
              After merchant receive these details, refund will be initiated.
              <br />
              <br />
              <h5>For Seller-Fulfilled orders:</h5>
              1. For damaged/ defective items, the seller will issue a refund if
              the item cannot be repaired or replaced.
              <br />
              2. In any case where a refund is required, the seller(s) need to
              authorise that refund. Onmee.in can assist in facilitating refunds
              for you only when the seller notifies us of the receipt of the
              item. Once the seller notifies us of the receipt of the return
              item, the above refund time period will apply for processing
              refunds.
              <br />
              <br />
              <h5>Refund for Pay on Delivery Orders:</h5>
              For Pay on Delivery orders, refunds will be processed either to
              your bank account (via National Electronic Funds Transfer (NEFT))
              or Any other payment methods
              <br />
              If you wish to receive the Pay on Delivery order’s refund to your
              bank account, you can update the details of the bank account in
              Your Account section or from the Returns Centre when you are
              returning an item.
              <br />
              <strong>Note :</strong> Refunds cannot be processed to third-party
              accounts. The name on your Onmee account should match with the
              name of the bank account holder.
              <br />
              <br />
              <h5>Shipping Cost Refunds:</h5>
              1. For Fulfilled by onmee Eligible items, return shipping costs of
              upto Rs. 100 will be refunded. The cost of gift-wrapping will also
              be refunded, if any. All such refunds will be issued through
              cheques. Note: If you incur return shipping charges over Rs.100
              for returning large and heavy items, you can contact us for an
              additional refund. For refund of such additional charges, proof of
              payment, like a courier receipt needs to be submitted.
              <br />
              2. If you're returning a Seller-Fulfilled item, you can request
              the seller to reimburse the return shipping charges you incurred.
              In such cases, a seller might ask you to provide the courier
              receipt.
              <br />
              <br />
              <strong>Note : </strong> Onmee is not responsible or answerable
              for any kind of damage or refund or any other legal activities
              <br />• Merchants are only responsible for their orders as the
              orders are packed and shipped by the merchants only.
              <br />
              <br />
              During pick-up, your product will be checked for the following
              conditions:
              <br />
              <br />
              <Table style={{ border: "1px solid #f1f1f1" }} responsive>
                <thead>
                  <tr>
                    <th>Category</th>
                    <th>Conditions</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Correct Product</td>
                    <td>
                      IMEI/ name/ image/ brand/ serial number/ article number/
                      bar code should match and MRP tag should be undetached and
                      clearly visible.
                    </td>
                  </tr>
                  <tr>
                    <td>Complete Product</td>
                    <td>
                      All in-the-box accessories (like remote control, starter
                      kits, instruction manuals, chargers, headphones, etc.),
                      freebies and combos (if any) should be present.
                    </td>
                  </tr>
                  <tr>
                    <td>Unused Product</td>
                    <td>
                      The product should be unused, unwashed, unsoiled, without
                      any stains and with non-tampered quality check
                      seals/return tags/warranty seals (wherever applicable).
                      Before returning a Mobile/ Laptop/ Tablet, the device
                      should be formatted and Screen Lock (Pin, Pattern or
                      Fingerprint) must be disabled. iCloud lock must be
                      disabled for Apple devices.
                    </td>
                  </tr>
                  <tr>
                    <td>Undamaged Product</td>
                    <td>
                      The product (including SIM trays/ charging port/ headphone
                      port, back-panel etc.) should be undamaged and without any
                      scratches, dents, tears or holes.
                    </td>
                  </tr>
                  <tr>
                    <td>Undamaged Packaging</td>
                    <td>
                      The product’s original packaging/ box should be undamaged.
                    </td>
                  </tr>
                </tbody>
              </Table>
              <br />
              The field executive will refuse to accept the return if any of the
              above conditions are not met.
              <br />
              <br />
              For any products for which a refund is to be given, the refund
              will be processed once the returned product has been received by
              the sel General Rules for a successful Return
              <br />
              <br />
              1. In certain cases where the seller is unable to process a
              replacement for any reason whatsoever, a refund will be given.
              <br />
              2. In cases where a product accessory is found
              missing/damaged/defective, the seller may either process a
              replacement of the particular accessory or issue an eGV for an
              amount equivalent to the price of the accessory, at the seller’s
              discretion
              <br />
              3. During open box deliveries, while accepting your order, if you
              received a different or a damaged product, you will be given a
              refund (on the spot refunds for cash-on-delivery orders). Once you
              have accepted an open box delivery, no return request will be
              processed, except for manufacturing defects. In such cases, these
              category-specific replacement/return general conditions will be
              applicable.
              <br />
              4. For products where installation is provided by Onmee's service
              partners, do not open the product packaging by yourself. Omee’s
              authorised personnel shall help in unboxing and installation of
              the product.
              <br />
              5. For Furniture, any product-related issues will be checked by
              authorised service personnel (free of cost) and attempted to be
              resolved by replacing the faulty/ defective part of the product.
              Full replacement will be provided only in cases where the service
              personnel opines that replacing the faulty/defective part will not
              resolve the issue.
              <br />
              <br />
              'In case the product was not delivered and you received a delivery
              confirmation email/SMS, report the issue within 7 days from the
              date of delivery confirmation for the seller to investigate.'
            </p>
          </section>
        </div>
      </Layout>
    </>
  );
};

export default ReturnPolicy;
