import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getMerchantCount,
  getMerchantList,
  markActive,
  updatePagination,
  resetFeatures,
  getPlans,
  markRejected,
  markInActive,
  updateUs,
  updateUserUpdated,
  deleteMerchant,
} from "../../slices/adminSlice";
import { FaCheck, FaTimes, FaStop, FaEye, FaRegGrinAlt } from "react-icons/fa";
import Modal from "react-modal";
import Pagination from "react-js-pagination";
import ReactTooltip from "react-tooltip";
import { useAlert } from "react-alert";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { BASE_URL, BASE_API_URL } from "../../services/constants";

const AdminMerchant = (props) => {
  const adminState = useSelector((state) => state.admin);
  const dispatch = useDispatch();
  const [merchant, setMerchant] = useState(null);
  const [tab, setTab] = useState(null);
  const [query, setQuery] = useState({});
  // const alert = useAlert();

  const updatePending = (tab) => {
    setTab(tab);
    let query = {};
    if (tab === "PENDING") {
      query.active = "F";
      query.rejected = "F";
      query.inactive = "F";
    } else if (tab === "ACTIVE") {
      query.active = "T";
      query.rejected = "F";
      query.inactive = "F";
    } else if (tab === "REJECTED") {
      query.rejected = "T";
    } else if (tab === "INACTIVE") {
      query.inactive = "T";
    }

    setQuery(query);
    dispatch(resetFeatures());
    dispatch(getMerchantList(query));
    dispatch(getMerchantCount(query));
  };

  useEffect(() => {
    if (adminState.userUpdated) {
      // alert.show("User was updated successfully !!!");
      toast.success("User was updated successfully !!!")
      dispatch(updateUserUpdated());
    }
  }, [adminState.userUpdated]);

  useEffect(() => {
    setTab("PENDING");
    let query = {};
    query.active = "F";
    query.rejected = "F";
    query.inactive = "F";
    setQuery(query);
    dispatch(resetFeatures());
    dispatch(getMerchantList(query));
    dispatch(getMerchantCount(query));
    dispatch(getPlans());
  }, [dispatch]);

  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      zIndex: "9999",
      width: "80%",
    },
  };

  const customStyles50 = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      zIndex: "9999",
      width: "50%",
    },
  };
  Modal.setAppElement("#root");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalName, setModalName] = useState(null);
  const [isUserModalOpen, setIsUserModalOpen] = useState(false);
  const [updatePlan, setUpdatePlan] = useState(false);
  const [planId, setPlanId] = useState(null);
  const [planCode, setPlanCode] = useState(null);

  const openModal = (merchantObj, name) => {
    setMerchant(merchantObj);
    setModalName(name);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const markActiveAndClose = () => {
    dispatch(markActive(merchant._id));
    setMerchant(null);
    setModalName(null);
    closeModal();
  };

  const markRejectedAndClose = () => {
    dispatch(markRejected(merchant._id));
    setMerchant(null);
    setModalName(null);
    closeModal();
  };

  const markInactiveAndClose = () => {
    dispatch(markInActive(merchant._id));
    setMerchant(null);
    setModalName(null);
    closeModal();
  };

  const deleteMerchantAndClose = () => {
    dispatch(deleteMerchant(merchant._id));
    setMerchant(null);
    setModalName(null);
    closeModal();
  };

  const handlePageChange = (pageNumber) => {
    dispatch(updatePagination(pageNumber));
    dispatch(getMerchantList(query));
  };

  const openUserModal = (merchantObj) => {
    setMerchant(merchantObj);
    setIsUserModalOpen(true);
  };

  const closeUserModal = () => {
    setIsUserModalOpen(false);
  };

  const updatingPlan = () => {
    setUpdatePlan(true);
    setPlanId(merchant.planId);
    setPlanCode(merchant.planCode);
  };

  const backToView = () => {
    setUpdatePlan(false);
    setPlanId(null);
    setPlanCode(null);
  };

  const savePlanForMerchant = () => {
    if (merchant && planId && planCode) {
      let userObj = {};
      userObj._id = merchant._id;
      userObj.planId = planId;
      userObj.planCode = planCode;
      // userObj.phone = merchant.phone;
      // userObj.gstno = merchant.gstno;
      // userObj.storeName = merchant.storeName;
      // userObj.address = merchant.address;
      // userObj.loc = merchant.loc;
      dispatch(updateUs(userObj));
      setIsUserModalOpen(false);
      setUpdatePlan(false);
      setMerchant(null);
      setModalName(null);
      dispatch(getMerchantList(query));
      dispatch(getMerchantCount(query));
    }
  };

  const onChangePlan = (e) => {
    setPlanId(e.target.value);

    if (adminState.plans != null && adminState.plans.length > 0) {
      for (let i = 0; i < adminState.plans.length; i++) {
        let item = adminState.plans[i];
        if (item._id === e.target.value) {
          setPlanCode(item.code);
        }
      }
    }
  };

  const docPath = (doc) => {
    let path = doc;

    if (path.indexOf("https://") > -1) {
      return path;
    } else {
      return BASE_API_URL + '/' + path;
    }
  };

  useEffect(()=>{    
    if(adminState.needRefresh){  
      dispatch(getMerchantList(query));
      dispatch(getMerchantCount(query));
    }
  },[dispatch, adminState.needRefresh])

  return (
    <div className="col-md-12">
      <div className="col-md-12">
        <div className="row">
          <div
            className={
              tab === "PENDING"
                ? "col-md-2 active ONM_button_pending_merch"
                : "col-md-2 ONM_button_pending_merch"
            }
            onClick={() => updatePending("PENDING")}
          >
            Pending for Approval
          </div>
          <div
            className={
              tab === "ACTIVE"
                ? "col-md-2 active ONM_button_pending_merch ml-2"
                : "col-md-2 ONM_button_pending_merch ml-2"
            }
            onClick={() => updatePending("ACTIVE")}
          >
            Active Merchants
          </div>
          <div
            className={
              tab === "REJECTED"
                ? "col-md-2 active ONM_button_pending_merch ml-2"
                : "col-md-2 ONM_button_pending_merch ml-2"
            }
            onClick={() => updatePending("REJECTED")}
          >
            Rejected Merchants
          </div>
          <div
            className={
              tab === "INACTIVE"
                ? "col-md-2 active ONM_button_pending_merch ml-2"
                : "col-md-2 ONM_button_pending_merch ml-2"
            }
            onClick={() => updatePending("INACTIVE")}
          >
            Inactive Merchants
          </div>
          <div className="col-md-3 text-right mt-2">
            <Pagination
              activePage={adminState.features.page}
              itemsCountPerPage={adminState.features.ps}
              totalItemsCount={adminState.merchantCount}
              pageRangeDisplayed={5}
              onChange={handlePageChange.bind(this)}
              itemClass="page-item"
              linkClass="page-link"
              innerClass="pagination ml-auto mr-auto"
              prevPageText="Prev"
              nextPageText="Next"    
              hideFirstLastPages={true}          
            />
          </div>
        </div>
      </div>

      <Modal isOpen={isModalOpen} style={customStyles50}>
        <div className="col-md-12">
          <div className="row">
            <div className="col-md-12">
              Are you sure you want to mark this merchant {modalName} ?
            </div>
            <div className="col-md-12 mt-2">
              <button
                className="btn btn-dark ml-2 float-right"
                onClick={() => closeModal()}
              >
                Close
              </button>
              {modalName === "active" && (
                <button
                  className="btn btn-primary float-right"
                  onClick={() => markActiveAndClose()}
                >
                  Activate
                </button>
              )}
              {modalName === "rejected" && (
                <button
                  className="btn btn-primary float-right"
                  onClick={() => markRejectedAndClose()}
                >
                  Reject
                </button>
              )}
              {modalName === "inactive" && (
                <button
                  className="btn btn-primary float-right"
                  onClick={() => markInactiveAndClose()}
                >
                  Mark Inactive
                </button>
              )}
              {modalName === "delete" && (
                <button
                  className="btn btn-primary float-right"
                  onClick={() => deleteMerchantAndClose()}
                >
                  Delete Merchant
                </button>
              )}
            </div>
          </div>
        </div>
      </Modal>

      <Modal isOpen={isUserModalOpen} style={customStyles}>
        <div className="col-md-12">
          <div className="row">
            {merchant && !updatePlan && (
              <>
                <div className="col-md-12 p-0 ONM_modal_header">
                  <h3>Merchant Details</h3>
                </div>

                <div className="col-md-6">
                  <label>First Name: {merchant.firstName}</label>
                </div>
                <div className="col-md-6">
                  <label>Last Name: {merchant.lastName}</label>
                </div>
                <div className="col-md-6">
                  <label>Email: {merchant.email}</label>
                </div>
                <div className="col-md-6">
                  <label>Phone: {merchant.phone}</label>
                </div>
                <div className="col-md-6">
                  <label>Store Name: {merchant.storeName}</label>
                </div>
                <div className="col-md-6">
                  <label>Address: {merchant.address}</label>
                </div>
                <div className="col-md-6">
                  <label>Gst: {merchant.gstno}</label>
                </div>
                <div className="col-md-6">
                  <label>Plan: {merchant.planCode}</label>
                </div>
                <div className="col-md-6">
                  <label>Paid: {merchant.paid ? "True" : "False"}</label>
                </div>
                <div className="col-md-6">
                  <label>Document: {merchant.documentId!=null ? <a target="_blank" href={docPath(merchant.documentId.path)}>{merchant.documentId.name}</a> : "False"}</label>
                </div>
                <div className="col-md-12 mt-2">
                  <button
                    className="btn btn-dark ml-2 float-right"
                    onClick={() => closeUserModal()}
                  >
                    Close
                  </button>
                  {merchant.active && (
                    <button
                      className="btn btn-dark ml-2 float-right"
                      onClick={() => updatingPlan()}
                    >
                      Update Plan
                    </button>
                  )}
                </div>
              </>
            )}
            {merchant && merchant.active && updatePlan && (
              <>
                <div className="col-md-12">
                  <div className="row">
                    <div className="col-md-12">
                      <h3>
                        Updating plan for{" "}
                        {merchant.storeName ||
                          merchant.firstName + " " + merchant.lastName}
                      </h3>
                    </div>
                    <div className="col-md-12">
                      <div className="form-group col-md-6">
                        <label htmlFor="planId">Plans</label>

                        <select
                          name="planId"
                          value={planId}
                          onChange={onChangePlan}
                          className="form-control"
                        >
                          <option value="">Choose your plan</option>
                          {adminState.plans != null &&
                            adminState.plans.map((plan) => {
                              return (
                                <option key={plan._id} value={plan._id}>
                                  {plan.name}
                                </option>
                              );
                            })}
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-12 mt-2">
                  <button
                    className="btn btn-dark ml-2 float-right"
                    onClick={() => closeUserModal()}
                  >
                    Close
                  </button>
                  <button
                    className="btn btn-dark ml-2 float-right"
                    onClick={() => backToView()}
                  >
                    Back
                  </button>
                  <button
                    className="btn btn-primary ml-2 float-right"
                    onClick={() => savePlanForMerchant()}
                  >
                    Save Plan
                  </button>
                </div>
              </>
            )}
          </div>
        </div>
      </Modal>

      <div className="table-responsive mt-2">
        <table className="table">
          <thead>
            <tr>
              <th>Name</th>
              <th>Email</th>
              <th>Store Name</th>
              <th>GST</th>
              <th>Address</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {adminState.merchantList != null
              ? adminState.merchantList.map((merchant) => {
                  return (
                    <tr key={merchant._id}>
                      <td>
                        {merchant.firstName} {merchant.lastName}
                      </td>
                      <td>{merchant.email}</td>
                      <td>{merchant.storeName}</td>
                      <td>{merchant.gstno}</td>
                      <td style={{ width: "35%" }}>{merchant.address}</td>                      <td>
                        {(tab === "PENDING" || tab === "REJECTED" || tab === "INACTIVE") && (
                          <FaCheck
                            className="ONM_icons"
                            data-tip="Mark Active"
                            onClick={() => openModal(merchant, "active")}
                          ></FaCheck>
                        )}
                        {(tab === "PENDING" || tab === "ACTIVE") && (
                          <FaTimes
                            className="ONM_icons"
                            data-tip="Mark Rejected"
                            onClick={() => openModal(merchant, "rejected")}
                          ></FaTimes>
                        )}
                        {(tab === "ACTIVE" || tab === "REJECTED") && (
                          <FaStop
                            className="ONM_icons"
                            data-tip="Mark Inactive"
                            onClick={() => openModal(merchant, "inactive")}
                          ></FaStop>
                        )}
                        {(tab === "INACTIVE") && (
                          <FaTimes
                            className="ONM_icons"
                            data-tip="Delete Merchant"
                            onClick={() => openModal(merchant, "delete")}
                          ></FaTimes>
                        )}
                        <FaEye
                          className="ONM_icons"
                          data-tip="View Merchant"
                          onClick={() => openUserModal(merchant)}
                        ></FaEye>
                        <ReactTooltip />
                      </td>
                    </tr>
                  );
                })
              : null}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default AdminMerchant;
