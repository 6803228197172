import React from "react"
// import { getProductsById } from "../services/product.service";

// const getProductById = async ( id ) => {
//     return cart.productId = await getProductsById( id );
// }

const localGetCarts = () => {
    let carts = localStorage.getItem('carts') ? JSON.parse( localStorage.getItem('carts') ) : [] ;

    // let customized_carts = carts.map( (cart) => {

    //     cart = cart.productId
        
        
    //     return cart;
    // })

    // console.log(customized_carts)

    return carts;
}

const localSaveToCart = (cartObj) => {
    let carts = localStorage.getItem('carts') ? JSON.parse( localStorage.getItem('carts') ) : [] ;
      
    carts = [ ...carts, cartObj];

    localStorage.setItem('carts', JSON.stringify(carts));
}

const localCartCount = () => {
    let carts = localStorage.getItem('carts') ? JSON.parse( localStorage.getItem('carts') ) : [] ;
    // console.log(carts);
    return carts.length;
}

const localDeleteCartObj = (id) => {
    let carts = localStorage.getItem('carts') ? JSON.parse( localStorage.getItem('carts') ) : [] ;
    
    // for (const cartObj of carts) {
    //     if( cartObj._id == id ){
    //         index = 
    //     }
    // }
    let updated = carts.map( cartObj => {
        
        if( cartObj._id != id )
            return cartObj;
    })

    // if (index > -1 )
    //     carts.splice(index, 1);

    localStorage.setItem('carts', JSON.stringify(updated));
}

const getUniqueId = () => {
    let uniqueId = localStorage.getItem('cart_unique_id');

    if( ! uniqueId ){
        // uniqueId = 'local_' + Date.now();
        // localStorage.setItem('cart_unique_id', uniqueId);

        uniqueId = mongoObjectId();
        localStorage.setItem('cart_unique_id', uniqueId);
    }

    // return 0;
    return uniqueId;
}

const mongoObjectId = () => {
    let timestamp = (new Date().getTime() / 1000 | 0).toString(16);
    return timestamp + 'xxxxxxxxxxxxxxxx'.replace(/[x]/g, function() {
        return (Math.random() * 16 | 0).toString(16);
    }).toLowerCase();
};

export { 
    localGetCarts, 
    localSaveToCart, 
    localCartCount, 
    localDeleteCartObj, 
    getUniqueId
};