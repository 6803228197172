import Pagination from "react-js-pagination";
import ProductList from "../../helpers/ProductList";
import ReactLoading from "react-loading";

const SearchProduct = (props) => {
  return (
    <div className="col-md-12">
      {props.loading && (
        <div className="ONM_loaders text-center">
          {/* <FaSpinner className="ONM_loader_icon" size={100}></FaSpinner> */}
          <ReactLoading
            type={"spinningBubbles"}
            color={"#fff"}
            height={"10%"}
            width={"10%"}
            className="ONM_react_loader"
          />
        </div>
      )}

      <div className="row">
        <div className="col-md-12 mt-3">
          <div className="col-md-12 mt-5 mb-5">
            <div className="row">
              {/* <div className="col-md-12 mt-0">
                <header className="page-header">
                  <h1>Search: {props.keyWord}</h1>
                </header>
              </div> */}

              {props.items ? (
                <ProductList
                  items={props.items}
                  onStoreUpdate={props.onStoreUpdate}
                  addToWishlist={props.addToWishlist}
                  addToCart={props.addToCart}
                  userObj={props.userObj}
                  type="search"
                ></ProductList>
              ) : (
                <div className="col-md-12">No products found</div>
              )}

              <div className="col-md-12 mt-5">
                <Pagination
                  activePage={props.page}
                  itemsCountPerPage={props.ps}
                  totalItemsCount={props.count}
                  pageRangeDisplayed={10}
                  onChange={props.handlePageChange.bind(this)}
                  itemClass="page-item"
                  linkClass="page-link"
                  innerClass="pagination ml-auto mr-auto"
                  prevPageText="Prev"
                  nextPageText="Next"
                  hideFirstLastPages={true}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SearchProduct;
