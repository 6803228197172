import { useEffect, useState } from "react";
import "./cart.scss";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteCart,
  saveToCart,
  getCarts,
  getCartsCount,
  productById,
  updateStock,
  updateCartItem,
} from "../../slices/headerSlice";
import { getUniqueId } from "../../helpers/LocalCart";
import WishCartList from "../../helpers/WishCartList";
import { Redirect } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { BASE_URL } from "../../services/constants";
import CommonLayout from "../shop/common-layout";
const Cart = (props) => {
  const headerState = useSelector((state) => state.header);
  const dispatch = useDispatch();
  // const alert = useAlert();

  useEffect(() => {
    if (headerState.user == null) {
      dispatch(getCartsCount(getUniqueId()));
      dispatch(getCarts(getUniqueId()));
    } else {
      dispatch(getCartsCount(headerState.user._id));
      dispatch(getCarts(headerState.user._id));
    }
  }, [dispatch]);

  useEffect(() => {
    if (headerState.cantIncCount) {
      // alert.show("No more stock available !!!");
      // alert.error("No more stock available !!!");
      toast.error("No more stock available !!!");
    }
  }, [headerState.cantIncCount]);

  useEffect(() => {
    if (headerState.cartItemRemoved) {
      // alert.show("No more stock available !!!");
      toast.success("Item removed !!!");
      dispatch(updateCartItem());
      if (headerState.user == null) {
        dispatch(getCartsCount(getUniqueId()));
        dispatch(getCarts(getUniqueId()));
      } else {
        dispatch(getCartsCount(headerState.user._id));
        dispatch(getCarts(headerState.user._id));
      }
    }
  }, [headerState.cartItemRemoved]);

  const updateCartObj = (obj, stock) => {
    if (stock <= 0) {
      dispatch(deleteCart(obj._id));
    } else {
      // const cartObj = {
      //   productId: obj.productId._id,
      //   userId: obj.userId != null ? obj.userId._id : getUniqueId(),
      //   stock: stock,
      //   update: 1
      // };

      // dispatch(saveToCart(cartObj));
      dispatch(updateStock({ item: obj, stock: stock }));
      dispatch(productById(obj.productId._id));
    }
  };

  const deleteCartFromList = (cart) => {
    dispatch(deleteCart(cart._id));
  };

  return (
    <CommonLayout parent="home" title="Cart">
      <div className="padding__class">
        <div className="">
          <div className="col-md-12">
            {headerState.cartsList && headerState.cartsList.length > 0 ? (
              <WishCartList
                items={headerState.cartsList}
                updateCartObj={updateCartObj}
                deleteCartFromList={deleteCartFromList}
                showAddToCart={true}
              ></WishCartList>
            ) : (
              <div className="container">
                <div className="row">
                  <div
                    className="empty__Cart"
                    style={{ textAlign: "center", width: "100%" }}
                  >
                    <img
                      className=""
                      style={{ maxWidth: "80px" }}
                      src={BASE_URL + "files/basket.png"}
                    />

                    <span style={{ fontSize: "16px", fontWeight: "bold" }}>
                      Your Onmee cart is empty
                    </span>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </CommonLayout>
  );
};

export default Cart;
