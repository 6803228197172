import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  bannerList,
  sliderList,
  bestOfferPercategory,
  bestOfferPercategoryFashion,
  bestOfferPercategoryAppliances,
  bestOfferPercategoryGrocery,
  bestOfferPercategoryKids,
  bestOfferPercategorySwadeshi,
  updateLoggedInUserHome,
} from "../../slices/homeSlice";
import Paragraph from "../common/Paragraph";
import { updateLocation } from "../../slices/headerSlice";
import FashionHome from "../../helpers/FashionHome";
import "./Home.scss";
import MainBanner from "./MainBanner";
import OfferHome from "./OfferHome";
import Offer from "../common/Service/service1";
import OnmeeCarousel from "../../helpers/OnmeeCarousel";
import SliderCarousel from "../../helpers/SliderCarousel";
import StaticHomeBanner from "./StaticHomeBanner";
import StoreHome from "./StoreHome";
import Banner from "../banner/Banner";
import Sliders from "../common/Blog/blog1";
const Home = () => {
  const dispatch = useDispatch();
  const homeState = useSelector((state) => state.home);
  const headerState = useSelector((state) => state.header);
  console.log(homeState.bannersRow3, "banner row");
  const refreshHome = () => {
    dispatch(bannerList("HOME"));
    dispatch(sliderList());
    dispatch(bestOfferPercategory("Electronics"));
    dispatch(bestOfferPercategoryFashion("Fashion"));
    dispatch(bestOfferPercategoryAppliances("Appliances"));
    dispatch(bestOfferPercategoryGrocery("Grocery"));
    dispatch(bestOfferPercategoryKids("Kids"));
    dispatch(bestOfferPercategorySwadeshi("Furnitures"));
  };

  useEffect(() => {
    refreshHome();
  }, [dispatch]);

  useEffect(() => {
    if (headerState.updateLocation) {
      refreshHome();
      dispatch(updateLocation(0));
    }
  }, [headerState.updateLocation]);

  // if(homeState.user != null && homeState.user.roles && homeState.user.roles.includes("ROLE_MERCHANT"))
  // {
  //   return <Redirect to="/merchant"></Redirect>;
  // }

  console.log(homeState.bannersRow1, "bannerss");

  return (
    <div>
      <div className="col-md-12 px-0">
        <div className="home__banner">
          {/* <SliderCarousel showThumbs={false} items={homeState.sliders}></SliderCarousel> */}
          <OnmeeCarousel
            showThumbs={false}
            items={homeState.bannersRow1}
          ></OnmeeCarousel>

          {/* <Banner items={homeState.bannersRow1} /> */}
        </div>
      </div>
      <div className="col-md-12 mt-5">
        <MainBanner banners={homeState.fashionBanner}></MainBanner>

        {/* <Paragraph
          title="title1 section-t-space"
          inner="title-inner1"
          hrClass={false}
        /> */}
        <Sliders
          type="fashion"
          title="title1"
          inner="title-inner1"
          items={homeState.fashionList}
          heading="Best offer's on Fashion"
        />
        {/* <FashionHome
          banners={homeState.fashionBanner}
          items={homeState.fashionList}
          name="Best offer's on Fashion"
        ></FashionHome> */}
      </div>
      <div className="mt-5">
        <Offer
          sectionClass="border-section small-section"
          tittle="Today's Offer"
        />
      </div>

      {/* <OfferHome></OfferHome> */}
      {/* <StoreHome
        items={homeState.electronicList}
        headerName="Best Offers on Electronics"
      /> */}
      <Sliders
        type="fashion"
        title="title1"
        inner="title-inner1"
        items={homeState.electronicList}
        heading="Best Offers on Electronics"
      />
      {homeState.bannersRow3 ? (
        <StaticHomeBanner items={homeState.bannersRow3}></StaticHomeBanner>
      ) : null}
      <Sliders
        type="fashion"
        title="title1"
        inner="title-inner1"
        items={homeState.swadeshiList}
        heading="Best Offers on Furnitures"
      />
      {/* <StoreHome
        items={homeState.swadeshiList}
        headerName="Best Offers on Furnitures"
      /> */}
      {homeState.bannersRow4 ? (
        <StaticHomeBanner items={homeState.bannersRow4}></StaticHomeBanner>
      ) : null}

      <Sliders
        type="fashion"
        title="title1"
        inner="title-inner1"
        items={homeState.kidsList}
        heading="Best Offers on Kids"
      />
      {homeState.bannersRow5 ? (
        <StaticHomeBanner items={homeState.bannersRow5}></StaticHomeBanner>
      ) : null}
      <Sliders
        type="fashion"
        title="title1"
        inner="title-inner1"
        items={homeState.groceryList}
        heading="Best Offers on Grocery"
      />

      {homeState.bannersRow6 ? (
        <StaticHomeBanner items={homeState.bannersRow6}></StaticHomeBanner>
      ) : null}
      <div className="mb-4">
        <Sliders
          type="fashion"
          title="title1"
          inner="title-inner1"
          items={homeState.appliancesList}
          heading="Best Offers on Appliances"
        />
      </div>
    </div>
  );
};

export default Home;
