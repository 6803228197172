import { Link } from "react-router-dom"

const ProductCard = (props) => {
    return (
        <div className="card col-md-3" >
            {/* <img className="card-img-top" src="img_avatar1.png" alt="Card image"/> */}
            <div className="card-body">
                <h4 className="card-title">
                    <Link to={"/product/"+props.item._id}>{props.item.name}</Link>
                </h4>
                <p className="card-text">{props.item.price}</p>
            </div>
        </div>
    )
};

export default ProductCard;