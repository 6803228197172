import { Link } from "react-router-dom";
import { BASE_URL, BASE_API_URL } from "../../services/constants";

const StaticHomeBanner = (props) => {
  let baseUri = BASE_URL;
  let urlToClick = null;

  const imgPath = (img) => {
    let path = img;

    if (path.indexOf("https://") > -1) {
    return path;
    } else {
    return BASE_API_URL + '/' + path;
    }
  };

  if (props.items.storeId) {
    urlToClick = "/category/" + props.items.mainCategoryId;

    if (props.items.subCategoryId) {
      urlToClick = urlToClick + "?subCatId=" + props.items.subCategoryId;
    }

    if (urlToClick.indexOf("?") > -1) {
      urlToClick = urlToClick + "&uId=" + props.items.storeId._id;
    } else {
      urlToClick = urlToClick + "?uId=" + props.items.storeId._id;
    }

    if (props.items.childCategoryId) {
      if (urlToClick.indexOf("?") > -1) {
        urlToClick = urlToClick + "&childCatId=" + props.items.childCategoryId;
      } else {
        urlToClick = urlToClick + "?childCatId=" + props.items.childCategoryId;
      }
    }
  }

  const adsBanner =
    props.items && props.items.imageId && urlToClick ? (
      <Link to={urlToClick}>
        <img src={imgPath(props.items.imageId.path)} alt="banner" />
      </Link>
    ) : (
      props.items &&
      props.items.imageId && (
        <img src={imgPath(props.items.imageId.path)} alt="banner" />
      )
    );

  return (
    <div className="col-md-12 mt-3 pt-3 pb-3 ONM_banner-container-parent">
      <div className="container ONM_banner-container">{adsBanner}</div>
    </div>
  );
};

export default StaticHomeBanner;
