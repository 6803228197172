import axios from "axios";
import authHeader from "./auth-header";
import { BASE_API_URL } from "./constants";

const API_URL = BASE_API_URL + "/api/review/";

export const saveReviews = (obj) => {
  return axios.post(API_URL + "save", obj, { headers: authHeader() });
};

export const getReviewList = (productId, features) => {
  let url = API_URL + "list?productId=" + productId;

  if (features != null) {
    url = url + "&sidx=" + features.sidx + "&ps=" + features.ps;
  }

  return axios.get(url, { headers: authHeader() });
};

export const getReviewCount = (productId) => {
  let url = API_URL + "count?productId=" + productId;
  return axios.get(url, { headers: authHeader() });
};

export const deleteReview = (obj) => {
  return axios.post(API_URL + "delete", obj, { headers: authHeader() });
};
