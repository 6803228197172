import { useEffect, useRef, useState } from "react";
import Pagination from "react-js-pagination";
import { useDispatch, useSelector } from "react-redux";
import {
  productList,
  productCount,
  updatePagination,
  getAllCategories,
  getSubCategories,
  resetSubCatList,
  uploadImage,
  removeImage,
  resetImages,
  saveProduct,
  updateImages,
  toBeDeleted,
  deleteProd,
  getAllFilters1,
  getBrandsByCat,
  saveActivityObj,
  updateMainCatId,
  updateCatId,
  updateSubCatId,
  updateChildCatId,
  updateBrand,
  updatePs,
  resetFilter,
  resetProductSaved,
  resetUploadError,
} from "../../slices/merchantSlice";
import { FaPlusSquare, FaTimes } from "react-icons/fa";

import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import Select from "react-validation/build/select";
import Textarea from "react-validation/build/textarea";

import CheckButton from "react-validation/build/button";
import ProductOnmeeCarousel from "../../helpers/ProductOnmeeCarousel";
import MerchantProductList from "./MerchantProductList";

import Modal from "react-modal";
import { useAlert } from "react-alert";
import Checkbox from "rc-checkbox";
// import { updateMainCatId } from "../../slices/OfferSlice";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const required = (value) => {
  if (!value) {
    return (
      <div className="alert alert-danger" role="alert">
        This field is required!
      </div>
    );
  }
};

const MerchantProduct = (props) => {
  const merchantState = useSelector((state) => state.merchant);
  const adminState = useSelector((state) => state.admin);
  const dispatch = useDispatch();
  const [isAddProduct, setIsAddProduct] = useState(false);
  const [toBeDeletedProd, setToBeDeletedProd] = useState(false);
  // const alert = useAlert();

  const [selectedCatName, setSelectedCatName] = useState(null);

  const form = useRef();
  const checkBtn = useRef();
  const [subCatList, setSubCatList] = useState(null);
  const [childCatList, setChildCatList] = useState(null);
  const [name, setName] = useState("");
  const [mainCategoryId, setMainCategoryId] = useState("");
  const [categoryId, setCategoryId] = useState("");
  const [subCategoryId, setSubCategoryId] = useState("");
  const [childCategoryId, setChildCategoryId] = useState("");
  const [productOwner, setProductOwner] = useState("");
  const [offer, setOffer] = useState("");
  const [price, setPrice] = useState("");
  const [description, setDescription] = useState("");
  const [id, setId] = useState(null);
  const [stock, setStock] = useState(null);
  const [weight, setWeight] = useState(null);
  const [length, setLength] = useState(null);
  const [height, setHeight] = useState(null);
  const [planCode, setPlanCode] = useState(null);
  const [tax, setTax] = useState(null);
  const [skuNumber, setSkuNumber] = useState(null);
  const [returnable, setReturnable] = useState(null);
  const [color, setColor] = useState(null);

  const [searchKeyword, setSearchKeyword] = useState("");
  const [filterMainCategoryId, setFilterMainCategoryId] = useState("");
  const [filterCategoryId, setFilterCategoryId] = useState("");
  const [filterSubCategoryId, setFilterSubCategoryId] = useState("");
  const [filterChildCategoryId, setFilterChildCategoryId] = useState("");
  const [filterBrand, setFilterBrand] = useState(null);
  const [limitPerPage, setLimitPerPage] = useState(0);

  const [listLoaded, setListLoaded] = useState(0);
  const [isAdmin, setIsAdmin] = useState(0);

  const showProductList = () => {
    if (isAdmin) {
      dispatch(productList({ userId: "", keyword: searchKeyword }));
      dispatch(productCount({ userId: "", keyword: searchKeyword }));

      // console.log('loaded 123')
      setListLoaded(1);
    } else if (
      merchantState.user != null &&
      merchantState.user.roles.includes("ROLE_MERCHANT") &&
      !isAdmin
    ) {
      dispatch(
        productList({ userId: merchantState.user._id, keyword: searchKeyword })
      );
      dispatch(
        productCount({ userId: merchantState.user._id, keyword: searchKeyword })
      );

      setListLoaded(1);
    }
  };

  useEffect(() => {
    if (
      !isAdmin &&
      adminState.user != null &&
      adminState.user.roles.includes("ROLE_ADMIN")
    ) {
      setIsAdmin(1);
    }
  }, [adminState.user]);

  useEffect(() => {
    // if( ! listLoaded )  {
    // console.log('loaded')
    showProductList();
    dispatch(getAllCategories());
    dispatch(getAllFilters1());
    // }
  }, [isAdmin, dispatch]);

  useEffect(() => {
    if (merchantState.imageUploadError) {
      toast.error(merchantState.imageUploadError);
      dispatch(resetUploadError());
    }
  }, [merchantState.imageUploadError]);

  useEffect(() => {
    if (merchantState.productSaved) {
      // alert.show("Product was saved/updated successfully !!!");
      toast.success("Product was saved/updated successfully !!!");
      setIsAddProduct(false);
      dispatch(resetProductSaved());
    }
  }, [merchantState.productSaved]);

  const handlePageChange = (pageNumber) => {
    dispatch(updatePagination(pageNumber));
    if (isAdmin) {
      dispatch(productList({ userId: "", keyword: searchKeyword }));
      dispatch(productCount({ userId: "", keyword: searchKeyword }));
    } else if (
      merchantState.user != null &&
      merchantState.user.roles.includes("ROLE_MERCHANT") &&
      !isAdmin
    ) {
      dispatch(
        productList({ userId: merchantState.user._id, keyword: searchKeyword })
      );
      dispatch(
        productCount({ userId: merchantState.user._id, keyword: searchKeyword })
      );
    }
  };

  const showAddPage = () => {
    setProductOwner(merchantState.user._id);
    setDescription(null);
    setStock(null);
    setWeight(null);
    setLength(null);
    setHeight(null);
    setPlanCode(merchantState.user.planCode);
    setIsAddProduct(true);
    setSubCatList(null);
    setChildCatList(null);
    setMainCategoryId(null);
    setName(null);
    setCategoryId(null);
    setSubCategoryId(null);
    setChildCategoryId(null);
    setOffer(null);
    setPrice(null);
    setTax(null);
    setSkuNumber(null);
    setReturnable(true);
    setId(null);
    setColor(null);
    setCaseCover(null);
    setCaseCoverCaseMaterialType(null);
    setCaseFeature(null);
    setScreenProtector(null);
    setBatteryType(null);
    setTvPlatforms(null);
    setTvDisplayTech(null);
    setTvHdFormats(null);
    setTvModelYears(null);
    setTvConnectivity(null);
    setCpuType(null);
    setUsage(null);
    setOperatingSystem(null);
    setMemorySize(null);
    setBrand(null);
    dispatch(resetSubCatList());
    dispatch(resetImages());
  };

  const editPage = (prod) => {
    if (!prod.userId) {
      toast.error("Cannot edit this product, the user does not exists!");
      return;
    }

    setSubCatList(null);
    setChildCatList(null);
    setId(prod._id);
    let mCId =
      prod.mainCategoryId && prod.mainCategoryId._id
        ? prod.mainCategoryId._id
        : prod.mainCategoryId;
    setMainCategoryId(mCId);
    let mainCatObj1 = null;

    if (merchantState.allCats && merchantState.allCats.length > 0) {
      let found = false;
      for (let i = 0; i < merchantState.allCats.length && !found; i++) {
        let temp = merchantState.allCats[i];

        if (temp._id === mCId) {
          found = true;
          mainCatObj1 = temp;
        }
      }
    }

    dispatch(getSubCategories(mCId));
    setName(prod.name);
    let ctId =
      prod.categoryId && prod.categoryId._id
        ? prod.categoryId._id
        : prod.categoryId;
    setCategoryId(ctId);
    if (
      mainCatObj1 != null &&
      mainCatObj1.categories != null &&
      mainCatObj1.categories.length > 0
    ) {
      let found = false;
      for (let i = 0; i < mainCatObj1.categories.length && !found; i++) {
        let a = mainCatObj1.categories[i];

        if (a._id === ctId) {
          found = true;
          setSubCatList(a.subCategories);
        }
      }
    }
    let sCId =
      prod.subCategoryId && prod.subCategoryId._id
        ? prod.subCategoryId._id
        : prod.subCategoryId;
    setSubCategoryId(sCId);

    if (mainCatObj1.categories != null && mainCatObj1.categories.length > 0) {
      let found = false;
      for (let i = 0; i < mainCatObj1.categories.length && !found; i++) {
        let a = mainCatObj1.categories[i];

        for (let j = 0; j < a.subCategories.length && !found; j++) {
          let b = a.subCategories[j];

          if (b._id === sCId) {
            found = true;
            setChildCatList(b.childCategories);
          }
        }
      }
    }
    setBrand(prod.brand);
    let cCId =
      prod.childCategoryId && prod.childCategoryId._id
        ? prod.childCategoryId._id
        : prod.childCategoryId;
    setChildCategoryId(cCId);

    if (ctId != null && sCId != null && cCId == null) {
      dispatch(getBrandsByCat({ subCatId: sCId }));
    } else if (ctId != null && sCId != null && cCId != null) {
      dispatch(getBrandsByCat({ childCatId: cCId }));
    } else if (ctId != null && sCId == null) {
      dispatch(getBrandsByCat({ catId: ctId }));
    }

    // console.log(prod);
    setProductOwner(prod.userId._id);
    setOffer(prod.offer);
    setPrice(prod.price);
    setDescription(prod.description);
    setStock(prod.stock);
    setWeight(prod.weight);
    setLength(prod.length);
    setHeight(prod.height);
    setPlanCode(prod.userId.planCode);
    setTax(prod.tax);
    setSkuNumber(prod.skuNumber);
    setReturnable(prod.returnable);
    setIsAddProduct(true);
    setColor(prod.color);
    setCaseCover(prod.caseCover);
    setCaseCoverCaseMaterialType(prod.caseCoverCaseMaterialType);
    setCaseFeature(prod.caseFeature);
    setScreenProtector(prod.screenProtector);
    setBatteryType(prod.batteryType);
    setTvPlatforms(prod.tvPlatForms);
    setTvDisplayTech(prod.tvDisplaytech);
    setTvHdFormats(prod.tvHdFormats);
    setTvModelYears(prod.tvModelYears);
    setTvConnectivity(prod.tvConnectivity);
    setCpuType(prod.cpuType);
    setUsage(prod.usage);
    setOperatingSystem(prod.operatingSystem);
    setMemorySize(prod.memorySize);
    dispatch(updateImages(prod.imageId));
  };

  const backToList = () => {
    setIsAddProduct(false);
  };

  const onChangeName = (e) => {
    setName(e.target.value);
  };

  const onChangeMainCatId = (e) => {
    setMainCategoryId(e.target.value);
    setCategoryId(null);
    setSubCategoryId(null);
    setChildCategoryId(null);
    dispatch(getSubCategories(e.target.value));
  };

  const onChangeCatId = (e) => {
    setCategoryId(e.target.value);
    if (merchantState.subCats != null && merchantState.subCats.length > 0) {
      let found = false;
      for (let i = 0; i < merchantState.subCats.length && !found; i++) {
        let a = merchantState.subCats[i];

        if (a._id === e.target.value) {
          found = true;
          setSubCatList(a.subCategories);
        }
      }
    }
    dispatch(getBrandsByCat({ catId: e.target.value }));
  };

  const onChangeSubCatId = (e) => {
    setSubCategoryId(e.target.value);
    if (merchantState.subCats != null && merchantState.subCats.length > 0) {
      let found = false;
      for (let i = 0; i < merchantState.subCats.length && !found; i++) {
        let a = merchantState.subCats[i];

        for (let j = 0; j < a.subCategories.length && !found; j++) {
          let b = a.subCategories[j];

          if (b._id === e.target.value) {
            setSelectedCatName(b.name);
            found = true;
            setChildCatList(b.childCategories);
          }
        }
      }
    }

    if (brand === null) {
      dispatch(getBrandsByCat({ subCatId: e.target.value }));
    }
  };

  const onChangeChildCatId = (e) => {
    setChildCategoryId(e.target.value);

    if (brand === null) {
      dispatch(getBrandsByCat({ childCatId: e.target.value }));
    }
  };

  const onChangePrice = (e) => {
    setPrice(e.target.value);
  };

  const onChangeOffer = (e) => {
    setOffer(e.target.value);
  };

  const onChangeDescription = (e) => {
    setDescription(e.target.value);
  };

  const onChangeStock = (e) => {
    setStock(e.target.value);
  };

  const onChangeWeight = (e) => {
    setWeight(e.target.value);
  };

  const onChangeLength = (e) => {
    setLength(e.target.value);
  };

  const onChangeHeight = (e) => {
    setHeight(e.target.value);
  };

  const onChangeTax = (e) => {
    setTax(e.target.value);
  };

  const onChangeSkuNumber = (e) => {
    setSkuNumber(e.target.value);
  };

  const removeProductImage = (id) => {
    dispatch(removeImage(id));
    // dispatch(resetImages());
  };

  const uploadHandler = (event) => {
    let imageFormData = new FormData();
    // console.log(event.target.files);
    imageFormData.append("imageData", event.target.files[0]);

    dispatch(uploadImage(imageFormData));
  };

  const handleSave = (e) => {
    e.preventDefault();
    form.current.validateAll();
    if (checkBtn.current.context._errors.length === 0) {
      let productObj = {
        name: name,
        description: description,
        price: price,
        userId: productOwner,
        offer: offer,
        stock: stock,
        weight: weight,
        length: length,
        height: height,
        imageId: merchantState.images,
        mainCategoryId: mainCategoryId,
        planCode: planCode,
        categoryId: categoryId,
        subCategoryId: subCategoryId,
        childCategoryId: childCategoryId,
        tax: tax,
        skuNumber: skuNumber,
        returnable: returnable,
        caseCover: caseCover,
        caseCoverCaseMaterialType: caseCoverCaseMaterialType,
        caseFeature: caseFeature,
        screenProtector: screenProtector,
        batteryType: batteryType,
        tvPlatForms: tvPlatForms,
        tvDisplaytech: tvDisplaytech,
        tvHdFormats: tvHdFormats,
        tvModelYears: tvModelYears,
        tvConnectivity: tvConnectivity,
        cpuType: cpuType,
        usage: usage,
        operatingSystem: operatingSystem,
        memorySize: memorySize,
        brand: brand,
      };

      if (id != null) {
        productObj.id = id;
      }

      // console.log(productObj);

      dispatch(saveProduct(productObj));

      let userId = null;
      if (isAdmin) {
        userId = adminState.user._id;
      } else {
        userId = merchantState.user._id;
      }

      let activityObj = {
        userId: userId,
        title: "Create a product: " + name,
        type: "create_product",
        fields: productObj,
      };

      if (id != null) {
        activityObj.title = "Update a product: " + name;
        activityObj.type = "update_product";
      }

      dispatch(saveActivityObj(activityObj));
    }
  };

  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      zIndex: "999",
    },
  };

  Modal.setAppElement("#root");
  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const deleteProduct = (prod) => {
    openModal();
    setToBeDeletedProd(prod);
    dispatch(toBeDeleted(prod));
  };

  const deleteAndCloseModal = () => {
    dispatch(deleteProd(toBeDeletedProd));
    setToBeDeletedProd(null);
    let userId = null;
    if (isAdmin) {
      userId = adminState.user._id;
    } else {
      userId = merchantState.user._id;
    }

    let activityObj = {
      userId: userId,
      title: "Delete a product:" + toBeDeletedProd.name,
      type: "delete_product",
      fields: toBeDeletedProd,
    };

    dispatch(saveActivityObj(activityObj));

    closeModal();
  };

  const handleCheckbox = (e) => {
    setReturnable(e.target.checked);
    // console.log(e.target.checked);
  };

  const [caseCover, setCaseCover] = useState(null);
  const [caseCoverCaseMaterialType, setCaseCoverCaseMaterialType] =
    useState(null);
  const [caseFeature, setCaseFeature] = useState(null);
  const [screenProtector, setScreenProtector] = useState(null);
  const [batteryType, setBatteryType] = useState(null);
  const [tvPlatForms, setTvPlatforms] = useState(null);
  const [tvDisplaytech, setTvDisplayTech] = useState(null);
  const [tvHdFormats, setTvHdFormats] = useState(null);
  const [tvModelYears, setTvModelYears] = useState(null);
  const [tvConnectivity, setTvConnectivity] = useState(null);
  const [cpuType, setCpuType] = useState(null);
  const [usage, setUsage] = useState(null);
  const [operatingSystem, setOperatingSystem] = useState(null);
  const [memorySize, setMemorySize] = useState(null);
  const [brand, setBrand] = useState(null);
  const [typingTimeout, setTypingTimeout] = useState(0);

  const onChangeColor = (e) => {
    setColor(e.target.value);
  };

  const onChangeCaseCover = (e) => {
    setCaseCover(e.target.value);
  };

  const onChangeCaseCoverCaseMaterialType = (e) => {
    setCaseCoverCaseMaterialType(e.target.value);
  };

  const onChangeCaseFeature = (e) => {
    setCaseFeature(e.target.value);
  };

  const onChangeScreenProtector = (e) => {
    setScreenProtector(e.target.value);
  };

  const onChangeBatteryType = (e) => {
    setBatteryType(e.target.value);
  };

  const onChangeTvPaltforms = (e) => {
    setTvPlatforms(e.target.value);
  };

  const onChangeTvDisplaytech = (e) => {
    setTvDisplayTech(e.target.value);
  };

  const onChangeTvHdFormats = (e) => {
    setTvHdFormats(e.target.value);
  };

  const onChangeTvModelYears = (e) => {
    setTvModelYears(e.target.value);
  };

  const onChangeTvConnectivity = (e) => {
    setTvConnectivity(e.target.value);
  };

  const onChangeCpuType = (e) => {
    setCpuType(e.target.value);
  };

  const onChangeUsage = (e) => {
    setUsage(e.target.value);
  };

  const onChangeOperatingSystem = (e) => {
    setOperatingSystem(e.target.value);
  };

  const onChangeMemorySize = (e) => {
    setMemorySize(e.target.value);
  };

  const onChangeBrand = (e) => {
    setBrand(e.target.value);
  };

  const doProductSearch = (keyword) => {
    if (adminState.user && adminState.user.roles.includes("ROLE_ADMIN")) {
      dispatch(productList({ userId: "", keyword: keyword }));
      dispatch(productCount({ userId: "", keyword: keyword }));
    } else if (merchantState.user) {
      dispatch(
        productList({ userId: merchantState.user._id, keyword: keyword })
      );
      dispatch(
        productCount({ userId: merchantState.user._id, keyword: keyword })
      );
    }
  };

  const handleKeywordChange = (e) => {
    if (typingTimeout) {
      clearTimeout(typingTimeout);
    }

    let keyword = e.target.value;
    setSearchKeyword(keyword);

    setTypingTimeout(setTimeout(doProductSearch(keyword), 500));
  };

  const onChangeFilterMainCatId = (e) => {
    setFilterMainCategoryId(e.target.value);
    setFilterCategoryId("");
    setFilterSubCategoryId("");
    setFilterChildCategoryId("");
    setFilterBrand("");
    dispatch(getSubCategories(e.target.value));

    dispatch(updateMainCatId(e.target.value));
    dispatch(updateCatId(null));
    dispatch(updateSubCatId(null));
    dispatch(updateChildCatId(null));
    dispatch(updateBrand(null));
    showProductList();
  };

  const onChangeFilterCatId = (e) => {
    setFilterCategoryId(e.target.value);
    if (merchantState.subCats != null && merchantState.subCats.length > 0) {
      let found = false;
      for (let i = 0; i < merchantState.subCats.length && !found; i++) {
        let a = merchantState.subCats[i];

        if (a._id === e.target.value) {
          found = true;
          setSubCatList(a.subCategories);
        }
      }
    }
    dispatch(getBrandsByCat({ catId: e.target.value }));

    dispatch(updateCatId(e.target.value));
    dispatch(updateSubCatId(null));
    dispatch(updateChildCatId(null));
    dispatch(updateBrand(null));
    showProductList();
  };

  const onChangeFilterSubCatId = (e) => {
    setFilterSubCategoryId(e.target.value);
    if (merchantState.subCats != null && merchantState.subCats.length > 0) {
      let found = false;
      for (let i = 0; i < merchantState.subCats.length && !found; i++) {
        let a = merchantState.subCats[i];

        for (let j = 0; j < a.subCategories.length && !found; j++) {
          let b = a.subCategories[j];

          if (b._id === e.target.value) {
            setSelectedCatName(b.name);
            found = true;
            setChildCatList(b.childCategories);
          }
        }
      }
    }

    if (filterBrand === null) {
      dispatch(getBrandsByCat({ subCatId: e.target.value }));
    }

    dispatch(updateSubCatId(e.target.value));
    dispatch(updateChildCatId(null));
    dispatch(updateBrand(null));
    showProductList();
  };

  const onChangeFilterChildCatId = (e) => {
    setFilterChildCategoryId(e.target.value);

    if (filterBrand === null) {
      dispatch(getBrandsByCat({ childCatId: e.target.value }));
    }

    dispatch(updateChildCatId(e.target.value));
    dispatch(updateBrand(null));
    showProductList();
  };

  const onChangeFilterBrand = (e) => {
    setFilterBrand(e.target.value);

    dispatch(updateBrand(e.target.value));
    showProductList();
  };

  const onChangeLimitPerPage = (e) => {
    setLimitPerPage(e.target.value);

    dispatch(updatePs(e.target.value));
    showProductList();
  };

  const isEnableAddProduct = () => {
    if (isAdmin) {
      return true;
    }

    if (merchantState.user) {
      let pickupName = merchantState.user.pickupName;
      let pickupAddressLine1 = merchantState.user.pickupAddressLine1;
      let pickupAddressLine2 = merchantState.user.pickupAddressLine2;
      let pickupPincode = merchantState.user.pickupPincode;
      let pickupPhone = merchantState.user.pickupPhone;

      // console.log(merchantState.user);

      if (isAdmin || merchantState.user.roles.includes("ROLE_ADMIN")) {
        return true;
      }

      if (
        !pickupName ||
        !pickupAddressLine1 ||
        !pickupAddressLine2 ||
        !pickupPincode ||
        !pickupPhone
      ) {
        return false;
      }
    }

    return true;
  };

  const resetFilterHandler = (e) => {
    setSearchKeyword("");
    setFilterMainCategoryId("");
    setFilterCategoryId("");
    setFilterSubCategoryId("");
    setFilterChildCategoryId("");
    setFilterBrand("");
    dispatch(getSubCategories(e.target.value));

    dispatch(updateMainCatId(null));
    dispatch(updateCatId(null));
    dispatch(updateSubCatId(null));
    dispatch(updateChildCatId(null));
    dispatch(updateBrand(null));
    showProductList();
  };

  return (
    <div className="col-md-12">
      <Modal isOpen={isModalOpen} style={customStyles}>
        <FaTimes
          onClick={() => closeModal()}
          className="float-right mb-2"
        ></FaTimes>
        <div className="col-md-12">
          <div className="row">
            <div className="col-md-12">
              Are you sure you want to delete the product ?
            </div>
            <div className="col-md-12 mt-2">
              <button
                className="btn btn-danger ml-2 float-right"
                onClick={() => closeModal()}
              >
                No
              </button>
              <button
                className="btn btn-primary float-right"
                onClick={() => deleteAndCloseModal()}
              >
                Yes
              </button>
            </div>
          </div>
        </div>
      </Modal>

      {!isAddProduct && (
        <div className="row ">
          <div className="col-md-12">
            <h1>Products</h1>
          </div>

          <div className="col-md-12 text-left">
            <div className="row">
              <div className="col-md-4">
                {!isAdmin && (
                  <button
                    className="btn btn-solid mr-4"
                    onClick={() => showAddPage()}
                    disabled={!isEnableAddProduct()}
                  >
                    <FaPlusSquare className="mr-2" size={14}></FaPlusSquare>
                    Add product
                  </button>
                )}
                <button onClick={resetFilterHandler} className="btn btn-reset">
                  Reset Filter
                </button>
                {!isAdmin && !isEnableAddProduct() && (
                  <span
                    style={{
                      display: "block",
                      color: "red",
                      fontSize: 10,
                      fontWeight: "500",
                      fontStyle: "italic",
                    }}
                  >
                    Please complete pickup info to enable add product
                  </span>
                )}
              </div>
              <div className="col-md-8">
                <Pagination
                  activePage={merchantState.features.page}
                  itemsCountPerPage={merchantState.features.ps}
                  totalItemsCount={merchantState.productCount}
                  pageRangeDisplayed={5}
                  onChange={handlePageChange.bind(this)}
                  itemClass="page-item"
                  linkClass="page-link"
                  innerClass="pagination float-right"
                  prevPageText="Prev"
                  nextPageText="Next"
                  hideFirstLastPages={false}
                />
              </div>
            </div>
          </div>

          <Form className="container row mt-3 merchant-form">
            <div className="form-group col-md-4 ">
              <input
                className="form-control"
                type="text"
                value={searchKeyword}
                onChange={handleKeywordChange}
                placeholder="search keywords"
              />
            </div>

            <div className="form-group col-md-2">
              <Select
                name="filterMainCategoryId"
                value={filterMainCategoryId}
                onChange={onChangeFilterMainCatId}
                // validations={[required]}
                className="form-control"
              >
                <option value="">main category</option>
                {merchantState.allCats != null &&
                  merchantState.allCats.map((cat) => {
                    return (
                      <option key={cat._id} value={cat._id}>
                        {cat.name}
                      </option>
                    );
                  })}
              </Select>
            </div>

            {filterMainCategoryId && (
              <div className="form-group col-md-2">
                <Select
                  name="filterCategoryId"
                  value={filterCategoryId}
                  onChange={onChangeFilterCatId}
                  // validations={[required]}
                  className="form-control"
                >
                  <option value="">category</option>
                  {merchantState.subCats != null &&
                    merchantState.subCats.map((cat) => {
                      return (
                        <option key={cat._id} value={cat._id}>
                          {cat.name}
                        </option>
                      );
                    })}
                </Select>
              </div>
            )}

            {subCatList != null && subCatList.length > 0 && filterCategoryId && (
              <div className="form-group col-md-2">
                <Select
                  name="filterSubCategoryId"
                  value={filterSubCategoryId}
                  onChange={onChangeFilterSubCatId}
                  // validations={[required]}
                  className="form-control"
                >
                  <option value="">sub-category</option>
                  {subCatList != null &&
                    subCatList.map((cat) => {
                      return (
                        <option key={cat._id} value={cat._id}>
                          {cat.name}
                        </option>
                      );
                    })}
                </Select>
              </div>
            )}

            {childCatList != null &&
              childCatList.length > 0 &&
              filterSubCategoryId && (
                <div className="form-group col-md-2">
                  <Select
                    name="filterChildCategoryId"
                    value={filterChildCategoryId}
                    onChange={onChangeFilterChildCatId}
                    className="form-control"
                  >
                    <option value="">child-category</option>
                    {childCatList != null &&
                      childCatList.map((cat) => {
                        return (
                          <option key={cat._id} value={cat._id}>
                            {cat.name}
                          </option>
                        );
                      })}
                  </Select>
                </div>
              )}

            {/* {merchantState.brandsList != null &&
            merchantState.brandsList.length > 0 && (
              <div className="form-group col-md-6">
                <Select
                  name="filterBrand"
                  value={filterBrand}
                  onChange={onChangeFilterBrand}
                  className="form-control"
                >
                  <option value="">brand</option>
                  {merchantState.brandsList != null &&
                    merchantState.brandsList.map((cat) => {
                      return (
                        <option key={cat._id} value={cat._id}>
                          {cat.name}
                        </option>
                      );
                    })}
                </Select>
              </div>
            )} */}
          </Form>

          <Form className="container row">
            <div className="form-group col-md-2">
              <span
                style={{
                  float: "left",
                  display: "block",
                  marginRight: "10px",
                  lineHeight: "30px",
                }}
              >
                Limit:{" "}
              </span>
              <Select
                style={{ display: "inline", width: "auto" }}
                name="limitPerPage"
                value={limitPerPage}
                onChange={onChangeLimitPerPage}
                // validations={[required]}
                className="form-control"
              >
                <option value="12">12</option>
                <option value="50">50</option>
                <option value="100">100</option>
              </Select>
            </div>
          </Form>

          {merchantState.productList ? (
            <MerchantProductList
              prodList={merchantState.productList}
              editPage={editPage}
              deleteProduct={deleteProduct}
            ></MerchantProductList>
          ) : (
            <div className="col-md-12" style={{ textAlign: "center" }}>
              No products found
            </div>
          )}
        </div>
      )}

      {isAddProduct && (
        <div className="row">
          <div className="col-md-6">
            <h1>{id != null ? "Edit Product" : "Add Product"}</h1>
          </div>
          <div className="col-md-6">
            <FaTimes
              onClick={() => backToList()}
              className="float-right mb-2 ONM_close_icon"
            ></FaTimes>
          </div>
          <Form onSubmit={handleSave} ref={form} className="merchant-form">
            <div className="col-md-12">
              <div className="row">
                <div className="form-group col-md-12">
                  <div className="col-md-4 align-content-center">
                    <ProductOnmeeCarousel
                      items={merchantState.images}
                      showThumbs={true}
                      removeProductImage={removeProductImage}
                      enableRemove={1}
                    ></ProductOnmeeCarousel>
                  </div>
                  {merchantState.imageUploading ? (
                    <>
                      <div className="spinner-grow text-muted"></div>
                      <div className="spinner-grow text-muted"></div>
                      <div className="spinner-grow text-muted"></div>
                    </>
                  ) : null}
                  <input
                    type="file"
                    name="file"
                    onChange={(e) => uploadHandler(e)}
                  />
                </div>
                <div className="form-group col-md-6">
                  <label htmlFor="name">Name</label>
                  <Input
                    type="text"
                    className="form-control"
                    name="name"
                    value={name}
                    onChange={onChangeName}
                    validations={[required]}
                  />
                </div>

                <div className="form-group col-md-6">
                  <label htmlFor="mainCategoryId">Main Category</label>

                  <Select
                    name="mainCategoryId"
                    value={mainCategoryId}
                    onChange={onChangeMainCatId}
                    validations={[required]}
                    className="form-control"
                  >
                    <option value="">Choose your main category</option>
                    {merchantState.allCats != null &&
                      merchantState.allCats.map((cat) => {
                        return (
                          <option key={cat._id} value={cat._id}>
                            {cat.name}
                          </option>
                        );
                      })}
                  </Select>
                </div>

                {mainCategoryId && (
                  <div className="form-group col-md-6">
                    <label htmlFor="categoryId">Category</label>

                    <Select
                      name="categoryId"
                      value={categoryId}
                      onChange={onChangeCatId}
                      validations={[required]}
                      className="form-control"
                    >
                      <option value="">Choose your category</option>
                      {merchantState.subCats != null &&
                        merchantState.subCats.map((cat) => {
                          return (
                            <option key={cat._id} value={cat._id}>
                              {cat.name}
                            </option>
                          );
                        })}
                    </Select>
                  </div>
                )}

                {subCatList != null && subCatList.length > 0 && categoryId && (
                  <div className="form-group col-md-6">
                    <label htmlFor="subCategoryId">Sub Category</label>

                    <Select
                      name="subCategoryId"
                      value={subCategoryId}
                      onChange={onChangeSubCatId}
                      validations={[required]}
                      className="form-control"
                    >
                      <option value="">Choose your sub-category</option>
                      {subCatList != null &&
                        subCatList.map((cat) => {
                          return (
                            <option key={cat._id} value={cat._id}>
                              {cat.name}
                            </option>
                          );
                        })}
                    </Select>
                  </div>
                )}

                {childCatList != null &&
                  childCatList.length > 0 &&
                  subCategoryId && (
                    <div className="form-group col-md-6">
                      <label htmlFor="childCategoryId">Child Category</label>

                      <Select
                        name="childCategoryId"
                        value={childCategoryId}
                        onChange={onChangeChildCatId}
                        className="form-control"
                      >
                        <option value="">Choose your child-category</option>
                        {childCatList != null &&
                          childCatList.map((cat) => {
                            return (
                              <option key={cat._id} value={cat._id}>
                                {cat.name}
                              </option>
                            );
                          })}
                      </Select>
                    </div>
                  )}

                {merchantState.brandsList != null &&
                  merchantState.brandsList.length > 0 &&
                  childCategoryId && (
                    <div className="form-group col-md-6">
                      <label htmlFor="brandId">Brand</label>

                      <Select
                        name="brandId"
                        value={brand}
                        onChange={onChangeBrand}
                        className="form-control"
                      >
                        <option value="">Choose your brand</option>
                        {merchantState.brandsList != null &&
                          merchantState.brandsList.map((cat) => {
                            return (
                              <option key={cat._id} value={cat._id}>
                                {cat.name}
                              </option>
                            );
                          })}
                      </Select>
                    </div>
                  )}

                <div className="form-group col-md-6">
                  <label htmlFor="offer">Offer</label>
                  <Input
                    type="number"
                    className="form-control"
                    name="offer"
                    value={offer}
                    onChange={onChangeOffer}
                  />
                </div>

                <div className="form-group col-md-6">
                  <label htmlFor="price">Price</label>
                  <Input
                    type="number"
                    className="form-control"
                    name="price"
                    value={price}
                    onChange={onChangePrice}
                  />
                </div>

                <div className="form-group col-md-6">
                  <label htmlFor="offer">Stock</label>
                  <Input
                    type="number"
                    className="form-control"
                    name="stock"
                    value={stock}
                    onChange={onChangeStock}
                  />
                </div>
                <div className="form-group col-md-6">
                  <label htmlFor="tax">Tax (CGST or SGST)</label>
                  <Input
                    type="number"
                    className="form-control"
                    name="tax"
                    value={tax}
                    onChange={onChangeTax}
                  />
                </div>
                <div className="form-group col-md-6">
                  <label htmlFor="skuNumber">SKU Number</label>
                  <Input
                    type="number"
                    className="form-control"
                    name="skuNumber"
                    value={skuNumber}
                    onChange={onChangeSkuNumber}
                  />
                </div>
                <div className="form-group col-md-6">
                  <label htmlFor="weight">Weight (Kg)</label>
                  <Input
                    type="number"
                    className="form-control"
                    name="weight"
                    value={weight}
                    onChange={onChangeWeight}
                    validations={[required]}
                  />
                </div>
                <div className="form-group col-md-6">
                  <label htmlFor="length">Length (Cm)</label>
                  <Input
                    type="number"
                    className="form-control"
                    name="length"
                    value={length}
                    onChange={onChangeLength}
                  />
                </div>
                <div className="form-group col-md-6">
                  <label htmlFor="height">Height (Cm)</label>
                  <Input
                    type="number"
                    className="form-control"
                    name="height"
                    value={height}
                    onChange={onChangeHeight}
                  />
                </div>

                <div className="form-group col-md-6">
                  <label htmlFor="returnable">Returnable</label>
                  <div className="">
                    <Checkbox
                      checked={returnable}
                      onChange={(e) => {
                        handleCheckbox(e);
                      }}
                    ></Checkbox>
                  </div>
                </div>
                {selectedCatName ? (
                  <>
                    {selectedCatName === "Clothing" ||
                    selectedCatName === "Tablets" ||
                    selectedCatName === "Wearable Devices" ||
                    selectedCatName === "Smart TV" ||
                    selectedCatName === "32 inches TV's" ||
                    selectedCatName === "Full HD TV's" ||
                    selectedCatName === "Android Based TV's" ||
                    selectedCatName === "Large Screen TV's" ||
                    selectedCatName === "Desktops" ||
                    selectedCatName === "Monitors" ||
                    selectedCatName === "Mouses" ||
                    selectedCatName === "Keyboards" ||
                    selectedCatName === "Laptop Bags" ||
                    selectedCatName === "Monitors" ||
                    selectedCatName === "Mixers & Grinders" ||
                    selectedCatName === "Juicers" ||
                    selectedCatName === "Rice & Pasta Cookers" ||
                    selectedCatName === "Induction Cooktops" ||
                    selectedCatName === "Expresso Machine" ||
                    selectedCatName === "Oven Toaster Grills" ||
                    selectedCatName === "Kettles" ||
                    selectedCatName === "Toasters" ||
                    selectedCatName === "Irons" ||
                    selectedCatName === "Water Purifiers" ||
                    selectedCatName === "Air Purifiers" ||
                    selectedCatName === "Vacuum Cleaners" ||
                    selectedCatName === "Air Cooler" ||
                    selectedCatName === "Water Heaters" ||
                    selectedCatName === "Humidifiers" ? (
                      <div className="form-group col-md-6">
                        <label htmlFor="Colors">Colors</label>
                        <Select
                          name="Colors"
                          value={color}
                          onChange={onChangeColor}
                          className="form-control"
                        >
                          <option value="">Choose your Colors</option>
                          {merchantState.filterList != null &&
                            merchantState.filterList.COLORS != null &&
                            merchantState.filterList.COLORS.map((filt) => {
                              return (
                                <option key={filt.code} value={filt.code}>
                                  {filt.value}
                                </option>
                              );
                            })}
                        </Select>
                      </div>
                    ) : null}
                    {selectedCatName === "Cases & Covers" ? (
                      <>
                        <div className="form-group col-md-6">
                          <label htmlFor="Colors">Cases & Covers</label>
                          <Select
                            name="CoCases & Coverslors"
                            value={caseCover}
                            onChange={onChangeCaseCover}
                            className="form-control"
                          >
                            <option value="">Choose your Cases & Covers</option>
                            {merchantState.filterList != null &&
                              merchantState.filterList.CASECOVERS != null &&
                              merchantState.filterList.CASECOVERS.map(
                                (filt) => {
                                  return (
                                    <option key={filt.code} value={filt.code}>
                                      {filt.value}
                                    </option>
                                  );
                                }
                              )}
                          </Select>
                        </div>
                        <div className="form-group col-md-6">
                          <label htmlFor="MaterialTypes">Material Types</label>
                          <Select
                            name="Material Types"
                            value={caseCoverCaseMaterialType}
                            onChange={onChangeCaseCoverCaseMaterialType}
                            className="form-control"
                          >
                            <option value="">Choose your Material Types</option>
                            {merchantState.filterList != null &&
                              merchantState.filterList.CASECOVERMATERIALTYPES !=
                                null &&
                              merchantState.filterList.CASECOVERMATERIALTYPES.map(
                                (filt) => {
                                  return (
                                    <option key={filt.code} value={filt.code}>
                                      {filt.value}
                                    </option>
                                  );
                                }
                              )}
                          </Select>
                        </div>
                        <div className="form-group col-md-6">
                          <label htmlFor="CaseFeatures">Case Features</label>
                          <Select
                            name="Case Features"
                            value={caseFeature}
                            onChange={onChangeCaseFeature}
                            className="form-control"
                          >
                            <option value="">Choose your Case Features</option>
                            {merchantState.filterList != null &&
                              merchantState.filterList.CASEFEATURES != null &&
                              merchantState.filterList.CASEFEATURES.map(
                                (filt) => {
                                  return (
                                    <option key={filt.code} value={filt.code}>
                                      {filt.value}
                                    </option>
                                  );
                                }
                              )}
                          </Select>
                        </div>
                      </>
                    ) : null}

                    {selectedCatName === "Screen Protectors" ? (
                      <div className="form-group col-md-6">
                        <label htmlFor="Colors">Material Types</label>
                        <Select
                          name="Colors"
                          value={screenProtector}
                          onChange={onChangeScreenProtector}
                          className="form-control"
                        >
                          <option value="">Choose your Material types</option>
                          {merchantState.filterList != null &&
                            merchantState.filterList
                              .SCREENPROTECTORMATERIALTYPE != null &&
                            merchantState.filterList.SCREENPROTECTORMATERIALTYPE.map(
                              (filt) => {
                                return (
                                  <option key={filt.code} value={filt.code}>
                                    {filt.value}
                                  </option>
                                );
                              }
                            )}
                        </Select>
                      </div>
                    ) : null}
                    {selectedCatName === "Power Banks" ? (
                      <div className="form-group col-md-6">
                        <label htmlFor="Batterytype">Battery Type</label>
                        <Select
                          name="Batterytype"
                          value={batteryType}
                          onChange={onChangeBatteryType}
                          className="form-control"
                        >
                          <option value="">Choose your battery types</option>
                          {merchantState.filterList != null &&
                            merchantState.filterList.BATTERYTYPE != null &&
                            merchantState.filterList.BATTERYTYPE.map((filt) => {
                              return (
                                <option key={filt.code} value={filt.code}>
                                  {filt.value}
                                </option>
                              );
                            })}
                        </Select>
                      </div>
                    ) : null}
                    {selectedCatName === "Smart TV" ||
                    selectedCatName === "32 inches TV's" ||
                    selectedCatName === "Full HD TV's" ||
                    selectedCatName === "Android Based TV's" ||
                    selectedCatName === "Large Screen TV's" ? (
                      <>
                        <div className="form-group col-md-6">
                          <label htmlFor="TVPlat">TV Platforms</label>
                          <Select
                            name="TVPLAT"
                            value={tvPlatForms}
                            onChange={onChangeTvPaltforms}
                            className="form-control"
                          >
                            <option value="">Choose your TV Platforms</option>
                            {merchantState.filterList != null &&
                              merchantState.filterList.TVPLATFORMS != null &&
                              merchantState.filterList.TVPLATFORMS.map(
                                (filt) => {
                                  return (
                                    <option key={filt.code} value={filt.code}>
                                      {filt.value}
                                    </option>
                                  );
                                }
                              )}
                          </Select>
                        </div>
                        <div className="form-group col-md-6">
                          <label htmlFor="DISPTECH">Display Technology</label>
                          <Select
                            name="TVPDISPTECHLAT"
                            value={tvDisplaytech}
                            onChange={onChangeTvDisplaytech}
                            className="form-control"
                          >
                            <option value="">
                              Choose your Display Technology
                            </option>
                            {merchantState.filterList != null &&
                              merchantState.filterList.TVDISPLAYTECH != null &&
                              merchantState.filterList.TVDISPLAYTECH.map(
                                (filt) => {
                                  return (
                                    <option key={filt.code} value={filt.code}>
                                      {filt.value}
                                    </option>
                                  );
                                }
                              )}
                          </Select>
                        </div>
                        <div className="form-group col-md-6">
                          <label htmlFor="HDFORMAT">TV HD Formats</label>
                          <Select
                            name="HDFORMAT"
                            value={tvHdFormats}
                            onChange={onChangeTvHdFormats}
                            className="form-control"
                          >
                            <option value="">Choose your TV HD Formats</option>
                            {merchantState.filterList != null &&
                              merchantState.filterList.TVHDFORMATS != null &&
                              merchantState.filterList.TVHDFORMATS.map(
                                (filt) => {
                                  return (
                                    <option key={filt.code} value={filt.code}>
                                      {filt.value}
                                    </option>
                                  );
                                }
                              )}
                          </Select>
                        </div>
                        <div className="form-group col-md-6">
                          <label htmlFor="TVMODELYEARS">TV Model Years</label>
                          <Select
                            name="TVMODELYEARS"
                            value={tvModelYears}
                            onChange={onChangeTvModelYears}
                            className="form-control"
                          >
                            <option value="">Choose your TV Model Years</option>
                            {merchantState.filterList != null &&
                              merchantState.filterList.TVMODELYEARS != null &&
                              merchantState.filterList.TVMODELYEARS.map(
                                (filt) => {
                                  return (
                                    <option key={filt.code} value={filt.code}>
                                      {filt.value}
                                    </option>
                                  );
                                }
                              )}
                          </Select>
                        </div>

                        <div className="form-group col-md-6">
                          <label htmlFor="TVCONNECTIVITY">Connectivity</label>
                          <Select
                            name="TVCONNECTIVITY"
                            value={tvConnectivity}
                            onChange={onChangeTvConnectivity}
                            className="form-control"
                          >
                            <option value="">Choose your Connectivity</option>
                            {merchantState.filterList != null &&
                              merchantState.filterList.TVCONNECTIVITY != null &&
                              merchantState.filterList.TVCONNECTIVITY.map(
                                (filt) => {
                                  return (
                                    <option key={filt.code} value={filt.code}>
                                      {filt.value}
                                    </option>
                                  );
                                }
                              )}
                          </Select>
                        </div>
                      </>
                    ) : null}

                    {selectedCatName === "Laptops" ? (
                      <>
                        <div className="form-group col-md-6">
                          <label htmlFor="CPUTYPE">CPU type</label>
                          <Select
                            name="CPUTYPE"
                            value={cpuType}
                            onChange={onChangeCpuType}
                            className="form-control"
                          >
                            <option value="">Choose your CPU type</option>
                            {merchantState.filterList != null &&
                              merchantState.filterList.CPUTYPE != null &&
                              merchantState.filterList.CPUTYPE.map((filt) => {
                                return (
                                  <option key={filt.code} value={filt.code}>
                                    {filt.value}
                                  </option>
                                );
                              })}
                          </Select>
                        </div>
                        <div className="form-group col-md-6">
                          <label htmlFor="USAGE">Usage</label>
                          <Select
                            name="USAGE"
                            value={usage}
                            onChange={onChangeUsage}
                            className="form-control"
                          >
                            <option value="">Choose your Usage</option>
                            {merchantState.filterList != null &&
                              merchantState.filterList.USAGE != null &&
                              merchantState.filterList.USAGE.map((filt) => {
                                return (
                                  <option key={filt.code} value={filt.code}>
                                    {filt.value}
                                  </option>
                                );
                              })}
                          </Select>
                        </div>

                        <div className="form-group col-md-6">
                          <label htmlFor="OPERATINGSYSTEM">
                            Operating System
                          </label>
                          <Select
                            name="OPERATINGSYSTEM"
                            value={operatingSystem}
                            onChange={onChangeOperatingSystem}
                            className="form-control"
                          >
                            <option value="">
                              Choose your Operating System
                            </option>
                            {merchantState.filterList != null &&
                              merchantState.filterList.OPERATINGSYSTEM !=
                                null &&
                              merchantState.filterList.OPERATINGSYSTEM.map(
                                (filt) => {
                                  return (
                                    <option key={filt.code} value={filt.code}>
                                      {filt.value}
                                    </option>
                                  );
                                }
                              )}
                          </Select>
                        </div>
                        <div className="form-group col-md-6">
                          <label htmlFor="MEMORYSIZE">Memory Size</label>
                          <Select
                            name="MEMORYSIZE"
                            value={memorySize}
                            onChange={onChangeMemorySize}
                            className="form-control"
                          >
                            <option value="">Choose your Memory Size</option>
                            {merchantState.filterList != null &&
                              merchantState.filterList.MEMORYSIZE != null &&
                              merchantState.filterList.MEMORYSIZE.map(
                                (filt) => {
                                  return (
                                    <option key={filt.code} value={filt.code}>
                                      {filt.value}
                                    </option>
                                  );
                                }
                              )}
                          </Select>
                        </div>
                      </>
                    ) : null}
                  </>
                ) : null}
                <div className="form-group col-md-12">
                  <label htmlFor="DESC">Description</label>
                  <Textarea
                    placeholder="Description for product"
                    value={description}
                    name="description"
                    onChange={onChangeDescription}
                  />
                </div>
              </div>
              <div className="text-right">
                <button className="btn btn-solid btn-block-xs mb-2">
                  <span>Save</span>
                </button>
              </div>
              <CheckButton style={{ display: "none" }} ref={checkBtn} />
            </div>
          </Form>
        </div>
      )}
    </div>
  );
};

export default MerchantProduct;
