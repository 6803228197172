import React, { useEffect, useState } from "react"
import OrderDetail from "../../helpers/OrderDetail";
import { getOrderById } from "../../services/order.service";
import { Link } from "react-router-dom";

const ThankYou = (props) => {

    const [order, setOrder] = useState(null);

    useEffect(()=>{
       if( order == null ){
        getOrderById( props.match.params.id ).then( res => {
            setOrder( res.data.result );
        })
       }
    }, [order]);

    const dateFormatted = () => {
        //September 28, 2021
        let options = { /* weekday: 'long', */ year: 'numeric', month: 'long', day: 'numeric' };
        return new Date( order.createdOn ).toLocaleDateString("en-US", options);
    }

    const orderTotal = () => {
        let total = order.amount;

        return 'Rs ' + total.toFixed(2);
    }

    const deliveryAddress = () => {

        return (
            <address>
                {order.userId.firstName} {order.userId.lastName}<br />
                {order.deliveryAddress.company ? (<>{order.deliveryAddress.company} <br /></>) : '' }
                {order.deliveryAddress.address ? (<>{order.deliveryAddress.address} <br /></>) : '' }
                {order.deliveryAddress.address2 ? (<>{order.deliveryAddress.address2} <br /></>) : '' }
                {order.deliveryAddress.city ? (<>{order.deliveryAddress.city} <br /></>) : '' }
                {order.deliveryAddress.state ? (<>{order.deliveryAddress.state} <br /></>) : '' }
                {order.deliveryAddress.postalCode ? (<>{order.deliveryAddress.postalCode} <br /></>) : '' }
                {order.deliveryAddress.country ? (<>{order.deliveryAddress.country} <br /></>) : '' }
                {order.deliveryAddress.phone ? (<>{order.deliveryAddress.phone} <br /></>) : '' }
                {order.userId.email}
            </address>
        )
    }

    const paymentMethod = () => {
        let method = 'Razorpay';

        return method;
    }

    return (
        <div className="content-container container">
            {order ? (
                <>
                <header className="page-header">
                    <h1 className="text-center">Thank You for your order.</h1>
                </header>

                <div className="col-md-12">
                    Thank you for your payment. Your transaction has been processed, and a receipt for your purchase has been emailed to you.

                    <ul className="mt-2">
                        <li>Order number: <strong>#{order.order_num}</strong></li>
                        <li>Date: <strong>{dateFormatted()}</strong></li>
                        <li>Email: <strong>{order.userId.email}</strong></li>
                        <li>Total: <strong>{orderTotal()}</strong></li>
                        <li>Payment method: <strong>{paymentMethod()}</strong></li>
                    </ul>

                    <div className="order-details mt-4">
                        <h3>Order details</h3>
                        <OrderDetail
                            items={order.products}
                        ></OrderDetail>
                    </div>

                    <div className="billing-address mt-4">
                        <h3>Delivery Address</h3>
                        {deliveryAddress()}
                    </div>
                </div>
                </>) : ( null )}

                <div className="back-to-home text-center mt-4">
                    <Link className="btn btn-primary btn-action" to="/">Back to Shop</Link>
                </div>
        </div>
    )
}

export default ThankYou;