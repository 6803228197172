import { FaMapMarker, FaPhone, FaStar } from "react-icons/fa";
import { Link } from "react-router-dom";
import StoreCard from "../../helpers/StoreCard";

const StoreHome = (props) => {
  const platItem = props.items
    ? props.items.map((item) => {
        return (
          <div key={item._id} className="col-md-3">
            <StoreCard item={item}></StoreCard>
          </div>
        );
      })
    : null;

  return (
    <div className="col-md-12 mt-3">
      <div className="container">
        <h2>{props.headerName}</h2>
        <div className="row">{platItem}</div>
      </div>
    </div>
  );
};

export default StoreHome;
