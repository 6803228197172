import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getOrders,
  getOrdersCount,
  updatePagination,
  resetFeatures,
} from "../../slices/headerSlice";
import Moment from "react-moment";
import Pagination from "react-js-pagination";
import { BASE_URL, BASE_API_URL } from "../../services/constants";
import Modal from "react-modal";
import { getShippingTrack } from "../../services/order.service";
import Layout from "../shop/common-layout";
const Order = (props) => {
  const headerState = useSelector((state) => state.header);
  const dispatch = useDispatch();

  Modal.setAppElement("#root");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [trackingDetail, setTrackingDetail] = useState("");

  const openModal = (awb) => {
    setIsModalOpen(true);
    shippingTrackDetail(awb);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  useEffect(() => {
    dispatch(resetFeatures());
    dispatch(getOrders(headerState.user._id));
    dispatch(getOrdersCount(headerState.user._id));
  }, [dispatch, headerState.user]);

  const handlePageChange = (pageNumber) => {
    dispatch(updatePagination(pageNumber));
    dispatch(getOrders(headerState.user._id));
  };

  let baseUri = BASE_URL;

  const imgPath = (img) => {
    let path = img;

    if (path.indexOf("https://") > -1) {
      return path;
    } else {
      return BASE_API_URL + "/" + path;
    }
  };

  const productPrice = (prod) => {
    let price = 0;

    if (prod.productId.price) price = prod.productId.price.toFixed(2);

    return price;
  };

  const formatAddress = (address) => {
    let name = address.firstName + " " + address.lastName;
    let company = address.company;
    let address1 = address.address;
    let address2 = address.address2;
    let phone = address.phone;
    let state = address.state;
    let city = address.city;
    let country = address.country;
    let postalCode = address.postalCode;

    let printCompany = company ? `(${company}) ` : "";
    let printAdress2 = address2 ? `, ${address2}` : "";

    return `${name} - ${printCompany}${address1}${printAdress2}, ${city}, ${state}, ${country} ${postalCode}`;
    // return address1;
  };

  const shippingTrackDetail = (awb) => {
    // if( isModalOpen ){
    getShippingTrack(awb).then((res) => {
      // console.log(res.data.result)
      setTrackingDetail(res.data.result);
    });
    // }
  };

  const customStyles50 = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      zIndex: "9999",
      width: "50%",
      overflow: "auto",
      maxHeight: "80%",
    },
  };

  return (
    <Layout title="order" parent="home">
      <div className="container">
        <Modal isOpen={isModalOpen} style={customStyles50}>
          <div className="col-md-12">
            <div className="close-modal">
              <button onClick={() => closeModal()}>&times;</button>
            </div>
            <div className="row">
              <div className="col-md-12 p-0 ONM_modal_header">
                <h3>Shipping Track</h3>
              </div>
              <div className="col-md-12 align-content-center p-0">
                <div className="form-group mt-2">
                  <div className="col-md-12 row">
                    {/* {trackingDetail && trackingDetail["ecomexpress-objects"].object.field.map( (obj, i)=>(
                    <>
                      {obj.name == 'scans' ? (
                        <>
                        {obj.object.field && obj.object.field.map( (scan) => (
                          <>
                            <div className="col-md-6">
                              <label><strong>{scan.name}:</strong> {scan.$t}</label>
                            </div>
                          </>
                        ))}
                        </>
                      ): (
                        <div className="col-md-6">
                          <label><strong>{obj.name}:</strong> {obj.$t}</label>
                        </div>
                      ) }
                    </>
                  ))} */}
                    {trackingDetail &&
                    trackingDetail["ecomexpress-objects"].object ? (
                      trackingDetail["ecomexpress-objects"].object[0].field.map(
                        (obj, i) => (
                          <>
                            {obj["$"].name == "scans" ? (
                              <>
                                {obj.object[0].field &&
                                  obj.object[0].field.map((scan) => (
                                    <>
                                      <div className="col-md-6">
                                        <label>
                                          <strong>{scan["$"].name}:</strong>{" "}
                                          {scan._}
                                        </label>
                                      </div>
                                    </>
                                  ))}
                              </>
                            ) : (
                              <div className="col-md-6">
                                <label>
                                  <strong>{obj["$"].name}:</strong> {obj._}
                                </label>
                              </div>
                            )}
                          </>
                        )
                      )
                    ) : (
                      <div className="col-md-12">No data found</div>
                    )}
                    {/* {console.log(trackingDetail)} */}
                    {/* {console.log(trackingDetail["ecomexpress-objects"].object.field)} */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal>
        <div className="row d-flex justify-content-center padding-class">
          <div className="col-md-12">
            <div className="row">
              <div className="col-md-12">
                <Pagination
                  activePage={headerState.orderFeatures.page}
                  itemsCountPerPage={headerState.orderFeatures.ps}
                  totalItemsCount={headerState.orderCount}
                  pageRangeDisplayed={5}
                  onChange={handlePageChange.bind(this)}
                  itemClass="page-item"
                  linkClass="page-link"
                  innerClass="pagination ml-auto mr-auto"
                  prevPageText="Prev"
                  nextPageText="Next"
                  hideFirstLastPages={true}
                />
              </div>
            </div>
          </div>
          <div className="col-md-8 align-content-center">
            {headerState.orderList && headerState.orderList.length > 0 ? (
              <ul className="ONM_order_ul">
                {headerState.orderList.map((order) => {
                  return (
                    <li className="mb-3 ONM_order_li" key={order._id}>
                      <div className="col-md-12 ONM_order_header pb-2 pt-2">
                        <div className="row">
                          <div className="col-md-4">
                            <label>Order Number</label>
                            <div className="ONM_order_value">
                              #{order.order_num}
                            </div>
                          </div>
                          <div className="col-md-4">
                            <label>Order Placed</label>
                            <div className="ONM_order_value">
                              <Moment format="D MMM YYYY">
                                {order.createdOn}
                              </Moment>
                            </div>
                          </div>
                          <div className="col-md-4">
                            <label>Total Amount</label>
                            <div className="ONM_order_value">
                              Rs {order.amount.toFixed(2)}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-12 mt-3">
                        <div className="row">
                          {order.products && order.products.length > 0 ? (
                            <>
                              <ul className="col-md-12">
                                {order.products.map((product) => {
                                  return (
                                    <li
                                      className="ONM-order_product_li"
                                      key={product._id}
                                    >
                                      <div className="col-md-12">
                                        <div className="row">
                                          <div className="col-md-4">
                                            {product.productId != null &&
                                            product.productId.imageId !=
                                              null ? (
                                              <img
                                                alt="<no image>"
                                                src={imgPath(
                                                  product.productId.imageId[0]
                                                    .path
                                                )}
                                              />
                                            ) : null}
                                          </div>
                                          <div className="col-md-8">
                                            <label className="ONM_order_label mr-2">
                                              Merchant:
                                              <span className="ONM_order_value">
                                                {product.productId &&
                                                product.productId.userId
                                                  ? " " +
                                                    product.productId.userId
                                                      .storeName
                                                  : "NA"}
                                              </span>
                                            </label>
                                            <label className="ONM_order_label mr-2">
                                              Name:
                                              <span className="ONM_order_value">
                                                {product.productId
                                                  ? " " + product.productId.name
                                                  : "NA"}
                                              </span>
                                            </label>
                                            <label className="ONM_order_label mr-2">
                                              Price:
                                              <span className="ONM_order_value">
                                                {product.productId
                                                  ? " Rs " +
                                                    productPrice(product)
                                                  : "NA"}
                                              </span>
                                            </label>
                                            <label className="ONM_order_label mr-2">
                                              Offer:
                                              <span className="ONM_order_value">
                                                {product.productId
                                                  ? " " +
                                                    product.productId.offer +
                                                    "%"
                                                  : "NA"}
                                              </span>
                                            </label>
                                            <label className="ONM_order_label mr-2">
                                              No.of items:
                                              <span className="ONM_order_value">
                                                {product.stock}
                                              </span>
                                            </label>
                                          </div>
                                        </div>
                                      </div>
                                    </li>
                                  );
                                })}
                              </ul>
                            </>
                          ) : null}
                        </div>
                      </div>
                      <div className="col-md-12 order-bottom">
                        <div className="row">
                          <div className="col-md-12 mt-3">
                            <label className="ONM_order_label">
                              Delivery address:
                            </label>
                            <div className="ONM_order_value">
                              {formatAddress(order.deliveryAddress)}
                            </div>
                          </div>
                          <div className="col-md-12">
                            {order.awb && (
                              <>
                                <label className="ONM_order_label mt-3">
                                  Shipping Track:
                                </label>
                                <div className="ONM_order_value shipping-track">
                                  <ul>
                                    {order.awb.map((track_code) => (
                                      <>
                                        <li>
                                          <a
                                            onClick={() =>
                                              openModal(track_code)
                                            }
                                          >
                                            {track_code}
                                          </a>
                                        </li>
                                      </>
                                    ))}
                                  </ul>
                                </div>
                              </>
                            )}
                          </div>
                        </div>
                      </div>
                    </li>
                  );
                })}
              </ul>
            ) : (
              <div className="empty-order">
                <h3>Your Onmee orders are empty</h3>
              </div>
            )}
          </div>

          <div className="col-md-12 mt-3">
            <Pagination
              activePage={headerState.orderFeatures.page}
              itemsCountPerPage={headerState.orderFeatures.ps}
              totalItemsCount={headerState.orderCount}
              pageRangeDisplayed={5}
              onChange={handlePageChange.bind(this)}
              itemClass="page-item"
              linkClass="page-link"
              innerClass="pagination ml-auto mr-auto"
              prevPageText="Prev"
              nextPageText="Next"
              hideFirstLastPages={true}
            />
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Order;
