import React, {useEffect, useState} from 'react';
import {useSelector} from "react-redux";
import { Redirect } from 'react-router';
import "./merchant.scss";

const Merchant = () => {

    const merchantState = useSelector(state => state.merchant);
    const [isMerchant, setIsMerchant] = useState(false);

    useEffect(() => {
        // console.log("use effect fired");
        if(merchantState.user && merchantState.user.roles 
            && merchantState.user.roles.includes("ROLE_MERCHANT"))
        {
            setIsMerchant(true);
        }
    }, [merchantState.user]);

    if (merchantState.user && merchantState.user.roles 
        && !merchantState.user.roles.includes("ROLE_MERCHANT")
        && !merchantState.user.roles.includes("ROLE_ADMIN")) {
        return <Redirect to="/profile" />;
    }

    if(!merchantState.user)
    {
        return <Redirect to="/merchant/login" />;
    }

    if(isMerchant)
    {
        return <Redirect to="/merchant/main" />
    }

    return (
        <h1>Merchant</h1>
    )    
}

export default Merchant;