import React, { useState, useEffect } from "react";
import NavBar from "./common/navbar";
import SideBar from "./common/sidebar";
import Cart from "../containers/Cart";
import CartContainer from "../containers/CartContainer";
import TopBarDark from "./common/topbar-dark";
import { Media, Container, Row, Col } from "reactstrap";
import LogoImage from "./common/logo";
import search from "../../assets/images/icon/search.png";
import { useDispatch, useSelector } from "react-redux";
import cart from "../../assets/images/icon/cart.png";

import { useLocation } from "react-router-dom";
import SearchOverlay from "./common/search-overlay";
import {
  getAllCategories,
  getCartsCount,
  getWishCount,
  searchList,
  update,
  clearSearchSelectors,
  getEveryCategories,
  updateLocation,
} from "../../slices/headerSlice";
import {
  LocalSaveToCart,
  localCartCount,
  getUniqueId,
} from "../../helpers/LocalCart";
import Modal from "react-modal";
import Geocode from "react-geocode";
import Dropdown from "react-bootstrap/Dropdown";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  updateAdmin,
  logout as resetAdminState,
} from "../../slices/adminSlice";
import {
  updateMerchant,
  logout as resetMerchantState,
} from "../../slices/merchantSlice";
import { updateAuth, logout as resetAuthState } from "../../slices/authSlice";
import { updateLoggedInUserProduct } from "../../slices/productSlice";
import {
  updateLoggedInUserHome,
  resetUserLocNotFound as resetStatusHome,
} from "../../slices/homeSlice";
import {
  updateLoggedInUserOffer,
  resetUserLocNotFound as resetStatusOffer,
} from "../../slices/OfferSlice";
import { generalToken } from "../../services/auth.service";
import {
  updateLoggedInUser,
  resetUserLocNotFound as resetStatusCategory,
} from "../../slices/categorySlice";
const HeaderOne = ({
  logoName,
  headerClass,
  topClass,
  noTopBar,
  direction,
}) => {
  const router = useLocation();
  const [IsLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [address, setAddress] = useState("");
  const [area, setArea] = useState("");
  const headerState = useSelector((state) => state.header);
  const [showError, setShowError] = useState(false);
  const [locationChanged, setLocationChanged] = useState(false);
  useEffect(() => {
    setAddress(headerState.zip_code);
    setArea(headerState.area);
  }, [headerState.zip_code, headerState.area]);
  useEffect(() => {
    let userId =
      headerState.user != null ? headerState.user._id : getUniqueId();

    dispatch(getAllCategories());
    dispatch(getEveryCategories());
    dispatch(getWishCount(userId));
    dispatch(getCartsCount(userId));
  }, [dispatch]);

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setShowError(false);
    // setAddress('');
    // setArea('');
  };
  const onChangeAddress = (e) => {
    setAddress(e.target.value);
  };

  const onChangeArea = (e) => {
    setArea(e.target.value);
  };

  /*=====================
		 Pre loader
		 ==========================*/
  useEffect(() => {
    setTimeout(function () {
      document.querySelectorAll(".loader-wrapper").style = "display:none";
    }, 2000);

    if (router.pathname !== "/layouts/Christmas")
      window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleScroll = () => {
    let number =
      window.pageXOffset ||
      document.documentElement.scrollTop ||
      document.body.scrollTop ||
      0;
    if (number >= 1000) {
      if (window.innerWidth < 576)
        document.getElementById("sticky").classList.remove("fixed");
      else document.getElementById("sticky").classList.add("fixed");
    } else document.getElementById("sticky").classList.remove("fixed");
  };

  const openNav = () => {
    var openmyslide = document.getElementById("mySidenav");
    if (openmyslide) {
      openmyslide.classList.add("open-side");
    }
  };
  const openSearch = () => {
    document.getElementById("search-overlay").style.display = "block";
  };

  // eslint-disable-next-line
  const load = () => {
    setIsLoading(true);
    fetch().then(() => {
      // deal with data fetched
      setIsLoading(false);
    });
  };
  const customStyles50 = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      zIndex: "9999",
      width: "30%",
    },
  };

  const signout = () => {
    localStorage.removeItem("user");
    dispatch(resetAdminState());
    dispatch(resetMerchantState());
    dispatch(resetAuthState());
    dispatch(update());
    dispatch(updateLoggedInUser());
    dispatch(updateLoggedInUserHome());
    dispatch(updateLoggedInUserOffer());
    dispatch(updateMerchant());
    dispatch(updateAdmin());
    dispatch(updateAuth());
    dispatch(updateLoggedInUserProduct());
    dispatch(getCartsCount(getUniqueId()));
    dispatch(getWishCount(getUniqueId()));
    localStorage.removeItem("accessToken");

    // generate unanimouse accessToken
    let userId = getUniqueId();
    generalToken(userId).then((res) => {
      // console.log( res.data );
      let accessToken = res.data.accessToken;
      localStorage.setItem("accessToken", JSON.stringify(accessToken));
    });
  };
  const handleChange = async () => {
    if (
      /*address != null && address.length === 6 &&*/ area != null &&
      area.length > 0
    ) {
      let pincode = address;
      let searchQuery = area + ", Karnataka, India " + pincode;
      Geocode.setApiKey("AIzaSyBhhIhmyHFUgqagBmIVaXO5IYmmkmLhLuM");
      Geocode.setLanguage("en");
      Geocode.setLocationType("ROOFTOP");
      // console.log(searchQuery);
      Geocode.fromAddress(searchQuery).then(
        (response) => {
          console.log("header geo");
          // console.log(response);
          const { lat, lng } = response.results[0].geometry.location;
          // console.log(response.results[0]);
          localStorage.setItem("latitude", lat);
          localStorage.setItem("longitude", lng);
          const address1 = response.results[0].formatted_address;
          let city = null;
          let zip_code = "";
          let area1 = "";
          /*
          for (
            let i = 0;
            i < response.results[0].address_components.length;
            i++
          ) {
            for (
              let j = 0;
              j < response.results[0].address_components[i].types.length;
              j++
            ) {
              if (
                response.results[0].address_components[i].types[j] ===
                "sublocality" || response.results[0].address_components[i].types[j] === 'administrative_area_level_1'
              ) {
                city = response.results[0].address_components[i].long_name;
              }
            }
          } */
          city = response.results[0].formatted_address;

          if (city == null) {
            for (
              let i = 0;
              i < response.results[0].address_components.length;
              i++
            ) {
              for (
                let j = 0;
                j < response.results[0].address_components[i].types.length;
                j++
              ) {
                if (
                  response.results[0].address_components[i].types[j] ===
                  "locality"
                ) {
                  city = response.results[0].address_components[i].long_name;
                }
              }
            }
          }
          if (zip_code == "") {
            for (
              let i = 0;
              i < response.results[0].address_components.length;
              i++
            ) {
              for (
                let j = 0;
                j < response.results[0].address_components[i].types.length;
                j++
              ) {
                if (
                  response.results[0].address_components[i].types[j] ===
                  "postal_code"
                ) {
                  zip_code =
                    response.results[0].address_components[i].long_name;
                }
              }
            }
          }
          if (area1 == "") {
            for (
              let i = 0;
              i < response.results[0].address_components.length;
              i++
            ) {
              for (
                let j = 0;
                j < response.results[0].address_components[i].types.length;
                j++
              ) {
                if (
                  response.results[0].address_components[i].types[j] ===
                  "sublocality"
                ) {
                  area1 = response.results[0].address_components[i].long_name;
                }
              }
            }
          }
          localStorage.setItem("address", address1);
          localStorage.setItem("city", city);
          localStorage.setItem("zip_code", pincode);
          localStorage.setItem("area", area1);
          setArea(area);
          setAddress(pincode);
          dispatch(update());
          closeModal();

          dispatch(updateLocation(1));
          setLocationChanged(true);
        },
        (error) => {
          console.log(error);
          setShowError(true);
        }
      );
    } else {
      setShowError(true);
    }
  };
  const handleReset = () => {
    localStorage.removeItem("latitude");
    localStorage.removeItem("longitude");
    localStorage.removeItem("address");
    localStorage.removeItem("city");
    localStorage.removeItem("zip_code");
    localStorage.removeItem("area");
    setAddress("");
    setArea("");
    dispatch(update());
    dispatch(updateLocation(1));
    closeModal();
    window.location.reload();
  };
  return (
    <div>
      <Modal isOpen={isModalOpen} style={customStyles50}>
        <div className="col-md-12" style={{ padding: "30px" }}>
          <div className="close-modal">
            <button onClick={() => closeModal()}>&times;</button>
          </div>
          <div className="row">
            <div className="col-md-12 p-0 ONM_modal_header">
              <h3>Choose your location</h3>
            </div>
            <div className="col-md-12 align-content-center p-0">
              <div className="col-md-12">
                <div className="row">
                  <div className="col-md-6 form-group">
                    <label htmlFor="address">Enter Pincode</label>
                    <input
                      autoFocus
                      type="text"
                      name="address"
                      value={address}
                      className="col-md-12"
                      onChange={onChangeAddress}
                      style={{ padding: "8px" }}
                    />
                  </div>
                  <div className="col-md-6 form-group">
                    <label htmlFor="address">Enter Area</label>
                    <input
                      type="text"
                      name="area"
                      value={area}
                      className="col-md-12"
                      onChange={onChangeArea}
                      style={{ padding: "8px" }}
                    />
                  </div>
                  <div className="col-md-12 form-group">
                    <button
                      className="btn btn-sm btn-primary mt-2 mr-2"
                      onClick={() => handleChange()}
                    >
                      Apply
                    </button>
                    <button
                      className="btn btn-sm btn-dark mt-2"
                      onClick={() => handleReset()}
                    >
                      Reset
                    </button>
                  </div>
                  {showError && (
                    <div className="col-md-12">
                      <div className="alert alert-danger">
                        Please enter a valid area
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
      <header id="sticky" className={`sticky ${headerClass}`}>
        <div className="mobile-fix-option"></div>
        {/*Top Header Component*/}
        {noTopBar ? (
          ""
        ) : (
          <TopBarDark
            topClass={topClass}
            headerstate={headerState}
            modelopen={openModal}
            signout={signout}
          />
        )}

        <Container>
          <Row>
            <Col>
              <div className="main-menu">
                <div className="menu-left">
                  <div className="navbar">
                    <a href={null} onClick={openNav}>
                      <div className="bar-style">
                        <i
                          className="fa fa-bars sidebar-bar"
                          aria-hidden="true"
                        ></i>
                      </div>
                    </a>
                    {/*SideBar Navigation Component*/}
                    <SideBar />
                  </div>
                  <div className="brand-logo">
                    <LogoImage logo={logoName} />
                  </div>
                </div>
                <div className="menu-right pull-right">
                  {/*Top Navigation Bar Component*/}
                  <NavBar
                    mainheader={headerState.mainHeader}
                    mainheaderwithcat={headerState.mainHeaderWithCategories}
                  />

                  <div>
                    <div className="icon-nav">
                      <ul>
                        <li className="onhover-div mobile-search">
                          <div>
                            <Media
                              src={search}
                              onClick={openSearch}
                              className="img-fluid"
                              alt=""
                            />
                          </div>
                        </li>

                        {/*Header Cart Component */}

                        <CartContainer
                          layout={direction}
                          icon={cart}
                          headerstate={headerState}
                        />
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </header>

      <SearchOverlay />
    </div>
  );
};

export default HeaderOne;
