import { useSelector } from "react-redux";
import Register from "../Register";

const MerchantRegister = (props) => {
    const merchantState = useSelector(state => state.merchant);
    
    return (
        <Register item={["merchant"]}></Register>
    )
};

export default MerchantRegister;