import React, { useEffect } from "react";
import { Media, Col, Row } from "reactstrap";
import { Link } from "react-router-dom";
import { BASE_URL, BASE_API_URL } from "../../../services/constants";
import { FaStar } from "react-icons/fa";
import ModalMap from "../../map/modalMap";
import PhoneContainer from "../../../helpers/PhoneContainer";
import classes from "./Card.module.css";
const Card = (props) => {
  let baseUri = BASE_URL;
  let urlToClick = "/category/" + props.item.mainCategoryId;

  if (props.item.subCategoryId) {
    urlToClick = urlToClick + "?subCatId=" + props.item.subCategoryId;
  }

  // if (props.item.childCategoryId) {
  //   if (urlToClick.indexOf("?") > -1) {
  //     urlToClick = urlToClick + "&childCatId=" + props.item.childCategoryId;
  //   } else {
  //     urlToClick = urlToClick + "?childCatId=" + props.item.childCategoryId;
  //   }
  // }

  if (props.item.userId) {
    if (urlToClick.indexOf("?") > -1) {
      urlToClick = urlToClick + "&uId=" + props.item.userId._id;
    } else {
      urlToClick = urlToClick + "?uId=" + props.item.userId._id;
    }
  }
  const imgPath = (img) => {
    let path = img;

    if (path && path.indexOf("https://") > -1) {
      return path;
    } else {
      return BASE_API_URL + "/" + path;
    }
  };

  const roundItOff = (num) => {
    return Math.round(num);
  };
  var column;
  if (props.type === "slider") {
    column = "12";
  } else if (props.type === "offer") {
    column = "4";
  } else {
    column = "3";
  }

  return (
    <>
      <Col md={column} key={props.key} className={classes.content__store}>
        {/* <span class={classes.label3}>new</span> */}
        <Link to={urlToClick}>
          {props.item && props.item.imageId ? (
            <div className="classic-effect">
              <img
                src={imgPath(props.item.imageId[0].path)}
                className="img-fluid pimg"
                alt="productimage"
              />
            </div>
          ) : (
            <div className="classic-effect">
              <img
                src={baseUri + "files/noimg.png"}
                className="img-fluid"
                alt="productimages"
              />
            </div>
          )}
        </Link>
        <div className="blog-details">
          <Link to={urlToClick}>
            <h4>
              {props.item.userId
                ? props.item.userId.storeName
                : props.item.name}
            </h4>
          </Link>
          <div className={classes.map__review}>
            <div className={classes.review__container}>
              <FaStar
                className={
                  roundItOff(props.item.star || 0) >= 1
                    ? "OMN-star-active mr-2"
                    : "ONM_icon_color-empty mr-2"
                }
              ></FaStar>
              <FaStar
                className={
                  roundItOff(props.item.star || 0) >= 2
                    ? "OMN-star-active mr-2"
                    : "ONM_icon_color-empty mr-2"
                }
              ></FaStar>
              <FaStar
                className={
                  roundItOff(props.item.star || 0) >= 3
                    ? "OMN-star-active mr-2"
                    : "ONM_icon_color-empty mr-2"
                }
              ></FaStar>
              {/* <FaStar
            className={
              roundItOff(props.item.star || 0) === 4
                ? "OMN-star-active mr-2"
                : "ONM_icon_color-empty mr-2"
            }
          ></FaStar> */}
            </div>
            <div className={classes.map__container}>
              <ModalMap size={14} item={props.item}></ModalMap>
              <PhoneContainer item={props.item}></PhoneContainer>
            </div>
          </div>

          <p>{props.item.userId ? props.item.userId.address : ""} </p>

          <h6>
            {props.item.offer && (
              <span className="ONM_offer-text">
                {props.item.offer ? props.item.offer + "% OFF" : ""}
              </span>
            )}
          </h6>
        </div>
      </Col>
    </>
  );
};

export default Card;
