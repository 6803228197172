import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { MENUITEMS } from "../../constant/menu";
import { Container, Row } from "reactstrap";
import classes from "./navbar.module.css";
import { useLocation } from "react-router-dom";

const NavBar = ({ mainheader, mainheaderwithcat }) => {
  const t = null;
  const [navClose, setNavClose] = useState({ right: "0px" });
  const location = useLocation();

  useEffect(() => {
    if (window.innerWidth < 750) {
      setNavClose({ right: "-410px" });
    }
    if (window.innerWidth < 1199) {
      setNavClose({ right: "-300px" });
    }
  }, []);

  const openNav = () => {
    setNavClose({ right: "0px" });
    if (location.pathname == "/layouts/Gym")
      document.querySelector("#topHeader").classList.add("zindex-class");
  };

  const closeNav = () => {
    setNavClose({ right: "-410px" });
    if (location.pathname == "/layouts/Gym")
      document.querySelector("#topHeader").classList.remove("zindex-class");
  };
  // eslint-disable-next-line

  const handleMegaSubmenu = (event) => {
    if (event.target.classList.contains("sub-arrow")) return;

    if (
      event.target.parentNode.nextElementSibling.classList.contains(
        "opensubmegamenu"
      )
    )
      event.target.parentNode.nextElementSibling.classList.remove(
        "opensubmegamenu"
      );
    else {
      document.querySelectorAll(".menu-content").forEach(function (value) {
        value.classList.remove("opensubmegamenu");
      });
      event.target.parentNode.nextElementSibling.classList.add(
        "opensubmegamenu"
      );
    }
  };
  console.log("main", mainheaderwithcat);
  const [mainmenu, setMainMenu] = useState(MENUITEMS);

  // Click Toggle menu
  const toggletNavActive = (item) => {
    if (!item.active) {
      MENUITEMS.forEach((a) => {
        if (MENUITEMS.includes(item)) a.active = false;
        if (!a.children) return false;
        a.children.forEach((b) => {
          if (a.children.includes(item)) {
            b.active = false;
          }
          if (!b.children) return false;
          b.children.forEach((c) => {
            if (b.children.includes(item)) {
              c.active = false;
            }
          });
        });
      });
    }
    item.active = !item.active;
    setMainMenu({ mainmenu: MENUITEMS });
  };

  const openMblNav = (event) => {
    if (event.target.classList.contains("sub-arrow")) return;

    if (event.target.nextElementSibling.classList.contains("opensubmenu"))
      event.target.nextElementSibling.classList.remove("opensubmenu");
    else {
      document.querySelectorAll(".nav-submenu").forEach(function (value) {
        value.classList.remove("opensubmenu");
      });
      document
        .querySelector(".mega-menu-container")
        .classList.remove("opensubmenu");
      event.target.nextElementSibling.classList.add("opensubmenu");
    }
  };

  return (
    <div>
      <div className="main-navbar">
        <div id="mainnav">
          {/* <div className="toggle-nav" onClick={openNav.bind(this)}>
            <i className="fa fa-bars sidebar-bar"></i>
          </div> */}
          <ul className="nav-menu" style={navClose}>
            <li className="back-btn" onClick={closeNav.bind(this)}>
              <div className="mobile-back text-right">
                <span>Back navbar</span>
                <i className="fa fa-angle-right pl-2" aria-hidden="true"></i>
              </div>
            </li>
            {mainheaderwithcat.map((menuItem, i) => {
              return (
                <li key={i} className="mega-menu">
                  <Link className="nav-link" to={"/category/" + menuItem._id}>
                    {" "}
                    {menuItem.name}
                    <span className="sub-arrow"></span>
                  </Link>

                  <div className={`mega-menu-container opensubmenu`}>
                    <Container>
                      <Row>
                        {menuItem.categories.map((menusub, key) => {
                          return (
                            <div className={`col-lg-2`} key={key}>
                              <div className="link-section">
                                <div className="menu-title">
                                  <h5
                                    onClick={(e) => handleMegaSubmenu(e)}
                                    className={classes.sub__head}
                                  >
                                    {menusub.name}
                                  </h5>
                                </div>
                                <div className="menu-content">
                                  <ul className={classes.menu__content__lists}>
                                    {menusub.subCategories.map(
                                      (sub, subkey) => {
                                        return (
                                          <li
                                            key={subkey}
                                            className={
                                              classes.menu__content__list
                                            }
                                          >
                                            <Link
                                              to={
                                                "/category/" +
                                                menusub.categoryId +
                                                "?subCatId=" +
                                                sub._id
                                              }
                                            >
                                              {sub.name}
                                            </Link>
                                          </li>
                                        );
                                      }
                                    )}
                                  </ul>
                                </div>
                              </div>
                            </div>
                          );
                        })}
                      </Row>
                    </Container>
                  </div>
                </li>
              );
            })}

            <li className="mega-menu">
              <Link className="nav-link" to={"/offer"}>
                {" "}
                Offer's
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default NavBar;
