import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import { BASE_URL, BASE_API_URL } from "../services/constants";
import { Link } from "react-router-dom";

const OnmeeCarousel = (props) => {
  let baseUri = BASE_URL;

  const imgPath = (img) => {
    let path = img;

    if (path.indexOf("https://") > -1) {
      return path;
    } else {
      return BASE_API_URL + "/" + path;
    }
  };

  const cItem = props.items.map((item) => {
    let urlToClick = null;
    if (item.storeId != null && item.subCategoryId) {
      urlToClick = "/category/" + item.mainCategoryId;
      urlToClick =
        urlToClick +
        "?subCatId=" +
        item.subCategoryId +
        "&uId=" +
        item.storeId._id +
        "&sname=" +
        item.storeId.storeName +
        "&banner=" +
        true;
    }

    return urlToClick ? (
      <>
        {" "}
        <Link key={item._id} style={{ display: "block" }} to={urlToClick}>
          <img alt="<no image>" src={imgPath(item.imageId.path)} />
        </Link>
        {/* <div className="content">
          <h4>welcome to fashion</h4>
          <h1>men fashion</h1>
          <a href="/category/6077ea9d3a92cd14d834cffb">
            <a class="btn btn-solid ">Shop Now </a>
          </a>
        </div> */}
      </>
    ) : (
      <>
        {" "}
        <div key={item._id}>
          <img alt="<no image>" src={imgPath(item.imageId.path)} />
        </div>
        {/* <div className="content">
          <h4>welcome to fashion</h4>
          <h1>men fashion</h1>
          <a href="/category/6077ea9d3a92cd14d834cffb">
            <a class="btn btn-solid ">Shop Now </a>
          </a>
        </div> */}
      </>
    );
  });

  return (
    <Carousel
      autoPlay={true}
      centerMode={false}
      showStatus={false}
      showThumbs={props.showThumbs}
      infiniteLoop={true}
      swipeable={true}
      emulateTouch={false}
      dynamicHeight={true}
    >
      {props.items ? cItem : null}
    </Carousel>
  );
};

export default OnmeeCarousel;
