import { useState } from "react";
import { FaStar } from "react-icons/fa";
import { Link } from "react-router-dom";
import ModalMap from "../components/map/modalMap";
import { BASE_URL, BASE_API_URL } from "../services/constants";
import PhoneContainer from "./PhoneContainer";
import classes from "../components/common/Blog/Card.module.css";

const StoreCard = (props) => {
  let baseUri = BASE_URL;
  const imgPath = (img) => {
    let path = img;

    if (path.indexOf("https://") > -1) {
      return path;
    } else if (path) {
      return BASE_API_URL + "/" + path;
    } else {
      return baseUri + "files/noimg.png";
    }
  };

  const roundItOff = (num) => {
    return Math.round(num);
  };

  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  let urlToClick = "/category/" + props.item.mainCategoryId;

  if (props.item.subCategoryId) {
    urlToClick = urlToClick + "?subCatId=" + props.item.subCategoryId;
  }

  if (props.item.childCategoryId) {
    if (urlToClick.indexOf("?") > -1) {
      urlToClick = urlToClick + "&childCatId=" + props.item.childCategoryId;
    } else {
      urlToClick = urlToClick + "?childCatId=" + props.item.childCategoryId;
    }
  }

  if (props.item.userId) {
    if (urlToClick.indexOf("?") > -1) {
      urlToClick = urlToClick + "&uId=" + props.item.userId._id;
    } else {
      urlToClick = urlToClick + "?uId=" + props.item.userId._id;
    }
  }

  return (
    <div className="card store-cat">
      <div className="ONM_img">
        <Link
          onClick={() => {
            props.onStoreUpdate(props.item);
          }}
          to={urlToClick}
        >
          {props.item && props.item.imageId ? (
            <img
              className="card-img-top"
              src={imgPath(props.item.imageId[0].path)}
              alt="Card"
            />
          ) : null}
        </Link>
      </div>
      <div className="blog-details row" style={{ textAlign: "left" }}>
        <Link
          onClick={() => {
            props.onStoreUpdate(props.item);
          }}
          to={urlToClick}
        >
          <h4>
            {props.item.userId ? props.item.userId.storeName : props.item.name}
          </h4>
        </Link>

        <div
          className={classes.map__review__store}
          style={{ width: "100%", marginTop: "0px" }}
        >
          <div>
            <FaStar
              className={
                roundItOff(props.item.star || 0) >= 1
                  ? "OMN-star-active mr-2"
                  : "ONM_icon_color-empty mr-2"
              }
            ></FaStar>
            <FaStar
              className={
                roundItOff(props.item.star || 0) >= 2
                  ? "OMN-star-active mr-2"
                  : "ONM_icon_color-empty mr-2"
              }
            ></FaStar>
            <FaStar
              className={
                roundItOff(props.item.star || 0) >= 3
                  ? "OMN-star-active mr-2"
                  : "ONM_icon_color-empty mr-2"
              }
            ></FaStar>
            {/* <FaStar
            className={
              roundItOff(props.item.star || 0) === 4
                ? "OMN-star-active mr-2"
                : "ONM_icon_color-empty mr-2"
            }
          ></FaStar> */}
          </div>
          <div>
            <ModalMap size={14} item={props.item}></ModalMap>
            <PhoneContainer item={props.item}></PhoneContainer>
          </div>
        </div>
        <p className="card-text col-md-12">
          {props.item.userId ? props.item.userId.address : ""}
        </p>

        <div className="ONM_offer-container col-md-12">
          {props.item.offer && (
            <span className="ONM_offer-text">
              {props.item.offer ? props.item.offer + "% OFF" : "0"}
            </span>
          )}
        </div>
      </div>
    </div>
  );
};

export default StoreCard;
