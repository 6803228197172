import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { login, adminLogin, verify } from "../services/auth.service";
import { getAll, getEverything } from "../services/category.service";
import { getBanners, saveBanners, deleteBanner } from "../services/home.service";
import { upload } from "../services/image.service";
import {
  getBrandCount,
  getBrandList,
  markMerchantActive,
  merchantCount,
  merchantList,
  customerList,
  customerCount,
  saveBrands,
  deleteBrand,
  getAllPlans,
  markMerchantInactive,
  markMerchantRejected,
  updateUser,
  deleteUserById as deleteMerchantById,
  deleteUserById as deleteCustomerById,
} from "../services/user.service";
import { getActivities, getActivitiesCount, deleteActivity } from "../services/activity.service";
import {
  adminOrderList,
  adminOrderCount,
} from "../services/order.service";

const user = JSON.parse(localStorage.getItem("user"));

export const signin = createAsyncThunk(
  "admin/signin",
  async (userParams, { getState, rejectWithValue }) => {
    try {
      const email = userParams.email;

      const response = await adminLogin(email);

      return response.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const verifyUser = createAsyncThunk(
  "admin/verifyUser",
  async (userParams, { getState, rejectWithValue }) => {
    try {
      const otp = userParams.otp;
      const id = userParams.id;

      const response = await verify(otp, id);

      return response.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const getMerchantList = createAsyncThunk(
  "admin/getMerchantList",
  async (params, { getState, rejectWithValue }) => {
    try {
      const state = getState().admin;
      const response = await merchantList(params, state.features);
      return response.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const getMerchantCount = createAsyncThunk(
  "admin/getMerchantCount",
  async (params, { getState, rejectWithValue }) => {
    try {
      const state = getState().admin;
      const response = await merchantCount(params, state.features);
      return response.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const markActive = createAsyncThunk(
  "admin/markActive",
  async (id, { getState, rejectWithValue }) => {
    try {
      const response = await markMerchantActive(id);
      return response.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const markInActive = createAsyncThunk(
  "admin/markInActive",
  async (id, { getState, rejectWithValue }) => {
    try {
      const response = await markMerchantInactive(id);
      return response.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const markRejected = createAsyncThunk(
  "admin/markRejected",
  async (id, { getState, rejectWithValue }) => {
    try {
      const response = await markMerchantRejected(id);
      return response.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const deleteMerchant = createAsyncThunk(
  "admin/deleteMerchant",
  async (id, { getState, rejectWithValue }) => {
    try {
      const response = await deleteMerchantById(id);
      return response.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const getAllCategories = createAsyncThunk(
  "admin/getAllCategories",
  async (mainCatId, { getState, rejectWithValue }) => {
    try {
      const response = await getEverything();

      return response.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const getAllBrands = createAsyncThunk(
  "admin/getAllBrands",
  async (name, { getState, rejectWithValue }) => {
    try {
      const state = getState().admin;
      const response = await getBrandList(name, state.brandsFeatures);
      return response.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const getBrandsCount = createAsyncThunk(
  "admin/getBrandsCount",
  async (name, { getState, rejectWithValue }) => {
    try {
      const response = await getBrandCount(name);
      return response.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const saveBrandsObj = createAsyncThunk(
  "admin/saveBrandsObj",
  async (obj, { getState, rejectWithValue }) => {
    try {
      const response = await saveBrands(obj);
      return response.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const deleteBrandObj = createAsyncThunk(
  "admin/deleteBrandObj",
  async (productObj, { getState, rejectWithValue }) => {
    try {
      const response = await deleteBrand(productObj);
      return response.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const bannerList = createAsyncThunk(
  "admin/bannerList",
  async (page, { getState, rejectWithValue }) => {
    try {
      const response = await getBanners(page);
      return response.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const uploadImage = createAsyncThunk(
  "admin/uploadImage",
  async (formData, { getState, rejectWithValue }) => {
    try {
      const response = await upload(formData);
      return response.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const saveBannerObj = createAsyncThunk(
  "admin/saveBannerObj",
  async (obj, { getState, rejectWithValue }) => {
    try {
      const response = await saveBanners(obj);
      return response.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const deleteBannerObj = createAsyncThunk(
  "admin/deleteBannerObj",
  async (id, { getState, rejectWithValue }) => {
    try {
      const response = await deleteBanner(id);
      return response.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const getPlans = createAsyncThunk(
  "admin/getPlans",
  async (param, { getState, rejectWithValue }) => {
    try {
      const response = await getAllPlans();
      return response.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const updateUs = createAsyncThunk(
  "admin/updateUs",
  async (params, { getState, rejectWithValue }) => {
    try {
      const response = await updateUser(params);

      return response.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const getAllActivities = createAsyncThunk(
  "admin/getAllActivities",
  async (name, { getState, rejectWithValue }) => {
    try {
      const state = getState().admin;
      const response = await getActivities(state.activitiesFeatures);
      return response.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const getAllActivitiesCount = createAsyncThunk(
  "admin/getAllActivitiesCount",
  async (name, { getState, rejectWithValue }) => {
    try {
      const response = await getActivitiesCount(name);
      return response.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const deleteActivityObj = createAsyncThunk(
  "admin/deleteActivityObj",
  async (id, { getState, rejectWithValue }) => {
    try {
      const response = await deleteActivity(id);
      return response.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const getCustomers = createAsyncThunk(
  "admin/getCustomers",
  async (params, { getState, rejectWithValue }) => {
    try {
      const state = getState().admin;
      const response = await customerList(params, state.customersFeatures);
      return response.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const getCustomersCount = createAsyncThunk(
  "admin/getCustomersCount",
  async (params, { getState, rejectWithValue }) => {
    try {
      const state = getState().admin;
      const response = await customerCount(params);
      return response.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const deleteCustomerObj = createAsyncThunk(
  "admin/deleteCustomerObj",
  async (id, { getState, rejectWithValue }) => {
    try {
      const response = await deleteCustomerById(id);
      return response.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const getAdminOrders = createAsyncThunk(
  "admin/getAdminOrders",
  async (params, { getState, rejectWithValue }) => {
    try {
      const state = getState().admin;
      const response = await adminOrderList(params, state.adminOrdersFeatures);
      return response.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const getAdminOrdersCount = createAsyncThunk(
  "admin/getAdminOrdersCount",
  async (params, { getState, rejectWithValue }) => {
    try {
      const state = getState().admin;
      const response = await adminOrderCount(params);
      return response.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

const initialState = {
  isLoggedIn: false,
  loginError: null,
  otpFailed: null,
  user: user,
  successful: false,
  loading: false,
  panel: "MERCHANTS",
  merchantCount: 0,
  features: { ps: 12, sidx: 0, page: 1 },
  brandsFeatures: { ps: 12, sidx: 0, page: 1 },
  brandsCount: 0,
  bannerSaved: false,
  imageUploading: false,
  imageUploadError: false,
  userUpdated: false,
  activitiesFeatures: { ps: 12, sidx: 0, page: 1 },
  activitiesCount: 0,
  customersFeatures: { ps: 12, sidx: 0, page: 1 },
  customersList: null,
  customersCount: 0,
  needRefresh: 0,
  adminOrdersFeatures: { ps: 12, sidx: 0, page: 1 },
  adminOrdersList: null,
  adminOrdersCount: 0,
};

const adminSlice = createSlice({
  name: "Admin",
  initialState: initialState,
  reducers: {
    update: (state, action) => {
      const user = localStorage.getItem("user");
      if (user != null) {
        state.user = JSON.parse(user);
      }
      state.city = localStorage.getItem("city");
    },
    updatePanel: (state, action) => {
      state.panel = action.payload;
    },
    updatePagination: (state, action) => {
      state.features.page = action.payload;
      state.features.sidx = (state.features.page - 1) * state.features.ps;
    },
    resetFeatures: (state, action) => {
      state.merchantList = null;
      state.merchantCount = 0;
      state.features.ps = 12;
      state.features.sidx = 0;
      state.features.page = 1;
    },
    updateBrandsPagination: (state, action) => {
      state.brandsFeatures.page = action.payload;
      state.brandsFeatures.sidx =
        (state.brandsFeatures.page - 1) * state.brandsFeatures.ps;
    },
    resetBrandsFeatures: (state, action) => {
      state.brandsList = null;
      state.brandsCount = 0;
      state.brandsFeatures.ps = 12;
      state.brandsFeatures.sidx = 0;
      state.brandsFeatures.page = 1;
    },
    toBeDeleted: (state, action) => {
      state.toBeDeletedBrand = action.payload;
    },
    clearImage: (state, action) => {
      state.image = null;
      state.imageUploading = false;
    },
    updateImages: (state, action) => {
      state.image = action.payload;
      state.imageUploading = false;
    },
    updateBannerSaved: (state, action) => {
      state.bannerSaved = false;
    },
    updateUserUpdated: (state, action) => {
      state.userUpdated = false;
    },
    updateAdmin: (state, action) => {
      const user = localStorage.getItem("user");
      if (user != null) {
        state.user = JSON.parse(user);
      }
      state.city = localStorage.getItem("city");
    },
    tobeDeletedActivity: (state, action) => {
      state.tobeDeletedActivity = action.payload;
    },
    updateActivitiesPagination: (state, action) => {
      state.activitiesFeatures.page = action.payload;
      state.activitiesFeatures.sidx =
        (state.activitiesFeatures.page - 1) * state.activitiesFeatures.ps;
    },
    tobeDeletedCustomer: (state, action) => {
      state.tobeDeletedCustomer = action.payload;
    },
    updateCustomersPagination: (state, action) => {
      state.customersFeatures.page = action.payload;
      state.customersFeatures.sidx =
        (state.customersFeatures.page - 1) * state.customersFeatures.ps;
    },
    updateLoginError: (state, action) => {
      state.loginError = null;
    },
    updateOtpFailed: (state, action) => {
      state.otpFailed = null;
    },
    resetUploadError: (state, action) => {
      state.imageUploadError = false;
    },
    logout: (state, action)  => {
      for (var key in initialState ){
        state[key] = initialState[key];
      }
      state.user = null;
    },
    updateAdminOrdersPagination: (state, action) => {
      state.adminOrdersFeatures.page = action.payload;
      state.adminOrdersFeatures.sidx =
        (state.adminOrdersFeatures.page - 1) * state.adminOrdersFeatures.ps;
    },
  },
  extraReducers: (builder) =>
    builder
      .addCase(signin.pending, (state, action) => {
        state.successful = false;
        state.loading = true;
      })
      .addCase(signin.fulfilled, (state, action) => {
        state.isLoggedIn = false;
        state.loading = false;
        if (action.payload) {
            if(!action.payload.error){
            state.loginError = null;
            state.successful = true;
            state.registeredUserId = action.payload.id;
            }else {
              state.loginError = action.payload.message;
            }
        }
      })
      .addCase(signin.rejected, (state, action) => {
        state.isLoggedIn = false;
        state.loading = false;
        let response = JSON.parse(action.payload.request.response);
        state.loginError = response.message;
      })
      .addCase(verifyUser.fulfilled, (state, action) => {
        state.isLoggedIn = true;

        if (action.payload._id) {
          localStorage.setItem("user", JSON.stringify(action.payload));
          localStorage.setItem(
            "accessToken",
            JSON.stringify(action.payload.accessToken)
          );
          state.user = action.payload;
        }else{
          state.isLoggedIn = false;
          state.otpFailed = action.payload.message;
        }
      })
      .addCase(getMerchantList.fulfilled, (state, action) => {
        state.merchantList = null;
        if (action.payload.result) {
          state.merchantList = action.payload.result;
        }

        state.needRefresh=0;
      })
      .addCase(getMerchantCount.fulfilled, (state, action) => {
        state.merchantCount = 0;
        if (action.payload.result) {
          state.merchantCount = action.payload.result;
        }

        state.needRefresh=0;
      })
      .addCase(markActive.pending, (state, action) => {
        state.markedActive = false;
      })
      .addCase(markActive.fulfilled, (state, action) => {
        if (action.payload.result) {
          state.markedActive = true;
          state.needRefresh = 1;
        }
      })
      .addCase(markInActive.fulfilled, (state, action) => {
          state.needRefresh = 1;
      })
      .addCase(markRejected.fulfilled, (state, action) => {
          state.needRefresh = 1;
      })
      .addCase(deleteMerchant.fulfilled, (state, action) => {
          state.needRefresh = 1;
      })
      .addCase(getAllCategories.fulfilled, (state, action) => {
        state.categories = null;
        if (action.payload.result) {
          state.categories = action.payload.result;
        }
      })
      .addCase(getAllBrands.fulfilled, (state, action) => {
        state.brandsList = null;
        if (action.payload.result) {
          state.brandsList = action.payload.result;
        }
      })
      .addCase(getBrandsCount.fulfilled, (state, action) => {
        state.brandsCount = 0;
        state.brandsCount = action.payload.result;
      })
      .addCase(saveBrandsObj.pending, (state, action) => {
        state.brandsSaved = false;
      })
      .addCase(saveBrandsObj.fulfilled, (state, action) => {
        state.brandsSaved = true;
        if (action.payload.result) {
          if (!state.brandsList) state.brandsList = [];

          let found = null;
          let index = -1;
          for (let i = 0; i < state.brandsList.length && !found; i++) {
            let item = state.brandsList[i];
            if (item._id === action.payload.result._id) {
              found = item;
              index = i;
            }
          }

          if (found != null) {
            state.brandsList[index] = action.payload.result;
          } else {
            state.brandsList.push(action.payload.result);
          }
        }
      })
      .addCase(deleteBrandObj.fulfilled, (state, action) => {
        if (state.brandsList != null && state.brandsList.length > 0) {
          let index = -1;
          for (let i = 0; i < state.brandsList.length; i++) {
            let item = state.brandsList[i];

            if (item._id === state.toBeDeletedBrand._id) {
              index = i;
            }
          }

          if (index > -1) {
            state.brandsList.splice(index, 1);
          }
          state.toBeDeletedBrand = null;
        }
      })
      .addCase(bannerList.fulfilled, (state, action) => {
        state.bannerList = null;
        state.bannerList = action.payload.result;
      })
      .addCase(uploadImage.pending, (state, action) => {
        state.imageUploading = true;
      })
      .addCase(uploadImage.fulfilled, (state, action) => {
        state.imageUploading = false;
        state.image = action.payload.result;
        state.imageUploadError=false;
      })
      .addCase(uploadImage.rejected, (state, action) => {
        state.imageUploading = false;
        try {              
          let response = JSON.parse(action.payload.request.response);
          state.imageUploadError = response.message.message;
        } catch (e) {
          // state.imageUploadError = e.message;
          let statusText = action.payload.request.statusText;
          if(statusText == 'Request Entity Too Large'){
            state.imageUploadError = 'Upload image error! please upload smaller size';
          }else{
            state.imageUploadError = statusText;
          }
        } 
      })
      .addCase(saveBannerObj.pending, (state, action) => {
        state.bannerSaved = false;
      })
      .addCase(saveBannerObj.fulfilled, (state, action) => {
        if (action.payload.result) {
          state.bannerSaved = true;
          if (state.bannerList != null && state.bannerList.length > 0) {
            let index = -1;
            for (let i = 0; i < state.bannerList.length; i++) {
              let item = state.bannerList[i];
              if (item._id === action.payload.result._id) {
                index = i;
              }
            }

            if (index > -1) {
              state.bannerList[index] = action.payload.result;
            } else {
              state.bannerList.push(action.payload.result);
            }
          }
        }
      })
      .addCase(deleteBannerObj.pending, (state, action) => {
        
      })
      .addCase(deleteBannerObj.fulfilled, (state, action) => {

        if (action.payload.result) {
          state.bannerSaved = true;
          if (state.bannerList != null && state.bannerList.length > 0) {
            let index = -1;
            for (let i = 0; i < state.bannerList.length; i++) {
              let item = state.bannerList[i];
              if (item._id === action.payload.result._id) {
                index = i;
              }
            }

            if (index > -1) {
              state.bannerList.splice(index, 1);
            }
          }
        }
      })
      .addCase(getPlans.fulfilled, (state, action) => {
        state.plans = action.payload.result;
      })
      .addCase(updateUs.pending, (state, action) => {
        state.userUpdated = false;
      })
      .addCase(updateUs.fulfilled, (state, action) => {
        state.userUpdated = true;
      })
      .addCase(deleteActivityObj.pending, (state, action) => {
        
      })
      .addCase(getAllActivities.fulfilled, (state, action) => {
        state.activitiesList = null;
        if (action.payload.result) {
          state.activitiesList = action.payload.result;
        }
      })
      .addCase(getAllActivitiesCount.fulfilled, (state, action) => {
        state.activitiesCount = 0;
        state.activitiesCount = action.payload.result;
      })
      .addCase(deleteActivityObj.fulfilled, (state, action) => {

        if (action.payload.result) {
          if (state.activitiesList != null && state.activitiesList.length > 0) {
            let index = -1;
            for (let i = 0; i < state.activitiesList.length; i++) {
              let item = state.activitiesList[i];
              if (item._id === action.payload.result._id) {
                index = i;
              }
            }

            if (index > -1) {
              state.activitiesList.splice(index, 1);
            }
          }
        }
      })
      .addCase(getCustomers.fulfilled, (state, action) => {
        state.customersList = null;
        if (action.payload.result) {
          state.customersList = action.payload.result;
        }
      })
      .addCase(getCustomersCount.fulfilled, (state, action) => {
        state.customersCount = 0;
        state.customersCount = action.payload.result;
      })
      .addCase(deleteCustomerObj.fulfilled, (state, action) => {

        if (action.payload.result) {
          if (state.customersList != null && state.customersList.length > 0) {
            let index = -1;
            for (let i = 0; i < state.customersList.length; i++) {
              let item = state.customersList[i];
              if (item._id === action.payload.result._id) {
                index = i;
              }
            }

            if (index > -1) {
              state.customersList.splice(index, 1);
            }
          }
        }
      })
      .addCase(getAdminOrders.fulfilled, (state, action) => {
        state.adminOrdersList = null;
        if (action.payload.result) {
          state.adminOrdersList = action.payload.result;
        }
      })
      .addCase(getAdminOrdersCount.fulfilled, (state, action) => {
        state.adminOrdersCount = 0;
        state.adminOrdersCount = action.payload.result;
      }),
});

export default adminSlice.reducer;
export const {
  updatePanel,
  updatePagination,
  resetFeatures,
  updateBrandsPagination,
  resetBrandsFeatures,
  toBeDeleted,
  clearImage,
  updateImages,
  updateBannerSaved,
  updateUserUpdated,
  updateAdmin,
  updateActivitiesPagination,
  tobeDeletedActivity,
  updateCustomersPagination,
  tobeDeletedCustomer,
  updateLoginError,
  updateOtpFailed,
  resetUploadError,
  logout,
  updateAdminOrdersPagination,
} = adminSlice.actions;
