import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { saveCarts } from "../services/cart.service";
import { getCategories } from "../services/header.service";
import { localGetCarts, localCartCount, localDeleteCartObj, getUniqueId } from "../helpers/LocalCart";
import {
  getCount,
  getList,
  deleteWishlistObj,
  moveWishObj,
} from "../services/wishlist.service";
import {
  getCartList,
  getCartCount,
  deleteCartObj,
  moveCartObj,
} from "../services/cart.service";
import { getProductsById, getSearchLists } from "../services/product.service";
import {
  saveAndProceed,
  paymentSuccess,
  getOrderList,
  getOrderCount,
} from "../services/order.service";
import { getEverything } from "../services/category.service";

const user = JSON.parse(localStorage.getItem("user"));
const city = localStorage.getItem("city");
const area = localStorage.getItem("area");
const zip_code = localStorage.getItem("zip_code");

export const getAllCategories = createAsyncThunk(
  "header/getAllCategories",
  async (params, { getState, rejectWithValue }) => {
    try {
      const response = await getCategories();

      return response.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const getEveryCategories = createAsyncThunk(
  "header/getEveryCategories",
  async (mainCatId, { getState, rejectWithValue }) => {
    try {
      const response = await getEverything();

      return response.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const getWishes = createAsyncThunk(
  "header/getWishes",
  async (userId, { getState, rejectWithValue }) => {
    try {
      const response = await getList(userId);

      return response.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const getWishCount = createAsyncThunk(
  "header/getWishCount",
  async (userId, { getState, rejectWithValue }) => {
    try {
      const response = await getCount(userId);
      return response.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const saveToCart = createAsyncThunk(
  "header/saveToCart",
  async (cartObj, { getState, rejectWithValue }) => {
    try {
      const response = await saveCarts(cartObj);
      return response.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const getCarts = createAsyncThunk(
  "header/getCarts",
  async (userId, { getState, rejectWithValue }) => {

    if( userId ){
      try {
        const response = await getCartList(userId);

        return response.data;
      } catch (err) {
        return rejectWithValue(err);
      }
    }else{
      // return {result: localGetCarsts()};
    }
  }
);

export const getCartsCount = createAsyncThunk(
  "header/getCartsCount",
  async (userId, { getState, rejectWithValue }) => {
    if( userId ){
      try {
        const response = await getCartCount(userId);
        return response.data;
      } catch (err) {
        return rejectWithValue(err);
      }
    }else{
      // return {result: localCartCount()};
    }
  }
);

export const deleteCart = createAsyncThunk(
  "header/deleteCart",
  async (id, { getState, rejectWithValue }) => {

    if( id ){
      try {
        const response = await deleteCartObj({ id: id });
        return response.data;
      } catch (err) {
        return rejectWithValue(err);
      }
    }else{
      // return {result: localDeleteCartObj(id)};
    }
  }
);

export const moveCart = createAsyncThunk(
  "header/moveCart",
  async (obj, { getState, rejectWithValue }) => {

    try {
      const response = await moveCartObj(obj);
      return response.data;
    } catch (err) {
      return rejectWithValue(err);
    }    
  }
);

export const productById = createAsyncThunk(
  "header/productById",
  async (id, { getState, rejectWithValue }) => {
    try {
      const response = await getProductsById(id);
      return response.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const saveAndProceedForPayment = createAsyncThunk(
  "header/saveAndProceedForPayment",
  async (orderObj, { getState, rejectWithValue }) => {
    try {
      const response = await saveAndProceed(orderObj);
      return response.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const paymentSucceeded = createAsyncThunk(
  "header/paymentSucceeded",
  async (orderObj, { getState, rejectWithValue }) => {
    try {
      const response = await paymentSuccess(orderObj);
      return response.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const searchList = createAsyncThunk(
  "header/searchList",
  async (name, { getState, rejectWithValue }) => {
    try {
      const response = await getSearchLists(name);
      return response.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const deleteWishlist = createAsyncThunk(
  "header/deleteWishlist",
  async (id, { getState, rejectWithValue }) => {
    try {
      const response = await deleteWishlistObj({ id: id });
      return response.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const moveWish = createAsyncThunk(
  "header/moveWish",
  async (obj, { getState, rejectWithValue }) => {

    try {
      const response = await moveWishObj(obj);
      return response.data;
    } catch (err) {
      return rejectWithValue(err);
    }    
  }
);

export const getOrders = createAsyncThunk(
  "header/getOrders",
  async (userId, { getState, rejectWithValue }) => {
    try {
      const state = getState().header;
      const response = await getOrderList(userId, state.orderFeatures);

      return response.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const getOrdersCount = createAsyncThunk(
  "header/getOrderCount",
  async (userId, { getState, rejectWithValue }) => {
    try {
      const response = await getOrderCount(userId);
      return response.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

const initialState = {
  list: [],
  mainHeader: [],
  sideHeader: [],
  user: user,
  city: city,
  area: area,
  zip_code: zip_code,
  loading: false,
  savedToCart: false,
  prodDetails: null,
  stockItem: null,
  stock: null,
  cartItemRemoved: false,
  orderPaid: false,
  toBePaidOrder: null,
  searchList: [],
  orderFeatures: { ps: 12, sidx: 0, page: 1 },
  orderCount: 0,
  orderList: null,
  allCategories: [],
  mainHeaderWithCategories: [],
  sideHeaderWithCategories: [],
  updateLocation: '',
  cartsCount: 0,
  cartsList: [],
};

const headerSlice = createSlice({
  name: "Header",
  initialState: initialState,
  reducers: {
    update: (state, action) => {
      state.city = localStorage.getItem("city");
      const user = localStorage.getItem("user");
      if (user != null) {
        state.user = JSON.parse(user);
      } else {
        state.user = null;
      }
    },
    updateStock: (state, action) => {
      if (action.payload.item && action.payload.stock) {
        state.stockItem = action.payload.item;
        state.stock = action.payload.stock;
        // console.log(action.payload.item);

        //update stock in database
        const cartObj = {
          productId: action.payload.item.productId._id,
          userId: action.payload.item.userId != null ? action.payload.item.userId._id : getUniqueId(),
          stock: action.payload.stock,
          update : 1,
        };
        saveCarts( cartObj )
      }
    },
    updateCartItem: (state, action) => {
      state.cartItemRemoved = false;
    },
    updatePaymentId: (state, action) => {
      state.toBePaidOrder.razorpayPaymentId =
        action.payload.razorpay_payment_id;
      state.toBePaidOrder.razorpaySignature = action.payload.razorpay_signature;
    },
    clearSearchSelectors: (state, action) => {
      state.searchList = [];
    },
    updateSavedToCart: (state, action) => {
      state.savedToCart = false;
    },
    updateWishItem: (state, action) => {
      state.wishItemRemoved = false;
    },
    updatePagination: (state, action) => {
      state.orderFeatures.page = action.payload;
      state.orderFeatures.sidx =
        (state.orderFeatures.page - 1) * state.orderFeatures.ps;
    },
    resetFeatures: (state, action) => {
      state.orderFeatures.ps = 12;
      state.orderFeatures.sidx = 0;
      state.orderFeatures.page = 1;
    },    
    updateLocation: (state, action) => {
      state.updateLocation = action.payload;
    },
    resetOderPaid: (state, action) => {
      state.orderPaid = false;
    }
  },
  extraReducers: (builder) =>
    builder
      .addCase(getAllCategories.fulfilled, (state, action) => {
        if (action.payload) {
          state.list = action.payload.result;
          state.mainHeader = [];
          state.sideHeader = [];

          state.list.forEach((head) => {
            if (head.mainHeader) {
              state.mainHeader.push(head);
            } else {
              state.sideHeader.push(head);
            }
          });
        }
      })
      .addCase(getWishes.fulfilled, (state, action) => {
        state.wishlists = action.payload.result;
      })
      .addCase(getWishCount.fulfilled, (state, action) => {
        state.wishCount = action.payload.result;
      })
      .addCase(saveToCart.pending, (state, action) => {
        state.savedToCart = false;
      })
      .addCase(saveToCart.fulfilled, (state, action) => {
        state.savedToCart = true;
      })
      .addCase(getCarts.fulfilled, (state, action) => {
        state.cartsList = action.payload.result;
      })
      .addCase(getCartsCount.fulfilled, (state, action) => {
        state.cartsCount = action.payload.result;
      })
      .addCase(productById.pending, (state, action) => {
        state.cantIncCount = false;
      })
      .addCase(productById.fulfilled, (state, action) => {
        if (
          action.payload &&
          action.payload.result &&
          action.payload.result.stock >= state.stock
        ) {
          state.cantIncCount = false;
          let found = null;
          for (var i = 0; i < state.cartsList.length && !found; i++) {
            let item = state.cartsList[i];
            if (item.productId._id === action.payload.result._id) {
              found = item;
            }

            if (found) {
              found.stock = state.stock;
            }
          }
          state.stock = null;
          state.stockItem = null;
        } else {
          state.stock = null;
          state.stockItem = null;
          state.cantIncCount = true;
        }
      })
      .addCase(deleteCart.pending, (state, action) => {
        state.cartItemRemoved = false;
      })
      .addCase(deleteCart.fulfilled, (state, action) => {
        state.cartItemRemoved = true;
      })
      .addCase(moveCart.pending, (state, action) => {
        
      })
      .addCase(moveCart.fulfilled, (state, action) => {
        
      })
      .addCase(saveAndProceedForPayment.fulfilled, (state, action) => {
        state.toBePaidOrder = action.payload.result;
      })
      .addCase(paymentSucceeded.fulfilled, (state, action) => {
        state.toBePaidOrder = null;
        state.orderPaid = true;
      })
      .addCase(searchList.fulfilled, (state, action) => {
        state.searchList = [];
        if (action.payload.result) {
          state.searchList = action.payload.result;
        }
      })
      .addCase(deleteWishlist.pending, (state, action) => {
        state.wishItemRemoved = false;
      })
      .addCase(deleteWishlist.fulfilled, (state, action) => {
        state.wishItemRemoved = true;
      })
      .addCase(moveWish.pending, (state, action) => {
        
      })
      .addCase(moveWish.fulfilled, (state, action) => {
        
      })
      .addCase(getOrders.fulfilled, (state, action) => {
        state.orderList = action.payload.result;
      })
      .addCase(getOrdersCount.fulfilled, (state, action) => {
        state.orderCount = action.payload.result;
      })
      .addCase(getEveryCategories.fulfilled, (state, action) => {
        state.allCategories = action.payload.result;
        state.mainHeaderWithCategories = [];
        state.sideHeaderWithCategories = [];

        state.allCategories.forEach((head) => {
          if (head.mainHeader) {
            state.mainHeaderWithCategories.push(head);
          } else {
            state.sideHeaderWithCategories.push(head);
          }
        });
        
        localStorage.setItem("allCats", JSON.stringify(state.allCategories));
      }),
});

export default headerSlice.reducer;
export const {
  update,
  updateStock,
  updateCartItem,
  updatePaymentId,
  clearSearchSelectors,
  updateSavedToCart,
  updateWishItem,
  updatePagination,
  resetFeatures,
  updateLocation,
  resetOderPaid,
} = headerSlice.actions;
