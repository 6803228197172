import StoreCard from "./StoreCard";

const OfferProductList = (props) => {
  const platItem = props.items != null && props.items.length > 0
    ? props.items.map((item) => {
        return (
          <div key={item._id} className="col-md-4 mt-2">
            <StoreCard item={item}></StoreCard>
          </div>
        );
      })
    : null;

  return (
    <div className="container">
      <div className="row">{platItem}</div>
    </div>
  );
};

export default OfferProductList;
