import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Collapse } from "reactstrap";
import classes from "../category/Fillter.module.css";
import Category from "../shop/common/category";
const OfferCatList = (props) => {
  const [setIsOpenbrand, setIsOpenBrand] = useState(true);
  const toggleDiscount = () => setIsOpenBrand(!setIsOpenbrand);
  const [setIsOpencategory, setIsOpenCategory] = useState(true);
  const toggleCategory = () => setIsOpenCategory(!setIsOpencategory);
  const listItems =
    props.items != null
      ? props.items.map((item) => {
          return (
            <li
              className="p-0"
              key={item._id}
              onClick={() => props.onCategoryUpdate(item)}
            >
              <a
                className={
                  item._id === props.selectedCatId ? "active_anchor" : null
                }
              >
                {item.name}
              </a>
            </li>
          );
        })
      : null;

  return (
    <div className>
      <div className="product-filter" style={{ marginTop: "20px" }}>
        <div className>
          <div className="collection-collapse-block open">
            <h3 className="collapse-block-title" onClick={toggleDiscount}>
              Fillter By Discount
            </h3>
            <Collapse isOpen={setIsOpenbrand}>
              <Link className="col-md-6 mb-3" to={"/offer/80/60"}>
                <div
                  className={
                    props.max === "80"
                      ? "ONM_offer-filter active"
                      : "ONM_offer-filter"
                  }
                >
                  60-80%
                </div>
              </Link>
              <Link className="col-md-6 mb-3" to={"/offer/60/40"}>
                <div
                  className={
                    props.max === "60"
                      ? "ONM_offer-filter active"
                      : "ONM_offer-filter"
                  }
                >
                  40-60%
                </div>
              </Link>
              <Link className="col-md-6 mb-3" to={"/offer/40/20"}>
                <div
                  className={
                    props.max === "40"
                      ? "ONM_offer-filter active"
                      : "ONM_offer-filter"
                  }
                >
                  20-40%
                </div>
              </Link>
              <Link className="col-md-6 mb-3" to={"/offer/20/0"}>
                <div
                  className={
                    props.max === "20"
                      ? "ONM_offer-filter active"
                      : "ONM_offer-filter"
                  }
                >
                  0-20%
                </div>
              </Link>
            </Collapse>
          </div>
        </div>

        <div className>
          <div className>
            <div className="collection-collapse-block open">
              <h3 className="collapse-block-title" onClick={toggleCategory}>
                Fillter By Discount
              </h3>
              <Collapse isOpen={setIsOpencategory}>
                <ul
                  className={`ONM_left-filters  ${classes.fillter__page} ${classes.cat__fillter} collection-collapse-block-content`}
                  style={{ marginTop: "20px" }}
                >
                  {listItems}
                </ul>
              </Collapse>
            </div>
          </div>
        </div>

        <div className={`row ${classes.clear__fillters}`}>
          <div className="col-md-12">
            <a
              className={classes.clear_button}
              href="/offer"
              onClick={() => props.onCategoryClear()}
            >
              Clear Filters
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OfferCatList;
