import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { saveCarts } from "../services/cart.service";
import {
  getBestOffersByCat,
  getAll,
  getBestOffersBySubCat,
  getBestChildOffers,
  getAllFilters,
} from "../services/category.service";
import { getBanners } from "../services/home.service";
import {
  getProductsByUser,
  getProductsCountByUser,
} from "../services/product.service";
import { getBrandsByCategory } from "../services/user.service";
import { save } from "../services/wishlist.service";

const user = JSON.parse(localStorage.getItem("user"));

export const getAllFilters1 = createAsyncThunk(
  "category/getAllFilters1",
  async (mainCatId, { getState, rejectWithValue }) => {
    try {
      const response = await getAllFilters();
      return response.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const getAllCategories = createAsyncThunk(
  "category/getAllCategories",
  async (mainCatId, { getState, rejectWithValue }) => {
    try {
      const response = await getAll(mainCatId);

      return response.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const bannerList = createAsyncThunk(
  "category/bannerList",
  async (page, { getState, rejectWithValue }) => {
    try {
      const response = await getBanners(page);
      return response.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const getOffersByCategory = createAsyncThunk(
  "category/getOffersByCategory",
  async (params, { getState, rejectWithValue }) => {
    try {
      const response = await getBestOffersByCat(params.name, params.mainCatId);
      return response.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const getOffersByCategory1 = createAsyncThunk(
  "category/getOffersByCategory1",
  async (params, { getState, rejectWithValue }) => {
    try {
      const response = await getBestOffersByCat(params.name, params.mainCatId);
      return response.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const getOffersByCategory2 = createAsyncThunk(
  "category/getOffersByCategory2",
  async (params, { getState, rejectWithValue }) => {
    try {
      const response = await getBestOffersByCat(params.name, params.mainCatId);
      return response.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const getOffersByCategory3 = createAsyncThunk(
  "category/getOffersByCategory3",
  async (params, { getState, rejectWithValue }) => {
    try {
      const response = await getBestOffersByCat(params.name, params.mainCatId);
      return response.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const getOffersByCategory4 = createAsyncThunk(
  "category/getOffersByCategory4",
  async (params, { getState, rejectWithValue }) => {
    try {
      const response = await getBestOffersByCat(params.name, params.mainCatId);
      return response.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const getOffersByCategoryList = createAsyncThunk(
  "category/getOffersByCategoryList",
  async (params, { getState, rejectWithValue }) => {
    try {
      const response = await getBestOffersByCat(params.name, params.mainCatId);
      return response.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const getProducts = createAsyncThunk(
  "category/getProducts",
  async (userId, { getState, rejectWithValue }) => {
    try {
      const state = getState().category;
      const response = await getProductsByUser(
        userId,
        state.features.catId,
        state.features,
        state.banner
      );

      return response.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const getProductsCount = createAsyncThunk(
  "category/getProductsCount",
  async (userId, { getState, rejectWithValue }) => {
    try {
      const state = getState().category;
      const response = await getProductsCountByUser(
        userId,
        "",
        state.features.catId,
        state.features
      );

      return response.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const catBannerList = createAsyncThunk(
  "category/catBannerList",
  async (page, { getState, rejectWithValue }) => {
    try {
      const response = await getBanners(page);
      return response.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const offerFor1SubCat = createAsyncThunk(
  "category/offerFor1SubCat",
  async (subCatId, { getState, rejectWithValue }) => {
    try {
      const response = await getBestOffersBySubCat(subCatId);
      return response.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const offerFor2SubCat = createAsyncThunk(
  "category/offerFor2SubCat",
  async (subCatId, { getState, rejectWithValue }) => {
    try {
      const response = await getBestOffersBySubCat(subCatId);
      return response.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const offerFor3SubCat = createAsyncThunk(
  "category/offerFor3SubCat",
  async (subCatId, { getState, rejectWithValue }) => {
    try {
      const response = await getBestOffersBySubCat(subCatId);
      return response.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const offerFor4SubCat = createAsyncThunk(
  "category/offerFor4SubCat",
  async (subCatId, { getState, rejectWithValue }) => {
    try {
      const response = await getBestOffersBySubCat(subCatId);
      return response.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const offerFor5SubCat = createAsyncThunk(
  "category/offerFor5SubCat",
  async (subCatId, { getState, rejectWithValue }) => {
    try {
      const response = await getBestOffersBySubCat(subCatId);
      return response.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const getChildOffer = createAsyncThunk(
  "category/getChildOffer",
  async (subId, { getState, rejectWithValue }) => {
    try {
      const response = await getBestChildOffers(subId);
      return response.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const subBannerList = createAsyncThunk(
  "category/subBannerList",
  async (page, { getState, rejectWithValue }) => {
    try {
      const response = await getBanners(page);
      return response.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const saveWishlist = createAsyncThunk(
  "category/saveWishlist",
  async (wishObj, { getState, rejectWithValue }) => {
    try {
      const response = await save(wishObj);
      return response.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const saveToCart = createAsyncThunk(
  "category/saveToCart",
  async (cartObj, { getState, rejectWithValue }) => {
    try {
      const response = await saveCarts(cartObj);
      return response.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

const initialState = {
  list: [],
  bannerList: [],
  row1Banner: null,
  row2Banner: null,
  row3Banner: null,
  row4Banner: null,
  row5Banner: null,
  row6Banner: null,
  top5Cat: null,
  offers0List: null,
  offers1List: null,
  offers2List: null,
  offers3List: null,
  offers4List: null,
  offerCatList: null,
  prodList: null,
  selectedBanner: null,
  selected: null,
  user: user,
  loading: false,
  count: 0,
  totalListCount: 0,
  features: { ps: 12, sidx: 0, page: 1, catId: null, brandsList: null },
  subOfferList: null,
  catBannerList: null,
  childCatOffer: null,
  subBanner: null,
  wishAdded: false,
  savedToCart: false,
  userLocNotFound: 0,
  banner: true,
};

export const getBrandsByCat = createAsyncThunk(
  "merchant/getBrandsByCat",
  async (param, { getState, rejectWithValue }) => {
    try {
      const response = await getBrandsByCategory(param);
      return response.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

const categorySlice = createSlice({
  name: "Category",
  initialState: initialState,
  reducers: {
    updateSelected: (state, action) => {
      state.selected = action.payload;
    },
    updatePriceFilter: (state, action) => {
      state.features.pmin = action.payload.pmin;
      if (action.payload.pmax) {
        state.features.pmax = action.payload.pmax;
      } else {
        state.features.pmax = null;
      }
    },
    updateOfferFilter: (state, action) => {
      state.features.omin = action.payload.omin;
      state.features.omax = action.payload.omax;
    },
    updatePagination: (state, action) => {
      state.features.page = action.payload;
      state.features.sidx = (state.features.page - 1) * state.features.ps;
    },
    updateCatId: (state, action) => {
      state.features.catId = action.payload;
    },
    updateSubCatId: (state, action) => {
      state.features.subCatId = action.payload;
    },
    updatemainCatId: (state, action) => {
      state.features.mainCatId = action.payload;
    },
    updateChildCatId: (state, action) => {
      state.features.childCatId = action.payload;
    },
    resetCatAndSubCat: (state, action) => {
      state.features.catId = null;
      state.features.subCatId = null;
    },
    updateBrand: (state, action) => {
      state.features.brand = action.payload;
    },
    resetFeatures: (state, action) => {
      state.features.childCatId = null;
      state.features.ps = 12;
      state.features.sidx = 0;
      state.features.page = 1;
      state.features.omin = null;
      state.features.omax = null;
      state.features.pmin = null;
      state.features.pmax = null;
      state.features.color = null;
      state.features.caseCover = null;
      state.features.caseCoverMatType = null;
      state.features.caseFeature = null;
      state.features.screenProtector = null;
      state.features.batteryType = null;
      state.features.tvPlatforms = null;
      state.features.tvDisplay = null;
      state.features.tvHdFormats = null;
      state.features.tvModelYears = null;
      state.features.tvConnectivity = null;
      state.features.cpuType = null;
      state.features.usage = null;
      state.features.os = null;
      state.features.memorySize = null;
      state.features.brand = null;
    },
    updateOtherFilters: (state, action) => {
      state.features.color = null;
      state.features.caseCover = null;
      state.features.caseCoverMatType = null;
      state.features.caseFeature = null;
      state.features.screenProtector = null;
      state.features.batteryType = null;
      state.features.tvPlatforms = null;
      state.features.tvDisplay = null;
      state.features.tvHdFormats = null;
      state.features.tvModelYears = null;
      state.features.tvConnectivity = null;
      state.features.cpuType = null;
      state.features.usage = null;
      state.features.os = null;
      state.features.memorySize = null;

      if (action.payload) {
        if (action.payload.COLORS) {
          action.payload.COLORS.forEach((el, index) => {
            state.features.color = state.features.color
              ? state.features.color + "," + el.code
              : el.code;
          });
        }

        if (action.payload.CASECOVERS) {
          action.payload.CASECOVERS.forEach((el, index) => {
            state.features.caseCover = state.features.caseCover
              ? state.features.caseCover + "," + el.code
              : el.code;
          });
        }

        if (action.payload.CASECOVERMATERIALTYPES) {
          action.payload.CASECOVERMATERIALTYPES.forEach((el, index) => {
            state.features.caseCoverMatType = state.features.caseCoverMatType
              ? state.features.caseCoverMatType + "," + el.code
              : el.code;
          });
        }

        if (action.payload.CASEFEATURES) {
          action.payload.CASEFEATURES.forEach((el, index) => {
            state.features.caseFeature = state.features.caseFeature
              ? state.features.caseFeature + "," + el.code
              : el.code;
          });
        }

        if (action.payload.SCREENPROTECTORMATERIALTYPE) {
          action.payload.SCREENPROTECTORMATERIALTYPE.forEach((el, index) => {
            state.features.screenProtector = state.features.screenProtector
              ? state.features.screenProtector + "," + el.code
              : el.code;
          });
        }

        if (action.payload.BATTERYTYPE) {
          action.payload.BATTERYTYPE.forEach((el, index) => {
            state.features.batteryType = state.features.batteryType
              ? state.features.batteryType + "," + el.code
              : el.code;
          });
        }

        if (action.payload.TVPLATFORMS) {
          action.payload.TVPLATFORMS.forEach((el, index) => {
            state.features.tvPlatforms = state.features.tvPlatforms
              ? state.features.tvPlatforms + "," + el.code
              : el.code;
          });
        }

        if (action.payload.TVDISPLAYTECH) {
          action.payload.TVDISPLAYTECH.forEach((el, index) => {
            state.features.tvDisplay = state.features.tvDisplay
              ? state.features.tvDisplay + "," + el.code
              : el.code;
          });
        }

        if (action.payload.TVHDFORMATS) {
          action.payload.TVHDFORMATS.forEach((el, index) => {
            state.features.tvHdFormats = state.features.tvHdFormats
              ? state.features.tvHdFormats + "," + el.code
              : el.code;
          });
        }

        if (action.payload.TVMODELYEARS) {
          action.payload.TVMODELYEARS.forEach((el, index) => {
            state.features.tvModelYears = state.features.tvModelYears
              ? state.features.tvModelYears + "," + el.code
              : el.code;
          });
        }

        if (action.payload.TVCONNECTIVITY) {
          action.payload.TVCONNECTIVITY.forEach((el, index) => {
            state.features.tvConnectivity = state.features.tvConnectivity
              ? state.features.tvConnectivity + "," + el.code
              : el.code;
          });
        }

        if (action.payload.CPUTYPE) {
          action.payload.CPUTYPE.forEach((el, index) => {
            state.features.cpuType = state.features.cpuType
              ? state.features.cpuType + "," + el.code
              : el.code;
          });
        }

        if (action.payload.USAGE) {
          action.payload.USAGE.forEach((el, index) => {
            state.features.usage = state.features.usage
              ? state.features.usage + "," + el.code
              : el.code;
          });
        }

        if (action.payload.OPERATINGSYSTEM) {
          action.payload.OPERATINGSYSTEM.forEach((el, index) => {
            state.features.os = state.features.os
              ? state.features.os + "," + el.code
              : el.code;
          });
        }

        if (action.payload.MEMORYSIZE) {
          action.payload.MEMORYSIZE.forEach((el, index) => {
            state.features.memorySize = state.features.memorySize
              ? state.features.memorySize + "," + el.code
              : el.code;
          });
        }
      }
    },
    updateWishlistAdded: (state, action) => {
      state.wishAdded = false;
    },
    updateLoggedInUser: (state, action) => {
      state.city = localStorage.getItem("city");
      const user = localStorage.getItem("user");
      if (user != null) {
        state.user = JSON.parse(user);
      } else {
        state.user = null;
      }
    },
    updateLoggedInCategory: (state, action) => {
      state.city = localStorage.getItem("city");
      const user = localStorage.getItem("user");
      if (user != null) {
        state.user = JSON.parse(user);
      } else {
        state.user = null;
      }
    },
    updateCartAdded: (state, action) => {
      state.savedToCart = false;
    },
    resetUserLocNotFound: (state, action) => {
      state.userLocNotFound = 0;
    },
  },
  extraReducers: (builder) =>
    builder
      .addCase(getProducts.pending, (state, action) => {
        state.loading = true;
        // state.prodList = null;
      })
      .addCase(getProducts.fulfilled, (state, action) => {
        state.loading = false;

        if (action.payload && action.payload.result != null) {
          state.prodList = action.payload.result;
        } else {
          state.prodList = null;
        }
      })
      .addCase(getProductsCount.pending, (state, action) => {
        state.count = 0;
      })
      .addCase(getProductsCount.fulfilled, (state, action) => {
        if (action.payload) {
          state.count = action.payload.result;
        } else {
          state.count = 0;
        }
      })
      .addCase(getOffersByCategoryList.fulfilled, (state, action) => {
        state.offers0List = null;
        if (action.payload.result && action.payload.result.length > 0) {
          state.offerCatList = action.payload.result;
          state.userLocNotFound = action.payload.userNotFound;
        } else {
          state.offerCatList = null;
        }
      })
      .addCase(getOffersByCategory.fulfilled, (state, action) => {
        state.offers0List = null;
        if (action.payload.result && action.payload.result.length > 0) {
          state.offers0List = action.payload.result;
          state.userLocNotFound = action.payload.userNotFound;
        }
      })
      .addCase(getOffersByCategory1.fulfilled, (state, action) => {
        state.offers1List = null;
        if (action.payload.result && action.payload.result.length > 0) {
          state.offers1List = action.payload.result;
          state.userLocNotFound = action.payload.serNotFound;
        }
      })
      .addCase(getOffersByCategory2.fulfilled, (state, action) => {
        state.offers2List = null;
        if (action.payload.result && action.payload.result.length > 0) {
          state.offers2List = action.payload.result;
          state.userLocNotFound = action.payload.userNotFound;
        }
      })
      .addCase(getOffersByCategory3.fulfilled, (state, action) => {
        state.offers3List = null;
        if (action.payload.result && action.payload.result.length > 0) {
          state.offers3List = action.payload.result;
          state.userLocNotFound = action.payload.userNotFound;
        }
      })
      .addCase(getOffersByCategory4.fulfilled, (state, action) => {
        state.offers4List = null;
        if (action.payload.result && action.payload.result.length > 0) {
          state.offers4List = action.payload.result;
          state.userLocNotFound = action.payload.userNotFound;
        }
      })
      .addCase(getAllCategories.fulfilled, (state, action) => {
        if (action.payload) {
          state.list = action.payload.result;
          state.top5Cat = {};
          if (state.list && state.list.length > 0) {
            let len = state.list.length > 5 ? 5 : state.list.length;

            for (let i = 0; i < len; i++) {
              state.top5Cat["CAT" + i] = {
                header: state.list[i],
                headerName: "Best Offers on " + state.list[i].name,
              };
            }

            state.selected = state.list[0];
          } else {
            state.selected = null;
            state.top5Cat = null;
          }
        }
      })
      .addCase(bannerList.fulfilled, (state, action) => {
        state.selectedBanner = null;
        state.row1Banner = null;
        state.row2Banner = null;
        state.row3Banner = null;
        state.row4Banner = null;
        state.row5Banner = null;
        state.row6Banner = null;

        if (action.payload) {
          state.bannerList = action.payload.result;
          if (state.bannerList != null && state.bannerList.length > 0) {
            state.bannerList.forEach((banner, index) => {
              if (banner.row === 1) {
                if (!state.row1Banner) state.row1Banner = [];

                state.row1Banner.push(banner);
              } else if (banner.row === 2) {
                if (!state.row2Banner) state.row2Banner = [];

                state.row2Banner.push(banner);
              } else if (banner.row === 3) {
                state.row3Banner = banner;
              } else if (banner.row === 4) {
                state.row4Banner = banner;
              } else if (banner.row === 5) {
                state.row5Banner = banner;
              } else if (banner.row === 6) {
                state.row6Banner = banner;
              }
            });
          }
        }
      })
      .addCase(catBannerList.pending, (state, action) => {
        state.catBannerList = null;
      })
      .addCase(catBannerList.fulfilled, (state, action) => {
        state.catBannerList = null;

        if (action.payload) {
          if (
            action.payload.result != null &&
            action.payload.result.length > 0
          ) {
            state.catBannerList = {};

            action.payload.result.forEach((banner, index) => {
              if (banner.row === 1) {
                if (!state.catBannerList.row1Banner)
                  state.catBannerList.row1Banner = [];

                state.catBannerList.row1Banner.push(banner);
              } else if (banner.row === 2) {
                if (!state.catBannerList.row2Banner)
                  state.catBannerList.row2Banner = [];

                state.catBannerList.row2Banner.push(banner);
              } else if (banner.row === 3) {
                state.catBannerList.row3Banner = banner;
              } else if (banner.row === 4) {
                state.catBannerList.row4Banner = banner;
              } else if (banner.row === 5) {
                state.catBannerList.row5Banner = banner;
              } else if (banner.row === 6) {
                state.catBannerList.row6Banner = banner;
              }
            });
          }
        }
      })
      .addCase(offerFor1SubCat.fulfilled, (state, action) => {
        if (!state.subOfferList) state.subOfferList = {};

        if (action.payload.result && action.payload.result.length > 0) {
          state.subOfferList.offer1 = action.payload.result;
        } else {
          state.subOfferList.offer1 = null;
        }
      })
      .addCase(offerFor2SubCat.fulfilled, (state, action) => {
        if (!state.subOfferList) state.subOfferList = {};

        if (action.payload.result && action.payload.result.length > 0) {
          state.subOffer1List.offer2 = action.payload.result;
        } else {
          state.subOfferList.offer2 = null;
        }
      })
      .addCase(offerFor3SubCat.fulfilled, (state, action) => {
        if (!state.subOfferList) state.subOfferList = {};

        if (action.payload.result && action.payload.result.length > 0) {
          state.subOffer1List.offer3 = action.payload.result;
        } else {
          state.subOfferList.offer3 = null;
        }
      })
      .addCase(offerFor4SubCat.fulfilled, (state, action) => {
        if (!state.subOfferList) state.subOfferList = {};

        if (action.payload.result && action.payload.result.length > 0) {
          state.subOffer1List.offer4 = action.payload.result;
        } else {
          state.subOfferList.offer4 = null;
        }
      })
      .addCase(offerFor5SubCat.fulfilled, (state, action) => {
        if (!state.subOfferList) state.subOfferList = {};

        if (action.payload.result && action.payload.result.length > 0) {
          state.subOffer1List.offer5 = action.payload.result;
        } else {
          state.subOfferList.offer5 = null;
        }
      })
      .addCase(getChildOffer.fulfilled, (state, action) => {
        if (!state.childCatOffer) state.childCatOffer = {};

        if (action.payload.result && action.payload.result.length > 0) {
          state.childCatOffer = action.payload.result;
        }
      })
      .addCase(getAllFilters1.fulfilled, (state, action) => {
        if (action.payload.result) {
          state.filters = action.payload.result;
        }
      })
      .addCase(subBannerList.fulfilled, (state, action) => {
        state.subBanner = null;

        if (
          action.payload &&
          action.payload.result != null &&
          action.payload.result.length > 0
        ) {
          state.subBanner = action.payload.result[0];
        }
      })
      .addCase(saveWishlist.pending, (state, action) => {
        state.wishAdded = false;
      })
      .addCase(saveWishlist.fulfilled, (state, action) => {
        state.wishAdded = true;
      })
      .addCase(getBrandsByCat.fulfilled, (state, action) => {
        state.brandsList = action.payload.result;
      })
      .addCase(saveToCart.pending, (state, action) => {
        state.savedToCart = false;
      })
      .addCase(saveToCart.fulfilled, (state, action) => {
        state.savedToCart = true;
      }),
});

export default categorySlice.reducer;
export const {
  updateSelected,
  updatePriceFilter,
  updatePagination,
  updateCatId,
  resetFeatures,
  updateOfferFilter,
  updateSubCatId,
  updateChildCatId,
  resetCatAndSubCat,
  updateOtherFilters,
  updateBrand,
  updateWishlistAdded,
  updateLoggedInUser,
  updateLoggedInCategory,
  updateCartAdded,
  resetUserLocNotFound,
  updatemainCatId,
} = categorySlice.actions;
