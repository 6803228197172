import React, { Fragment, useEffect } from "react";
import { Link } from "react-router-dom";
import CartHeader from "../headers/common/cart-header";
import { useDispatch, useSelector } from "react-redux";
import { getUniqueId } from "../../helpers/LocalCart";
import {
  deleteCart,
  saveToCart,
  getCarts,
  getCartsCount,
  productById,
  updateStock,
  updateCartItem,
} from "../../slices/headerSlice";
import { Media } from "reactstrap";

const CartContainer = ({ icon, headerstate }) => {
  const symbol = "rs";
  const headerStateData = useSelector((state) => state.header);
  const categoryState = useSelector((state) => state.category);
  var cartList = headerStateData.cartsList;

  const total = 123;
  const dispatch = useDispatch();
  const totalPrice = () => {
    let totalPrice = 0;
    cartList &&
      cartList.forEach((cart) => {
        if (cart.productId != null) {
          totalPrice +=
            cart.productId.stock > 0
              ? cart.stock *
                (cart.productId.price -
                  (cart.productId.price * cart.productId.offer) / 100)
              : 0;
        }
      });

    return totalPrice.toFixed(2);
  };
  useEffect(() => {
    if (headerstate.user == null) {
      dispatch(getCartsCount(getUniqueId()));
      dispatch(getCarts(getUniqueId()));
    } else {
      dispatch(getCartsCount(headerstate.user._id));
      dispatch(getCarts(headerstate.user._id));
    }
  }, [categoryState.savedToCart, dispatch]);
  return (
    <Fragment>
      <li className="onhover-div mobile-cart">
        <div className="cart-qty-cls">{headerstate.cartsCount || 0}</div>
        <Link href={`/page/account/cart`}>
          <div>
            <Media src={icon} className="img-fluid" alt="" />
            <i className="fa fa-shopping-cart"></i>
          </div>
        </Link>
        <ul className="show-div shopping-cart">
          {cartList &&
            cartList.map((item, index) => (
              <CartHeader key={index} item={item} />
            ))}
          {cartList && cartList.length > 0 ? (
            <div>
              <li>
                <div className="total">
                  <h5>
                    subtotal :{" "}
                    <span>
                      {symbol} :<strong>{totalPrice()}</strong>
                    </span>
                  </h5>
                </div>
              </li>
              <li>
                <div className="buttons view-cart">
                  <Link to={`/cart`}>
                    <a>view cart</a>
                  </Link>

                  {headerstate.user && (
                    <Link to={`/checkout`}>
                      <a className="checkout">checkout</a>
                    </Link>
                  )}
                </div>
              </li>
            </div>
          ) : (
            <li>
              <h5>Your cart is currently empty.</h5>
            </li>
          )}
        </ul>
      </li>
    </Fragment>
  );
};

export default CartContainer;
