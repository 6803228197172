const setDirectCheckout = (isDirect) => {
    localStorage.setItem('is_direct_checkout', isDirect);
}

const isDirectCheckout = () => {
    let is_direct_checkout = parseInt( localStorage.getItem('is_direct_checkout') );
    // console.log(carts);
    return is_direct_checkout;
}

const setDirectCheckoutItem = (productId) => {
    localStorage.setItem('direct_checkout_item', productId);
}

const getDirectCheckoutItem = () => {
    let direct_checkout_item = localStorage.getItem('direct_checkout_item');
    // console.log(carts);
    return direct_checkout_item;
}

export {
    setDirectCheckout,
    isDirectCheckout,
    setDirectCheckoutItem,
    getDirectCheckoutItem
}