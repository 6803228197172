// import StoreCardCat from "./StoreCardCat";
import ProductCard from "./ProductCard";

const ProductList = (props) => {
  const col = props.type === "search" ? "3" : "4";
  const platItem =
    props.items != null && props.items.length > 0
      ? props.items.map((item) => {
          return (
            <div key={item._id} className={`col-md-${col} mt-2`}>
              {/* <StoreCardCat item={item} onStoreUpdate={props.onStoreUpdate}
            userObj={props.userObj}></StoreCardCat> */}
              <ProductCard
                item={item}
                onStoreUpdate={props.onStoreUpdate}
                userObj={props.userObj}
                addToWishlist={props.addToWishlist}
                addToCart={props.addToCart}
              ></ProductCard>
            </div>
          );
        })
      : null;

  return (
    <div className="container">
      <div className="row">{platItem}</div>
    </div>
  );
};

export default ProductList;
