import { useEffect, useState } from "react";
import {
  FaHeart,
  FaMinus,
  FaMinusSquare,
  FaPhone,
  FaPlusSquare,
  FaPlus,
  FaShoppingCart,
  FaStar,
  FaTrash,
} from "react-icons/fa";
import { Link } from "react-router-dom";
import ModalMap from "../components/map/modalMap";
import { BASE_URL, BASE_API_URL } from "../services/constants";
import PhoneContainer from "./PhoneContainer";
import { getProductsById } from "../services/product.service";
// import { useState } from "react/cjs/react.production.min";

const WishCartProductCard = (props) => {
  let baseUri = BASE_URL;

  const [product, setProduct] = useState(props.item.productId);

  const imgPath = (img) => {
    let path = img;

    if (path.indexOf("https://") > -1) {
      return path;
    } else if (path) {
      return BASE_API_URL + "/" + path;
    } else {
      return baseUri + "files/noimg.png";
    }
  };

  useEffect(() => {
    if (typeof product != "object") {
      getProductsById(product).then((res) => {
        setProduct(res.data.result);
      });
    }
  }, [product]);

  const roundItOff = (num) => {
    return Math.round(num);
  };

  const onChangeStock = (e, value) => {
    props.updateCartObj(props.item, e && e.target ? e.target.value : value);
  };

  const calculatePrice = () => {
    let price = 0;
    if (product != null) {
      price = product.price - product.price * (product.offer / 100);
    }
    return price.toFixed(2);
  };

  const itemPrice = () => {
    let price = calculatePrice();
    let regular_price = product != null ? product.price : 0;
    var sale_price = 0;

    if (price != regular_price) {
      sale_price = price;
    }

    return (
      <>
        {sale_price ? (
          <>
            <span className="sale-price">Rs. {sale_price}</span>
            <span className="regular-price small-cap">Rs. {regular_price}</span>
          </>
        ) : (
          <span className="regular-price">Rs. {price}</span>
        )}
      </>
    );
  };

  const item_subtotal = () => {
    let price = calculatePrice();
    let qty = props.item.stock;
    let subtotal = (price * qty).toFixed(2);

    return <span className="regular-price">Rs. {subtotal}</span>;
  };

  return (
    <>
      <td>
        <div className="item-img">
          {typeof product === "object" && product != null ? (
            <>
              <Link to={"/product/" + product._id}>
                {product.imageId != null ? (
                  <img
                    className="card-img-top"
                    src={imgPath(product.imageId[0].path)}
                    alt="Card"
                  />
                ) : (
                  <img
                    className="card-img-top"
                    src={baseUri + "files/noimg.png"}
                    alt="Card"
                  />
                )}
              </Link>
              {/* <div className="text-right ONM_review-star">
             <FaStar
               className={
                 roundItOff(product.star || 0) >= 1
                   ? "OMN-star-active mr-2"
                   : "ONM_icon_color-empty mr-2"
               }
             ></FaStar>
             <FaStar
               className={
                 roundItOff(product.star || 0) >= 2
                   ? "OMN-star-active mr-2"
                   : "ONM_icon_color-empty mr-2"
               }
             ></FaStar>
             <FaStar
               className={
                 roundItOff(product.star || 0) >= 3
                   ? "OMN-star-active mr-2"
                   : "ONM_icon_color-empty mr-2"
               }
             ></FaStar>
             <FaStar
               className={
                 roundItOff(product.star || 0) === 4
                   ? "OMN-star-active mr-2"
                   : "ONM_icon_color-empty mr-2"
               }
             ></FaStar>{" "}
           </div> */}
            </>
          ) : null}
        </div>
      </td>
      <td>
        <div className="item-desc row">
          <h4 className="item-title col-md-12">
            {typeof product === "object" && product != null ? (
              <Link to={"/product/" + product._id}>{product.name}</Link>
            ) : (
              <>product removed</>
            )}
          </h4>
          {/* <p className="card-text col-md-12">
            {props.item.userId ? props.item.userId.address : ""}
          </p> */}
          {/* <div className="ONM_icon_color ONM_card-icons-container">
            <ModalMap size={14} item={product}></ModalMap>
            <PhoneContainer item={product}></PhoneContainer>
          </div> */}
        </div>
      </td>
      <td>
        <div className="item-price">
          <h3>{itemPrice()}</h3>
        </div>
      </td>
      {props.showQuantity && product != null && product.stock > 0 ? (
        <td>
          <div className="item-stock container">
            <div className="row">
              <FaMinus
                className="mt-2"
                size={12}
                onClick={(e) => onChangeStock(null, props.item.stock - 1)}
              ></FaMinus>
              <input
                type="number"
                name="stock"
                min="0"
                className="form-control stockinput"
                value={props.item.stock}
                onChange={(e) => onChangeStock(e)}
              ></input>
              <FaPlus
                className="mt-2"
                size={12}
                onClick={(e) => onChangeStock(null, props.item.stock + 1)}
              ></FaPlus>
            </div>
          </div>
        </td>
      ) : (
        props.showQuantity && (
          <td>
            <div className="no-stock">No Stocks Available</div>
          </td>
        )
      )}
      {props.addToCart && product.stock > 0 ? (
        <td>
          <div className="item-buy">
            <button
              className="btn btn-dark"
              onClick={() => props.addToCart(props.item)}
            >
              Add to Cart
            </button>
          </div>
        </td>
      ) : (
        ""
      )}

      {!props.addToCart ? (
        <td>
          <div className="item-subtotal">
            <h3>{item_subtotal()}</h3>
          </div>
        </td>
      ) : (
        ""
      )}

      <td>
        {props.deleteWishFromList && (
          <div className="table-action">
            <FaTrash
              onClick={() => props.deleteWishFromList(props.item)}
              className="trash"
            ></FaTrash>
          </div>
        )}

        {props.deleteCartFromList && (
          <div className="table-action">
            <FaTrash
              onClick={() => props.deleteCartFromList(props.item)}
              className="trash"
            ></FaTrash>
          </div>
        )}
      </td>
    </>
  );
};

export default WishCartProductCard;
