import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { merchantLogin, verify } from "../services/auth.service";
import {
  getAllFilters,
  getEverything,
  getSubs,
} from "../services/category.service";
import {
  getProductsCountByUser,
  save,
  deleteP,
  getProductsByUserMerchant,
} from "../services/product.service";
import {
  getAllPlans,
  getBrandsByCategory,
  merchantSignup,
  updateUser,
  paymentSuccessForMerchant,
  signupMerchantAndProceedToPay,  
} from "../services/user.service";
import {
  merchantOrderList,
  merchantOrderCount,
} from "../services/order.service";
import { upload } from "../services/image.service";
import { saveActivity } from "../services/activity.service";

const user = JSON.parse(localStorage.getItem("user"));

const initialState = {
  list: [],
  selected: null,
  user: user,
  loading: false,
  panel: "ADDPRODUCT",
  productList: null,
  productCount: 0,
  allCats: [],
  features: { 
    ps: 12, sidx: 0, page: 1,
    catId:null,
    mainCatId:null,
    subCatId:null,
    childCatId:null,
    brand:null
  },
  emailSent: false,
  images: null,
  imageUploadError: false,
  toBeDeletedProd: null,
  productSaved: false,
  imageSaved: false,
  plans: null,
  profileImage: null,
  profImageSaved: false,
  rzpOrdId: null,
  mAmount: null,
  mUId: null,
  existingUser: false,
  loginError: null,
  otpFailed: null,
  imageUploading: false,
  merchantOrdersFeatures: { ps: 12, sidx: 0, page: 1 },
  merchantOrdersList: null,
  merchantOrdersCount: 0,
};

export const updateUs = createAsyncThunk(
  "merchant/updateUs",
  async (params, { getState, rejectWithValue }) => {
    try {
      const response = await updateUser(params);

      return response.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const signin = createAsyncThunk(
  "merchant/signin",
  async (userParams, { getState, rejectWithValue }) => {
    try {
      const email = userParams.email;

      const response = await merchantLogin(email);

      return response.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const verifyUser = createAsyncThunk(
  "merchant/verifyUser",
  async (userParams, { getState, rejectWithValue }) => {
    try {
      const otp = userParams.otp;
      const id = userParams.id;

      const response = await verify(otp, id);

      return response.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const signupMerchant = createAsyncThunk(
  "merchant/signupMerchant",
  async (params, { getState, rejectWithValue }) => {
    try {
      const response = await merchantSignup(params);

      return response.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const signupMerchantAndProceedToP = createAsyncThunk(
  "merchant/signupMerchantAndProceedToP",
  async (params, { getState, rejectWithValue }) => {
    try {
      const response = await signupMerchantAndProceedToPay(params);

      return response.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const paymentSuccessForM = createAsyncThunk(
  "merchant/paymentSuccessForM",
  async (params, { getState, rejectWithValue }) => {
    try {
      const response = await paymentSuccessForMerchant(params);

      return response.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const productList = createAsyncThunk(
  "merchant/productList",
  async (params, { getState, rejectWithValue }) => {
    try {
      const state = getState().merchant;
      const response = await getProductsByUserMerchant(
        params.userId,
        params.keyword,
        state.features.catId,
        state.features
      );

      return response.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const productCount = createAsyncThunk(
  "merchant/productCount",
  async (params, { getState, rejectWithValue }) => {
    try {
      const state = getState().merchant;
      const response = await getProductsCountByUser(
        params.userId,
        params.keyword,
        state.features.catId,
        state.features
      );

      return response.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const getAllCategories = createAsyncThunk(
  "merchant/getAllCategories",
  async (params, { getState, rejectWithValue }) => {
    try {
      const response = await getEverything();

      return response.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const getSubCategories = createAsyncThunk(
  "merchant/getSubCategories",
  async (mainCatId, { getState, rejectWithValue }) => {
    try {
      const response = await getSubs(mainCatId);

      return response.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const uploadImage = createAsyncThunk(
  "merchant/uploadImage",
  async (formData, { getState, rejectWithValue }) => {
    try {
      const response = await upload(formData);
      return response.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const saveProduct = createAsyncThunk(
  "merchant/saveProduct",
  async (productObj, { getState, rejectWithValue }) => {
    try {
      const response = await save(productObj);
      return response.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const deleteProd = createAsyncThunk(
  "merchant/deleteProd",
  async (productObj, { getState, rejectWithValue }) => {
    try {
      const response = await deleteP(productObj);
      return response.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const getAllFilters1 = createAsyncThunk(
  "merchant/getAllFilters1",
  async (mainCatId, { getState, rejectWithValue }) => {
    try {
      const response = await getAllFilters();
      return response.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const getBrandsByCat = createAsyncThunk(
  "merchant/getBrandsByCat",
  async (param, { getState, rejectWithValue }) => {
    try {
      const response = await getBrandsByCategory(param);
      return response.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const getPlans = createAsyncThunk(
  "merchant/getPlans",
  async (param, { getState, rejectWithValue }) => {
    try {
      const response = await getAllPlans();
      return response.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const uploadProfileImage = createAsyncThunk(
  "merchant/uploadProfileImage",
  async (formData, { getState, rejectWithValue }) => {
    try {
      const response = await upload(formData);
      return response.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const saveActivityObj = createAsyncThunk(
  "merchant/saveActivityObj",
  async (activityObj, { getState, rejectWithValue }) => {
    
    try {
      const response = await saveActivity(activityObj);
      return response.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const getMerchantOrders = createAsyncThunk(
  "merchant/getMerchantOrders",
  async (params, { getState, rejectWithValue }) => {
    try {
      const state = getState().merchant;
      const response = await merchantOrderList(params, state.merchantOrdersFeatures);
      return response.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const getMerchantOrdersCount = createAsyncThunk(
  "merchant/getMerchantOrdersCount",
  async (params, { getState, rejectWithValue }) => {
    try {
      const state = getState().merchant;
      const response = await merchantOrderCount(params);
      return response.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

const merchantSlice = createSlice({
  name: "Merchant",
  initialState: initialState,
  reducers: {
    updateMerchant: (state, action) => {
      const user = localStorage.getItem("user");

      if (user != null) {
        state.user = JSON.parse(user);
      }

      state.city = localStorage.getItem("city");
      if (action.payload) {
        state.panel = action.payload;
      }
    },
    updatePanel: (state, action) => {
      state.panel = action.payload;
    },
    updatePagination: (state, action) => {
      state.features.page = action.payload;
      state.features.sidx = (state.features.page - 1) * state.features.ps;
    },
    resetFeatures: (state, action) => {
      state.productList = null;
      state.productCount = 0;
      state.features.ps = 12;
      state.features.sidx = 0;
      state.features.page = 1;
      state.features.catId = null;
      state.features.mainCatId = null;
      state.features.subCatId = null;
      state.features.childCatId = null;
      state.features.brand = null;
    },
    resetSubCatList: (state, action) => {
      state.subCats = null;
    },
    resetImages: (state, action) => {
      state.images = null;
      state.imageSaved = false;
    },
    updateImages: (state, action) => {
      state.images = action.payload;
      state.imageSaved = false;
    },
    removeImage: (state, action) => {
      let removedId = action.payload;
      if (state.images != null && state.images.length > 0) {
        let index = -1;
        for (let i = 0; i < state.images.length; i++) {
          let item = state.images[i];

          if (item._id === removedId) {
            index = i;
          }
        }

        if (index > -1) {
          state.images.splice(index, 1);
        }
      }
      state.imageSaved = false;
    },
    toBeDeleted: (state, action) => {
      state.toBeDeletedProd = action.payload;
    },
    updateProfImages: (state, action) => {
      state.profileImage = action.payload;
      state.profImageSaving = false;
    },
    resetProfImages: (state, action) => {
      state.profileImage = null;
      state.profImageSaving = false;
    },
    updateExistingUser: (state, action) => {
      state.existingUser = false;
    },
    updateLoginError: (state, action) => {
      state.loginError = null;
    },
    updateOtpFailed: (state, action) => {
      state.otpFailed = null;
    },
    updatePs: (state, action) => {
      state.features.ps = action.payload;
    },
    updateCatId: (state, action) => {
      state.features.catId = action.payload;
    },
    updateMainCatId: (state, action) => {
      state.features.mainCatId = action.payload;
    },
    updateSubCatId: (state, action) => {
      state.features.subCatId = action.payload;
    },
    updateChildCatId: (state, action) => {
      state.features.childCatId = action.payload;
    },
    updateBrand: (state, action) => {
      state.features.brand = action.payload;
    },
    resetFilter: (state, action) => {
      state.features.catId = null;
      state.features.mainCatId = null;
      state.features.subCatId = null;
      state.features.childCatId = null;
      state.features.brand = null;
    },
    resetProductSaved: (state, action) => {
      state.productSaved = false;
    },
    resetUploadError: (state, action) => {
      state.imageUploadError = false;
    },    
    logout: (state, action)  => {
      for (var key in initialState ){
        state[key] = initialState[key];
      }
      state.user = null;
    },    
    updateMerchantOrdersPagination: (state, action) => {
      state.merchantOrdersFeatures.page = action.payload;
      state.merchantOrdersFeatures.sidx =
        (state.merchantOrdersFeatures.page - 1) * state.merchantOrdersFeatures.ps;
    },
  },
  extraReducers: (builder) =>
    builder
      .addCase(getSubCategories.fulfilled, (state, action) => {
        state.subCats = action.payload.result;
      })
      .addCase(getAllCategories.fulfilled, (state, action) => {
        state.allCats = action.payload.result;
      })
      .addCase(updateUs.fulfilled, (state, action) => {
        localStorage.setItem("user", JSON.stringify(action.payload));
        if (action.payload) {
          state.user = { ...state.user, ...action.payload };
          console.log('update profile');
        }
      })
      .addCase(productList.fulfilled, (state, action) => {
        if (action.payload && action.payload.result) {
          state.productList = action.payload.result;
        } else {
          state.productList = null;
        }
      })
      .addCase(productCount.fulfilled, (state, action) => {
        if (action.payload && action.payload.result) {
          state.productCount = action.payload.result;
        } else {
          state.productCount = 0;
        }
      })
      .addCase(signupMerchant.fulfilled, (state, action) => {
        state.emailSent = true;
        state.rzpOrdId = null;
        state.mAmount = null;
        state.mUId = null;
      })
      .addCase(signupMerchantAndProceedToP.fulfilled, (state, action) => {
        if (action.payload && action.payload.existingUser) {
          state.existingUser = action.payload.existingUser;
        } else {
          state.rzpOrdId = action.payload.rzpOrdId;
          state.mAmount = action.payload.amount;
          state.mUId = action.payload.userId;
        }
      })
      .addCase(paymentSuccessForM.fulfilled, (state, action) => {
        state.emailSent = true;
      })
      .addCase(signin.pending, (state, action) => {
        state.successful = false;
        state.loading = true;
      })
      .addCase(signin.fulfilled, (state, action) => {
        state.isLoggedIn = false;
        state.loading = false;

        // if (action.payload) {
        //   if (action.payload.active) {
        //     state.notActive = false;
        //     state.successful = true;
        //     state.registeredUserId = action.payload.id;
        //   } else {
        //     state.notActive = true;
        //     state.message = action.payload.message;
        //   }
        // }
        if (action.payload) {
          if (action.payload.active) {
            state.notActive = false;
            state.successful = true;
            state.registeredUserId = action.payload.id;
          } else if(action.payload.error) {
            state.loginError = action.payload.message;
          } else {
            state.notActive = true;
            state.message = action.payload.message;
          }
        }
      })
      .addCase(signin.rejected, (state, action) => {
        state.isLoggedIn = false;
        state.loading = false;
        let response = JSON.parse(action.payload.request.response);
        state.loginError = response.message;
      })
      .addCase(verifyUser.fulfilled, (state, action) => {
        state.isLoggedIn = true;
        state.successful = false;
        if (action.payload._id) {
          localStorage.setItem("user", JSON.stringify(action.payload));
          localStorage.setItem(
            "accessToken",
            JSON.stringify(action.payload.accessToken)
          );
          state.user = action.payload;
        }else{
          state.isLoggedIn = false;
          state.otpFailed = action.payload.message;
        }
      })
      .addCase(uploadImage.pending, (state, action) => {
        state.imageUploading = true;
      })
      .addCase(uploadImage.fulfilled, (state, action) => {
        state.imageUploading = false;
        if (!state.images) {
          state.images = [];
        }
        state.imageUploadError=false;
        state.images.push(action.payload.result);
      })
      .addCase(uploadImage.rejected, (state, action) => {
        state.imageUploading = false;
        try {              
          let response = JSON.parse(action.payload.request.response);
          state.imageUploadError = response.message.message;
        } catch (e) {
          // state.imageUploadError = e.message;
          let statusText = action.payload.request.statusText;
          if(statusText == 'Request Entity Too Large'){
            state.imageUploadError = 'Upload image error! please upload smaller size';
          }else{
            state.imageUploadError = statusText;
          }
        }        
      })
      .addCase(saveProduct.pending, (state, action) => {
        state.productSaved = false;
      })
      .addCase(saveProduct.fulfilled, (state, action) => {
        state.productSaved = true;
        if (state.productList != null && state.productList.length > 0) {
          let index = -1;
          for (let i = 0; i < state.productList.length; i++) {
            let item = state.productList[i];

            if (item._id === action.payload.result._id) {
              index = i;
            }
          }

          if (index > -1) {
            state.productList[index] = action.payload.result;
          } else {
            if (!state.productList) {
              state.productList = [];
            }

            state.productList.push(action.payload.result);
          }
        } else {
          if (!state.productList) {
            state.productList = [];
          }

          state.productList.push(action.payload.result);
        }
      })
      .addCase(deleteProd.fulfilled, (state, action) => {
        if (state.productList != null && state.productList.length > 0) {
          let index = -1;
          for (let i = 0; i < state.productList.length; i++) {
            let item = state.productList[i];

            if (item._id === state.toBeDeletedProd._id) {
              index = i;
            }
          }

          if (index > -1) {
            state.productList.splice(index, 1);
          }
          state.toBeDeletedProd = null;
        }
      })
      .addCase(getAllFilters1.fulfilled, (state, action) => {
        state.filterList = action.payload.result;
      })
      .addCase(getBrandsByCat.fulfilled, (state, action) => {
        state.brandsList = action.payload.result;
      })
      .addCase(getPlans.fulfilled, (state, action) => {
        state.plans = action.payload.result;
      })
      .addCase(uploadProfileImage.pending, (state, action) => {
        state.profImageSaving = true;
      })
      .addCase(uploadProfileImage.fulfilled, (state, action) => {
        if (!state.profileImage) {
          state.profileImage = {};
        }

        state.profileImage = action.payload.result;
        state.profImageSaving = false;
      })
      .addCase(uploadProfileImage.rejected, (state, action) => {
        try {              
          let response = JSON.parse(action.payload.request.response);
          state.imageUploadError = response.message.message;
        } catch (e) {
          // state.imageUploadError = e.message;
          let statusText = action.payload.request.statusText;
          if(statusText == 'Request Entity Too Large'){
            state.imageUploadError = 'Upload image error! please upload smaller size';
          }else{
            state.imageUploadError = statusText;
          }
        }
        state.profImageSaving = false;        
      })
      .addCase(saveActivityObj.pending, (state, action) => {
        
      })
      .addCase(saveActivityObj.fulfilled, (state, action) => {
       
      })
      .addCase(getMerchantOrders.fulfilled, (state, action) => {
        state.merchantOrdersList = null;
        if (action.payload.result) {
          state.merchantOrdersList = action.payload.result;
        }
      })
      .addCase(getMerchantOrdersCount.fulfilled, (state, action) => {
        state.merchantOrdersCount = 0;
        state.merchantOrdersCount = action.payload.result;
      }),
});

export default merchantSlice.reducer;
export const {
  updateMerchant,
  updatePanel,
  updatePagination,
  resetFeatures,
  resetSubCatList,
  resetImages,
  updateImages,
  removeImage,
  toBeDeleted,
  updateProfImages,
  resetProfImages,
  updateExistingUser,
  updateLoginError,
  updateOtpFailed,
  updatePs,
  updateMainCatId,
  updateCatId,
  updateSubCatId,
  updateChildCatId,
  updateBrand,
  resetFilter,
  resetProductSaved,
  resetUploadError,
  logout,
  updateMerchantOrdersPagination,
} = merchantSlice.actions;
