import React, { Fragment } from "react";
import Slider from "react-slick";
import { Link } from "react-router-dom";

import { Product7 } from "../../../services/script";
import { Media, Container, Row, Col } from "reactstrap";
import { BASE_URL, BASE_API_URL } from "../../../services/constants";
import Card from "./Card";
const BlogSection = ({
  type,
  sectionClass,
  title,
  inner,
  model,
  items,
  heading,
}) => {
  let baseUri = BASE_URL;

  var data = [
    {
      img: "https://themes.pixelstrap.com/multikart/assets/images/blog/3.jpg",
      tittle: "somename",
      desc: "somedesc",
      date: "24-02-1998",
    },
    {
      img: "https://themes.pixelstrap.com/multikart/assets/images/blog/3.jpg",
      tittle: "somename",
      desc: "somedesc",
      date: "24-02-1998",
    },
    {
      img: "https://themes.pixelstrap.com/multikart/assets/images/blog/3.jpg",
      tittle: "somename",
      desc: "somedesc",
      date: "24-02-1998",
    },
  ];

  return (
    <Fragment>
      {model === "category" ? (
        <div className="col-md-12 mt-3">
          <div className="container">
            {heading !== "Best Offers on Cleaning & Households" && (
              <>
                <h2>{heading}</h2>
                <div className="row">
                  {" "}
                  {items && items.length >= 4 ? (
                    <Slider {...Product7} className="slide-3 ">
                      {items &&
                        items.length > 0 &&
                        items.map((item, index) => (
                          <Card item={item} key={index} type="slider" />
                        ))}
                    </Slider>
                  ) : (
                    <>
                      {items &&
                        items.length > 0 &&
                        items.map((item, index) => (
                          <Card item={item} key={index} type="normal" />
                        ))}
                    </>
                  )}
                </div>
              </>
            )}
          </div>
        </div>
      ) : model === "offer" ? (
        <div className="col-md-12 ">
          <div className="container">
            <h2 style={{ marginTop: "0px" }}>{heading}</h2>
            <div className="row">
              {" "}
              {items && items.length > 4 ? (
                <Slider {...Product7} className="slide-3 ">
                  {items &&
                    items.length > 0 &&
                    items.map((item, index) => (
                      <Card item={item} key={index} type="slider" />
                    ))}
                </Slider>
              ) : (
                <>
                  <Row>
                    {items &&
                      items.length > 0 &&
                      items.map((item, index) => (
                        <Card item={item} key={index} type="offer" />
                      ))}
                  </Row>
                </>
              )}
            </div>
          </div>
        </div>
      ) : (
        <section className={sectionClass}>
          <Container>
            <Row>
              <Col md="12">
                <div className={title}>
                  <h2 className={inner}>{heading}</h2>
                </div>
                {items && items.length >= 4 ? (
                  <Slider {...Product7} className="slide-3  ">
                    {items &&
                      items.length > 0 &&
                      items.map((item, index) => (
                        <Card item={item} key={index} type="slider" />
                      ))}
                  </Slider>
                ) : (
                  <>
                    <Row className="normal__view">
                      {items &&
                        items.length > 0 &&
                        items.map((item, index) => (
                          <Card item={item} key={index} type="normal" />
                        ))}
                    </Row>
                  </>
                )}
              </Col>
            </Row>
          </Container>
        </section>
      )}
    </Fragment>
  );
};
export default BlogSection;
