import { FaStar } from "react-icons/fa";
import { Link } from "react-router-dom";
import ModalMap from "../components/map/modalMap";
import { BASE_URL, BASE_API_URL } from "../services/constants";
import PhoneContainer from "./PhoneContainer";

const StoreCard = (props) => {
  let baseUri = BASE_URL;
  let urlToClick = "/category/" + props.item.mainCategoryId;

  if (props.item.subCategoryId) {
    urlToClick = urlToClick + "?subCatId=" + props.item.subCategoryId;
  }

  if (props.item.childCategoryId) {
    if (urlToClick.indexOf("?") > -1) {
      urlToClick = urlToClick + "&childCatId=" + props.item.childCategoryId;
    } else {
      urlToClick = urlToClick + "?childCatId=" + props.item.childCategoryId;
    }
  }

  if (props.item.userId) {
    if (urlToClick.indexOf("?") > -1) {
      urlToClick = urlToClick + "&uId=" + props.item.userId._id;
    } else {
      urlToClick = urlToClick + "?uId=" + props.item.userId._id;
    }
  }

  const imgPath = (img) => {
    let path = img;

    if (path && path.indexOf("https://") > -1) {
      return path;
    } else if(path){
      return BASE_API_URL + '/' + path;
    } else {
      return baseUri + 'files/noimg.png';
    }
  };

  const roundItOff = (num) => {
    return Math.round(num);
  };

  return (
    <div className="card store">
      <div className="ONM_img">
        <Link to={urlToClick}>
          {/* {console.log(props)} */}
          {/* {props.item.userId && props.item.userId.imageId ? (
            <img
              className="card-img-top"
              src={imgPath(props.item.userId.imageId[0].path)}
              alt="Card"
            />
          ) : ( props.item && props.item.imageId ? (
            <img
              className="card-img-top"
              src={imgPath(props.item.imageId[0].path)}
              alt="Card"
            />
          ) : null ) } */}
          {props.item && props.item.imageId && (
          <img
              className="card-img-top"
              src={imgPath(props.item.imageId[0].path)}
              alt="Card"
            />
          )}
          
        </Link>
        <div className="text-right ONM_review-star">
          <FaStar
            className={
              roundItOff(props.item.star || 0) >= 1
                ? "OMN-star-active mr-2"
                : "ONM_icon_color-empty mr-2"
            }
          ></FaStar>
          <FaStar
            className={
              roundItOff(props.item.star || 0) >= 2
                ? "OMN-star-active mr-2"
                : "ONM_icon_color-empty mr-2"
            }
          ></FaStar>
          <FaStar
            className={
              roundItOff(props.item.star || 0) >= 3
                ? "OMN-star-active mr-2"
                : "ONM_icon_color-empty mr-2"
            }
          ></FaStar>
          {/* <FaStar
            className={
              roundItOff(props.item.star || 0) === 4
                ? "OMN-star-active mr-2"
                : "ONM_icon_color-empty mr-2"
            }
          ></FaStar> */}
        </div>
      </div>
      <div className="card-body row">
        <h4 className="card-title col-md-12">
          <Link to={urlToClick}>
            {props.item.userId ? props.item.userId.storeName : props.item.name}
          </Link>
        </h4>
        <p className="card-text col-md-12">
          {props.item.userId ? props.item.userId.address : ""}
        </p>
        <div className="ONM_icon_color ONM_card-icons-container">
          <ModalMap size={14} item={props.item}></ModalMap>
          <PhoneContainer item={props.item}></PhoneContainer>
        </div>
        <div className="ONM_offer-container col-md-12">
          {props.item.offer && (<span className="ONM_offer-text">
            {props.item.offer ? props.item.offer + "% OFF" : ""}
          </span>)}
        </div>
      </div>
    </div>
  );
};

export default StoreCard;
