import React, { useState } from "react";
import {
  Container,
  Row,
  Col,
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  Table,
} from "reactstrap";

import TableSpecification from "./TableSpecification";
import ReviewList from "./ReviewList";

const DetailTab = ({
  productState,
  openModal,
  handlePageChange,
  changeTab,
}) => {
  const [activeTab, setActiveTab] = useState("1");

  return (
    <>
      <section className="tab-product m-0">
        <Container>
          <Row>
            <Col sm="12" lg="12">
              <Row className="product-page-main m-0">
                <Nav tabs className="nav-material">
                  <NavItem className="nav nav-tabs" id="myTab" role="tablist">
                    <NavLink
                      className={activeTab === "1" ? "active" : ""}
                      onClick={() => setActiveTab("1")}
                    >
                      Description
                    </NavLink>
                  </NavItem>
                  <NavItem className="nav nav-tabs" id="myTab" role="tablist">
                    <NavLink
                      className={activeTab === "2" ? "active" : ""}
                      onClick={() => setActiveTab("2")}
                    >
                      Specifications
                    </NavLink>
                  </NavItem>
                  <NavItem className="nav nav-tabs" id="myTab" role="tablist">
                    <NavLink
                      className={activeTab === "3" ? "active" : ""}
                      onClick={() => setActiveTab("3")}
                    >
                      Write Review
                    </NavLink>
                  </NavItem>
                </Nav>
                <TabContent activeTab={activeTab} className="nav-material">
                  <TabPane tabId="1">
                    <p className="mb-0 pb-0">
                      {productState.details.description
                        ? productState.details.description
                        : "No Description in This product"}
                    </p>
                  </TabPane>
                  <TabPane tabId="2">
                    <div className="mb-0 pb-0">
                      <TableSpecification details={productState.details} />
                    </div>
                  </TabPane>
                  <TabPane tabId="3">
                    <ReviewList
                      list={productState.reviews}
                      count={productState.reviewsCount}
                      features={productState.reviewFeatures}
                      handlePageChange={handlePageChange}
                      openModal={openModal}
                    ></ReviewList>
                  </TabPane>
                </TabContent>
              </Row>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};

export default DetailTab;
