import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getMerchantOrders,
  getMerchantOrdersCount,
  updateMerchantOrdersPagination,
} from "../../slices/merchantSlice";
import Moment from "react-moment";
import { FaTimes, FaEye } from "react-icons/fa";
import Pagination from "react-js-pagination";
import { BASE_URL, BASE_API_URL } from "../../services/constants";

import Modal from "react-modal";

const MerchantOrder = (props) => {
  const merchantState = useSelector((state) => state.merchant);
  const dispatch = useDispatch();
  const [orderObj, setOrder] = useState(null);

  useEffect(() => {
    dispatch(getMerchantOrders());
    dispatch(getMerchantOrdersCount());
  }, [dispatch]);

  const handlePageChange = (pageNumber) => {
    dispatch(updateMerchantOrdersPagination(pageNumber));
    dispatch(getMerchantOrders());
  };

  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      zIndex: "999",
      maxWidth: "90%",
      maxHeight: "90%",
    },
  };

  Modal.setAppElement("#root");
  const [isOrderModalOpen, setIsOrderModalOpen] = useState(false);

  const openOrderModal = (obj) => {
    setOrder(obj);
    setIsOrderModalOpen(true);
  };

  const closeOrderModal = () => {
    setIsOrderModalOpen(false);
  };

  const imgPath = (img) => {
    let path = img;

    if (path.indexOf("https://") > -1) {
      return path;
    } else {
      return BASE_API_URL + "/" + path;
    }
  };

  const productPrice = (prod) => {
    let price = 0;

    if (prod.productId.price) price = prod.productId.price.toFixed(2);

    return price;
  };

  const formatAddress = (address) => {
    let name = address.firstName + " " + address.lastName;
    let company = address.company;
    let address1 = address.address;
    let address2 = address.address2;
    let phone = address.phone;
    let state = address.state;
    let city = address.city;
    let country = address.country;
    let postalCode = address.postalCode;

    let printCompany = company ? `(${company}) ` : "";
    let printAdress2 = address2 ? `, ${address2}` : "";

    return `${name} - ${printCompany}${address1}${printAdress2}, ${city}, ${state}, ${country} ${postalCode}`;
    // return address1;
  };

  const orderAmount = (products) => {
    let amount = 0;
    products.forEach((oProd) => {
      amount =
        amount +
        (oProd.amount - (oProd.offer / 100) * oProd.amount) * oProd.stock;
    });

    return amount.toFixed(2);
  };

  return (
    <div className="col-md-12">
      <Modal isOpen={isOrderModalOpen} style={customStyles}>
        <div className="col-md-12">
          <div className="row">
            {orderObj && (
              <>
                <div className="col-md-12 p-0 ONM_modal_header">
                  <h3>Order Details</h3>
                </div>
                <div className="col-md-6">
                  <label>
                    <strong>Order Number:</strong> #{orderObj.order_num}
                  </label>
                </div>
                <div className="col-md-6">
                  <label>
                    <strong>Order Placed:</strong>{" "}
                    <Moment format="D MMM YYYY">{orderObj.createdOn}</Moment>
                  </label>
                </div>
                <div className="col-md-6">
                  <label>
                    <strong>Customer Name:</strong>{" "}
                    {orderObj.userId
                      ? orderObj.userId.firstName && orderObj.userId.lastName
                        ? orderObj.userId.firstName +
                          " " +
                          orderObj.userId.lastName
                        : "-"
                      : "-"}
                  </label>
                </div>
                <div className="col-md-6">
                  <label>
                    <strong>Customer Email:</strong>{" "}
                    {orderObj.userId ? orderObj.userId.email : "-"}
                  </label>
                </div>
                <div className="col-md-12">
                  <label>
                    <strong>Total Amount:</strong> Rs{" "}
                    {orderAmount(orderObj.products)}
                  </label>
                </div>
                <div className="col-md-12">
                  <label>
                    <strong>Delivery Address:</strong>{" "}
                    {formatAddress(orderObj.deliveryAddress)}
                  </label>
                </div>
                <div className="col-md-12">
                  {orderObj.awb && (
                    <>
                      <label>
                        <strong>Shipping Track:</strong>
                      </label>
                      <div className="shipping-track">
                        <ul>
                          {orderObj.merchantAwb &&
                            orderObj.merchantAwb.map((row) => (
                              <>
                                {row.userId._id === merchantState.user._id && (
                                  <li>
                                    {row.userId.storeName}:{" "}
                                    <a
                                      target="_blank"
                                      href={
                                        "https://ecomexpress.in/tracking/?awb_field=" +
                                        row.awb
                                      }
                                    >
                                      {row.awb}
                                    </a>
                                  </li>
                                )}
                              </>
                            ))}
                        </ul>
                      </div>
                    </>
                  )}
                </div>
                <div className="col-md-12 mt-3">
                  <div className="row">
                    {orderObj.products && orderObj.products.length > 0 ? (
                      <>
                        <ul className="col-md-12">
                          <label>
                            <strong>Order Items:</strong>
                          </label>
                          {orderObj.products.map((product) => {
                            if (
                              product.productId.userId &&
                              product.productId.userId._id !=
                                merchantState.user._id
                            ) {
                              return null;
                            } else {
                              return (
                                <li
                                  className="ONM-order_product_li"
                                  key={product._id}
                                >
                                  <div className="col-md-12">
                                    <div className="row">
                                      <div className="col-md-2">
                                        {product.productId != null &&
                                        product.productId.imageId != null ? (
                                          <img
                                            alt="<image missing>"
                                            src={imgPath(
                                              product.productId.imageId[0].path
                                            )}
                                          />
                                        ) : null}
                                      </div>
                                      <div className="col-md-10">
                                        <label className="ONM_order_label mr-2 d-block">
                                          Merchant:
                                          <span className="ONM_order_value">
                                            {product.productId &&
                                            product.productId.userId
                                              ? " " +
                                                product.productId.userId
                                                  .storeName
                                              : "NA"}
                                          </span>
                                        </label>
                                        <label className="ONM_order_label mr-2 d-block">
                                          Name:
                                          <span className="ONM_order_value">
                                            {product.productId
                                              ? " " + product.productId.name
                                              : "NA"}
                                          </span>
                                        </label>
                                        <label className="ONM_order_label mr-2">
                                          Price:
                                          <span className="ONM_order_value">
                                            {product.productId
                                              ? " Rs " + productPrice(product)
                                              : "NA"}
                                          </span>
                                        </label>
                                        <label className="ONM_order_label mr-2">
                                          Offer:
                                          <span className="ONM_order_value">
                                            {product.productId
                                              ? " " +
                                                product.productId.offer +
                                                "%"
                                              : "NA"}
                                          </span>
                                        </label>
                                        <label className="ONM_order_label mr-2">
                                          No.of items:
                                          <span className="ONM_order_value">
                                            {product.stock}
                                          </span>
                                        </label>
                                      </div>
                                    </div>
                                  </div>
                                </li>
                              );
                            }
                          })}
                        </ul>
                      </>
                    ) : null}
                  </div>
                </div>
                <div className="col-md-12 mt-2">
                  <button
                    className="btn btn-dark ml-2 float-right"
                    onClick={() => closeOrderModal()}
                  >
                    Close
                  </button>
                </div>
              </>
            )}
          </div>
        </div>
      </Modal>

      <div className="row">
        <div className="col-md-12">
          <div className="row">
            <div className="col-md-6"></div>
            <div className="col-md-6 text-right">
              <Pagination
                activePage={merchantState.merchantOrdersFeatures.page}
                itemsCountPerPage={merchantState.merchantOrdersFeatures.ps}
                totalItemsCount={merchantState.merchantOrdersCount}
                pageRangeDisplayed={5}
                onChange={handlePageChange.bind(this)}
                itemClass="page-item"
                linkClass="page-link"
                innerClass="pagination ml-auto mr-auto"
                prevPageText="Prev"
                nextPageText="Next"
                hideFirstLastPages={true}
              />
            </div>
          </div>
        </div>
        <div className="table-responsive mt-2">
          <table className="table">
            <thead>
              <tr>
                <th>Order Number</th>
                <th>Order Placed</th>
                <th>Customer Name</th>
                <th>Customer Email</th>
                <th>Total Amount</th>
                <th>Paid</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {merchantState.merchantOrdersList != null &&
              merchantState.merchantOrdersList.length > 0
                ? merchantState.merchantOrdersList.map((order) => {
                    return (
                      <tr key={order._id}>
                        {/* {console.log(order.userId)} */}
                        <td> #{order.order_num}</td>
                        <td>
                          <Moment format="D MMM YYYY">{order.createdOn}</Moment>
                        </td>
                        <td>
                          {order.userId
                            ? order.userId.firstName && order.userId.lastName
                              ? order.userId.firstName +
                                " " +
                                order.userId.lastName
                              : "-"
                            : "-"}
                        </td>
                        <td>{order.userId ? order.userId.email : "-"}</td>
                        <td>Rs {orderAmount(order.products)}</td>
                        <td>{order.paid ? "yes" : "no"}</td>
                        <td>
                          <FaEye
                            className="ONM_icons"
                            data-tip="View Fields"
                            onClick={() => openOrderModal(order)}
                          ></FaEye>
                        </td>
                      </tr>
                    );
                  })
                : null}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default MerchantOrder;
