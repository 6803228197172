import React, { useState, useRef, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Redirect } from "react-router-dom";

import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import Select from "react-validation/build/select";
import CheckButton from "react-validation/build/button";
import { isEmail } from "validator";
import { Link } from "react-router-dom";

import GooglePlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-google-places-autocomplete";

import { getCartsCount, getWishCount, update } from "../../slices/headerSlice";
import {
  getPlans,
  paymentSuccessForM,
  signin,
  signupMerchantAndProceedToP,
  updateExistingUser,
  updateMerchant,
  verifyUser,
  updateLoginError,
  updateOtpFailed,
} from "../../slices/merchantSlice";
import { updateAuth } from "../../slices/authSlice";
import { BASE_URL } from "../../services/constants";
import Modal from "react-modal";
import { useAlert } from "react-alert";
import { updateLoggedInUserProduct } from "../../slices/productSlice";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { logout as resetMerchantState } from "../../slices/merchantSlice";
import { logout as resetAdminState } from "../../slices/adminSlice";
import { logout as resetAuthState } from "../../slices/authSlice";
import Layout from "../shop/common-layout";
const required = (value) => {
  if (!value) {
    return (
      <div className="alert alert-danger" role="alert">
        This field is required!
      </div>
    );
  }
};

const validEmail = (value) => {
  if (!isEmail(value)) {
    return (
      <div className="alert alert-danger" role="alert">
        This is not a valid email.
      </div>
    );
  }
};

const MerchantLogin = (props) => {
  const form = useRef();
  const checkBtn = useRef();
  const form1 = useRef();
  const check1Btn = useRef();
  const verify = useRef();
  const checkVerifyBtn = useRef();

  //For Merchant Login
  const [otp, setOtp] = useState("");
  const [email, setEmail] = useState("");

  // For Merchant Registration
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [emailRegister, setEmailRegister] = useState("");
  const [phone, setPhone] = useState("");
  const [gstno, setGstno] = useState("");
  const [storeName, setStoreName] = useState("");
  const [address, setAddress] = useState("");
  const [file, setFile] = useState(null);
  const [fileError, setFileError] = useState(false);
  const [lat, setLat] = useState(null);
  const [lng, setLng] = useState(null);

  // const alert = useAlert();

  const {
    isLoggedIn,
    loading,
    user,
    successful,
    registeredUserId,
    emailSent,
    notActive,
    message,
    plans,
    rzpOrdId,
    mAmount,
    mUId,
    existingUser,
    loginError,
    otpFailed,
  } = useSelector((state) => state.merchant);

  const dispatch = useDispatch();

  const onChangeEmail = (e) => {
    const email = e.target.value;
    setEmail(email);
  };

  const onChangeEmailRegister = (e) => {
    const email = e.target.value;
    setEmailRegister(email);
  };

  const onFirstNameChange = (e) => {
    setFirstName(e.target.value);
  };

  const onLastNameChange = (e) => {
    setLastName(e.target.value);
  };

  const onPhoneChange = (e) => {
    setPhone(e.target.value);
  };

  const onGstChange = (e) => {
    setGstno(e.target.value);
  };

  const onStoreNameChange = (e) => {
    setStoreName(e.target.value);
  };

  const onFileChange = (e) => {
    setFile(e.target.files[0]);
    // console.log(formData);
    setFileError(false);
  };

  const handleRegister = (e) => {
    e.preventDefault();
    form1.current.validateAll();

    if (check1Btn.current.context._errors.length === 0 && file != null) {
      let userObj = {};
      // userObj.firstName = firstName;
      // userObj.lastName = lastName;
      // userObj.phone = phone;
      // userObj.gstno = gstno;
      // userObj.storeName = storeName;
      // userObj.address = address;
      // userObj.email = emailRegister;
      // userObj.planCode = planCode;
      // userObj.planId = planId;

      // if (lat != null && lng != null) {
      //   userObj.loc = { type: "Point", coordinates: [lat, lng] };
      // }

      // Create an object of formData
      const formData = new FormData();

      // Update the formData object
      formData.append("firstName", firstName);
      formData.append("lastName", lastName);
      formData.append("phone", phone);
      formData.append("gstno", gstno);
      formData.append("storeName", storeName);
      formData.append("address", address);
      formData.append("email", emailRegister);
      formData.append("planCode", planCode);
      formData.append("planId", planId);

      if (lat != null && lng != null) {
        formData.append("loc", { type: "Point", coordinates: [lat, lng] });
      }

      formData.append("userDoc", file, file.name);

      // for (var pair of formData.entries()) {
      //   console.log(pair[1],pair[0]);
      // }
      dispatch(signupMerchantAndProceedToP(formData));
      dispatch(updateExistingUser());
    }

    if (file == null) {
      setFileError(true);
    }
  };

  useEffect(() => {
    if (rzpOrdId != null) {
      displayRazorpay();
    }
  }, [rzpOrdId]);

  useEffect(() => {
    if (existingUser) {
      // alert.error(
      //   "A user with this email id already exists. Please try to login or signup with a different email"
      // );
      // dispatch(updateExistingUser());
    }
  }, [existingUser, dispatch]);

  useEffect(() => {
    if (loginError) {
      let toastId = toast.error(loginError);
      dispatch(updateLoginError());
    }
  }, [loginError, dispatch]);

  useEffect(() => {
    if (otpFailed) {
      let toastId = toast.error(otpFailed);
      dispatch(updateOtpFailed());
    }
  }, [otpFailed, dispatch]);

  const handleChange = async (value) => {
    const results = await geocodeByAddress(value.label);
    const latLng = await getLatLng(results[0]);
    setAddress(value.label);
    setLat(latLng.lat);
    setLng(latLng.lng);
  };

  const handleLogin = (e) => {
    e.preventDefault();

    form.current.validateAll();

    if (checkBtn.current.context._errors.length === 0) {
      dispatch(signin({ email: email }));
    }
  };

  const onChangeOtp = (e) => {
    const otp = e.target.value;
    setOtp(otp);
  };

  const handleVerification = (e) => {
    e.preventDefault();
    verify.current.validateAll();

    if (checkVerifyBtn.current.context._errors.length === 0) {
      dispatch(verifyUser({ otp: otp, id: registeredUserId }));
      // closeModal();
    }
  };

  useEffect(() => {
    dispatch(getPlans());
  }, [dispatch]);

  const [planId, setPlanId] = useState("");
  const [planCode, setPlanCode] = useState(null);

  const onChangePlan = (e) => {
    setPlanId(e.target.value);

    if (plans != null && plans.length > 0) {
      for (let i = 0; i < plans.length; i++) {
        let item = plans[i];
        if (item._id === e.target.value) {
          setPlanCode(item.code);
        }
      }
    }
  };

  Modal.setAppElement("#root");
  const [isModalOpen1, setIsModalOpen1] = useState(false);
  const customStyles50 = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      zIndex: "9999",
      width: "100%",
      maxWidth: "450px",
    },
  };

  const openModal = (merchantObj, name) => {
    setIsModalOpen1(true);
    setOtp("");
  };

  const closeModal = () => {
    setIsModalOpen1(false);
  };

  useEffect(() => {
    if (successful) {
      openModal();
    }
  }, [successful]);

  const resetState = () => {
    if (user && user.roles.includes("ROLE_USER")) {
      dispatch(resetAdminState());
      dispatch(resetMerchantState());
    } else if (user && user.roles.includes("ROLE_MERCHANT")) {
      dispatch(resetAdminState());
      dispatch(resetAuthState());
    } else if (user && user.roles.includes("ROLE_ADMIN")) {
      dispatch(resetMerchantState());
      dispatch(resetAuthState());
    }
  };

  useEffect(() => {
    if (isLoggedIn) {
      resetState();
    }
  }, [isLoggedIn]);

  if (user != null && user.roles && user.roles.includes("ROLE_USER")) {
    // dispatch(update());
    // dispatch(updateMerchant());
    // dispatch(updateAuth());
    // dispatch(updateLoggedInUserProduct());
    // dispatch(getCartsCount(user._id));
    // dispatch(getWishCount(user._id));
    // return <Redirect to="/" />;
  }

  if (user != null && user.roles && user.roles.includes("ROLE_MERCHANT")) {
    dispatch(update());
    dispatch(updateAuth());
    dispatch(updateLoggedInUserProduct());
    return <Redirect to="/merchant/main" />;
  }

  const loadScript = (src) => {
    return new Promise((resolve) => {
      const script = document.createElement("script");
      script.src = src;
      script.onload = () => {
        resolve(true);
      };
      script.onerror = () => {
        resolve(false);
      };
      document.body.appendChild(script);
    });
  };
  let baseUri = BASE_URL;

  async function displayRazorpay() {
    const res = await loadScript(
      "https://checkout.razorpay.com/v1/checkout.js"
    );

    if (!res) {
      alert("Razorpay SDK failed to load. Are you online?");
      return;
    }

    if (rzpOrdId != null) {
      var options = {
        key: "rzp_test_HCImT64Pg77Nrx", // Enter the Key ID generated from the Dashboard
        amount: mAmount.toString(), // Amount is in currency subunits. Default currency is INR. Hence, 50000 refers to 50000 paise
        currency: "INR",
        name: "Onmee",
        description: "Transaction",
        image: baseUri + "files/onmee_logo.png",
        order_id: rzpOrdId, //This is a sample Order ID. Pass the `id` obtained in the response of Step 1
        handler: function (response) {
          let userObj = {
            _id: mUId,
            rzpOrdId: rzpOrdId,
            razorpayPaymentId: response.razorpay_payment_id,
            razorpaySignature: response.razorpay_signature,
          };
          dispatch(paymentSuccessForM(userObj));
        },
        theme: {
          color: "#3399cc",
        },
      };
      var rzp1 = new window.Razorpay(options);
      rzp1.open();
      rzp1.on("payment.failed", function (response) {
        console.log(response);
      });
    }
  }

  return (
    <Layout title="Merchant Login" parent="home">
      <div className="container-fluid mt-2 mb-2">
        <Modal isOpen={isModalOpen1} style={customStyles50}>
          <div className="col-md-12">
            <div className="close-modal">
              <button onClick={() => closeModal()}>&times;</button>
            </div>
            <div className="row">
              <div className="col-md-12 p-0 ONM_modal_header">
                <h3>Verify Details</h3>
              </div>
              <div className="col-md-12 align-content-center p-0">
                <div className="form-group mt-2">
                  <div className="col-md-12">
                    <Form onSubmit={handleVerification} ref={verify}>
                      <div className="form-group">
                        <span>Otp code sent to your email</span>
                      </div>

                      <div className="form-group">
                        <label htmlFor="otp">Enter Otp</label>
                        <Input
                          type="text"
                          name="otp"
                          value={otp}
                          onChange={onChangeOtp}
                          validations={[required]}
                          className="col-md-12"
                        />
                      </div>

                      <div className="form-group">
                        <button className="btn btn-primary btn-block">
                          Verify
                        </button>
                      </div>

                      <CheckButton
                        style={{ display: "none" }}
                        ref={checkVerifyBtn}
                      />
                    </Form>
                    <br />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal>

        <div className="row d-flex justify-content-center mt-5">
          {!emailSent && !notActive && (
            <div className="ONM_login col-md-9">
              <div className="row">
                <div className="col-md-6 align-content-center ONM_login_border contact-page register-page ">
                  <h3 className="text-center">Login</h3>
                  {/* <p>
                  Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry.
                </p> */}
                  <Form
                    onSubmit={handleLogin}
                    ref={form}
                    className="theme-form"
                  >
                    <div className="form-group">
                      <label htmlFor="email">Email</label>
                      <Input
                        autoFocus
                        type="text"
                        className="form-control"
                        name="email"
                        value={email}
                        onChange={onChangeEmail}
                        validations={[required, validEmail]}
                      />
                    </div>

                    <div className="form-group">
                      <button
                        className="btn btn-primary btn-block"
                        disabled={loading}
                      >
                        {loading && (
                          <span className="spinner-border spinner-border-sm"></span>
                        )}
                        <span>Login</span>
                      </button>
                      <small class="form-text text-muted">
                        By continuing, you agree to Onmee's{" "}
                        <Link to={"/terms-of-use"}>Term of Use</Link> and{" "}
                        <Link to={"/privacy-policy"}>Privacy Policy</Link>.{" "}
                      </small>
                    </div>
                    <CheckButton style={{ display: "none" }} ref={checkBtn} />
                  </Form>
                </div>

                <div className="col-md-6 align-content-center contact-page register-page merchant ">
                  <h3 className="text-center">Create an Account</h3>
                  {/* <p>
                  Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry.
                </p> */}
                  <Form
                    onSubmit={handleRegister}
                    ref={form1}
                    className="theme-form"
                  >
                    <div className="row">
                      <div className="form-group col-md-6">
                        <label htmlFor="firstName">First Name</label>
                        <Input
                          type="text"
                          className="form-control"
                          name="firstName"
                          value={firstName}
                          onChange={onFirstNameChange}
                          validations={[required]}
                        />
                      </div>
                      <div className="form-group col-md-6">
                        <label htmlFor="lastName">Last Name</label>
                        <Input
                          type="text"
                          className="form-control"
                          name="lastName"
                          value={lastName}
                          onChange={onLastNameChange}
                          validations={[required]}
                        />
                      </div>
                      <div className="form-group col-md-6">
                        <label htmlFor="phone">Phone</label>
                        <Input
                          type="text"
                          className="form-control"
                          name="phone"
                          value={phone}
                          onChange={onPhoneChange}
                          validations={[required]}
                        />
                      </div>

                      <div className="form-group col-md-6">
                        <label htmlFor="emailRegister">Email</label>
                        <Input
                          type="text"
                          className="form-control"
                          name="emailRegister"
                          value={emailRegister}
                          onChange={onChangeEmailRegister}
                          validations={[required, validEmail]}
                        />
                      </div>
                      <div className="form-group col-md-6">
                        <label htmlFor="gstno">Gst #</label>
                        <Input
                          type="text"
                          className="form-control"
                          name="gstno"
                          value={gstno}
                          onChange={onGstChange}
                          validations={[required]}
                        />
                      </div>

                      <div className="form-group col-md-6">
                        <label htmlFor="storeName">Store Name</label>
                        <Input
                          type="text"
                          className="form-control"
                          name="storeName"
                          value={storeName}
                          onChange={onStoreNameChange}
                          validations={[required]}
                        />
                      </div>
                      <div className="form-group col-md-6">
                        <label htmlFor="planId">Plans</label>

                        <Select
                          name="planId"
                          value={planId}
                          onChange={onChangePlan}
                          validations={[required]}
                          className="form-control"
                        >
                          <option value="">Choose your plan</option>
                          {plans != null &&
                            plans.map((plan) => {
                              return (
                                <option key={plan._id} value={plan._id}>
                                  {plan.name}
                                </option>
                              );
                            })}
                        </Select>
                      </div>
                      <div className=" col-md-6">
                        <label htmlFor="address">Store Address</label>
                        <div>
                          <GooglePlacesAutocomplete
                            apiKey="AIzaSyBhhIhmyHFUgqagBmIVaXO5IYmmkmLhLuM"
                            minLengthAutocomplete="5"
                            selectProps={{
                              address,
                              onChange: handleChange,
                            }}
                          />
                        </div>
                      </div>
                      <div className="form-group col-md-6">
                        <label htmlFor="storeName">
                          Proof of Docs (GST, Aadhar, etc)
                        </label>
                        <input
                          type="file"
                          name="file"
                          onChange={(e) => onFileChange(e)}
                        />
                        {fileError && (
                          <div class="alert alert-danger" role="alert">
                            File is required
                          </div>
                        )}
                      </div>
                      <div className="form-group col-md-12">
                        <button className="btn btn-primary btn-block">
                          Register
                        </button>
                        <small class="form-text text-muted">
                          By creating an account, you agree to Onmee's{" "}
                          <Link to={"/terms-of-use"}>Term of Use</Link> and{" "}
                          <Link to={"/privacy-policy"}>Privacy Policy</Link>.{" "}
                        </small>
                      </div>
                      <div className="form-group col-md-12">
                        {existingUser == true ? (
                          <p className="mt-2 alert alert-danger">
                            A user with this email id already exists. Please try
                            to login or signup with a different email
                          </p>
                        ) : null}
                      </div>
                    </div>
                    <CheckButton style={{ display: "none" }} ref={check1Btn} />
                  </Form>
                </div>
              </div>
            </div>
          )}

          {/* {successful && (
          <div className="ONM_login_otp col-md-4">
          </div>
        )} */}

          {emailSent && (
            <div className="ONM_login_otp col-md-4 mt-4 mb-4 align-content-center p-0">
              <div className="form-group mt-3">
                <div className="col-md-12">
                  Email has been sent to the admins for verification.
                </div>
              </div>
            </div>
          )}

          {notActive && (
            <div className="ONM_login_otp col-md-4 mt-4 mb-4 align-content-center p-0">
              <div className="form-group mt-3">
                <div className="col-md-12">{message}</div>
              </div>
            </div>
          )}
        </div>
      </div>
    </Layout>
  );
};

export default MerchantLogin;
