import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { saveCarts } from "../services/cart.service";
import { getProductsById, getRecents } from "../services/product.service";
import {
  getReviewList,
  getReviewCount,
  saveReviews,
} from "../services/review.service";
import { save } from "../services/wishlist.service";

const user = JSON.parse(localStorage.getItem("user"));

export const productById = createAsyncThunk(
  "product/productById",
  async (id, { getState, rejectWithValue }) => {
    try {
      const response = await getProductsById(id);
      return response.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const recents = createAsyncThunk(
  "product/recents",
  async (id, { getState, rejectWithValue }) => {
    try {
      const response = await getRecents(id);
      return response.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const getReviews = createAsyncThunk(
  "product/getReviews",
  async (productId, { getState, rejectWithValue }) => {
    try {
      const state = getState().product;
      const response = await getReviewList(productId, state.reviewFeatures);
      return response.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const getReviewsCount = createAsyncThunk(
  "product/getReviewsCount",
  async (productId, { getState, rejectWithValue }) => {
    try {
      const response = await getReviewCount(productId);
      return response.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const addReview = createAsyncThunk(
  "product/addReview",
  async (obj, { getState, rejectWithValue }) => {
    try {
      const response = await saveReviews(obj);
      return response.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const saveWishlist = createAsyncThunk(
  "product/saveWishlist",
  async (wishObj, { getState, rejectWithValue }) => {
    try {
      const response = await save(wishObj);
      return response.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const saveToCart = createAsyncThunk(
  "product/saveToCart",
  async (cartObj, { getState, rejectWithValue }) => {
    // console.log(cartObj)
    try {
      const response = await saveCarts(cartObj);
      // console.log(response)
      return response.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

const initialState = {
  details: null,
  user: user,
  recents: null,
  loading: false,
  reviewAdded: false,
  reviews: [],
  reviewsCount: 0,
  reviewFeatures: { ps: 12, sidx: 0, page: 1 },
  wishAdded: false,
  savedToCart: false,
};

const productSlice = createSlice({
  name: "Product",
  initialState: initialState,
  reducers: {
    updateReviewAdded: (state, action) => {
      state.reviewAdded = false;
    },
    updateLoggedInUserProduct: (state, action) => {
      state.city = localStorage.getItem("city");
      const user = localStorage.getItem("user");
      if (user != null) {
        state.user = JSON.parse(user);
      } else {
        state.user = null;
      }
    },
    updatePagination: (state, action) => {
      state.reviewFeatures.page = action.payload;
      state.reviewFeatures.sidx =
        (state.reviewFeatures.page - 1) * state.reviewFeatures.ps;
    },
    resetFeatures: (state, action) => {
      state.reviews = [];
      state.reviewsCount = 0;
      state.reviewFeatures.ps = 12;
      state.reviewFeatures.sidx = 0;
      state.reviewFeatures.page = 1;
    },
    updateWishlistAdded: (state, action) => {
      state.wishAdded = false;
    },
    updateCartAdded: (state, action) => {
      state.savedToCart = false;
    },
  },
  extraReducers: (builder) =>
    builder
      .addCase(productById.pending, (state, action) => {
        state.loading = true;
        state.details = null;
      })
      .addCase(productById.fulfilled, (state, action) => {
        state.loading = false;

        if (action.payload && action.payload.result != null) {
          state.details = action.payload.result;
        }
      })
      .addCase(recents.fulfilled, (state, action) => {
        if (action.payload && action.payload.result != null) {
          state.recents = action.payload.result;
        }
      })
      .addCase(getReviews.fulfilled, (state, action) => {
        if (action.payload && action.payload.result != null) {
          state.reviews = action.payload.result;
        }
      })
      .addCase(getReviewsCount.fulfilled, (state, action) => {
        state.reviewsCount = action.payload.result || 0;
      })
      .addCase(addReview.pending, (state, action) => {
        state.reviewAdded = false;
      })
      .addCase(addReview.fulfilled, (state, action) => {
        if (action.payload.result) {
          state.reviewsCount = state.reviewsCount + 1;
          state.reviews.push(action.payload.result);
          state.reviewAdded = true;
        }
      })
      .addCase(saveWishlist.pending, (state, action) => {
        state.wishAdded = false;
      })
      .addCase(saveWishlist.fulfilled, (state, action) => {
        state.wishAdded = true;
      })
      .addCase(saveToCart.pending, (state, action) => {
        state.savedToCart = false;
      })
      .addCase(saveToCart.fulfilled, (state, action) => {
        state.savedToCart = true;
      }),
});

export default productSlice.reducer;
export const {
  updateReviewAdded,
  updateLoggedInUserProduct,
  updatePagination,
  resetFeatures,
  updateWishlistAdded,
  updateCartAdded,
} = productSlice.actions;
