import { combineReducers } from "@reduxjs/toolkit";
import MessageReducer from "../slices/messageSlice";
import AuthReducer from "../slices/authSlice";
import HomeReducer from "../slices/homeSlice"
import HeaderReducer from "../slices/headerSlice";
import CategoryReducer from "../slices/categorySlice";
import SubCategoryReducer from "../slices/subcategorySlice";
import MerchantReducer from "../slices/merchantSlice";
import ProductReducer from "../slices/productSlice";
import StoreReducer from "../slices/storeSlice";
import OfferReducer from "../slices/OfferSlice";
import AdminReducer from "../slices/adminSlice";
import SearchReducer from "../slices/searchSlice";

export default combineReducers({
    message: MessageReducer,
    auth: AuthReducer,
    home: HomeReducer,
    header: HeaderReducer,
    category: CategoryReducer,
    subcategory: SubCategoryReducer,
    merchant: MerchantReducer,
    product: ProductReducer,
    store: StoreReducer,
    offer: OfferReducer,
    admin: AdminReducer,
    search: SearchReducer,
});