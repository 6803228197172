import Layout from "../components/shop/common-layout";

const TermsOfUse = () => {
  return (
    <Layout title="terms of use" parent="home">
      <div className="content-container container">
        <section className="page-content terms__ofuse">
          <p>
            <b>SERVICE TERMS AND CONDITIONS</b>
            <br />
            <br />
            Welcome to ONMEE. This document is an electronic record in terms of
            Information Technology Act, 2000 and published in accordance with
            the provisions of Rule 3 of the Information Technology
            (Intermediaries guidelines) Rules, 2011 that require publishing the
            rules and regulations, privacy policy and Terms of Use for access or
            usage of ONMEE marketplace platform - www.onmee.in (hereinafter
            referred to as " Platform/Website/Application") The Platform is
            owned by M/s.Cimani Innovation Pvt. Ltd., having its registered
            office at Nanjareddy Colonyhal, 3rd Stage, Bangalore - 560071.
          </p>
          <p>
            Your use of the ONMEE and its services are governed by the following
            terms and conditions ("Terms of Use") as applicable to M/s.Cimani
            Innovation Pvt. Ltd including the applicable policies which are
            incorporated herein by way of reference. These Terms of Use apply to
            Your visit to and use of the Website/Platform whether through a
            computer or a mobile phone, the Service and the Application, as well
            as to all information, recommendations and or services provided to
            You on or through the Website, the Service and the Application. By
            availing any of our Service or by clicking on the "I ACCEPT" button,
            You are consenting to be bound by these Terms of Use. You
            acknowledge and admit that you have read and understood all these
            Terms of Use before you use the Platform. If You do not accept any
            of the Terms of Use, then please do not use the Platform or avail
            any of the services being provided therein.
          </p>
          <p>
            YOUR AGREEMENT TO THESE TERMS OF USE SHALL OPERATE AS A BINDING
            AGREEMENT BETWEEN YOU AND M/s.CIMANI INNOVATION PVT. LTD., IN
            RESPECT OF THE USE AND SERVICES OF THE WEBSITE/PLATFORM. Your
            acceptance of the Terms of Use shall be deemed to include your
            acceptance of the privacy policy and all other operating rules,
            policies, and procedures that may be published/amended/updated on
            the Website/Application. By accepting these Terms of Use, you also
            allow ONMEE to send you promotional emails and SMS alerts from time
            to time. For the purpose of these Terms of Use, wherever the context
            so requires "You" or "User" shall mean any natural or legal person
            who has agreed to become a buyer on the Platform by providing data
            while registering on the Platform as Registered User. The term
            "ONMEE","We","Us","Our" shall mean M/s.Cimani Innovation Pvt. Ltd
            and its affiliates. When You use any of the services provided by Us
            through the Platform, including but not limited to, (e.g. Product
            Reviews, Seller Reviews), You will be subject to the rules,
            guidelines, policies, terms, and conditions applicable to such
            service, and they shall be deemed to be incorporated into this Terms
            of Use and shall be considered as part and parcel of this Terms of
            Use. We reserve the right, at Our sole discretion, to change,
            modify, add or remove portions of these Terms of Use, at any time
            without any prior written notice to You. You shall ensure to review
            these Terms of Use periodically for updates/changes. Your continued
            use of the Platform following the posting of changes will mean that
            You accept and agree to the revisions. As long as You comply with
            these Terms of Use, We grant You a personal, non-exclusive,
            non-transferable, limited privilege to enter and use the Platform.
          </p>
          <p>
            <b>1. DEFINITIONS</b>
          </p>
          <p>
            All the defined and capitalized terms in these Terms of Use will
            have the meaning assigned to them here below:
            <br />
            i. "Account" shall mean the account created by You/the Customer on
            the Application/Platform for availing the Services provided by
            ONMEE.
            <br />
            ii. "Applicable Laws" shall mean the laws of India and include all
            applicable statutes, enactments, acts of legislature or parliament,
            laws, ordinances, rules, by-laws, regulations, notifications,
            guidelines, policies, directions, directives and orders of any
            governmental authority, tribunal, or a court of India.
            <br />
            iii. "Application" shall mean the application named "ONMEE", updated
            by M/s.Cimani Innovation Pvt. Ltd from time to time.
            <br />
            iv. "Customer/You" means a person/user who is a registered user and
            has an Account on the Application/Website.
            <br />
            v. "Force Majeure Event" shall mean any event arising due to any
            cause beyond the reasonable control of ONMEE. <br />
            vi. "ONMEE" or "Us" or "We" or "Our" shall mean M/s.Cimani
            Innovation Pvt. Ltd.., a company incorporated under the Companies
            Act 2013, and having its registered office at Nanjareddy Colonyhal,
            3rd Stage, Bangalore - 560071 , which expression shall, unless it be
            repugnant to the context or meaning thereof, be deemed to mean and
            include all its successors, affiliates and permitted assigns. <br />
            vii. "Registration Data" shall mean and include providing and
            maintaining the present, valid, true, complete, current and updated,
            accurate name, email ID, phone number and such other information, as
            may be required by ONMEE from the Customer from time to time for
            registration on the Platform. <br />
            viii. "Service(s)" means the services offered by ONMEE through the
            Platform/Website/Application, as elaborated in Clause 5 below.{" "}
            <br />
            ix. "Website" shall mean the Application and the
            website www.onmee.in operated by ONMEE or any other software that
            enables the use of the Application or such other URL as may be
            specifically provided by ONMEE. <br />
            x. “Terms of Use" shall mean these Customer/User terms and
            conditions.
          </p>
          <p>
            <b>2. ABOUT ONMEE:</b>
          </p>
          <p>
            ONMEE is a marketplace/ ecommerce platform that allows
            Users/Customers to view Store details, view offers, discounts,
            advertisements, purchase Products and/or avail Services in various
            locations. The actual contract for sale is directly between the
            seller and buyer. ONMEE is not a party to the transaction. ONMEE is
            only a facilitator and is not and cannot be a party to or control in
            any manner any transactions on ONMEE.
          </p>
          <p>
            <b>3. ELIGIBILITY</b>
          </p>
          <p>
            You shall be "Eligible" to use the Services only when You fulfill
            all of the following conditions: i. You have attained at least 18
            (eighteen) years of age. ii. You are a legal person duly registered
            under law or as an entity or a person have the legal capacity/ are
            competent to enter into a contract under the Applicable Laws. iii.
            If You reside in a jurisdiction that restricts the use of the
            Service because of age, or restricts the ability to enter into
            contracts such as this Terms of Use due to age, then You shall abide
            by the same and you are not authorized to use the Services.
          </p>
          <p>
            <b>4. ACCOUNT REGISTRATION:</b>
          </p>
          <p>
            i. Your Registration Obligations: You understand and acknowledge
            that You can register on the Website only after complying with the
            requirements of this Clause 4 and by entering Your Registration
            Data. You shall ensure that the Registration Data provided by You is
            accurate, complete, current, valid and true and is updated from time
            to time. We shall bear no liability for false, incomplete, old or
            incorrect Registration Data provided by You.
          </p>

          <p>
            ii. User Account, Password and Security: You are solely responsible
            for maintaining the confidentiality of Your Registration Data
            including your user account and password and shall be liable for all
            activities and transactions that occur through Your Account, whether
            initiated by You or any third party. Your Account cannot be
            transferred, assigned or sold to a third party. We shall not be
            liable for any loss that You may incur as a result of someone else
            using Your password or Account, either with or without Your
            knowledge. You agree to (a) immediately notify ONMEE of any
            unauthorized use of your password or account or any other breach of
            security, and (b) ensure that you sign out from your account at the
            end of each session when accessing the Services.
          </p>

          <p>
            iii. We reserve the right to suspend or terminate Your Account with
            immediate effect and for an indefinite period, if We have a reason
            to believe that the Registration Data or any other data provided by
            You is incorrect or false, or that the security of Your Account has
            been compromised in any way, or for any other reason We may find
            just or equitable. You agree that ONMEE will not be liable to You or
            to any third party for any modification, suspension, interruption or
            discontinuance of the Service offered under the Website/Application.
          </p>

          <p>
            iv. Except for the Registration Data or any other data submitted by
            You during the use of any other service offered through the Platform
            ("Permitted Information"), ONMEE does not want You to, and You shall
            not, send any confidential or proprietary information to ONMEE on
            the Website or otherwise, unless otherwise is required by Applicable
            Laws. In accepting these Terms of Use You agree that any information
            or materials that You or individuals acting on Your behalf provide
            to ONMEE other than the Permitted Information will not be considered
            confidential or proprietary.
          </p>

          <p>
            v. You shall ensure that You download the correct Application and
            version for Your device. We are not liable if You do not have a
            compatible mobile device or if You download the wrong version of the
            Application for Your mobile device. We reserve the right to
            terminate the Service and the use of the Application should You be
            using the Service or Application with an incompatible or
            unauthorized device.
          </p>

          <p>
            vi. You shall open only one Account in association with the
            Registration Data provided by You. In case of any unauthorized use
            of Your Account please immediately reach Us at ________________{" "}
          </p>

          <p>
            vii. In case, You are unable to access Your Account, please inform
            Us at_______________ and make a written request for blocking Your
            Account. We shall not be liable for any unauthorized transactions
            made through Your Account prior to the expiry of 72 (seventy two)
            hours after You have made a request in writing for blocking Your
            Account, and shall not have any liability in case of Force Majeure
            Event.{" "}
          </p>

          <p>
            <b>5. SERVICES: </b>
          </p>
          <p>
            ONMEE provides a number of Internet-based services through the
            Platform. The Service enables You/Users to view Store details,
            offers, discounts, advertisements and/or purchase products/avail
            services (collectively, "Products/Services"). The Products may also
            be purchased through the Platform through various methods of
            payments offered. The sale/purchase of Products through the Platform
            shall be additionally governed by specific policies of sale. In
            addition, these Terms of Use may be further supplemented by Product
            specific conditions, which may be displayed along with that Product.
            ONMEE does not warrant that Product description or other content on
            the Platform is accurate, complete, reliable, current, or error-free
            and assumes no liability in this regard. By using the Application or
            the Service, You further agree that:
          </p>

          <p>
            i. You shall use the Service or download the Application for Your
            sole, personal use and will not resell or assign it to a third
            party;
          </p>

          <p>
            ii. You shall not use an account that is subject to any rights of a
            person other than You without appropriate authorization;{" "}
          </p>

          <p>
            iii. You shall not use the Service or Website for unlawful purposes;
            iv. You shall not try to directly/indirectly interfere with or
            disrupt the Service, Website or servers or our network in any way
            whatsoever. Further , you shall not transmit or otherwise upload any
            content that (i) infringes any intellectual property or other
            proprietary rights of any party; (ii) you do not have a right to
            upload under any law or under contractual or fiduciary
            relationships; (iii) contains software viruses or any other computer
            code, files or programs designed to interrupt, destroy or limit the
            functionality of any computer software or hardware or
            telecommunications equipment v. You acknowledge and agree to provide
            ONMEE with such information and documents which ONMEE may request
            form time to time; vi. You shall not use an unauthorized network to
            avail the Service;
          </p>

          <p>
            vii. You shall at all times comply with all Applicable Law from Your
            country of domicile and residence and the country, state and/or city
            in which You are present while using the Website/Application or
            Service; and
          </p>

          <p>
            viii. You acknowledge and agree that you shall comply with the
            Information Technology Act, 2000 and the rules, regulations and
            guidelines notified thereunder from time to time. ix. ONMEE reserves
            the right to immediately terminate the Service and the use of the
            Application in the event of non-compliance with any of the above
            requirements. Further, ONMEE may store the information provided by
            You or record your calls for all Service related matters. You shall
            promptly inform ONMEE on any change in the information provided by
            You. x. ONMEE or an authorised representative of ONMEE, may provide
            information regarding services, discounts and promotions provided by
            ONMEE to You by way of an SMS or email to Your registered mobile
            number/registered email ID. However, You may choose to opt-out of
            certain communications from ONMEE including standard newsletters or
            advertising messages, promotional messages/offers at any point of
            time. xi. ONMEE shall be entitled to collect, process and transfer
            your information as and when it deems fit subject to the Applicable
            Laws and it may store or transfer your information in order to
            perform ONMEE’s obligations under these Terms of Use.{" "}
          </p>

          <p>
            xii. You hereby grant ONMEE and and its affiliated companies a
            non-exclusive, worldwide, perpetual, irrevocable, royalty-free,
            sub-licensable (through multiple tiers) right to exercise the
            copyright, publicity, database rights or any other rights You have
            in your information, in any media now known or not currently known,
            with respect to Your information. YOU AGREE AND PERMIT ONMEE TO
            SHARE YOUR INFORMATION AND/OR PERMITTED INFORMATION, WITH THIRD
            PARTIES SUBJECT TO APPLICABLE LAWS.
          </p>

          <p>
            xiii. ONMEE shall be entitled to enter into any tie-up in terms of
            joint-venture or otherwise with any other institution engaged in the
            business of providing services analogous and/or similar to those
            herein contained. In such case, depending upon the modality and the
            mechanism as may be devised, You will be provided with the services
            by ONMEE jointly and/or severally with the party/ies in joint
            venture. You hereby give Your irrevocable consent and permission to
            such a tie-up. In the event of such a tie-up, the terms and
            conditions herein contained will, mutatis mutandis, become
            applicable in respect of such tie-up arrangement also.{" "}
          </p>

          <p>
            <b>6. PLATFORM FOR TRANSACTION AND COMMUNICATION:</b>
          </p>
          <p>
            The Users may utilize to meet and interact with one another for
            their transactions on the Platform. ONMEE is not and cannot be a
            party to or control in any manner any transaction between the
            ONMEE's Users. Henceforward:
          </p>

          <p>
            • All commercial/contractual terms are offered by and agreed to
            between Users/Buyers and Sellers alone. The commercial/contractual
            terms include without limitation price, shipping costs, payment
            methods, payment terms, date, period and mode of delivery,
            warranties related to Products and Services and after sales services
            related to Products and Services. ONMEE does not have any control or
            does not determine or advise or in any way involve itself in the
            offering or acceptance of such commercial/contractual terms between
            the Buyers and Sellers.
          </p>

          <p>
            • ONMEE does not make any representation or Warranty as to specifics
            (such as quality, value, salability, etc) of the Products or
            Services proposed to be sold or offered to be sold or purchased on
            the Platform. • ONMEE is not responsible for any non-performance or
            breach of any contract entered into between Buyers and Sellers.
            ONMEE does not guarantee that the concerned Buyers and/or Sellers
            will perform any transaction concluded on the Platform. • At no time
            shall ONMEE hold any right, title or interest over the Products nor
            shall ONMEE have any obligations or liabilities in respect of such
            contract entered into between Buyers and Sellers.
          </p>

          <p>
            • The ONMEE is only a platform that facilitates a transaction
            between You and the Seller, which may be utilized by You/Users to
            reach a larger base to buy and sell products or services. ONMEE is
            only providing a platform for communication and it is agreed that
            the contract for sale of any of the Products/Services shall be a
            strictly bipartite contract between the Seller and the Buyer. At no
            time shall ONMEE hold any any right, title or interest over the
            products nor shall ONMEE have any obligations or liabilities in
            respect of such contract. ONMEE is not responsible for
            unsatisfactory or delayed performance of services or damages or
            delays as a result of products which are out of stock, unavailable
            or back ordered.{" "}
          </p>

          <p>
            • By accepting these Terms of Use and using the Service, You agree
            that You shall defend, and indemnify ONMEE and hold their
            affiliates, subsidiaries, group companies (as applicable) and each
            and any of its officers, licensors, directors, employees and
            representatives, agents harmless from and against any and all
            claims, costs, damages, losses, liabilities and expenses (including
            attorneys' fees and costs) arising out of or in connection with:{" "}
          </p>

          <p>
            (a) Your violation or breach of any term of these Terms of Use or
            any Applicable Law or regulation, whether or not referenced herein;
          </p>
          <p>
            (b) Your violation of any rights of any third party, including the
            Sellers or{" "}
          </p>
          <p>
            (c) Your use or misuse of the Application or Service.
            Notwithstanding its reasonable efforts in that behalf, ONMEE shall
            not take responsibility or control the information provided by other
            Users which is made available on the Platform.{" "}
          </p>

          <p>
            {" "}
            <b>7. USER CONDUCT AND RULES ON THE PLATFORM: </b>
          </p>
          <p>
            You agree, undertake and confirm that Your use of the Platform shall
            be strictly governed by the following binding principles:{" "}
          </p>

          <p>
            • You shall not host, display, upload, modify, publish, transmit,
            update or share any information which:{" "}
          </p>

          <p>
            ◦ belongs to another person and to which You do not have any right
            to{" "}
          </p>

          <p>
            ◦ is grossly harmful, harassing, blasphemous, defamatory, obscene,
            pornographic, paedophilic, libellous, invasive of another's privacy,
            hateful, or racially, ethnically objectionable, disparaging,
            relating or encouraging money laundering or gambling, or otherwise
            unlawful in any manner whatever{" "}
          </p>

          <p>◦ is misleading in any way </p>

          <p>
            ◦ involves the transmission of "junk mail", "chain letters", or
            unsolicited mass mailing or "spamming"{" "}
          </p>

          <p>
            ◦ promotes illegal activities or conduct that is abusive,
            threatening, obscene, defamatory or libellous ◦ infringes upon or
            violates any third party's rights including, but not limited to,
            intellectual property rights, rights of privacy (including without
            limitation unauthorized disclosure of a person's name, email
            address, physical address or phone number) or rights of publicity{" "}
          </p>

          <p>
            ◦ contains restricted or password-only access pages, or hidden pages
            or images (those not linked to or from another accessible page){" "}
          </p>

          <p>
            ◦ provides instructional information about illegal activities such
            as making or buying illegal weapons, violating someone's privacy, or
            providing or creating computer viruses{" "}
          </p>

          <p>
            ◦ contains video, photographs, or images of another person (with a
            minor or an adult).{" "}
          </p>

          <p>
            ◦ tries to gain unauthorized access or exceeds the scope of
            authorized access to the Platform or to profiles, blogs,
            communities, account information, bulletins, friend request, or
            other areas of the Platform or solicits passwords or personal
            identifying information for commercial or unlawful purposes from
            other users{" "}
          </p>

          <p>
            ◦ interferes with another User's use and enjoyment of the Platform
            or any other individual's User and enjoyment of similar services{" "}
          </p>

          <p>
            ◦ infringes any patent, trademark, copyright or other proprietary
            rights or third party's trade secrets or rights of publicity or
            privacy or shall not be fraudulent or involve the sale of
            counterfeit or stolen products{" "}
          </p>

          <p>◦ violates any law for the time being in force </p>

          <p>
            ◦ threatens the unity, integrity, defence, security or sovereignty
            of India, friendly relations with foreign states, or public order or
            causes incitement to the commission of any cognizable offence or
            prevents investigation of any offence or is insulting any other
            nation{" "}
          </p>

          <p>
            ◦ shall not be false, inaccurate or misleading ◦ shall not create
            liability for Us or cause Us to lose (in whole or in part) the
            services of Our internet service provider ("ISPs") or other
            suppliers{" "}
          </p>

          <p>
            • A User may be considered fraudulent or loss to business due to
            fraudulent activity if any of the following scenarios are met:{" "}
          </p>

          <p>
            ◦ Users doesn't reply to the payment verification mail sent by ONMEE{" "}
          </p>

          <p>
            ◦ Users fails to produce adequate documents during the payment
            details verification ◦ Misuse of another Users's phone/email{" "}
          </p>

          <p>◦ Users uses invalid address, email and phone no. </p>

          <p>◦ Overuse of a voucher code </p>

          <p>◦ Use of a special voucher not tagged to the email ID used. </p>

          <p>◦ Users returns the wrong product </p>

          <p>◦ Users refuses to pay for an order </p>

          <p>◦ Users involved in the snatch and run of any order </p>

          <p>
            ◦ Miscellaneous activities conducted with the sole intention to
            cause loss to business/revenue to ONMEE{" "}
          </p>

          <p>◦ User with a very high return rate </p>

          <p>
            ◦ Repeated request for monetary compensation for fake/used order{" "}
          </p>

          <p>
            • ONMEE may cancel any order that classify as 'Bulk Orders'/'Fraud
            orders' under certain criteria at any stage of the product delivery.
            An order can be classified as 'Bulk Order'/'Fraud Order' if it meets
            with the below mentioned criteria, and any additional criteria as
            defined by ONMEE:{" "}
          </p>

          <p>
            ◦ Products ordered are not for self-consumption but for commercial
            resale{" "}
          </p>

          <p>
            ◦ Multiple orders placed for same product at the same address,
            depending on the product category.{" "}
          </p>

          <p>◦ Bulk quantity of the same product ordered </p>

          <p>◦ Invalid address given in order details </p>

          <p>◦ Any malpractice used to place the order </p>

          <p>
            ◦ Any promotional voucher used for placing the 'Bulk Order' may not
            be refunded{" "}
          </p>

          <p>◦ Any order paced using a technological glitch/loophole. </p>

          <p>
            • ONMEE does not facilitate business to business transaction between
            Sellers and business customers. You are advised to refrain from
            transacting on the Platform if You intend to avail the benefits of
            input tax credit.{" "}
          </p>

          <p>
            • You shall not use the Platform for any unlawful and fraudulent
            purposes, which may cause annoyance and inconvenience and abuses any
            policy and rules of the company and interrupt or causes to
            interrupt, damages the use by other Users of ONMEE.{" "}
          </p>

          <p>
            • You shall not use any false e-mail address, impersonates any
            person or entity, or otherwise misleads ONMEE by sharing multiple
            address and phone numbers or transacting with malafide intentions.{" "}
          </p>

          <p>
            • You shall not use any "deep-link", "page-scrape", "robot",
            "spider" or other automatic device, program, algorithm or
            methodology, or any similar or equivalent manual process, to access,
            acquire, copy or monitor any portion of the Platform or any Content,
            or in any way reproduce or circumvent the navigational structure or
            presentation of the Platform or any Content, to obtain or attempt to
            obtain any materials, documents or information through any means not
            purposely made available through the Platform. We reserve Our right
            to bar any such activity.{" "}
          </p>

          <p>
            {" "}
            • You shall not attempt to gain unauthorized access to any portion
            or feature of the Platform, or any other systems or networks
            connected to the Platform or to any server, computer, network, or to
            any of the services offered on or through the Platform, by hacking,
            password "mining" or any other illegitimate means.{" "}
          </p>

          <p>
            {" "}
            • You may not pretend that You are, or that You represent, someone
            else, or impersonate any other individual or entity{" "}
          </p>

          <p>
            {" "}
            • You shall at all times ensure full compliance with the applicable
            provisions of the Information Technology Act, 2000 and rules
            thereunder as applicable and as amended from time to time and also
            all applicable Domestic laws, rules and regulations (including the
            provisions of any applicable Exchange Control Laws or Regulations in
            Force) and International Laws, Foreign Exchange Laws, Statutes,
            Ordinances and Regulations (including, but not limited to Income
            Tax,GST, Central Excise, Custom Duty, Local Levies) regarding Your
            use of Our Service. You shall not engage in any transaction in an
            item/Product or service, which is prohibited by the provisions of
            any applicable law including exchange control laws or regulations
            for the time being in force.{" "}
          </p>

          <p>
            • You shall not engage in advertising to, or solicitation of, other
            Users of the Platform to buy or sell any products or services,
            including, but not limited to, products or services related to that
            being displayed on the Platform or related to us.{" "}
          </p>

          <p>
            {" "}
            • The content posted on the Platform (“Content”) does not reflect
            ONMEE views. In no event shall ONMEE assume or have any
            responsibility or liability for any Content posted or for any
            claims, damages or losses resulting from use of Content and/or
            appearance of Content on the Platform. You hereby represent and
            warrant that You have all necessary rights in and to all Content
            which You provide and all information it contains and that such
            Content shall not infringe any proprietary or other rights of third
            parties or contain any libellous, tortious, or otherwise unlawful
            information.{" "}
          </p>

          <p>
            • ONMEE hereby disclaims any guarantees of exactness as to the
            finish, appearance, size, color etc., of the Product as ordered by
            the User. ONMEE may offer You Return & Exchange Policy for certain
            Products purchased through this Platform, wherein ONMEE may provide
            You an option to return or exchange Products purchased on ONMEE
            within the return/exchange period.{" "}
          </p>

          <p>
            <b>8. CONTENTS POSTED ON PLATFORM:</b>
          </p>

          <p>
            All text, graphics, user interfaces, visual interfaces, photographs,
            trademarks, logos, sounds, music and artwork (collectively,
            "Content"), is a third party user generated content, which ONMEE has
            no control over such third party user generated content as ONMEE is
            merely an intermediary for the purposes of this Terms of Use. Such
            Content will become Our property and You grant Us the worldwide,
            perpetual and transferable rights in such Content. We shall be
            entitled to, consistent with Our Privacy Policy as adopted in
            accordance with applicable law, use the Content or any of its
            elements for any type of use forever, including but not limited to
            promotional and advertising purposes and in any media whether now
            known or hereafter devised, including the creation of derivative
            works that may include the Content You provide. You may use
            information on the Products/Services made available on the Platform
            for downloading, provided that You
          </p>

          <p>
            • do not remove any proprietary notice language in all copies of
            such documents,
          </p>

          <p>
            • use such information only for your personal, non-commercial
            informational purpose and do not copy or post such information on
            any networked computer or broadcast it in any media,
          </p>

          <p>• make no modifications to any such information, and</p>

          <p>
            • do not make any additional representations or warranties relating
            to such documents.
          </p>

          <p>
            <b>9. VIOLATION OF TERMS OF USE:</b>
          </p>

          <p>
            You shall not perform any activity that is undesirable or illegal
            under law while using the Platform or availing the Services.
            Further, You shall not act in violation of Applicable Law. In the
            event You violate any of the Terms of Use or spirit of these Terms
            of Use, or are found to be involved in the activities set out above,
            You shall be liable to pay a fine to us and we shall also have the
            right to suspend/terminate the Account and registration. In the
            event You fail to pay fine, we may at our discretion, take such
            steps as may be available to us under Applicable Law. You shall also
            be blacklisted as a result of non-payment of the fine or misbehaving
            as the case may be, and in such event, Your Account may be
            terminated by ONMEE. ONMEE reserves the right to investigate at its
            sole discretion if it reasonably believes that it is required by
            applicable law, reporting You to the law enforcement authorities or
            otherwise taking appropriate legal action for any activity which is
            considered undesirable or illegal under law.
          </p>

          <p>
            <b>10. DISCLAIMER OF WARRANTIES AND LIABILITY:</b>
          </p>

          <p>
            This Website, Application and all content on the Platform (including
            but not limited to Products/Services, included on or otherwise made
            available to You through Platform) are provided on "as is" and "as
            available" basis without any representation or warranties of any
            kind, express or implied including without limitation warranties of
            title or implied warranties of merchantability or fitness for a
            particular purpose. You acknowledge, by Your access of the Website
            and/or Application, that Your access of the Website and/or
            Application and availing of Services is at Your sole risk, that You
            assume full responsibility for Your access and use of the Platform,
            and that ONMEE shall not be liable for any damages of any kind
            related to Your access and use of this Website and/or Application.
            Without prejudice to the forgoing paragraph, ONMEE does not warrant
            that: Platform will be constantly available, or available at all or
            The information on Platform is complete, true, accurate or
            non-misleading. We do not authorize anyone to make a warranty on Our
            behalf and You shall not rely on any statement of warranty as a
            warranty by Us. All the Products sold on Platform are governed by
            different state laws and if Seller is unable to deliver such
            Products due to implications of different state laws, Seller will
            return or will give credit for the amount (if any) received in
            advance by Seller from the sale of such Product that could not be
            delivered to You. You will be required to enter a valid phone number
            while placing an order on the Platform. By registering Your phone
            number with us, You consent to be contacted by Us via phone calls
            and/or SMS notifications, in case of any order or shipment or
            delivery related updates. All images, audio, video and text in the
            Website and/or Application are only for illustrative purposes. None
            of the models, actors in the images, if any are endorsing the
            Products/Services in any manner. Any resemblance of any matter to
            anybody or anything is purely unintentional and/or coincidental.
          </p>

          <p>
            <b>11. E-PLATFORM FOR COMMUNICATION:</b>
          </p>

          <p>
            You agree, understand and acknowledge that ONMEE is an electronic
            platform that facilitates Users/You to view Store details, view
            offers and discounts, purchase Products and/or avail Services listed
            on the Platform at the price indicated therein at any time. You
            further agree and acknowledge that ONMEE is only a facilitator and
            is not a party to or control in any manner any transactions on
            ONMEE. Further, ONMEE does not guarantee or provide assurance in
            respect of the actions, behavior of Seller on the Website/Platform.
          </p>

          <p>
            <b>12. INDEMNITY:</b>
          </p>
          <p>
            By accepting these Terms of Use and using the Service, You agree
            that You shall indemnify and hold harmless ONMEE, its licensee,
            affiliates, subsidiaries, group companies (as applicable) and their
            officers, directors, agents, and employees, from any claim, costs,
            damages or demand, or actions including reasonable attorneys' fees,
            made by any third party or penalty imposed due to or arising out of
            Your breach of this Terms of Use, Privacy Policy and other Policies,
            or Your violation of any law, rules or regulations or the rights
            (including infringement of intellectual property rights) of a third
            party.
          </p>

          <p>
            <b>13. TRADEMARK, COPYRIGHT AND RESTRICTION:</b>
          </p>

          <p>
            Platform is controlled and operated by ONMEE and Products, offers
            and Services are provided by respective Sellers/Retailers. All
            material on Platform, including images, illustrations, audio clips,
            and video clips, are protected by copyrights, trademarks, and other
            intellectual property rights. Material on ONMEE is solely for Your
            personal, non-commercial use. You shall not copy, reproduce,
            republish, upload, post, transmit or distribute such material in any
            way, including by email or other electronic means and whether
            directly or indirectly and You must not assist any other person to
            do so. Without the prior written consent of the owner, modification
            of the materials, use of the materials on any other ONMEE or
            networked computer environment or use of the materials for any
            purpose other than personal, non-commercial use is a violation of
            the copyrights, trademarks and other proprietary rights, and is
            prohibited. Any use for which You receive any remuneration, whether
            in money or otherwise, is a commercial use for the purposes of this
            clause. It is expressly clarified that You will retain ownership and
            shall solely be responsible for any content that You provide or
            upload when using any Service, including any text, data,
            information, images, photographs, music, sound, video or any other
            material which you may upload, transmit or store when making use of
            Our various Service. However, We reserve the right to use/reproduce
            any content uploaded by You and You agree to grant royalty free,
            irrevocably, unconditionally, perpetually and worldwide right to Us
            to use the content for reasonable business purpose.
          </p>

          <p>
            <b>14. LIMITATION OF LIABILITY: </b>
          </p>

          <p>
            ONMEE shall not be liable for any damages resulting from the use of
            or inability to use the Website/Application, including damages
            caused by wrong usage of the Platform, error in call centre number,
            network issues, malware, viruses or any incorrectness or
            incompleteness of the Information on the
            Application/Website/Platform . IN NO EVENT SHALLONMEE BE LIABLE FOR
            ANY INDIRECT, PUNITIVE, INCIDENTAL, SPECIAL, CONSEQUENTIAL DAMAGES
            OR ANY OTHER DAMAGES WHATSOEVER, WHETHER BASED ON CONTRACT, TORT,
            NEGLIGENCE, STRICT LIABILITY OR OTHERWISE, EVEN IF ONMEE HAS BEEN
            ADVISED OF THE POSSIBILITY THEREOF WHICH HAS RESULTED FROM:{" "}
          </p>

          <p>• the use or the inability to use the Services or Products </p>

          <p>
            • unauthorized access to or alteration of the user's transmissions
            or data{" "}
          </p>

          <p>
            • breach of condition, representations or warranties by the
            manufacturer of the Products{" "}
          </p>

          <p>
            • any other matter relating to the Services/Products including,
            without limitation, damages for loss of use, data or profits,
            arising out of or in any way connected with the use or performance
            of the Platform or Service. ONMEE shall not be held responsible for
            non-availability of the ONMEE during periodic maintenance operations
            or any unplanned suspension of access to the ONMEE. The User
            understands and agrees that any material and/or data downloaded at
            ONMEE is done entirely at Users own discretion and risk and they
            will be solely responsible for any damage to their mobile or loss of
            data that results from the download of such material and/or data. To
            the maximum extend that is permissible under law, ONMEE’s liability
            shall be limited to an amount equal to the Products purchased value
            bought by You. ONMEE shall not be liable for any dispute or
            disagreement between Users{" "}
          </p>

          <p>
            <b>15. CONFLICT </b>
          </p>
          <p>
            In the event ofany contradiction or inconsistency between this Terms
            of Use and any other agreement executed between You and ONMEE, the
            terms of the Terms of Use shall prevail unless the exception has
            been expressly agreed to in writing by making reference to the
            relevant Clause sought to be modified under this Terms of Use.
          </p>

          <p>
            <b>16. TERMINATION: </b>
          </p>
          <p>
            ONMEE may suspend or terminate your use of the ONMEE or any Service
            if it believes, in its sole and absolute discretion that you have
            infringed, breached, violated, abused, or unethically manipulated or
            exploited any term of these Terms of Use or anyway otherwise acted
            unethically. Unless terminated in accordance with this Clause 16,
            the agreement between ONMEE and You is perpetual in nature upon
            downloading the Application, which shall survive indefinitely unless
            and until terminated. You may terminate the agreement at all times
            by deletion of Your Account, thus disabling the use by You of the
            Platform. You can close Your Account at any time by following the
            instructions on the Website. Termination of this agreement will not
            prejudice accrued rights of either ONMEE or You. If You or ONMEE
            terminates your use of the Platform or any Service, ONMEE may delete
            any content or other materials relating to your use of the Service
            and ONMEE will have no liability to you or any third party for doing
            so. However, your transactions details may be preserved by ONMEE for
            purposes of tax or regulatory compliance. ONMEE may unilaterally
            terminate Your account on any event as mentioned in the Terms Of
            Use. Any pending refunds if any will be forfeited in such case.
            Returns/Refund for such Users shall be at the sole discretion of
            ONMEE. If You use any false e-mail address or use the Website for
            any unlawful and fraudulent purposes, which may cause annoyance and
            inconvenience and abuses any policy and rules of the company or
            mislead ONMEE by sharing multiple address and phone numbers or
            transacting with malafide intentions then ONMEE reserves the right
            to refuse access to the portal, terminate accounts including any
            linked accounts without notice to you.
          </p>

          <p>
            <b>17. INVALIDITY OF ONE OR MORE PROVISIONS</b>
          </p>
          <p>
            The invalidity of any term of these Terms of Use shall not affect
            the validity of the other provisions of these Terms of Use. If and
            to the extent that any provision of these Terms of Use is invalid,
            or is unacceptable in the given circumstances, a provision shall
            apply between the parties instead that is acceptable considering all
            the circumstances, taking into account the content and the purpose
            of these Terms of Use.{" "}
          </p>

          <p>
            <b>18. APPLICABLE LAW & DISPUTE</b>
          </p>
          <p>
            RESOLUTION The Terms of Use shall be governed by and construed in
            accordance with the laws of India. Any dispute, claim or controversy
            arising out of or relating to these Terms of Use or the breach,
            termination, enforcement, interpretation or validity thereof or the
            use of the Website, the Service or the Application
            (collectively, "Disputes") the parties shall attempt to settle the
            same amicably, through negotiation and consultation at such offices
            of ONMEE as ONMEE may designate. In the event the dispute is not
            resolved internally between after at least 30 (thirty) days of
            negotiation, in good faith, the same shall be subject to binding and
            final arbitration in accordance with the Arbitration and
            Conciliation Act, 1996 as amended from time to time or in case the
            Arbitration and Conciliation Act, 1996 is no longer in force, as per
            any law relating to arbitration in force at the time of such
            reference. The reference shall be made to a sole arbitrator mutually
            appointed by ONMEE and You. The place of the arbitration shall be
            Bengaluru, Karnataka, unless otherwise mutually agreed by ONMEE and
            You in writing. Subject to the above, any Dispute will be subject to
            the exclusive jurisdiction of courts in Bangalore, India.
          </p>

          <p>
            <b>19. CONTACTING THE SELLER:</b>
          </p>
          <p>
            In the event that You wish to contact ONMEE about the seller, You
            may proceed to do so by clicking on the seller name on the product
            listing pages. Alternatively, You may also reach out to customer
            support at _______________.
          </p>

          <p>
            <b>20. DISCLAIMER: </b>
          </p>
          <p>
            You acknowledge and undertake that you are accessing the services on
            the Platform and transacting at your own risk and are using your
            best and prudent judgment before entering into any transactions
            through ONMEE. We shall neither be liable nor responsible for any
            actions or inactions of sellers nor any breach of conditions,
            representations or warranties by the sellers or manufacturers of the
            Products and hereby expressly disclaim and any all responsibility
            and liability in that regard. We shall not mediate or resolve any
            dispute or disagreement between You and the sellers or manufacturers
            of the Products. We further expressly disclaim any warranties or
            representations (express or implied) in respect of quality,
            suitability, accuracy, reliability, completeness, timeliness,
            performance, safety, merchantability, fitness for a particular
            purpose, or legality of the Products listed or displayed or
            transacted or the content (including product or pricing information
            and/or specifications) on Platform. While we have taken precautions
            to avoid inaccuracies in content, this Website, all content,
            information (including the price of products), Products, Services
            and related graphics are provided as is, without warranty of any
            kind. At no time shall any right, title or interest in the products
            sold through or displayed on Platform vest with ONMEE nor shall
            ONMEE have any obligations or liabilities in respect of any
            transactions on Platform.
          </p>

          <p>
            <b>21. NOTICE</b>
          </p>
          <p>
            ONMEE may give notice by means of a general notice on the Service or
            Application, or by electronic mail to Your email address or a
            message on Your registered mobile number, or by written
            communication sent by regular mail to Your address on record in
            ONMEE’s account information. You may contact ONMEE by electronic
            mail at Our email address __________________or by written
            communication sent by regular mail to Our address at Nanjareddy
            Colonyhal, 3rd Stage, Bangalore - 560071{" "}
          </p>

          <p>
            <b>22. ASSIGNMENT </b>
          </p>
          <p>
            Youshall not assign Your rights/obligations under these Terms of Use
            without prior written approval of ONMEE. ONMEE can assign its rights
            and obligations under the Terms of Use to any third party.{" "}
          </p>

          <p>
            <b>23. MODIFICATION OF THE SERVICE AND TERMS OF USE </b>
          </p>
          <p>
            ONMEE reserves the right, at its sole discretion, to modify or
            replace, in part or full, any of these Terms of Use, or change,
            suspend, block, discontinue or restrict your use to all or any
            feature of the Service or Application at any time. ONMEE shall not
            be required to notify You of any changes made to these Terms of Use.
            The revised Terms of Use shall be made available on the Website. You
            are requested to regularly visit the Website to view the most
            current Terms of Use. You can determine when ONMEE last modified the
            Terms of Use by referring to the "Last Updated" legend above. It
            shall be Your responsibility to check these Terms of Use
            periodically for changes. ONMEE may require You to provide Your
            consent to the updated Terms of Use in a specified manner prior to
            any further use of the Website and the Services. If no such separate
            consent is sought, Your continued use of the Website, following the
            changes to the Terms of Use, will constitute Your acceptance of
            those changes. Your use of the Website and the Services is subject
            to the most current version of the Terms of Use made available on
            the Website at the time of such use.{" "}
          </p>

          <p>
            <b>24. FORCE MAJEURE</b>
          </p>
          <p>
            ONMEE shall not be liable for any failure or delay to perform any
            obligations under this Terms of Use, if the performance is
            prevented, hindered or delayed by a Force Majeure Event such as
            strike, riot, fire, epidemic, flood, natural disaster, or other
            similar cause beyond ONMEE's control and in such case our
            obligations under this Terms of Use shall be suspended for so long
            as the Force Majeure Event continues.{" "}
          </p>

          <p>
            <b>25. CUSTOMER RELATIONSHIP MANAGEMENT</b>
          </p>
          <p>
            Any questions, issues, opinions, suggestions, concerns or feedback
            regarding the Services shall be communicated to us through email
            address mentioned in Clause 21. You agree to be fair, accurate and
            non-disparaging while leaving comment, feedbacks, testimonials or
            reviews on or about the Products or Services. Reporting of any issue
            shall be within 5(five) days of the happening of the issue, failing
            which, such issue will not be addressed. Any issue reported on
            channels other than the above may be addressed by ONMEE only on a
            best-effort basis. ONMEE takes no liability for inability to get
            back on other channels. ONMEE shall endeavor to respond to Your
            issues within 5 working days of Your reporting the same and endeavor
            to resolve it at the earliest possible. It is hereby clarified that
            issues are resolved on severity basis, and certain may be resolved
            earlier than the other. However, ONMEE shall not be liable for any
            damages or losses in the event You are not satisfied with any such
            resolution. Where You avail of any Services/Products offered by
            Sellers on our Website, we shall re-direct all Your issues,
            opinions, suggestions, questions and feedback to the
            Sellers/Retailers.
          </p>

          <p>
            <b>26. CART NOTIFICATION </b>
          </p>
          <p>
            You understand that, in the event selected products in your cart is
            out of the inventory, ONMEE provides You an option in your cart to
            select the product of another seller, You understand that price of
            the product by another seller may differ.{" "}
          </p>

          <p>
            <b>27. MULTIPLE SELLERS </b>
          </p>
          <p>
            You understand that Products of a particular specification may be
            sold on the Platform by multiple sellers and the Product price on
            the listing page of the Platform, may not always reflect the lowest
            price for that particular specification. This is because the seller
            whose price is displayed on the list page is selected based on the
            application of a number of parameters and price is only one such
            parameter. However, once you land on the product display page on the
            Platform for a particular specification, You will have access to the
            price offered by all sellers on the Platform for the relevant
            specification.{" "}
          </p>

          <p>
            <b>28. SERVICE FEE </b>
          </p>
          <p>
            Subject to the Terms of Use and the below terms and conditions,
            ONMEE may levy a service fee/fee on orders on the ONMEE Platform.
            The fee shall be charged on the overall order value. ONMEE reserves
            the right to modify/waive off this Fee from time to time. You shall
            be solely responsible for compliance of all applicable laws for
            making payment of Fees to ONMEE.{" "}
          </p>

          <p>
            <b>29. GRIEVANCE </b>
          </p>
          <p>
            OFFICER In accordance with Information Technology Act 2000 and rules
            made there under and the Consumer Protection (E-Commerce) Rules,
            2020, the name and contact details of the Grievance Officer are
            provided below:
            <br />
            <br /> Mr. /Ms.__________ <br />
            Designation: ______________ <br />
            Contact us: __________________ <br />
            Phone:_____________
          </p>
        </section>
      </div>
    </Layout>
  );
};

export default TermsOfUse;
