import React, { useState, useContext } from "react";
import { Collapse } from "reactstrap";
import { Link } from "react-router-dom";
const Category = ({ items, acher, maincatid, subObj }) => {
  // console.log(items, "category");
  const listItems =
    items != null
      ? items.map((item) => {
          return (
            <li className="p-0" key={item._id}>
              <Link
                to={
                  "/category/" +
                  maincatid +
                  "?subCatId=" +
                  subObj +
                  "&childCatId=" +
                  item._id
                }
                className={
                  item._id === acher ? "active_anchor" : "ONM_child_cat_filter"
                }
              >
                {item.name}
              </Link>
            </li>
          );
        })
      : null;
  const context = [];
  const [isCategoryOpen, setIsCategoryOpen] = useState(true);
  const toggleCategory = () => setIsCategoryOpen(!isCategoryOpen);
  const setSelectedCategory = context.setSelectedCategory;
  const [url, setUrl] = useState();

  const updateCategory = (category) => {
    setSelectedCategory(category);
  };

  return (
    <>
      <div className="collection-collapse-block open">
        <h3 className="collapse-block-title" onClick={toggleCategory}>
          Category
        </h3>
        <Collapse isOpen={isCategoryOpen}>
          <div className="collection-collapse-block-content">
            <div className="collection-brand-filter">
              <ul className="category-list">{listItems}</ul>
            </div>
          </div>
        </Collapse>
      </div>
    </>
  );
};

export default Category;
