import { useState } from "react";
import { FaTimes, FaMapMarker, FaMapMarkedAlt } from "react-icons/fa";
import Modal from "react-modal";
import MapContainer from "./MapComponent";

const ModalMap = (props) => {
  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      zIndex: "999",
    },
  };

  Modal.setAppElement("#root");
  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  return (
    <>
      {  !props.button || props.button == "icon" ? (
        <FaMapMarker
          size={14}
          className="mr-2"
          style={{ cursor: "pointer" }}
          onClick={() => openModal()}
        ></FaMapMarker>
      ) : null }
      {props.button == "button" && (
        <a className="ONM_clear-filter" onClick={() => openModal()}>
          Map
        </a>
      )}
      {props.button == "link" && (
        <a className="open-map" onClick={() => openModal()}>
          <FaMapMarkedAlt /> Map
        </a>
      )}
      
      <Modal isOpen={isModalOpen} style={customStyles} contentLabel="Map">
        <FaTimes onClick={closeModal} className="float-right mb-2"></FaTimes>
        {props.item && props.item.userId && props.item.userId.loc ? (
          <MapContainer item={props.item.userId.loc.coordinates}></MapContainer>
        ) : null}
      </Modal>
    </>
  );
};

export default ModalMap;
