import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getCustomers,
  getCustomersCount,
  updateCustomersPagination,
  tobeDeletedCustomer,
  deleteCustomerObj,
} from "../../slices/adminSlice";

import { FaTimes, FaEye } from "react-icons/fa";
import Pagination from "react-js-pagination";

import Modal from "react-modal";

import { FaTrash } from "react-icons/fa";

const AdminCustomer = (props) => {
  const adminState = useSelector((state) => state.admin);
  const dispatch = useDispatch();
  const [customerObj, setCustomer] = useState(null);

  const [deleteCustomerId, setDeleteCustomer] = useState(null);

  useEffect(() => {
    dispatch(getCustomers());
    dispatch(getCustomersCount());
  }, [dispatch]);

  const handlePageChange = (pageNumber) => {
    dispatch(updateCustomersPagination(pageNumber));
    dispatch(getCustomers());
  };
  
  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      zIndex: "999",
      maxWidth: "90%",
      maxHeight: "90%",
    },
  };

  Modal.setAppElement("#root");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isUserModalOpen, setIsUserModalOpen] = useState(false);

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const openUserModal = (obj) => {
    setCustomer(obj);
    setIsUserModalOpen(true);
  };

  const closeUserModal = () => {
    setIsUserModalOpen(false);
  };


  const deleteCustomer = (id) => {
    openModal();
    setDeleteCustomer(id);
    dispatch(tobeDeletedCustomer(id));
  };

  const deleteAndCloseModal = () => {
    dispatch(deleteCustomerObj(deleteCustomerId));
    setDeleteCustomer(null);
    closeModal();
    dispatch(getCustomers());
    dispatch(getCustomersCount());
  };

  const formatAddress = (address) =>{

    let name = address.firstName+ ' ' +address.lastName;
    let company = address.company;
    let address1 = address.address;
    let address2 = address.address2;
    let phone = address.phone;
    let state = address.state;
    let city = address.city;
    let country = address.country;
    let postalCode = address.postalCode;

    let printCompany = company ? `(${company}) ` : '';
    let printAdress2 = address2 ? `, ${address2}` : '';

    return `${name} - ${printCompany}${address1}${printAdress2}, ${city}, ${state}, ${country} ${postalCode}`;
    // return address1;
  }

  const dateFormat = (date) => {

    let options = { 
      /* weekday: 'long', */ 
      year: 'numeric', 
      month: 'long', 
      day: 'numeric',
      hour: 'numeric', // numeric, 2-digit
      minute: 'numeric', // numeric, 2-digit
      second: 'numeric', // numeric, 2-digit
    };
    return new Date( date ).toLocaleDateString("en-US", options);
  }

  return (
    <div className="col-md-12">
      <Modal isOpen={isModalOpen} style={customStyles}>
        <FaTimes
          onClick={() => closeModal()}
          className="float-right mb-2"
        ></FaTimes>
        <div className="col-md-12">
          <div className="row">
            <div className="col-md-12">
              Are you sure you want to delete the customer ?
            </div>
            <div className="col-md-12 mt-2">
              <button
                className="btn btn-danger ml-2 float-right"
                onClick={() => closeModal()}
              >
                No
              </button>
              <button
                className="btn btn-primary float-right"
                onClick={() => deleteAndCloseModal()}
              >
                Yes
              </button>
            </div>
          </div>
        </div>
      </Modal>

      <Modal isOpen={isUserModalOpen} style={customStyles}>
        <div className="col-md-12">
          <div className="row">

            {customerObj && (
                <>
                <div className="col-md-12 p-0 ONM_modal_header">
                    <h3>Customer Details</h3>
                </div>
                <div className="col-md-6">
                  <label><strong>Name:</strong> {customerObj.firstName && customerObj.firstName + ' ' +customerObj.lastName}</label>
                </div>
                <div className="col-md-6">
                  <label><strong>Email:</strong> {customerObj.email}</label>
                </div>
                <div className="col-md-6">
                    <label><strong>Phone:</strong> {customerObj.phone}</label>
                </div>
                <div className="col-md-6">
                    <label><strong>Signup Date:</strong> {dateFormat(customerObj.createdOn)}</label>
                </div>
                <div className="col-md-12">
                    <label htmlFor=""><strong>Delivery Addresses</strong></label>
                    {customerObj.deliveryAddress != null && customerObj.deliveryAddress.map((address) => (
                    <p className="delivery-address" key={address._id}>{formatAddress(address)} 
                        {/* <FaTrash
                        onClick={() => removeDeliveryAddressHandler(address._id)}
                        ></FaTrash> */}
                    </p>
                    ))}
                </div>
                <div className="col-md-12 mt-2">
                    <button
                    className="btn btn-dark ml-2 float-right"
                    onClick={() => closeUserModal()}
                    >
                    Close
                    </button>                  
                </div>
                </>
            )}
          </div>
        </div>
      </Modal>

     
        <div className="row">
            <div className="col-md-12">
            <div className="row">
                <div className="col-md-6">
                </div>
                <div className="col-md-6 text-right">
                <Pagination
                    activePage={adminState.customersFeatures.page}
                    itemsCountPerPage={adminState.customersFeatures.ps}
                    totalItemsCount={adminState.customersCount}
                    pageRangeDisplayed={5}
                    onChange={handlePageChange.bind(this)}
                    itemClass="page-item"
                    linkClass="page-link"
                    innerClass="pagination ml-auto mr-auto"
                    prevPageText="Prev"
                    nextPageText="Next"
                    hideFirstLastPages={true}
                />
                </div>
            </div>
            </div>
            <div className="table-responsive mt-2">
            <table className="table">
                <thead>
                <tr>
                    <th>Name</th>
                    <th>Email</th>
                    <th>Phone</th>
                    <th>Signup Date</th>
                    <th>Action</th>
                </tr>
                </thead>
                <tbody>
                {adminState.customersList != null &&
                adminState.customersList.length > 0
                    ? adminState.customersList.map((customer) => {
                        return (
                        <tr key={customer._id}>
                            <td>{customer.firstName ? customer.firstName + ' ' + customer.lastName : '-'}</td>
                            <td>{customer.email ? customer.email : '-'}</td>
                            <td>{customer.phone ? customer.phone : '-'}</td>
                            <td>{customer.createdOn ? dateFormat(customer.createdOn) : '-'}</td>
                            <td>
                            <FaEye
                                className="ONM_icons"
                                data-tip="View Fields"
                                onClick={() => openUserModal(customer)}
                            ></FaEye>
                            <FaTrash
                                onClick={() => deleteCustomer(customer._id)}
                                className="ONM_icons"
                            ></FaTrash>
                            </td>
                        </tr>
                        );
                    })
                    : null}
                </tbody>
            </table>
            </div>
        </div>

    </div>
  );
};

export default AdminCustomer;
